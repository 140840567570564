import { getInitials } from 'components/Chat/helper'
import React from 'react'

export interface INameInitialsProps {
  className?: string
  name: { firstName: string; lastName: string }
}

const NameInitials: React.FC<INameInitialsProps> = ({ className, name }) => {
  return (
    <span
      className={`rounded-full inline-flex justify-center items-center text-white font-semibold uppercase ${className}`}
    >
      {getInitials(name)}
    </span>
  )
}

NameInitials.defaultProps = {
  className: 'bg-tertiary h-[50px] w-[50px]',
}
export default NameInitials
