import React from 'react'

function ExclamationMarkIcon({ className = '' }) {
  return (
    <svg className={className} viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 0C6.49102 0 0 6.49102 0 14.5C0 22.509 6.49102 29 14.5 29C22.509 29 29 22.509 29 14.5C29 6.49102 22.509 0 14.5 0ZM14.5 7.25C15.5008 7.25 16.3125 8.06166 16.3125 9.0625C16.3125 10.0633 15.5008 10.875 14.5 10.875C13.4992 10.875 12.6875 10.065 12.6875 9.0625C12.6875 8.05996 13.4975 7.25 14.5 7.25ZM16.7656 21.75H12.2344C11.4867 21.75 10.875 21.1439 10.875 20.3906C10.875 19.6373 11.4839 19.0312 12.2344 19.0312H13.1406V15.4062H12.6875C11.937 15.4062 11.3281 14.7974 11.3281 14.0469C11.3281 13.2964 11.9398 12.6875 12.6875 12.6875H14.5C15.2505 12.6875 15.8594 13.2964 15.8594 14.0469V19.0312H16.7656C17.5161 19.0312 18.125 19.6401 18.125 20.3906C18.125 21.1411 17.5189 21.75 16.7656 21.75Z" />
    </svg>
  )
}
ExclamationMarkIcon.defaultProps = {
  className: '',
}
export default ExclamationMarkIcon
