import { useState, useEffect, useMemo } from 'react'
import find from 'lodash/find'
import get from 'lodash/get'
import toLower from 'lodash/toLower'

export default function useIndustryContainer({
  methods,
  assessment,
  setIndustryChanged,
  title,
  titlePath,
}) {
  const [initIndustry, setInitIndustry] = useState('')

  const formValues = methods.watch()
  /**
   * Find the industry question to utilize its id while
   * comparing the selected industry option and the option
   * which was initially assigned to form.
   */
  const industryQuestion = useMemo(() => {
    const question = find(
      assessment,
      (data) => toLower(get(data?.content, titlePath)) === toLower(title)
    )
    return question
  }, [assessment, titlePath, title])

  /**
   * Finding the value and assign to state the initial form value of industry
   */
  useEffect(() => {
    if (industryQuestion && initIndustry === '') {
      const value = formValues[industryQuestion.id]
      if (value) setInitIndustry(value)
    }
  }, [initIndustry, industryQuestion, formValues])

  /**
   * set the status of industry value changed
   */
  useEffect(() => {
    if (formValues && industryQuestion) {
      const isChanged = formValues[industryQuestion.id] !== initIndustry
      setIndustryChanged(isChanged)
    }
  }, [initIndustry, setIndustryChanged, industryQuestion, formValues])
}
