import React from 'react'
import { ButtonTabsWrapper, ButtonTabWrapper } from './style'
import { AppFC } from 'types'

export interface ITabsProps {
  options: ITabOption[]
  selectedTab: ITabOption
  setSelectedTab: (opt: ITabOption) => void
  size?: 'lg' | 'base'
}

export interface ITabOption {
  label: string
  link?: string
}

const ButtonTabs = ({ options, setSelectedTab, selectedTab, size = 'base' }: ITabsProps) => {
  return (
    <ButtonTabsWrapper id="button-tabs" tabsCount={options.length}>
      {options.map((opt, index) => {
        return (
          <ButtonTab
            isSelected={opt.label === selectedTab.label}
            size={size}
            onClick={() => setSelectedTab(opt)}
            key={index}
          >
            {opt.label}
          </ButtonTab>
        )
      })}
    </ButtonTabsWrapper>
  )
}

export interface IButtonTabProps {
  isSelected: boolean
  size: 'lg' | 'base'
}

const ButtonTab: AppFC<IButtonTabProps> = ({ isSelected, children, onClick, size }) => {
  return (
    <ButtonTabWrapper onClick={onClick} isSelected={isSelected} size={size}>
      {children}
    </ButtonTabWrapper>
  )
}

export default ButtonTabs
