import { bindActionCreators, Dispatch } from 'redux'
import get from 'lodash/get'
import { connect } from 'react-redux'
import {
  getSalesChartDataAction,
  getFinanceDataAction,
  getMarketingChartDataAction,
} from 'store/actions/owner/ownerData'
import { formatPNLData } from 'components/Owner/Data/Finance/utils'
import Sales from 'components/Owner/Data/Sales'
import Finance from 'components/Owner/Data/Finance'
import Banner from 'components/Common/Banner'
import Marketing from 'components/Owner/Data/Marketing'
import FinanceBanner from 'components/Owner/Data/Finance/BannerStats'
import SmsSubscriptionModal from 'components/NotificationPanel/SmsSubscriptionModal'
import { getUserSubscriptionPrompt } from 'store/selectors/owner'
import { setShowSubscriptionPromptAction, updateUserProfileAction } from 'store/actions/user'

/* --------------------------- Owner Data --------------------------- */

const bannerProps = (state: any) => {
  return {
    businessName: state.user?.businessProfile?.businessName || 'Your Business',
    firstName: state.user.user.firstName,
  }
}

export const BannerContainer = connect(bannerProps, null)(Banner)
/* --------------------------- Marketing Data --------------------------- */

const marketingDataProps = (state: any) => {
  return {
    marketingData: state.owner.marketingData,
  }
}

function marketingDataDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchMarketingData: getMarketingChartDataAction.STARTED,
    },
    dispatch
  )
}
export const MarketingContainer = connect(marketingDataProps, marketingDataDispatch)(Marketing)

/* --------------------------- Product Data --------------------------- */
const financeDataProps = (state: any) => {
  return {
    isLoading: state.loadingStatus.financeDataLoading,
    financeData: {
      ...state.owner.financeData,
      pnlData: {
        ...get(state.owner.financeData, 'pnlData'),
        chartData: formatPNLData(get(state.owner.financeData, 'pnlData.chartData')),
      },
    },
  }
}

function financeDataDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getFinanceDataAction: getFinanceDataAction.STARTED,
    },
    dispatch
  )
}
export const FinanceContainer = connect(financeDataProps, financeDataDispatch)(Finance)
export const FinanceBannerContainer = connect(financeDataProps, financeDataDispatch)(FinanceBanner)

/* --------------------------- Sales Data --------------------------- */

const salesDataProps = (state: any) => {
  return {
    isLoading: state.loadingStatus.salesDataLoading,
    salesChartData: state.owner.salesChartData,
  }
}

function salesDataDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSalesChartDataAction: getSalesChartDataAction.STARTED,
    },
    dispatch
  )
}
export const SalesContainer = connect(salesDataProps, salesDataDispatch)(Sales)

/*---------------------------------SMS SUBSCRIPTION-----------------------*/
const SmsSubscriptionModalDataProps = (state: any) => {
  return {
    user: state.user.user,
    isUpdateProfileLoading: state?.user?.isUpdateProfileLoading,
    showSubscriptionPrompt: getUserSubscriptionPrompt(state),
  }
}

function SmsSubscriptionModalDataDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setShowSubscriptionPromptAction,
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const SmsSubscriptionModalContainer = connect(
  SmsSubscriptionModalDataProps,
  SmsSubscriptionModalDataDispatch
)(SmsSubscriptionModal)
