import React, { forwardRef, memo, ForwardRefRenderFunction, ElementType } from 'react'
import CurrencyInput from 'react-currency-input-field'
import ErrorMessage from 'components/Common/ErrorMessage'
import { FontSize } from 'types'
import { PlacesType } from 'react-tooltip'
import ToolTipWithButton from 'components/Common/ToolTipWithButton'
import InfoText from 'components/Common/InfoText'

export interface ICurrencyFieldProps {
  name?: string
  label?: string
  placeholder?: string
  value?: string
  onChange?: (event: any) => void
  defaultValue?: string
  disabled?: boolean
  autoFocus?: boolean
  hidden?: boolean
  className?: string
  id?: string
  onBlur?: (event: any) => void
  handleChange?: (event: any) => void
  onFocus?: (event: any) => void
  errors?: any
  prefix?: string
  onValueChange?: (value: any) => void
  isSubmitted?: boolean
  allowNegativeValue?: boolean
  selectedOption?: number
  Icon?: ElementType | null
  fontSize?: FontSize
  labelFontSize?: FontSize
  toolTipText?: string
  toolTipWidth?: string
  toolTipPlace?: PlacesType
  toolTipClass?: string
  toolTipOpacity?: number
  toolTipBackgroundColor?: string
  infoText?: string
}

const CurrencyInputField: ForwardRefRenderFunction<any, ICurrencyFieldProps> = (
  {
    name,
    label,
    placeholder,
    onChange,
    onValueChange,
    value,
    defaultValue,
    disabled,
    autoFocus,
    hidden,
    handleChange,
    id,
    className,
    onBlur,
    prefix,
    onFocus,
    errors,
    isSubmitted,
    selectedOption,
    allowNegativeValue,
    Icon,
    fontSize,
    labelFontSize,
    toolTipText,
    toolTipWidth,
    toolTipPlace,
    toolTipClass,
    toolTipOpacity,
    toolTipBackgroundColor,
    infoText,
  },
  ref
) => {
  const getClasses = () => {
    let classes = ` border border-grey-light bg-grey-light rounded-xl	placeholder-text-silver font-primary outline-0  
      ${
        fontSize ? ` text-${fontSize} ` : ' xs:text-base sm:text-xl'
      } mt-1.5 block w-full px-3 py-2 text-black font-bold placeholder-font-medium placeholder-text-base placeholder-font-primary
      ${
        disabled ? '' : 'hover:border hover:border-primary-brandDark'
      }  focus:ring focus:ring-primary-brandLight focus:border focus:border-primary-brandDark shadow-primary-brandDark ${className}`
    if (errors) {
      classes = `${classes} border border-primary-error hover:border hover:border-primary-error focus:border-primary-error focus:ring-0 focus:ring-transparent`
    } else {
      classes = `${classes}`
    }
    return classes
  }
  const valueCheck = (value) => {
    if (value === '-0') {
      value = '0'
    } else if (value && value.startsWith('.')) {
      value = ''
    }
    if (onValueChange) onValueChange(value)
  }

  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {infoText && <InfoText infoText={infoText} />}
        <ToolTipWithButton
          label={label}
          toolTipText={toolTipText}
          toolTipPlace={toolTipPlace}
          toolTipWidth={toolTipWidth}
          labelFontSize={labelFontSize}
          toolTipClass={toolTipClass}
          toolTipOpacity={toolTipOpacity}
          toolTipBackgroundColor={toolTipBackgroundColor}
        />

        <div className={`${Icon ? 'flex items-center gap-3' : ''}`}>
          {Icon && <Icon />}
          <CurrencyInput
            allowNegativeValue={allowNegativeValue}
            autoFocus={autoFocus}
            className={getClasses()}
            data-testid={name}
            defaultValue={defaultValue}
            disabled={disabled}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder ? placeholder : ''}
            prefix={prefix}
            ref={ref}
            step={1}
            value={value}
            onValueChange={valueCheck}
          />
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </div>
  )
}
export default memo(forwardRef(CurrencyInputField))
