import React, { FC } from 'react'

export interface IDescriptionIconProps {
  className?: string
  id?: string
}
const DescriptionIcon: FC<IDescriptionIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="text">
        <path
          id="Vector"
          d="M1 4.125C1 3.36445 1.57455 2.75 2.28571 2.75H16.7143C17.4254 2.75 18 3.36445 18 4.125C18 4.88555 17.4254 5.5 16.7143 5.5H2.28571C1.57455 5.5 1 4.88555 1 4.125ZM1 11C1 10.2395 1.57455 9.625 2.28572 9.625H14.1429C14.854 9.625 15.4286 10.2395 15.4286 11C15.4286 11.7605 14.854 12.375 14.1429 12.375H2.28572C1.57455 12.375 1 11.7605 1 11ZM11.5714 17.875C11.5714 18.6355 10.9969 19.25 10.2857 19.25H2.28571C1.57455 19.25 1 18.6355 1 17.875C1 17.1145 1.57455 16.5 2.28571 16.5H10.2857C10.9969 16.5 11.5714 17.1145 11.5714 17.875Z"
          fill="#D4D4D8"
        />
      </g>
    </svg>
  )
}
DescriptionIcon.defaultProps = {
  className: '',
}
export default DescriptionIcon
