import React from 'react'

function CommercialSpaceIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M15.7715 2.49608C15.6787 2.458 15.5713 2.458 15.4346 2.49608L3.0752 7.5244C2.6333 7.70507 2.34375 8.13475 2.34375 8.60839V23.8281C2.34375 24.4775 1.81885 25 1.17188 25C0.524902 25 0 24.4775 0 23.8281V8.60839C0 7.17772 0.868164 5.88866 2.19482 5.35155L14.5557 0.323914C15.2588 0.0568237 15.9912 0.0568237 16.6504 0.323914L29.0527 5.35155C30.3809 5.88866 31.25 7.17772 31.25 8.60839V23.8281C31.25 24.4775 30.7275 25 30.0781 25C29.4287 25 28.9062 24.4775 28.9062 23.8281V8.60839C28.9062 8.13475 28.6182 7.70507 28.1738 7.5244L15.7715 2.49608ZM6.98731 11.7187V23.8281C6.98731 24.4775 6.50879 25 5.81543 25C5.20996 25 4.64355 24.4775 4.64355 23.8281V11.3281C4.64355 10.249 5.56152 9.37499 6.59668 9.37499H24.6094C25.6885 9.37499 26.5625 10.249 26.5625 11.3281V23.8281C26.5625 24.4775 26.04 25 25.3906 25C24.7412 25 24.2188 24.4775 24.2188 23.8281V11.7187H6.98731ZM21.4844 20.3125C22.1338 20.3125 22.6562 20.8349 22.6562 21.4844V23.8281C22.6562 24.4775 22.1338 25 21.4844 25H18.3594C17.71 25 17.1875 24.4775 17.1875 23.8281V21.4844C17.1875 20.8349 17.71 20.3125 18.3594 20.3125H21.4844ZM8.5498 15.2344C8.5498 14.5849 9.11621 14.0625 9.72168 14.0625H14.4092C15.1025 14.0625 15.5811 14.5849 15.5811 15.2344V17.5781C15.5811 18.2275 15.1025 18.75 14.4092 18.75H9.72168C9.11621 18.75 8.5498 18.2275 8.5498 17.5781V15.2344ZM14.4092 20.3125C15.1025 20.3125 15.5811 20.8349 15.5811 21.4844V23.8281C15.5811 24.4775 15.1025 25 14.4092 25H9.72168C9.11621 25 8.5498 24.4775 8.5498 23.8281V21.4844C8.5498 20.8349 9.11621 20.3125 9.72168 20.3125H14.4092Z" />
      </g>
    </svg>
  )
}

CommercialSpaceIcon.defaultProps = {
  className: '',
}
export default CommercialSpaceIcon
