import React, { FC, useState } from 'react'
import ModalBox from 'components/Common/ModalBox'
import PDFViewer from 'pages/Common/container/PdfViewer'
import { IndustryReport } from '__generated__/api-types-and-hooks'
import { ampli } from 'ampli'

interface IIndustryReportResultProps {
  industryReport: IndustryReport
}

const IndustryReportResults: FC<IIndustryReportResultProps> = ({ industryReport }) => {
  const [pdfFileUrl, setPdfFileUrl] = useState('')

  const decodeHTMLEntities = (text: string) => {
    const tempElement = document.createElement('div')
    // eslint-disable-next-line
    const sanitizedText = text.replace(/[^\u0000-\u007F]/g, '')
    tempElement.innerHTML = sanitizedText
    return tempElement.textContent || tempElement.innerText || ''
  }

  return (
    <div className="mt-8">
      {industryReport.searchedKeyword && (
        <div className="border-b border-primary-disabled">
          <div className="space-y-4 py-10 lg:w-[70%]">
            <h2 className="text-2xl font-medium text-zinc">Showing results for:</h2>
            <h4 className="text-xl font-normal text-zinc">"{industryReport.searchedKeyword}"</h4>
          </div>
        </div>
      )}
      {industryReport.summaryText && (
        <div className="mt-10 lg:w-[70%] space-y-4">
          <h3 className="font-bold text-zinc">Learn:</h3>
          <div className="text-zinc">{industryReport.summaryText}</div>
        </div>
      )}
      {industryReport.results && industryReport.results.length > 0 && (
        <div className="mt-12 lg:w-[70%] space-y-8">
          <div className="space-y-2">
            <h3 className="font-bold text-zinc">View related reports:</h3>
            <h3 className="font-bold text-zinc">({industryReport.results.length})</h3>
          </div>
          {industryReport.results.map((result) => (
            <div className="flex flex-col gap-4">
              <div
                className="text-primary cursor-pointer w-fit"
                onClick={() => {
                  ampli.reportOpened({ reportName: result?.docTitle || '' })
                  setPdfFileUrl(result?.docUrl || '')
                }}
              >
                {result?.docTitle?.replace(/_/g, ' ')}
              </div>
              <div className="text-zinc">{decodeHTMLEntities(result?.summary || '')}</div>
            </div>
          ))}
          {pdfFileUrl && (
            <ModalBox width="w-full">
              <PDFViewer fileUrl={pdfFileUrl} handleClose={() => setPdfFileUrl('')} />
            </ModalBox>
          )}
        </div>
      )}
    </div>
  )
}

export default IndustryReportResults
