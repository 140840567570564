import React from 'react'

function DocumentStarIcon({ className = '' }) {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 13.1L16.5 14.6L15.8 11.8L18 9.9L15.1 9.7L14 7L12.9 9.6L10 9.9L12.2 11.8L11.5 14.6L14 13.1ZM0 8H8V10H0V8ZM0 0H12V2H0V0ZM0 4H12V6H0V4Z"
        fill="black"
      />
    </svg>
  )
}

export default DocumentStarIcon
