import React from 'react'
import { AppFC } from 'types'

export interface IMessageBoxProps {
  label: string
  message: string
  date: string
}

const MessageBox: AppFC<IMessageBoxProps> = ({ label, message, date }) => {
  return (
    <div className="border border-grey-lighter4x p-12 rounded-lg mt-9 bg-white">
      <p className="text-base text-black-light ">
        <label className="font-normal">{label}</label>
        <label className="font-tertiary">{date}</label>
      </p>
      <p className="text-base text-black-light">{message}</p>
    </div>
  )
}

export default MessageBox
