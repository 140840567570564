import React, { useState } from 'react'
import get from 'lodash/get'
import { useDatePickerHandler } from 'components/Common/DatePicker'
import { formatNumber } from 'utils/helper'
import {
  IPNLData,
  setPNLData,
  getCashBalance,
  getCashBalancePercentage,
} from 'components/Owner/Data/Finance/utils'
import { BannerStatCardWrapper } from 'components/Owner/Data/Finance/styles'
import Loader from 'components/Loaders'

enum ITabs {
  YTD = 'YTD',
  TTM = 'TTM',
}

const BannerStats = ({ isLoading, financeData }) => {
  const [activeTab, setActiveTab] = useState(ITabs.YTD)
  const { selectedDate } = useDatePickerHandler()
  const getPNL = setPNLData(financeData)
  const cashBalance = getCashBalance(
    get(financeData, 'cashFlowData.chartData', []),
    ITabs.YTD ? selectedDate.startDate : undefined,
    ITabs.YTD ? selectedDate.endDate : undefined
  )
  return (
    <div className="flex flex-col gap-4">
      {isLoading && (
        <>
          <Loader loader="TableLoader" />
          <Loader loader="TableLoader" />
        </>
      )}
      <BannerStatCardWrapper isLoading={isLoading}>
        <div className="flex uppercase justify-between">
          <div className="heading uppercase">Revenue</div>
          <div className="flex gap-4 font-semibold leading-7 text-lg font-primary">
            <span
              className={`tab ${activeTab === ITabs.YTD ? 'activetab' : ''}`}
              onClick={() => setActiveTab(ITabs.YTD)}
            >
              YTD
            </span>
            <span
              className={`tab ${activeTab === ITabs.TTM ? 'activetab' : ''}`}
              onClick={() => setActiveTab(ITabs.TTM)}
            >
              TTM
            </span>
          </div>
        </div>
        <div className="value">${formatNumber(Number(getPNL(IPNLData.Net_Income)))}</div>
        <div className="details">
          <div className="text-green footer-details">
            {getPNL(IPNLData.Net_Income, 'day_percentage_change')}%{' '}
            <span> Since this time last year</span>
          </div>
          <div className="sources uppercase text-xs">{getPNL(IPNLData.Net_Income, 'Source')}</div>
        </div>
      </BannerStatCardWrapper>
      <BannerStatCardWrapper isLoading={isLoading}>
        <div className="heading uppercase">CASH BALANCE</div>
        <div className="value">${cashBalance ? formatNumber(cashBalance) : 0}</div>
        <div className="details">
          <div className="text-green footer-details">
            {getCashBalancePercentage(financeData)}% <span> Since this time last year</span>
          </div>
          <div className="sources uppercase text-xs">{getPNL(IPNLData.Other_Income, 'Source')}</div>
        </div>
      </BannerStatCardWrapper>
    </div>
  )
}

export default BannerStats
