import React from 'react'

function RightDirectionArrowIcon({ className }) {
  return (
    <svg
      width="24"
      className={className}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" />
      <g clipPath="url(#clip0_1880_8926)">
        <path
          d="M18.0651 12.3325C18.4484 11.9533 18.4484 11.3376 18.0651 10.9585L13.1588 6.10552C12.7755 5.72639 12.153 5.72639 11.7697 6.10552C11.3864 6.48466 11.3864 7.10038 11.7697 7.47951L15.0048 10.6764L5.597 10.6764C5.05424 10.6764 4.61575 11.1101 4.61575 11.647C4.61575 12.1838 5.05424 12.6176 5.597 12.6176L15.0017 12.6176L11.7728 15.8144C11.3895 16.1936 11.3895 16.8093 11.7728 17.1884C12.1561 17.5676 12.7786 17.5676 13.1619 17.1884L18.0681 12.3355L18.0651 12.3325Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1880_8926">
          <rect
            width="13.7376"
            height="15.5294"
            fill="white"
            transform="translate(18.3525 19.7646) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
RightDirectionArrowIcon.defaultProps = {
  className: '',
}

export default RightDirectionArrowIcon
