import React, { useEffect, useMemo, useState } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import compact from 'lodash/compact'
import last from 'lodash/last'
import moment from 'moment'
import BarChart from 'components/Common/BarChart'
import DatePicker, { useDatePickerHandler } from 'components/Common/DatePicker'
import ArrowHead from 'components/Common/SvgIcons/ArrowHead'
import {
  intervalTypes,
  getYearlyChartData,
  getLastUpdatedData,
  getIntervalChartData,
  SalesSummary,
} from 'components/Owner/Data/utils'
import colors from 'colors'
import { chartRangeChanged, chartRangeViewed, formatNumber, getRoleFromPath } from 'utils/helper'
import GrowingContainer from 'components/Common/GrowingContainer'
import ExclamationMarkIcon from 'components/Common/SvgIcons/ExclamationMarkIcon'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import { StatValue, SectionTitle } from 'components/Owner/Data/styles'
import Loader from 'components/Loaders'
import OwnerApi from 'api/ownerData'
import withChartWrapper, { WithChartWrapperProps } from '../ChartHOC'
import { OperationName } from 'types'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { ChartStatus, GetChartDataInput } from '../../../../__generated__/api-types-and-hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { IChartNames, IDataTabs, ROLES } from 'config'

type SalesData = {
  data?: SalesSummary[]
  status: ChartStatus
}

const onFetchDataMethod = async (input: GetChartDataInput): Promise<SalesData> => {
  const chartName = 'GROSS_SALES_SUMMARY'
  const res = await OwnerApi.getSalesChartData({ ...input, chartName })
  const data = JSON.parse(res.getChartData.data)
  if (!data?.chartData?.length) {
    return { data: undefined, status: ChartStatus.Success }
  }
  return { data: data?.chartData, status: ChartStatus.Success }
}

const CHART_DATA_QUERY_KEY: OperationName = 'getChartData'

interface ISalesChartProps extends WithChartWrapperProps<GetChartDataInput, SalesData> {}

const Sales = withChartWrapper(
  onFetchDataMethod,
  ({ onFetchData, chartData: salesData }: ISalesChartProps) => {
    const { selectedDate, handleClick, formattedDate, handleNextDate, handlePreviousDate } =
      useDatePickerHandler()
    const [datePickerOpened, setDatePickerOpened] = useState(false)
    const section = IDataTabs.OVERVIEW
    const chartName = [IChartNames.GROSS_SALES_SUMMARY]

    const onClickHandler = (startDate: string, endDate: string, type: string) => {
      handleClick(startDate, endDate, type)
      getRoleFromPath() === ROLES.BUSINESS_OWNER &&
        chartRangeChanged({ section, chartName, endDate, startDate })
    }
    const handleNext = () => {
      const { startDate, endDate } = handleNextDate()
      getRoleFromPath() === ROLES.BUSINESS_OWNER &&
        chartRangeChanged({ section, chartName, endDate, startDate })
    }
    const handlePrevious = () => {
      const { startDate, endDate } = handlePreviousDate()
      getRoleFromPath() === ROLES.BUSINESS_OWNER &&
        chartRangeChanged({ section, chartName, endDate, startDate })
    }

    useEffect(() => {
      if (datePickerOpened && getRoleFromPath() === ROLES.BUSINESS_OWNER) {
        chartRangeViewed({ section, chartName })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerOpened])

    const { mutate: fetchData, isLoading } = useFetchChartData(onFetchData, CHART_DATA_QUERY_KEY)

    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    console.log('userId ', userId)
    useEffect(() => {
      fetchData({
        businessId: userId,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        chartName: '',
        userId: userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])
    const chartData = useMemo(() => {
      if (selectedDate.type === 'year') {
        return getYearlyChartData(
          `${moment(selectedDate.startDate).format('YYYY')}`,
          salesData?.data
        )
      }
      const data = getIntervalChartData(
        salesData?.data,
        selectedDate.startDate,
        selectedDate.endDate,
        selectedDate.type
      )
      return data
    }, [selectedDate, salesData])

    const lastUpdated = useMemo(() => {
      return getLastUpdatedData(get(salesData, 'chartData'))
    }, [salesData])

    const aggValue: any = useMemo(() => {
      return last(compact(map(chartData, 'aggValue')))
    }, [chartData])

    const percentageVal = useMemo(() => {
      const value: any = last(compact(map(chartData, 'percentageVal')))
      const resp = { value, className: '', arrowColor: '', arrowClass: '' }
      if (value < 0)
        return {
          ...resp,
          className: 'text-primary-error',
          arrowColor: colors.primary.error,
          arrowClass: 'rotate-180',
        }
      if (value > 0) return { ...resp, className: 'text-green', arrowColor: colors.green.DEFAULT }
      return resp
    }, [chartData])

    return (
      <GrowingContainer>
        <div className="grid col-span-12">
          <div className="bg-secondary p-7 w-full">
            <div className="flex xs:flex-col lg:flex-row gap-9">
              <div className="totalSale">
                <SectionTitle>WHAT ARE THE TOTAL SALES OF MY BUSINESS?</SectionTitle>
                {isLoading && <Loader loader="TableLoader" />}
                <div className={`flex gap-5 flex-col items-center ${isLoading && 'hidden'}`}>
                  <p className="text-lg leading-7 font-semibold text-primary-text font-primary">
                    Total Gross Sales {formattedDate}
                  </p>
                  <StatValue>${formatNumber(aggValue) || 0}</StatValue>
                  <div className="flex gap-3 text-2xl font-primary items-center">
                    {percentageVal.value !== 0 && (
                      <ArrowHead
                        color={percentageVal.arrowColor}
                        className={`cursor-pointer ${percentageVal.arrowClass}`}
                      />
                    )}
                    <span className={percentageVal.className}>{percentageVal.value || 0}%</span>
                    <span> {intervalTypes[selectedDate.type]}</span>
                    <ExclamationMarkIcon className="fill-primary hover:fill-primary-brand h-[32px] w-[32px]" />
                  </div>
                </div>
              </div>
              <div className="grow flex flex-col gap-12">
                <div>
                  <div className="flex flex-col">
                    <div className="flex justify-end mb-6">
                      <DatePicker
                        handleClick={onClickHandler}
                        value={formattedDate}
                        onNextDate={handleNext}
                        onPreviousDate={handlePrevious}
                        datePickerOpened={datePickerOpened}
                        setDatePickerOpened={setDatePickerOpened}
                      />
                      <div className="clearfix"></div>
                    </div>
                    {isLoading && <Loader loader="ChartLoader" />}
                    <div className={`h-[300px] relative ${isLoading && 'hidden'} `}>
                      <BarChart
                        data={chartData || []}
                        xDataKey="name"
                        yDataKey="value"
                        barName="Value"
                      />
                    </div>
                  </div>
                </div>
                <SectionFooter
                  lastUpdated={lastUpdated}
                  getChartDataAction={() => {
                    fetchData({
                      businessId: userId,
                      startDate: selectedDate.startDate,
                      endDate: selectedDate.endDate,
                      chartName: '',
                      userId,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </GrowingContainer>
    )
  }
)

export default Sales
