import React from 'react'

function AdvisorPlusIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="26"
      viewBox="0 0 16 21"
    >
      <path d="M15 10.5004C15 10.1831 14.7577 9.92344 14.4615 9.92344H8.53846V3.57728C8.53846 3.25839 8.29764 3 8 3C7.70237 3 7.46154 3.25998 7.46154 3.57728V9.92344H1.53846C1.24083 9.92344 1 10.1812 1 10.5C1 10.8177 1.24096 11.0773 1.53846 11.0773H7.46154V17.4234C7.46154 17.7423 7.70237 18 8 18C8.29764 18 8.53846 17.7422 8.53846 17.4234V11.0773H14.4615C14.7577 11.0773 15 10.8177 15 10.5004Z" />
    </svg>
  )
}
AdvisorPlusIcon.defaultProps = {
  className: 'cursor-pointer',
}
export default AdvisorPlusIcon
