import React from 'react'

import { AppFC } from 'types'

import ModalBox from 'components/Common/ModalBox'
import Button from 'components/Common/Button'

export interface IFilterSettingsMenu {
  onSettingsApplied: (...args: any[]) => any
  onClose: (...args: any[]) => any
  onReset: (...args: any[]) => any
  title?: string
}

const FilterSettingsMenu: AppFC<IFilterSettingsMenu> = ({
  onSettingsApplied,
  children,
  onClose,
  onReset,
  title = '',
}) => {
  return (
    <ModalBox width={'w-full'}>
      <div className="flex flex-col gap-9 h-screen w-screen p-6">
        <div className="grid grid-rows-2 gap-3">
          <div className="flex justify-between items-center">
            <div className="h-12 w-12 flex items-center">
              <Button leftIcon="cross" variant="text" onClick={onClose} />
            </div>
            <span className="font-black font-primary text-2xl capitalize">{title}</span>
            <Button variant="text" label="Reset" onClick={onReset} />
          </div>
          <div id="filter-settings-menu-divider" className="w-full h-px bg-primary-disabled" />
        </div>
        {children}
        <Button label="Apply" onClick={onSettingsApplied} />
      </div>
    </ModalBox>
  )
}

export default FilterSettingsMenu
