import React from 'react'

import { AppFC } from 'types'
import useNavigateTo from 'hooks/useNavigateTo'

import Heading from 'components/Common/Heading'
import ProfilePicture from 'components/Dashboard/Advisor/ProfilePicture/ProfilePicture.component'

export interface IProfileContentProps {
  firstName?: string
  lastName?: string
  profileImgSrc?: string
  bsoName?: string
  businessName?: string
  isBSO?: boolean
}

/** Displays the user's name, profile picture and a link to edit their account details. */
const ProfileSection: AppFC<IProfileContentProps> = ({
  isBSO = false,
  firstName = '',
  lastName = '',
  profileImgSrc = '',
  bsoName = '',
  businessName = '',
}) => {
  const navigate = useNavigateTo('account')
  return (
    <section className="flex items-center gap-3 xs:justify-center lg:justify-start mt-2 sm:mt-auto ">
      {!isBSO && <ProfilePicture src={profileImgSrc} firstName={firstName} lastName={lastName} />}
      <div className="flex flex-col gap-2 text-white">
        <Heading
          variant="secondary"
          className="text-white xs:text-[32px] leading-7 break-all line-clamp-4"
        >
          {`${firstName} ${lastName}`}
        </Heading>
        <Heading
          variant="secondary"
          className="text-white xs:text-[20px] leading-7 break-all line-clamp-4 !font-black"
        >
          {isBSO ? bsoName : businessName}
        </Heading>
        <div>
          <span
            onClick={navigate}
            className="text-sm font-semibold text-white hover:text-sky hover:cursor-pointer"
          >
            Edit Account Profile
          </span>
        </div>
      </div>
    </section>
  )
}

export default ProfileSection
