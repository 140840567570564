import {
  BusinessProfileResponse,
  BusinessSummaryResponse,
  ChartStatus,
  Maybe,
  UserNotificationData,
} from '__generated__/api-types-and-hooks'
import { ANNOUNCEMENT_NAMES, STATUS, USER_ROLES } from 'config'
import { get } from 'lodash'
import pick from 'lodash/pick'
import {
  GET_USER_BUSINESS_PROFILE,
  GET_USER_PROFILE,
  UPDATE_USER_BUSINESS_PROFILE,
  UPDATE_USER_PROFILE,
  CHAT_UNREAD_MESSAGE_COUNT,
  GET_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  UPDATE_MY_TOOLS,
  CLIENTS_SUMMARY,
  RESEND_USER_INVITE,
  OPEN_CONFIRMATION_MODAL,
  SAVE_ATTESTATION,
  RESET_ATTESTATION_URL,
  SET_TENANT_ID,
  SET_MONTHLY_ATTESTATION_FORM,
  SET_SHOW_SUBSCRIPTION_PROMPT,
  RESET_ERROR,
  SET_ANNOUNCEMENT_ACTIVE_STEP,
  PUBLISH_ANNOUNCEMENT_ACTION,
} from 'store/types'
import { ApiResponseError } from 'utils/error-handling/api-response-error'

interface IAnnouncementStepsType {
  title: string
}

export interface UserState {
  user: any
  businessProfile: BusinessProfileResponse
  notifications: {
    data: Maybe<UserNotificationData>[]
    lastEvaluatedKey: string
    totalCount: number
    totalUnread: number
    isLoading: boolean
  }
  announcementSteps: IAnnouncementStepsType[]
  activeStep: string
  announcementError: string
  error: string
  disableButtonsButtons: boolean

  userProfile: any
  chatUsers: {
    glRole: USER_ROLES
    users: any[]
  }
  chatUnreadMessageCount: number
  updateOwner: string
  summary: any
  email: string
  name: string
  isSuccess: boolean
  attestationUrl: string
  isLoading: boolean
  tenantId: string
  openTenantIdModal: boolean
  attestationData: {}
  isUpdateProfileLoading: boolean
  isLoadingBusinessProfile: boolean
  showSubscriptionPrompt: boolean
  mobileContactError: string
  updateUserProfileErrors: ApiResponseError[]
  businessSummary: BusinessSummaryResponse
}

const initialState: UserState = {
  user: {},
  businessProfile: {},
  notifications: {
    data: [],
    lastEvaluatedKey: '',
    totalCount: 0,
    totalUnread: 0,
    isLoading: true,
  },
  announcementSteps: [
    {
      title: ANNOUNCEMENT_NAMES.MAKE,
    },
    {
      title: ANNOUNCEMENT_NAMES.AFTER_PUBLISH,
    },
  ],
  activeStep: '',
  announcementError: '',
  error: '',
  disableButtonsButtons: false,
  userProfile: {},
  chatUsers: {
    glRole: USER_ROLES.BUSINESS_OWNER,
    users: [],
  },
  chatUnreadMessageCount: 0,
  updateOwner: '',
  summary: {},
  email: '',
  name: '',
  isSuccess: false,
  attestationUrl: '',
  isLoading: false,
  tenantId: '',
  openTenantIdModal: false,
  attestationData: {},
  isUpdateProfileLoading: false,
  isLoadingBusinessProfile: false,
  showSubscriptionPrompt: false,
  mobileContactError: '',
  updateUserProfileErrors: [],
  businessSummary: {
    data: [],
    status: ChartStatus.Processing,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_CHAT_USERS':
      return {
        ...state,
        chatUsers: action.payload,
      }
    case PUBLISH_ANNOUNCEMENT_ACTION.STARTED:
      return {
        ...state,
        announcementError: '',
      }
    case PUBLISH_ANNOUNCEMENT_ACTION.REJECTED:
      return {
        ...state,
        announcementError: action.payload,
        activeStep: state.announcementSteps[1].title,
      }
    case SET_ANNOUNCEMENT_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload.title,
      }
    case GET_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        ...action.payload,
      }

    case GET_USER_PROFILE.RESET:
      return {
        ...state,
        userProfile: {},
      }
    case SET_MONTHLY_ATTESTATION_FORM.STARTED:
      return {
        ...state,
        isLoadingBusinessProfile: true,
      }

    case GET_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        businessProfile: action?.payload,
        isLoadingBusinessProfile: false,
      }
    case UPDATE_USER_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
        isUpdateProfileLoading: true,
        mobileContactError: null,
      }
    case UPDATE_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        user: action?.payload,
        userProfile: action?.payload,
        disableButtons: false,
        isUpdateProfileLoading: false,
      }
    case UPDATE_USER_PROFILE.REJECTED:
      return {
        ...state,
        disableButtons: false,
        isUpdateProfileLoading: false,
        ...action.payload,
      }
    case UPDATE_USER_BUSINESS_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
      }
    case UPDATE_USER_BUSINESS_PROFILE.REJECTED:
      return {
        ...state,
        error: action.payload,
        disableButtons: false,
      }
    case UPDATE_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButtons: false,
      }

    case CHAT_UNREAD_MESSAGE_COUNT.FULLFILLED:
      return {
        ...state,
        chatUnreadMessageCount: action.payload,
      }
    case DELETE_USER_NOTIFICATIONS.STARTED:
    case GET_USER_NOTIFICATIONS.STARTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      }
    case DELETE_USER_NOTIFICATIONS.FULLFILLED:
    case GET_USER_NOTIFICATIONS.FULLFILLED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          ...pick(action.payload, ['data', 'lastEvaluatedKey', 'totalCount', 'totalUnread']),
        },
      }
    case GET_USER_NOTIFICATIONS.RESET:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          lastEvaluatedKey: '',
          data: action.payload?.isDashboard ? state.notifications.data : [],
          totalCount: action.payload?.isDashboard ? state.notifications.totalCount : 0,
          isLoading: true,
        },
      }
    case UPDATE_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        updateOwner: action.payload,
      }
    case CLIENTS_SUMMARY.FULLFILLED:
      return {
        ...state,
        summary: action.payload,
      }
    case RESEND_USER_INVITE.STARTED:
      return {
        ...state,
        status: STATUS.LOADING,
        isSuccess: false,
      }
    case RESEND_USER_INVITE.REJECTED:
      return {
        ...state,
        error: get(action.payload, 'error.message', ''),
        status: STATUS.COMPLETED,
        isSuccess: false,
      }
    case RESEND_USER_INVITE.FULLFILLED:
      return {
        ...state,
        status: STATUS.COMPLETED,
        isSuccess: true,
      }
    case OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        ...action.payload,
        isSuccess: false,
      }
    case SAVE_ATTESTATION.STARTED:
      return {
        ...state,
        isLoading: action.payload?.generateUrl ? true : false,
      }
    case SAVE_ATTESTATION.FULLFILLED:
      return {
        ...state,
        attestationUrl: action.payload,
      }
    case SAVE_ATTESTATION.REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_ATTESTATION_URL:
      return {
        ...state,
        attestationUrl: '',
      }
    case SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload,
      }
    case SET_SHOW_SUBSCRIPTION_PROMPT:
      return {
        ...state,
        showSubscriptionPrompt: action.payload,
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        mobileContactError: null,
      }
  }

  return state
}

export default reducer
