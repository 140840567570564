import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AdvisorOwnerActivityLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="15" rx="0" ry="0" width="1" height="99" />
        <rect x="0" y="113" rx="0" ry="0" width="218" height="1" />
        <rect x="0" y="15" rx="0" ry="0" width="218" height="1" />
        <rect x="218" y="15" rx="0" ry="0" width="1" height="99" />

        <rect x="14" y="27" rx="2" ry="2" width="70" height="15" />
        <rect x="14" y="59" rx="2" ry="2" width="52" height="38" />

        <rect x="230" y="15" rx="0" ry="0" width="1" height="99" />
        <rect x="230" y="113" rx="0" ry="0" width="218" height="1" />
        <rect x="230" y="15" rx="0" ry="0" width="218" height="1" />
        <rect x="448" y="15" rx="0" ry="0" width="1" height="99" />

        <rect x="246" y="27" rx="2" ry="2" width="70" height="15" />
        <rect x="246" y="59" rx="2" ry="2" width="52" height="38" />

        <rect x="0" y="125" rx="0" ry="0" width="1" height="133" />
        <rect x="0" y="258" rx="0" ry="0" width="218" height="1" />
        <rect x="0" y="125" rx="0" ry="0" width="218" height="1" />
        <rect x="218" y="125" rx="0" ry="0" width="1" height="134" />

        <rect x="14" y="136" rx="2" ry="2" width="70" height="15" />
        <rect x="14" y="210" rx="2" ry="2" width="52" height="38" />

        <rect x="230" y="125" rx="0" ry="0" width="1" height="133" />
        <rect x="230" y="258" rx="0" ry="0" width="218" height="1" />
        <rect x="230" y="125" rx="0" ry="0" width="218" height="1" />
        <rect x="448" y="125" rx="0" ry="0" width="1" height="134" />

        <rect x="246" y="136" rx="2" ry="2" width="70" height="15" />
        <rect x="246" y="210" rx="2" ry="2" width="52" height="38" />
      </ContentLoader>
    </>
  )
}

export default AdvisorOwnerActivityLoader
