import React, { useEffect, useMemo } from 'react'
import map from 'lodash/map'
import size from 'lodash/size'
import get from 'lodash/get'
import { formatNumber, getRoleFromPath } from 'utils/helper'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import Loader from 'components/Loaders'
import withChartWrapper, { WithChartWrapperProps } from '../ChartHOC'
import { ChartStatus, GetChartDataInput } from '../../../../__generated__/api-types-and-hooks'
import {
  TopSellingProductChannel,
  getIntervalChartDataForChannel,
  getLastUpdatedData,
} from './utils'
import OwnerApi from 'api/ownerData'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { ISelectedDate } from 'components/Common/DatePicker/useDatePickerHandler'
import { ROLES } from 'config'

const Row = ({ salesChannel, grossSales, returns, fees, netSales }) => {
  return (
    <div className="tableData">
      <div className="flex justify-between py-4 border-b border-primary-outline gap-2">
        <div className="w-full leading-5 text-lg font-medium text-primary-text">{salesChannel}</div>
        <div className="leading-5 text-sm font-primary text-primary-text w-full max-w-[135px]">
          ${formatNumber(grossSales) || 0}
        </div>
        <div className="leading-5 text-sm font-primary text-primary-text w-full max-w-[100px] text-end">
          ${formatNumber(returns) || 0}
        </div>
        <div className="leading-5 text-sm font-primary text-primary-text w-full max-w-[100px] text-end">
          ${formatNumber(fees) || 0}
        </div>
        <div className="leading-5 text-sm font-primary text-primary-text w-full max-w-[100px] text-end">
          ${formatNumber(netSales) || 0}
        </div>
      </div>
    </div>
  )
}

type SalesSourcesData = {
  data?: TopSellingProductChannel[]
  status: ChartStatus
}

const onFetchDataMethod = async (input: GetChartDataInput): Promise<SalesSourcesData> => {
  const chartName = 'TOP_GROSS_SALES_BY_CHANNEL'
  const res = await OwnerApi.getSalesChartData({ ...input, chartName })
  const data = JSON.parse(res.getChartData.data)
  if (!data?.chartData?.length) {
    return { data: undefined, status: ChartStatus.Success }
  }
  return { data: data?.chartData, status: ChartStatus.Success }
}

interface ISalesSourcesProps extends WithChartWrapperProps<GetChartDataInput, SalesSourcesData> {
  selectedDate: ISelectedDate
}

const SalesSources = withChartWrapper(
  onFetchDataMethod,
  ({ chartData, onFetchData, selectedDate }: ISalesSourcesProps) => {
    const { mutate: fetchData, isLoading } = useFetchChartData(onFetchData)
    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    useEffect(() => {
      fetchData({
        businessId: userId,
        endDate: selectedDate.endDate,
        chartName: '',
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const topSellingProductsByChannel = useMemo(() => {
      const data = getIntervalChartDataForChannel(
        chartData?.data,
        selectedDate.startDate,
        selectedDate.endDate,
        selectedDate.type
      )
      return data
    }, [chartData?.data, selectedDate])

    const lastUpdated = useMemo(() => {
      return getLastUpdatedData(chartData?.data)
    }, [chartData?.data])

    return (
      <div className="totalSale xs:p-4 xxl:p-6">
        <h5 className="font-semibold leading-7 text-lg font-primary text-primary-text mb-6">
          WHERE ARE MY SALES COMING FROM?
        </h5>
        {isLoading && <Loader loader="TableLoader" />}
        <div className={`flex flex-col ${isLoading && 'hidden'}`}>
          <div className="table-header">
            <div className="flex justify-between pb-5 border-b border-primary-outline gap-2">
              <div className="w-full leading-5 text-sm font-semibold text-primary-text">
                SALES CHANNEL
              </div>
              <div className="leading-5 text-sm font-semibold text-primary-text w-full max-w-[135px]">
                GROSS SALES
              </div>
              <div className="leading-5 text-sm font-semibold text-primary-text w-full max-w-[100px] text-end">
                FEES
              </div>
              <div className="leading-5 text-sm font-semibold text-primary-text w-full max-w-[100px] text-end">
                TAXES
              </div>
              <div className="leading-5 text-sm font-semibold text-primary-text w-full max-w-[100px] text-end">
                NET SALES
              </div>
            </div>
          </div>
          {map(topSellingProductsByChannel, (channel) => (
            <Row
              key={channel.source}
              salesChannel={get(channel, 'source')}
              grossSales={get(channel, 'totalSales', 0)}
              returns={get(channel, 'returns', 0)}
              fees={get(channel, 'fees', 0)}
              netSales={get(channel, 'netSales', 0)}
            />
          ))}
        </div>
        <div className="mt-5">
          {size(topSellingProductsByChannel) > 3 && (
            <h6
              onClick={() => {}}
              className="text-primary font-bold text-sm leading-5 cursor-pointer"
            >
              Show All
            </h6>
          )}
        </div>
        <SectionFooter
          lastUpdated={lastUpdated}
          getChartDataAction={() => {
            fetchData({
              businessId: userId,
              endDate: selectedDate.endDate,
              chartName: '',
              userId,
            })
          }}
        />
      </div>
    )
  }
)

export default SalesSources
