import React, { useEffect, useState } from 'react'
import ArrowHead from 'components/Common/SvgIcons/ArrowHead'
import { chartRangeViewed, formatNumber, getRoleFromPath } from 'utils/helper'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import colors from 'colors'
import { StatValue } from 'components/Owner/Data/styles'
import {
  AccountingCharts,
  CashBalanceReport,
  ChartStatus,
  DateRanges,
  QueryAccountingReportInput,
} from '../../../../../__generated__/api-types-and-hooks'
import Loader from 'components/Loaders'
import moment from 'moment'
import withChartWrapper, { WithChartWrapperProps } from '../../ChartHOC'
import { OperationName } from 'types'
import OwnerApi from 'api/ownerData'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { IDataTabs, ROLES } from 'config'
import { useSelector } from 'react-redux'
import { RootState } from 'App'

enum ITabs {
  YTD = 'YTD',
  TTM = 'TTM',
}

type CashBalanceChartData = { data?: CashBalanceReport[]; status: ChartStatus }

const onFetchDataMethod = async (
  params: QueryAccountingReportInput
): Promise<CashBalanceChartData | undefined> => {
  const data = await OwnerApi.getOwnerAccountingData<CashBalanceReport[]>(params)
  return data?.getAccountingCharts
}

const ACCOUNTING_CHARTS_QUERY_KEY: OperationName = 'getAccountingCharts'

interface ICashBalanceChartProps
  extends WithChartWrapperProps<QueryAccountingReportInput, CashBalanceChartData> {}

const CashBalanceChart = withChartWrapper(
  onFetchDataMethod,
  ({ onFetchData, chartData }: ICashBalanceChartProps) => {
    const [activeTab, setActiveTab] = useState(ITabs.YTD)

    const data = chartData?.data?.length ? chartData.data[0] : undefined
    const percentageValue = data?.growth ?? 0
    const section = IDataTabs.OVERVIEW
    const chartName = [AccountingCharts.CashBalance]

    const { mutate: fetchData, isLoading } = useFetchChartData(
      onFetchData,
      ACCOUNTING_CHARTS_QUERY_KEY
    )

    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    useEffect(() => {
      fetchData({
        chartType: AccountingCharts.CashBalance,
        dateRange: activeTab === ITabs.YTD ? DateRanges.ThisYearToDate : DateRanges.Last12Months,
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    const handleClick = (tabName: ITabs) => {
      setActiveTab(tabName)
      chartRangeViewed({ section, chartName })
    }

    return (
      <div className="rounded-xl p-5 mb-4 flex flex-col bg-white w-full h-full justify-between">
        <div className="flex flex-col sm:flex-row font-semibold leading-7 text-lg font-primary gap-4">
          <h5>WHAT IS MY CASH BALANCE?</h5>
          <div className="flex sm:ml-auto gap-3">
            <span
              className={`cursor-pointer ${activeTab === ITabs.YTD ? 'text-primary' : ''}`}
              onClick={() => handleClick(ITabs.YTD)}
            >
              YTD
            </span>
            <span
              className={`cursor-pointer ${activeTab === ITabs.TTM ? 'text-primary' : ''}`}
              onClick={() => handleClick(ITabs.TTM)}
            >
              TTM
            </span>
          </div>
        </div>
        {isLoading && <Loader loader="TableLoader" />}
        <div className={`p-4 flex gap-2 flex-col items-center ${isLoading && 'hidden'} `}>
          {data && (
            <>
              <StatValue>${formatNumber(Number(data?.cashBalance || 0))}</StatValue>
              <div className="flex gap-3 text-2xl font-primary items-center leading-7">
                {percentageValue !== 0 && (
                  <ArrowHead
                    color={percentageValue > 0 ? colors.green.DEFAULT : colors.primary.error}
                    className={`cursor-pointer`}
                    down={percentageValue < 0}
                  />
                )}
                {percentageValue !== 0 && (
                  <span className={percentageValue > 0 ? 'text-green' : 'text-primary-error'}>
                    {percentageValue || 0}%
                  </span>
                )}
                {percentageValue === 0 && <span>{0}%</span>}
                {'YoY'}
              </div>
            </>
          )}
        </div>
        <div>
          <SectionFooter
            lastUpdated={moment().format('YYYY-MM-DD')}
            getChartDataAction={() =>
              fetchData({
                chartType: AccountingCharts.CashBalance,
                dateRange:
                  activeTab === ITabs.YTD ? DateRanges.ThisYearToDate : DateRanges.Last12Months,
                userId,
              })
            }
            source={data?.source ?? 'QuickBooks'}
          />
        </div>
      </div>
    )
  }
)

export default CashBalanceChart
