import { ROLES } from 'config'
import { useEffect } from 'react'

export const useGetBusinessesAndClients = (role, userId, getBusinessesAction, getClientsAction) => {
  useEffect(() => {
    if (role === ROLES.BSO_ADVISOR)
      getClientsAction({
        advisorId: userId,
        includeInvitedClients: true,
      })
  }, [getClientsAction, userId, role])

  useEffect(() => {
    if (role === ROLES.APP_ADMIN || role === ROLES.BSO_ADMIN)
      getBusinessesAction({ allStatuses: true })
  }, [getBusinessesAction, role])
}
