import React, { FC } from 'react'

import { CardWrapper, TitleWrapper } from './styles'

interface IPlaybooksCategoryContentCardProps {
  title: string
}
/** A gray card that displays a title along with nested content */
const PlaybooksCategoryContentCard: FC<IPlaybooksCategoryContentCardProps> = ({
  title,
  children,
}) => {
  return (
    <CardWrapper className="bg-[#ffffffca]">
      <TitleWrapper>{title}</TitleWrapper>
      {children}
    </CardWrapper>
  )
}

export default PlaybooksCategoryContentCard
