import { GET_ASSESSMENT_PLAY_LISTING, GET_DASHBOARD_LISTING } from 'store/types'

/** Note that this is only one variant of what may be returned as an "assessment" - other requests may return different properties */
interface AssessmentResponseItem {
  totalItems: number
  totalAcceptedItems: number
  /** This property only exists once the assessment is complete. TODO: The API should probably send this no matter what for consistency. */
  completedAt?: string // timestamp
  isStarted: boolean
  isCompleted: boolean
  inProgress: boolean
  isLocked: boolean
  /** Currently the closest property to an "ID" - it's not yet consistent, though */
  assessmentType: string
}

const initialState = {
  playData: {},
  /* TODO: The difference between "dashboardList" and "assessmentPlayList" should be clarified by renaming or documentation */
  dashboardList: [],
  /**  */
  assessmentPlayList: {
    assessments: [] as AssessmentResponseItem[],
    plays: [],
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_LISTING.FULLFILLED:
      return {
        ...state,
        dashboardList: action.payload,
      }
    case GET_ASSESSMENT_PLAY_LISTING.FULLFILLED:
      return {
        ...state,
        assessmentPlayList: action.payload,
      }
    default:
  }
  return state
}

export default reducer
