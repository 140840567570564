import React from 'react'
import { AppFC } from 'types'

const Card: AppFC = ({ children, className = '', onClick = () => {} }) => {
  return (
    <div onClick={onClick} className={`bg-white shadow-base p-5 rounded-[20px] ${className}`}>
      {children}
    </div>
  )
}

export default Card
