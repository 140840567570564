import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import GrowingContainer from 'components/Common/GrowingContainer'
import SelectField from 'components/Common/SelectField'
import TopSearchResults from './TopSearchResults'
import StatCard from './StatCard'

enum IInterval {
  week = 'week',
  month = 'month',
  year = 'year',
}

const timeOptions = [
  { value: IInterval.week, label: 'This week' },
  { value: IInterval.month, label: 'This month' },
  { value: IInterval.year, label: 'This year' },
]

const Marketing: FC<any> = ({ marketingData, fetchMarketingData }) => {
  const [interval, setInterval] = useState<IInterval>(timeOptions[0].value)

  const fetchDetails = useCallback(() => {
    const startTime = moment().startOf(interval).format('YYYY-MM-DD')
    const endTime = moment().endOf(interval).format('YYYY-MM-DD')
    fetchMarketingData({ startTime, endTime })
  }, [fetchMarketingData, interval])

  useEffect(() => {
    fetchDetails()
  }, [fetchDetails])

  const getValue = (attr: string, defaultVal = 0) => {
    return get(marketingData, attr) ? parseInt(get(marketingData, attr)) : defaultVal
  }

  return (
    <GrowingContainer>
      <div className="grid col-span-12">
        <h3 className="xs:text-[25px] sm:text-3xl leading-9 text-black p-7 font-primary">
          Marketing
        </h3>
        <div className="grid grid-cols-12 gap-4 mb-24">
          <div className="xs:col-span-12 md:col-span-6 lg:col-span-4">
            <TopSearchResults
              title="How close to the top of the search results am I?"
              value={getValue('position')}
              handleRefresh={fetchDetails}
              updatedDate={get(marketingData, 'updatedDate')}
            />
          </div>
        </div>
        <div className="max-w-[343px] mb-7 text-lg font-primary text-primary-text">
          <SelectField
            label={'Filter by Time'}
            name={'status'}
            placeholder="Select"
            options={timeOptions}
            value={interval}
            onChange={(option) => {
              setInterval(option)
            }}
          />
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="xs:col-span-12 md:col-span-6 lg:col-span-4">
            <StatCard
              title="How many times has my website link been seen?"
              aggValue={getValue('impressions')}
              aggValuelabel="impressions"
              handleRefresh={fetchDetails}
              updatedDate={get(marketingData, 'updatedDate')}
            />
          </div>
          <div className="xs:col-span-12  md:col-span-6 lg:col-span-4">
            <StatCard
              title={
                <>
                  How many times has someone clicked from a search results page to get to my
                  website?
                </>
              }
              aggValue={getValue('clicks')}
              aggValuelabel="clicks"
              handleRefresh={fetchDetails}
              updatedDate={get(marketingData, 'updatedDate')}
            />
          </div>
          <div className="col-span-4 hidden">
            <StatCard
              title={
                <>
                  How many times has someone clicked from a search results page to get to my
                  website?
                </>
              }
              aggValue={getValue('clicks')}
              aggValuelabel="clicks"
              handleRefresh={fetchDetails}
              updatedDate={get(marketingData, 'updatedDate')}
            />
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default Marketing
