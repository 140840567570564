import React from 'react'
import TableHeader from 'components/Common/TableHeader'
import TableBody from 'components/Common/TableBody'
import Loader from 'components/Loaders'
import { Columns } from 'types'
import TablePaginationV2 from 'components/Common/TablePaginationV2'
import { SORT_ORDER } from 'config'

export interface IDataTableProps {
  columns: Columns
  rows: any[]
  noOfItemsPerPageOptions: { value: string; label: string }[]
  ExpandedComponent: any
  /** The number of equally spaced grid columns used in conjuction with "display: grid" to style the table. */
  gridColumns?: number
  isLoading?: boolean
  defaultSort: { [key: string]: string }
  loaderType?: string
  currentPage: number
  noOfItemsPerPage: number
  totalPages: number
  moveNextHandler: (pageNumber: number) => void
  movePrevHandler: (pageNumber: number) => void
  updateNoOfItemPerPageHandler: (itemsPerPage: string) => void
  apiSortHandler: (sortValue: string) => void
}
const DataTableV2 = ({
  columns,
  rows,
  noOfItemsPerPageOptions,
  ExpandedComponent,
  gridColumns,
  defaultSort,
  currentPage,
  noOfItemsPerPage,
  totalPages,
  isLoading,
  loaderType,
  moveNextHandler,
  movePrevHandler,
  updateNoOfItemPerPageHandler,
  apiSortHandler,
}: IDataTableProps) => {
  const sortHandler = ({ sortField, sortOrder, fieldType }) => {
    let sortDirection = SORT_ORDER.DESC.toLowerCase()
    if (sortOrder === 'ASCENDING') sortDirection = SORT_ORDER.ASC.toLowerCase()

    switch (sortField) {
      case 'name':
        return apiSortHandler(`name_${sortDirection}`)
      case 'businessName':
        return apiSortHandler(`businessName_${sortDirection}`)
      case 'created':
        return apiSortHandler(`createdAt_${sortDirection}`)
      default:
        return apiSortHandler(`activatedAt_${sortDirection}`)
    }
  }

  return (
    <div>
      <div className="overflow-hidden xs:w-full md:w-full lg:w-full overflow-x-auto">
        <table className="w-full">
          <TableHeader
            gridColumns={gridColumns}
            columns={columns}
            sortHandler={sortHandler}
            defaultSort={defaultSort}
          />
          {isLoading ? (
            <Loader loader={loaderType} />
          ) : (
            <TableBody
              gridColumns={gridColumns}
              columns={columns}
              rows={rows}
              ExpandedComponent={ExpandedComponent}
            />
          )}
        </table>
        {rows.length > 0 && (
          <TablePaginationV2
            options={noOfItemsPerPageOptions}
            noOfItemsPerPage={noOfItemsPerPage}
            currentPage={currentPage}
            totalPages={totalPages}
            moveNextHandler={moveNextHandler}
            movePreviousHandler={movePrevHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
          />
        )}
      </div>
    </div>
  )
}
DataTableV2.defaultProps = {
  ExpandedComponent: React.Fragment,
  defaultSort: {},
  loaderType: 'UserManagementLoader',
}
export default DataTableV2
