import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import UserApi from 'api/user'

import UserManagement from 'api/userManagement'
import { FORM, formKeys } from 'config'
import CalendarApi from 'api/calendar'
import { isSameObject, getRoleFromPath } from 'utils/helper'
import { ROLES, USER_STATUS } from 'config/enums'
import { ampli } from 'ampli'

import {
  ADD_USER_TO_GROUP,
  ENABLE_DISABLE_USER,
  GET_ADVISORS,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES,
  GET_ADVISORS_ACCOUNT_DETAILS_UM,
  GET_BUSINESSES_UM,
  GET_BUSINESS_AND_ASSESSMENT_RESPONSE,
  GET_USER_PROFILE_AND_ADVISOR,
  UPDATE_OWNER_PROFILE,
  UPDATE_ADVISOR_PROFILE,
} from 'store/types'
import {
  enableDisableUserAction,
  getAdvisorsAccountDetailsAction,
  getAdvisorsAccountDetailsUMAction,
  getAdvisorsAction,
  getBusinessesAction,
  getBusinessesUMAction,
  updateOwnerProfileAction,
  updateAdvisorProfileAction,
  openUserManagementEditModalAction,
  checkUserProfileAction,
  checkBusinessProfileAction,
  addUserToGroupAction,
  setTotalPagesAction,
} from 'store/actions/userManagement'

import hookForms from 'utils/hookForms'
import { getBoAdvisorAction } from 'store/actions/calendar'
import {
  getUserBusinessProfileAction,
  getUserProfileAction,
  updateUserProfileAction,
} from 'store/actions/user'
import { get, pickBy } from 'lodash'
import { getBoAdvisorsList } from 'store/selectors/userManagement'

import { setFormData, setProfileAction } from 'store/actions/form'
import { setFlashUrlParams } from 'utils/flashParameters'
import fetchAlgoliaData from 'utils/fetchAlgoliaData'
import { IFilter } from 'components/Admin/UserManagement/config/interfaces'
import { apiErrorHandler } from 'utils/error-handling/error-handlers/apiErrorHandler'

/* ----- BUSINESSES ------- */

type ApiPayload = {
  tenantId: string
  isPaginated: boolean
  allStatuses: boolean
}

export interface Error {
  message: string
  stack: string
}

function* getBusinessesUM(action) {
  const algoliaSearchKey = yield select((state) => state.user.user.algoliaSearchKey)
  const currentPage = yield select((state) => state.userManagement.currentPage)
  const noOfItemsPerPage = yield select((state) => state.userManagement.noOfItemsPerPage)
  const filterState = (yield select((state) => state.userManagement.filters)) as IFilter[]
  const sortValue = yield select((state) => state.userManagement.sortValue)
  let filters = 'role:"BUSINESS_OWNER"'
  let searchQuery = ''

  filterState.forEach((filter) => {
    if (filter.type === 'status' && filter.values.length) {
      filters += ' AND ' + filter.values.map((value) => `status:"${value}"`).join(' AND ')
    }

    if (filter.type === 'advisor' && filter.values.length) {
      filters +=
        ' AND ' +
        filter.values
          .map((value) => {
            if (value === 'not assigned') return 'isAdvisorAssigned:false'
            return `advisors.name:"${value}"`
          })
          .join(' AND ')
    }

    if (filter.type === 'search' && filter.values.length) {
      searchQuery += filter.values.map((value) => value).join(' ')
    }
  })

  const algoliaRes = yield call(fetchAlgoliaData, {
    page: currentPage,
    perPage: noOfItemsPerPage,
    searchQuery,
    filters,
    userAlgoliaSearchKey: algoliaSearchKey,
    sortValue,
  })
  const algoliaResList = { list: algoliaRes.data }

  if (algoliaRes) {
    yield put(getBusinessesUMAction.FULLFILLED(algoliaResList))
    yield put(setTotalPagesAction(algoliaRes.totalRows))
  } else {
    yield put(getBusinessesUMAction.FULLFILLED(null))
  }
}

function* getBusinesses(action) {
  /* ------ ALL_DATA ------- */
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    let payload: ApiPayload = {
      tenantId,
      isPaginated: false,
      allStatuses: action?.payload?.allStatuses,
    }

    let userResponse = yield call(UserManagement.getBusinesses, payload)
    userResponse = JSON.parse(userResponse?.getBusinesses?.data)

    // let userResponse = businessAccountDetails //dummyData

    if (userResponse) {
      yield put(getBusinessesAction.FULLFILLED(userResponse))
    } else {
      yield put(getBusinessesAction.FULLFILLED(null))
    }
  } catch (error) {
    yield put(getBusinessesAction.REJECTED('Error while getting Data'))
    console.log('error: ', error)
  }
}

/* ----- ADVISORS From Algolia ------- */
function* getBusinessesAdvisorsUMDetails() {
  const algoliaSearchKey = yield select((state) => state.user.user.algoliaSearchKey)
  const currentPage = yield select((state) => state.userManagement.currentPage)
  const noOfItemsPerPage = yield select((state) => state.userManagement.noOfItemsPerPage)
  const filterState = (yield select((state) => state.userManagement.filters)) as IFilter[]
  const sortValue = yield select((state) => state.userManagement.sortValue)
  let filters = 'role:"BSO_ADVISOR"'
  let searchQuery = ''

  filterState.forEach((filter) => {
    if (filter.type === 'status' && filter.values.length) {
      filters += ' AND ' + filter.values.map((value) => `status:"${value}"`).join(' AND ')
    }

    if (filter.type === 'search' && filter.values.length) {
      searchQuery += filter.values.map((value) => value).join(' ')
    }
  })

  const algoliaRes = yield call(fetchAlgoliaData, {
    page: currentPage,
    perPage: noOfItemsPerPage,
    searchQuery,
    filters,
    userAlgoliaSearchKey: algoliaSearchKey,
    sortValue,
  })
  const algoliaResList = { list: algoliaRes.data }

  if (algoliaRes) {
    yield put(getAdvisorsAccountDetailsUMAction.FULLFILLED(algoliaResList))
    yield put(setTotalPagesAction(algoliaRes.totalRows))
  } else {
    yield put(getAdvisorsAccountDetailsUMAction.FULLFILLED(null))
  }
}

/* ----- ADVISORS ------- */
function* getBusinessesAdvisorsDetails() {
  /* ------ ALL_DATA ------- */
  const tenantId = yield select((state) => state.user.tenantId)

  let payload: any = {}
  payload.tenantId = tenantId
  payload.isPaginated = false

  let userResponse = yield call(UserManagement.getAdvisors, payload)
  userResponse = JSON.parse(userResponse?.getAdvisors?.data)

  if (userResponse) {
    yield put(getAdvisorsAccountDetailsAction.FULLFILLED(userResponse))
  } else {
    yield put(getAdvisorsAccountDetailsAction.FULLFILLED(null))
  }
}

function* addUserToGroup(action) {
  const tenantId = yield select((state) => state.user?.tenantId)
  let { userId } = action.payload

  const res = yield call(UserApi.addUserToGroup, {
    role: 'BSO_ADMIN',
    userName: userId,
    remove: action?.payload?.remove ? action?.payload?.remove : undefined,
    tenantId,
  })
  if (res) yield put(addUserToGroupAction.FULLFILLED())
  else {
    setFlashUrlParams({ message: 'Unable To Give Admin Role' })
  }
}

function* getAdvisors(action) {
  const tenantId = yield select((state) => state.user.tenantId)

  let payload: any = action?.payload
  payload.tenantId = tenantId

  let userResponse = yield call(UserManagement.getAdvisors, payload)
  userResponse = JSON.parse(userResponse?.getAdvisors?.data)

  if (userResponse) {
    yield put(getAdvisorsAction.FULLFILLED(userResponse))
  } else {
    yield put(getAdvisorsAction.FULLFILLED(null))
  }
}

// BUSINESS_PROFILE
function* updateOwnerProfile(action) {
  const { userId } = action.payload
  const tenantId = yield select((state) => state.user.tenantId)

  const form = yield select((state) => state.form)
  try {
    const userProfileFormValues = hookForms.getForm(FORM.USER_PROFILE_FORM).getValues()
    const userManagementBusinessFormValues = hookForms
      .getForm(FORM.USER_MANAGEMENT_FORM)
      .getValues()

    userProfileFormValues.mobileContactNumber = userProfileFormValues?.mobileContactNumber?.replace(
      /\D/g,
      ''
    )
    const apiCalls: any = []
    if (!isSameObject(userProfileFormValues, form.USER_PROFILE_FORM)) {
      yield call(UserApi.updateUserProfile, {
        ...userProfileFormValues,
        userId,
        tenantId,
        advisors: userProfileFormValues.advisors,
      })
    }

    if (!isSameObject(userManagementBusinessFormValues, form.USER_MANAGEMENT_FORM))
      apiCalls.push(
        call(UserApi.updateBusinessProfile, {
          ...userManagementBusinessFormValues,
          id: userId,
          tenantId,
        })
      )
    if (apiCalls.length > 0) yield all(apiCalls)

    yield call(getBusinesses, { payload: { allStatuses: true } })
    yield put(openUserManagementEditModalAction(false))
    yield put(updateOwnerProfileAction.FULLFILLED())
  } catch (error: any) {
    const formattedError = apiErrorHandler(error)
    yield put(updateOwnerProfileAction.REJECTED(formattedError))
  }
}
function* getUserProfileAndAdvisors(action) {
  const tenantId = yield select((state) => state.user.tenantId)

  yield put(checkUserProfileAction(false))
  yield put(getUserProfileAction.FULLFILLED({ userProfile: {} }))
  yield put(openUserManagementEditModalAction(true))
  const res = yield call(UserApi.getUserProfile, action?.payload?.userId)
  const user = res?.getMyProfile
  const userProfile = res?.getMyProfile
  yield put(
    getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
  )
  const response = yield call(CalendarApi.getBoAdvisors, tenantId, action?.payload?.userId)
  yield put(getBoAdvisorAction.FULLFILLED(get(response, 'getBoAdvisors.data', [])))
  const advisors = yield select(getBoAdvisorsList)

  if (action.payload?.setForms?.length > 0) {
    const { setForms } = action.payload
    if (advisors && advisors.length > 0) {
      res.getMyProfile.advisors = advisors?.map((advisor) => advisor.value)
    }
    const payload = { forms: setForms, profile: res?.getMyProfile }
    yield put(setProfileAction(payload))
    yield put(checkUserProfileAction(true))
  }
}
function* getBusinessAndAssessmentResponse(action) {
  const { userId } = action.payload
  yield put(checkBusinessProfileAction(false))

  // Get Business profile
  const res = yield call(UserApi.getBusinessProfile, userId)
  yield put(getUserBusinessProfileAction.FULLFILLED(res?.getBusinessProfile))
  if (action.payload?.setForms?.length > 0) {
    const { setForms } = action.payload

    const businessProfile = pickBy(res.getBusinessProfile, (value, key) =>
      formKeys[setForms[0]].includes(key)
    )

    yield put(setFormData({ form: setForms[0], data: businessProfile }))
    yield put(checkBusinessProfileAction(true))
  }
}

export const getUserAssessments = (assessments) => {
  let userAssessments = (assessments = get(assessments, 'getAssessments', []))
  userAssessments = userAssessments
    .map((assessment) => ({
      ...assessment,
      meta: assessment?.meta ? JSON.parse(assessment.meta) : null,
      content: assessment?.content ? JSON.parse(assessment.content) : null,
      options: assessment?.options ? JSON.parse(assessment.options) : null,
      previous: assessment?.previous ? JSON.parse(assessment.previous) : [],
    }))
    .filter((assessment) => assessment?.meta?.showToBusinessProfile)
  return userAssessments
}

function* enableDisableUser(action) {
  try {
    const userId = action?.payload?.userId
    const tenantId = yield select((state) => state.user.tenantId)
    const loggedInUser = yield select((state) => state.user?.user)

    let payloadData = {
      status: action.payload.status,
      userId,
      tenantId,
    }
    yield call(UserApi.updateUserProfile, payloadData)

    if (action.payload?.status === USER_STATUS.DISABLED) {
      ampli.userDisabled({
        adminUserId: loggedInUser.id,
        adminUserName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
        disabledUserId: action.payload?.userId,
        disabledUserName: action.payload?.name,
        disabledUserRole: action.payload?.userRole,
      })
    }

    const res = yield call(UserApi.getUserProfile, action?.payload?.userId)

    const user = res?.getMyProfile
    const userProfile = res?.getMyProfile
    yield put(
      getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
    )
    yield put(enableDisableUserAction.FULLFILLED(true))
    yield call(getBusinesses, { payload: { allStatuses: true } })
    yield call(getBusinessesAdvisorsDetails)

    yield call(getUserProfileAndAdvisors, {
      payload: {
        userId,
        setForms: [FORM.USER_PROFILE_FORM],
      },
    })
    if (action.payload.isBusiness) {
      yield call(getBusinessAndAssessmentResponse, {
        payload: {
          userId,
          type: 'initial',
          setForms: [FORM.USER_MANAGEMENT_BUSINESS_FORM],
        },
      })
    }
  } catch (error) {
    console.log(error)
    yield put(enableDisableUserAction.REJECTED(true))
  }
}

function* updateAdvisorProfile(action) {
  const id = yield select((state) => state.user.user.id)
  const userRole = getRoleFromPath()
  const { userId } = action.payload
  const tenantId = yield select((state) => state.user.tenantId)

  const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
  const advisorAssignedOwner = hookForms.getForm(FORM.OWNERS)
  const advisorAssignedOwnerValues = advisorAssignedOwner.getValues()
  let userProfileFormValues = userProfileForm.getValues()

  userProfileFormValues.mobileContactNumber = userProfileFormValues?.mobileContactNumber?.replace(
    /\D/g,
    ''
  )
  const formId = userRole === ROLES.BSO_ADMIN ? FORM.ADVISOR_PROFILE_FORM : FORM.PROFILE_FORM
  userProfileFormValues = pickBy(userProfileFormValues, (value, key) =>
    formKeys[formId].includes(key)
  )
  try {
    yield call(UserApi.updateUserProfile, {
      ...userProfileFormValues,
      userId,
      tenantId,
      owners: advisorAssignedOwnerValues.owners,
    })
    yield put(updateAdvisorProfileAction.FULLFILLED())
    yield call(getBusinessesAdvisorsDetails)
    yield put(openUserManagementEditModalAction(false))
    yield put(updateAdvisorProfileAction.FULLFILLED())
    if (id === userId) {
      const userProfile = yield call(UserApi.getUserProfile)
      yield put(updateUserProfileAction.FULLFILLED(userProfile?.getMyProfile))
    }
  } catch (error: any) {
    console.log('error: ', error)
    const formattedError = apiErrorHandler(error)
    yield put(updateAdvisorProfileAction.REJECTED(formattedError))
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherUserManagement() {
  yield takeLatest(GET_BUSINESSES.STARTED, getBusinesses)
  yield takeLatest(GET_BUSINESSES_UM.STARTED, getBusinessesUM)
  yield takeLatest(GET_ADVISORS_ACCOUNT_DETAILS.STARTED, getBusinessesAdvisorsDetails)
  yield takeLatest(GET_ADVISORS_ACCOUNT_DETAILS_UM.STARTED, getBusinessesAdvisorsUMDetails)
  yield takeLatest(GET_ADVISORS.STARTED, getAdvisors)
  yield takeLatest(ADD_USER_TO_GROUP.STARTED, addUserToGroup)
  yield takeLatest(GET_USER_PROFILE_AND_ADVISOR.STARTED, getUserProfileAndAdvisors)
  yield takeLatest(UPDATE_OWNER_PROFILE.STARTED, updateOwnerProfile)
  yield takeLatest(GET_BUSINESS_AND_ASSESSMENT_RESPONSE.STARTED, getBusinessAndAssessmentResponse)
  yield takeLatest(ENABLE_DISABLE_USER.STARTED, enableDisableUser)
  yield takeLatest(UPDATE_ADVISOR_PROFILE.STARTED, updateAdvisorProfile)
}
