import React from 'react'
import { isMobileOnly } from 'mobile-device-detect'
import {
  DescriptionWrapper,
  PlayDescriptionWrapper,
} from 'components/Assessment/AssessmentQuestion/styles'
import { createMarkup } from 'components/Assessment/AssessmentQuestion/utils'

const Description = ({ text, playQuestion, user, businessName }) => {
  if (!text) return <></>
  return playQuestion ? (
    <PlayDescriptionWrapper id="description-play-description-wrapper" isMobileOnly={isMobileOnly}>
      {text}
    </PlayDescriptionWrapper>
  ) : (
    <DescriptionWrapper id="description-wrapper" isMobileOnly={isMobileOnly}>
      <div
        dangerouslySetInnerHTML={createMarkup(text, user, businessName)}
        // className={`font-medium inline font-primary text-base text-black-light`}
      />
    </DescriptionWrapper>
  )
}

export default Description
