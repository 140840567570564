import { CrossIcon, EllipseIcon } from 'components/Common/SvgIcons'
import { useChatContext } from 'stream-chat-react'
import { isMobileOnly } from 'mobile-device-detect'
import { getBusinessName, getChannelName } from 'components/Chat/helper'
import type { StreamChatGenerics } from 'components/Chat/types'
import { assessmentIcons } from 'config'
import get from 'lodash/get'
import React from 'react'

const UserProfile = ({
  usersAssessmentResponseByQuestion,
  setShowUserProfile = (boolean) => {},
}) => {
  const { channel: activeChannel, client } = useChatContext<StreamChatGenerics>()
  const findMembers: any = Object.values(get(activeChannel, 'state.members', {})).filter(
    (f: any) => f.user.id !== client.userID
  )
  let icon: any = null
  if (findMembers.length === 1) {
    icon = assessmentIcons.find(
      (icons) => icons.iconLabel === usersAssessmentResponseByQuestion[findMembers[0].user.id]?.icon
    )
  }

  return (
    <div
      className={`${
        isMobileOnly ? 'h-screen  bg-cream-dark' : ''
      } xs:col-span-12 col-span-3 xl:col-span-3 xl:h-[50vh] xs:pt-10 md:pt-0 pb-5`}
    >
      {/* {isMobileOnly && (
        <div className="bg-white z-10 absolute top-0 w-full mb-10 border-b-[1px] border-secondary-text">
          <div className="flex flex-row justify-between items-center px-5 h-[80px]">
            <div className="text-primary-text text-xl font-primary">Files Received</div>
            <span onClick={() => setShowUserProfile(false)}>
              <CrossIcon className={'fill-silver  h-[18px] w-[18px] cursor-pointer'} />
            </span>
          </div>
        </div>
      )} */}
      <div className="bg-white z-10 absolute top-0 w-full mb-10 border-b-[1px] border-secondary-text mdl:block xl:hidden hidden xs:block">
        <div className="flex flex-row justify-between items-center px-6  mdl:h-[72px] sm:h-[80px] xs:h-[80px]">
          <div className="text-primary-text text-xl font-primary ">Files Received</div>
          <span onClick={() => setShowUserProfile(false)}>
            <CrossIcon className={'fill-silver  h-[18px] w-[18px] cursor-pointer'} />
          </span>
        </div>
      </div>
      <div className="xs:col-span-12 col-span-3 xl:col-span-3 mx-5 xs:pt-10 md:pt-0 pb-5">
        <div className="flex justify-center items-center mt-5 flex-col mdl:hidden xl:flex xl:mt-24">
          {icon ? (
            <icon.value className="bg-tertiary rounded-full h-[118px] w-[118px] fill-white" />
          ) : (
            <EllipseIcon className={'h-[118px] w-[118px] fill-tertiary'} />
          )}

          <label className="text-3xl font-primary text-primary-text my-3">
            {getChannelName(findMembers)}
          </label>
          <label className="text-base font-primary text-primary-text">
            {getBusinessName(findMembers)}
          </label>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
