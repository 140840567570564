import { useMutation, useQueryClient } from 'react-query'
import { OperationName } from 'types'

export const useFetchChartData = <I, O>(
  onFetchData: (params: I) => Promise<O | null | undefined>,
  queryKey?: OperationName
) => {
  const client = useQueryClient()
  return useMutation(onFetchData, {
    onSuccess: () => {
      // invalidate the query cache
      if (queryKey) {
        client.invalidateQueries(queryKey)
      }
    },
  })
}
