import styled from 'styled-components'
import tw from 'twin.macro'

export interface IResponsiveProps {
  isMobileOnly: boolean
}

export const TitleContainer = styled.div.attrs({
  className:
    'xs:max-w-full sm:max-w-[100%] md:max-w-[100%] lg:max-w-[100%] xl:max-w-[100%] text-center',
})<IResponsiveProps>`
  ${(p) => (p.isMobileOnly ? tw`text-2xl` : tw`text-4xl`)}
  span {
    ${tw`font-secondary `}
  }
`
export const InfoContainer = styled.div.attrs({
  className: 'flex my-6 gap-2 w-full',
})``

export const InfoWrapper = styled.div.attrs({
  className: 'max-w-[100%] text-left text-base',
})``

export const LabelWrapper = styled.div.attrs({
  className: 'text-left font-primary text-sm font-semibold my-2',
})``

export const Label = styled.label.attrs({
  className: 'text-primary-error',
})``
export const ButtonWrapper = styled.div.attrs({
  className: 'mt-5 mb-[10px] font-normal text-primary-text max-w-[400px] m-auto',
})<IResponsiveProps>`
  ${(p) => (p.isMobileOnly ? tw`px-5` : '')}
`

export const CheckboxWrapper = styled.div.attrs({
  className: 'flex justify-center flex-col items-center',
})``

export const DescriptionWrapper = styled.div.attrs({
  className: 'pt-6 text-left text-black-light font-primary mt-4',
})<IResponsiveProps>`
  ${(p) => (p.isMobileOnly ? tw`text-base` : '')}
`
export const PlayDescriptionWrapper = styled.div.attrs({
  className: 'font-normal text-primary-text font-primary mt-4',
})<IResponsiveProps>`
  ${tw`text-lg sm:text-xl`}
`
export const SelectFieldWrapper = styled.div`
  .select__control {
    background-color: white;
  }
  .select__dropdown-indicator {
    padding: 6px;
  }
`
