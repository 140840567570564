import React from 'react'
import { AppFC } from 'types'

import { getInitials } from 'components/Chat/helper'

interface IProfilePictureProps {
  src?: string
  firstName?: string
  lastName?: string
}

const ProfilePicture: AppFC<IProfilePictureProps> = ({ src, firstName = '', lastName = '' }) => {
  const initials = getInitials({ firstName, lastName })

  return (
    <div className="w-[122px] h-[122px] flex-shrink-0 rounded-full bg-white flex items-center justify-center">
      <div className="h-full w-full flex justify-center items-center p-5">
        {src ? (
          <img src={src} className="object-cover w-full h-full rounded-full" alt="profile-pic" />
        ) : (
          <span
            className={`rounded-full inline-flex justify-center items-center text-black-light font-bold uppercase text-5xl`}
          >
            {initials}
          </span>
        )}
      </div>
    </div>
  )
}

export default ProfilePicture
