import React, { FC, useEffect, useState } from 'react'
import DatePicker, { useDatePickerHandler } from 'components/Common/DatePicker'
import GrowingContainer from 'components/Common/GrowingContainer'
import Growth from 'components/Owner/Data/Product/Growth'
import TopSellingProducts from 'components/Owner/Data/Product/TopSellingProducts'
import {
  chartRangeChanged,
  chartRangeViewed,
  getRoleFromPath,
  helpDesk,
  navigateTo,
} from 'utils/helper'
import SalesSources from 'components/Owner/Data/Product/SalesSources'
import { PageHeading } from 'components/Owner/Data/styles'
import { IChartNames, IDataTabs, ROLES } from 'config'
const Product: FC<any> = () => {
  const { selectedDate, handleClick, formattedDate, handleNextDate, handlePreviousDate } =
    useDatePickerHandler()
  const [datePickerOpened, setDatePickerOpened] = useState(false)
  const section = IDataTabs.PRODUCT
  const chartName = [
    IChartNames.TOP_GROSS_SALES_BY_CHANNEL,
    IChartNames.SALES_GROWTH_RATE,
    IChartNames.TOP_SELLING_PRODUCTS,
  ]

  const onClickHandler = (startDate: string, endDate: string, type: string) => {
    handleClick(startDate, endDate, type)
    chartRangeChanged({ section, chartName, startDate, endDate })
  }
  const handleNext = () => {
    const { startDate, endDate } = handleNextDate()
    chartRangeChanged({ section, chartName, startDate, endDate })
  }

  const handlePrevious = () => {
    const { startDate, endDate } = handlePreviousDate()
    chartRangeChanged({ section, chartName, startDate, endDate })
  }

  useEffect(() => {
    if (datePickerOpened) {
      chartRangeViewed({ section, chartName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerOpened])

  return (
    <GrowingContainer>
      <div className="grid col-span-12">
        <div className="flex flex-col smd:flex-row smd:justify-between py-7 px-5">
          <div className="flex flex-col">
            <PageHeading>Product</PageHeading>
            {getRoleFromPath() === ROLES.BUSINESS_OWNER && (
              <p className="leading-9 text-black mb-2 font-primary mt-4">
                If you haven’t connected your Square account to our system, please visit{' '}
                <span
                  className="text-primary hover:cursor-pointer"
                  onClick={() => navigateTo('', 'tools/featured')}
                >
                  Tools
                </span>
                .
              </p>
            )}
            <p className="leading-9 text-black mb-4 font-primary">
              Once Square is connected, you should see your data in the charts 24 hours after
              connecting. If you are still having problems, please{' '}
              <span className="text-primary hover:cursor-pointer" onClick={() => helpDesk()}>
                contact support.
              </span>
            </p>
          </div>
          <div className="flex mt-6 smd:mt-0 smd:justify-end">
            <DatePicker
              handleClick={onClickHandler}
              value={formattedDate}
              onNextDate={handleNext}
              onPreviousDate={handlePrevious}
              datePickerOpened={datePickerOpened}
              setDatePickerOpened={setDatePickerOpened}
            />
            <div className="clearfix"></div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-5">
          <div className="xs:col-span-12 md:col-span-8 bg-secondary h-fit rounded-xl ">
            <TopSellingProducts selectedDate={selectedDate} />
          </div>
          <div className="xs:col-span-12 md:col-span-4 ">
            <Growth selectedDate={selectedDate} />
          </div>
          <div className="col-span-12 bg-secondary rounded-xl ">
            <SalesSources selectedDate={selectedDate} />
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default Product
