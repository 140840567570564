import styled from 'styled-components'
import tw from 'twin.macro'

interface IButtonTabsWrapperProps {
  tabsCount: number
}

export const ButtonTabsWrapper = styled.div((props: IButtonTabsWrapperProps) => {
  return [
    tw`gap-[5px] flex flex-shrink`,
    ` sm:grid-template-columns: repeat(${props.tabsCount}, minmax(0, 1fr))`,
  ]
})

interface IButtonTabWrapperProps {
  isSelected: boolean
  size: 'lg' | 'base'
}

export const ButtonTabWrapper = styled.div((props: IButtonTabWrapperProps) => {
  const conditionalStyles = [
    props.isSelected
      ? tw`bg-white text-tertiary shadow-base`
      : tw`bg-transparent text-primary-focus`,
    { base: tw`text-base`, lg: tw`text-lg` }[props.size],
  ]
  return [
    tw`min-w-[137px] sm:min-w-[164px] rounded-t-[30px] cursor-pointer flex font-primary col-span-1 flex justify-center items-center p-4 text-center`,
    `font-weight: 650;`,
    conditionalStyles,
  ]
})
