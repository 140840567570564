import React from 'react'
import { isMobileOnly } from 'mobile-device-detect'

import { createMarkup } from 'components/Assessment/AssessmentQuestion/utils'
import { TitleContainer } from 'components/Assessment/AssessmentQuestion/styles'

const Title = ({ user, text, businessName, marginTop = '', id = '' }) => {
  return (
    <>
      {text && (
        <TitleContainer id={id} isMobileOnly={isMobileOnly}>
          {
            <div
              dangerouslySetInnerHTML={createMarkup(text, user, businessName)}
              className={`${
                marginTop ? marginTop : 'mt-6'
              } font-secondary font-medium text-left xs:text-4xl xs:leading-10 `}
            />
          }
        </TitleContainer>
      )}
    </>
  )
}

export default Title
