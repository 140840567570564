import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'components/Common/Button'
import {
  UpComingMeetingMobileViewContainer,
  UpcomingMeetingContainer,
} from 'pages/Clients/container'

const Meeting = () => {
  let { clientId } = useParams()
  const [showUpComingMeeting, setShowUpComingMeeting] = useState(true)

  return (
    <>
      <div className="xs:py-11 md:p-6 mdl:px-6 mdl:py-12">
        <div className="px-6">
          <div className="xs:text-center sm:text-left  text-2xl font-primary text-primary-text leading-8">
            Meeting Record
          </div>
          <div className="flex gap-2 py-8 justify-start sm:w-[33%]">
            <Button
              label={'Upcoming'}
              variant={`${showUpComingMeeting ? 'primary' : 'secondary'}`}
              onClick={() => setShowUpComingMeeting(true)}
              className="w-full"
            />
            <Button
              label={'Previous'}
              variant={`${!showUpComingMeeting ? 'primary' : 'secondary'}`}
              onClick={() => setShowUpComingMeeting(false)}
              className="w-full"
            />
          </div>
        </div>
        <div className="hidden md:block">
          <UpcomingMeetingContainer filter={`${showUpComingMeeting ? 'upcoming' : 'previous'} `} />
        </div>
        <div className="block md:hidden">
          <UpComingMeetingMobileViewContainer
            filter={`${showUpComingMeeting ? 'upcoming' : 'previous'} `}
            ownerId={clientId}
          />
        </div>
      </div>
    </>
  )
}

export default Meeting
