import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import * as yup from 'yup'

const schemaBuilder = new ValidationSchemaBuilder()

const validations = {
  businessOwnerProfileForm: schemaBuilder
    .firstName()
    .lastName()
    .email()
    .mobileContactNumber()
    .getSchema(),
  advisorProfileForm: schemaBuilder
    .firstName()
    .lastName()
    .email()
    .mobileContactNumber()
    .getSchema(),
  profileForm: schemaBuilder.firstName().lastName().mobileContactNumber().getSchema(),
  businessProfileForm: schemaBuilder
    .custom({
      businessName: yup.string().nullable(true),
    })
    .getSchema(),
  advisorBusinessProfileForm: schemaBuilder.mobileContactNumber().getSchema(),
}

export default validations
