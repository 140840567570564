import React from 'react'
import { AppFC } from 'types'
import { LinkWrapper } from './style'

export interface ILinkProps {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
  showUnderline?: 'hover' | 'always'
  variant?: 'basic' | 'primary' | 'white'
}

const Link: AppFC<ILinkProps> = ({
  children,
  onClick,
  className = '',
  showUnderline = 'hover',
  variant = 'primary',
  size = 'base',
}) => {
  return (
    <LinkWrapper
      showUnderline={showUnderline}
      variant={variant}
      size={size}
      onClick={onClick}
      className={className}
    >
      {children}
    </LinkWrapper>
  )
}

export default Link
