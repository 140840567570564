import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AdvisorPlayBookAndToolsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="0" rx="0" ry="0" width="1" height="49" />
        <rect x="0" y="49" rx="0" ry="0" width="449" height="1" />
        <rect x="0" y="0" rx="0" ry="0" width="449" height="1" />
        <rect x="449" y="0" rx="0" ry="0" width="1" height="50" />

        <circle cx="28" cy="25" r="17" />
        <rect x="50" y="16" rx="0" ry="0" width="210" height="15" />
        <rect x="424" y="16" rx="0" ry="0" width="10" height="15" />

        <rect x="0" y="60" rx="0" ry="0" width="1" height="49" />
        <rect x="0" y="109" rx="0" ry="0" width="449" height="1" />
        <rect x="0" y="60" rx="0" ry="0" width="449" height="1" />
        <rect x="449" y="60" rx="0" ry="0" width="1" height="50" />

        <circle cx="28" cy="84" r="17" />
        <rect x="50" y="76" rx="0" ry="0" width="210" height="15" />
        <rect x="424" y="76" rx="0" ry="0" width="10" height="15" />

        <rect x="0" y="120" rx="0" ry="0" width="1" height="49" />
        <rect x="0" y="169" rx="0" ry="0" width="449" height="1" />
        <rect x="0" y="120" rx="0" ry="0" width="449" height="1" />
        <rect x="449" y="120" rx="0" ry="0" width="1" height="50" />

        <circle cx="28" cy="143" r="17" />
        <rect x="50" y="136" rx="0" ry="0" width="210" height="15" />
        <rect x="424" y="136" rx="0" ry="0" width="10" height="15" />

        <rect x="0" y="180" rx="0" ry="0" width="1" height="49" />
        <rect x="0" y="229" rx="0" ry="0" width="449" height="1" />
        <rect x="0" y="180" rx="0" ry="0" width="449" height="1" />
        <rect x="449" y="180" rx="0" ry="0" width="1" height="50" />

        <circle cx="28" cy="201" r="17" />
        <rect x="50" y="196" rx="0" ry="0" width="210" height="15" />
        <rect x="424" y="196" rx="0" ry="0" width="10" height="15" />
      </ContentLoader>
    </>
  )
}

export default AdvisorPlayBookAndToolsLoader
