import React, { FC, ReactElement, Children, useMemo, useCallback } from 'react'
import { AppFC } from 'types'

interface IButtonTabsV2Props {
  activeTab: string
  onChange: (activeTab: string) => void
  children: object[]
  size?: 'lg' | 'base'
  classes?: string
}
interface ITabProps {
  name: string
  title: string
  isEnabled?: boolean
  component: ReactElement
}

interface IChildProps {
  title: string
  isEnabled?: boolean
  name: string
}

export const Tab: AppFC<ITabProps> = ({ component: Component }) => <div>{Component}</div>

const ButtonTabsV2: FC<IButtonTabsV2Props> = ({
  activeTab,
  onChange,
  children,
  size = 'base',
  classes,
}) => {
  const handleTabChange = (activeTab: string) => {
    onChange(activeTab)
  }
  const fontSize = useMemo(() => `text-${size}`, [size])

  const getClasses = useCallback(
    (name) => {
      return `
    ${activeTab === name ? 'bg-white text-tertiary' : 'bg-transparent text-primary-focus'}
 min-w-[137px] sm:min-w-[164px] rounded-t-[30px] cursor-pointer flex font-primary col-span-1 flex justify-center items-center p-4 text-center font-[650] ${fontSize}`
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Tab, fontSize, activeTab]
  )

  return (
    <>
      <div className={`w-full flex overflow-auto`}>
        <div className={'gap-4 flex'}>
          {Children.map(children as React.ReactElement, (child, index) => {
            const { title, isEnabled = true, name } = child.props as IChildProps

            return (
              isEnabled && (
                <li
                  key={index}
                  className={`${getClasses(name)}`}
                  onClick={() => handleTabChange(name)}
                >
                  {title}
                </li>
              )
            )
          })}
        </div>
      </div>
      <div className={`tab-content  ${classes ?? ''}`}>
        {Children.map(
          children as React.ReactElement,
          (child) => activeTab === child.props.name && child
        )}
      </div>
    </>
  )
}

export default ButtonTabsV2
