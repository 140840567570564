import React from 'react'

function ToolsIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width={'40px'}
      height={'39.9px'}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M507.4 114.5c-2.25-9.5-9.626-17-19-19.63c-9.501-2.625-19.63 0-26.63 7L397.9 165.7l-44.25-7.375l-7.376-44.25l63.88-63.88c6.876-6.875 9.626-17.13 7.001-26.5c-2.625-9.5-10.25-16.88-19.75-19.25c-51.75-12.75-105.4 2-143.3 39.75C216.2 82.12 201.9 136.9 215.5 188.1l-193.3 193.3c-29.63 29.63-29.63 77.88 0 107.5C36.47 504.1 55.6 512 75.98 512c20.25 0 39.25-7.875 53.63-22.25l193.1-193.1c52.13 13.75 106.9-.75 144.9-38.88C505.5 219.1 520.4 166.4 507.4 114.5zM445 235.2c-31.75 31.75-78.38 42.63-121.8 28.13l-9.376-3.125L106.1 467.1c-16.63 16.63-45.5 16.63-62.13 0c-17.13-17.13-17.13-45.13 0-62.25l207-207L248.7 188.6c-14.38-43.5-3.625-90.13 28-121.8c22.75-22.63 52.75-34.88 83.76-34.88c6.876 0 13.88 .625 20.75 1.75l-69.26 69.38l13.75 83l83.13 13.88l69.26-69.38C484.9 168.9 472.8 207.5 445 235.2zM79.99 415.1c-8.876 0-16 7.125-16 16s7.125 16 16 16s16-7.125 16-16S88.86 415.1 79.99 415.1z" />{' '}
    </svg>
  )
}
export default ToolsIcon
