import React, { useState, useEffect } from 'react'

import { checkFileTypeHandler } from 'components/Assessment/AssessmentQuestion/utils'
import { UploadFileWrapper } from 'components/Assessment/style'

import UploadFileField from 'components/Common/UploadFileField'
import { isEmpty } from 'lodash'

const Upload = ({
  userId,
  isMultiple,
  uploadType,
  uploadedFile,
  activeQuestion,
  uploadFileAction,
  uploadFileLoading,
  setSelectedOption,
  isCompletedAssessment,
}) => {
  const [link, setLink]: any = useState('')
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    if (!isEmpty(uploadedFile)) {
      setPreview(true)
      setLink(uploadedFile)
      setSelectedOption(uploadedFile)
    }
  }, [uploadedFile, setSelectedOption])

  useEffect(() => {
    return () => {
      setPreview(false)
      setLink('')
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (uploadedFile) => {
    if (!isEmpty(link)) {
      setPreview(true)
    } else if (uploadedFile) {
      uploadFileAction({
        files: [uploadedFile],
        userId,
        isMultiple,
      })
    }
  }

  return (
    <div>
      <div className="w-full">
        {!preview && (
          <div className="mt-3">
            <div>
              <UploadFileWrapper>
                <UploadFileField
                  type={'file'}
                  id="file_input"
                  className="text-lg"
                  label={uploadFileLoading ? 'Uploading' : activeQuestion?.options[0]?.value}
                  onChange={(e) => {
                    let fileData: any = e.target.files
                    let { isError, uploadedFile } = checkFileTypeHandler(
                      uploadType,
                      fileData,
                      activeQuestion?.meta?.fileExtension || ''
                    )
                    if (isError) return
                    handleSubmit(uploadedFile)
                  }}
                  disabled={isCompletedAssessment}
                  isLoading={uploadFileLoading}
                />
              </UploadFileWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
Upload.defaultProps = {
  uploadType: 'image',
  userFiles: [],
}

export default Upload
