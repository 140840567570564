import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import SelectField from 'components/Common/SelectField'
import { FIELD_NAME, LABEL, assessmentOption, industry } from 'config'
import { SelectFieldWrapper } from 'components/Admin/BusinessOwnerStepper/style'

import { getBusinessLocationOptions } from 'utils/helper'
import { BusinessProfileMultiSelectFieldsContainer } from 'pages/Admin/container'
import useRegisterIndustryContainer from 'hooks/useRegisterIndustryContainer'

const AdditionalBusinessProfile = ({
  options,
  control,
  isDirty,
  setValue,
  formValues,
  unregister,
  isEditClient,
  isOwnerCreation,
  isUserManagement,
  getAssessmentOptionsAction,
}) => {
  useEffect(() => {
    getAssessmentOptionsAction(assessmentOption.initial)
  }, [getAssessmentOptionsAction])

  useRegisterIndustryContainer(formValues, unregister, isDirty, setValue)
  return (
    <>
      {(isUserManagement || isEditClient) && (
        <SelectFieldWrapper applyStyling={isUserManagement}>
          <Controller
            name={FIELD_NAME.BUSINESS_STAGE_OPTION_ID}
            control={control}
            render={({ field }) => (
              <SelectField
                placeholder={`Select ${LABEL.BUSINESS_STAGE}`}
                {...field}
                classes={'mt-4'}
                label={LABEL.BUSINESS_STAGE}
                options={options[FIELD_NAME.BUSINESS_STAGE]}
              />
            )}
          />
        </SelectFieldWrapper>
      )}

      <SelectFieldWrapper applyStyling={isUserManagement}>
        <Controller
          name={FIELD_NAME.BUSINESS_CLASSIFICATION_OPTION_ID}
          control={control}
          render={({ field }) => (
            <SelectField
              placeholder={`Select ${LABEL.INDUSTRY}`}
              {...field}
              classes={'mt-4'}
              label={
                isEditClient || isUserManagement
                  ? LABEL.INDUSTRY
                  : LABEL.CREATION_BUSINESSCLASSIFICATION
              }
              options={options[FIELD_NAME.BUSINESS_CLASSIFICATION]}
            />
          )}
        />
      </SelectFieldWrapper>

      {formValues?.businessClassificationOptionId?.trim() === industry.foodId && (
        <SelectFieldWrapper applyStyling={isUserManagement}>
          <Controller
            name={FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS}
            control={control}
            render={({ field }) => (
              <SelectField
                placeholder="Select Food Industry Type"
                isMulti={true}
                {...field}
                classes="mt-4"
                label={LABEL.FOOD_INDUSTRY}
                options={options[FIELD_NAME.FOOD_SUB_INDUSTRY]}
              />
            )}
          />
        </SelectFieldWrapper>
      )}

      {formValues?.businessClassificationOptionId?.trim() === industry.servicesId && (
        <SelectFieldWrapper applyStyling={isUserManagement}>
          <Controller
            name={FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID}
            control={control}
            render={({ field }) => (
              <SelectField
                placeholder="Select Services Industry Type"
                isMulti={false}
                {...field}
                classes="mt-4"
                label={LABEL.SERVICES_INDUSTRY}
                options={options[FIELD_NAME.SERVICES_SUB_INDUSTRY]}
              />
            )}
          />
        </SelectFieldWrapper>
      )}

      {formValues?.businessClassificationOptionId &&
        formValues?.businessClassificationOptionId?.trim() !== industry.foodId && (
          <SelectFieldWrapper applyStyling={isUserManagement}>
            <Controller
              name={FIELD_NAME.BUSINESS_LOCATIONS_OPTION_IDS}
              control={control}
              render={({ field }) => (
                <SelectField
                  placeholder={`Select ${LABEL.BUSINESS_LOCATION}`}
                  {...field}
                  isMulti={true}
                  classes="mt-4"
                  label={
                    isOwnerCreation ? LABEL.CREATION_BUSINESS_LOCATION : LABEL.BUSINESS_LOCATION
                  }
                  options={getBusinessLocationOptions(formValues, options)}
                />
              )}
            />
          </SelectFieldWrapper>
        )}
      {isUserManagement && (
        <BusinessProfileMultiSelectFieldsContainer
          control={control}
          applyStyling={isUserManagement}
        />
      )}
      {isUserManagement && (
        <SelectFieldWrapper applyStyling={isUserManagement}>
          <Controller
            name={FIELD_NAME.TOP_GOAL_OPTION_ID}
            control={control}
            render={({ field }) => (
              <SelectField
                placeholder={`Select ${LABEL.TOP_GOAL}`}
                {...field}
                classes={'mt-4'}
                label={LABEL.TOP_GOAL}
                options={options[FIELD_NAME.TOP_GOAL]}
              />
            )}
          />
        </SelectFieldWrapper>
      )}
    </>
  )
}

AdditionalBusinessProfile.defaultProps = {
  isUserManagement: false,
  isEditClient: false,
  isOwnerCreation: false,
}

export default AdditionalBusinessProfile
