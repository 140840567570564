import React from 'react'

function MinusIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.6181 7.765C12.6181 8.30187 12.1844 8.73562 11.6475 8.73562H0.970625C0.433748 8.73562 0 8.30187 0 7.765C0 7.22812 0.433748 6.79437 0.970625 6.79437H11.6475C12.1844 6.79437 12.6181 7.22812 12.6181 7.765Z" />
    </svg>
  )
}

MinusIcon.defaultProps = {
  className: '',
}
export default MinusIcon
