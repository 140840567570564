import React from 'react'
import { PieChart, Pie, Cell, Sector, Tooltip } from 'recharts'
import { COLORS } from '../../../../pages/DataReports/styles'

export interface IClientDataDonutChartProps {
  data: { name?: string | null; value?: number | null }[]
  total: number
  width?: number
  height?: number
}
const CustomTooltip = ({
  data,
  total,
}: {
  data: { name?: string | null; value?: number | null }
  total: number
}) => {
  if (data?.value && total) {
    const percentage = ((data.value / total) * 100).toFixed(2)
    return (
      <div className="border border-solid border-silver-lighter rounded p-1 bg-white opacity-80">
        <p>Label: {data.name}</p>
        <p>Count: {data.value}</p>
        <p>Percentage: {percentage} %</p>
      </div>
    )
  }

  return null
}

const renderActiveShape = (total) => (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props
  return (
    <g>
      <text x={cx} y={cy - 10} dy={8} textAnchor="middle" className="font-bold text-xl">
        {total}
      </text>
      <text x={cx} y={cy + 10} dy={8} textAnchor="middle">
        Active
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}

export const ClientDataDonutChart = ({
  data,
  total,
  width,
  height,
}: IClientDataDonutChartProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const activeData = data[activeIndex]
  return (
    <PieChart width={width ?? 160} height={height ?? 150}>
      <Pie
        activeIndex={activeIndex}
        data={data}
        innerRadius={60}
        outerRadius={70}
        isAnimationActive={false}
        dataKey="value"
        activeShape={renderActiveShape(total)}
        onMouseEnter={(data, index) => setActiveIndex(index)}
      >
        {data.map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={total === 0 ? '#D4D4D8' : COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip total={total} data={activeData} />} />
    </PieChart>
  )
}
