import { SET_ACTIVE_STEP, INITIALIZE_STEPPER } from 'store/types'

export const setActiveStepAction = (payload) => ({
  type: SET_ACTIVE_STEP,
  payload,
})

export const initializeStepperAction = (payload) => ({
  type: INITIALIZE_STEPPER,
  payload,
})
