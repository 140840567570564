import { IndustryReport } from '__generated__/api-types-and-hooks'
import { BSOProfile } from 'store/reducers/bso'
import { SearchIndustryReportPayload } from 'store/sagas/bso'
import { GET_BSO_PROFILE, SEARCH_INDUSTRY_REPORT } from 'store/types'

export const getBSOProfileAction = {
  STARTED: () => ({
    type: GET_BSO_PROFILE.STARTED,
  }),
  FULLFILLED: (bsoProfile: BSOProfile) => ({
    type: GET_BSO_PROFILE.FULLFILLED,
    payload: bsoProfile,
  }),
  REJECTED: () => ({
    type: GET_BSO_PROFILE.REJECTED,
  }),
  RESET: () => ({
    type: GET_BSO_PROFILE.RESET,
  }),
}

export const searchIndustryReportAction = {
  STARTED: (payload: SearchIndustryReportPayload) => ({
    type: SEARCH_INDUSTRY_REPORT.STARTED,
    payload,
  }),
  FULLFILLED: (industryReport: IndustryReport) => ({
    type: SEARCH_INDUSTRY_REPORT.FULLFILLED,
    payload: industryReport,
  }),
  REJECTED: (error) => ({
    type: SEARCH_INDUSTRY_REPORT.REJECTED,
    error,
  }),
}
