import React, { useEffect } from 'react'

import { Controller } from 'react-hook-form'

import { FORM } from 'config'
import InputField from 'components/Common/InputField'
import useFormContainer from 'hooks/useFormContainer'
import Loader from 'components/Loaders'
import { isEmpty } from 'lodash'
import ErrorMessage from '../ErrorMessage'

// THIS COMPONENT IS DUPLICATED IN-PART ELSEWHERE
// TODO: CLEAN IT UP
const EditUserProfile = ({
  checkformValueChanged,
  isUserProfileFormSet,
  checkErrors,
  onSubmit,
  resetErrorAction,
  error,
  errors,
  isValid,
  isDirty,
  control,
  register,
  methods,
  handleSubmit,
  mobileContactError,
}) => {
  useEffect(() => {
    checkErrors(errors, isValid)
    // eslint-disable-next-line
  }, [checkErrors, Object.keys(errors), isValid])

  useEffect(() => {
    checkformValueChanged(isDirty)
  }, [checkformValueChanged, isDirty])
  useFormContainer(FORM.USER_PROFILE_FORM, methods)

  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3 border-r border-grey-darker !pr-5">
      <div className="my-8">
        <label className="font-normal text-2xl">User Profile</label>
      </div>
      {!isUserProfileFormSet ? (
        <Loader loader="AdvisorEditOwnerDetail" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid xs:grid-cols-12  gap-3">
            <div className="col-span-12">
              <InputField
                errors={errors.firstName}
                type={'text'}
                label="First Name"
                {...register('firstName')}
              />
            </div>
            <div className="col-span-12">
              <InputField
                errors={errors.lastName}
                type={'text'}
                label="Last Name"
                {...register('lastName')}
              />
            </div>
            <div className="col-span-12">
              <InputField
                errors={errors.email}
                type={'email'}
                label="Email Address (Sign-in)"
                {...register('email')}
                disabled={true}
              />
            </div>
            <div className="col-span-12">
              <Controller
                name={'mobileContactNumber'}
                control={control}
                render={({ field }) => (
                  <InputField
                    type="text"
                    placeholder="(XXX) XXX-XXXX"
                    mask="(999) 999-9999"
                    label="Phone Number"
                    value={field.value}
                    errors={errors.mobileContactNumber || mobileContactError}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      if (!isEmpty(mobileContactError)) {
                        resetErrorAction()
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>
        </form>
      )}
      {error && <ErrorMessage id="client-error">{error.messages}</ErrorMessage>}
    </div>
  )
}

export default EditUserProfile
