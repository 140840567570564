import { pathName } from 'config'
import useNavigateTo from 'hooks/useNavigateTo'
import { useCallback } from 'react'

/** Navigates to a play or assessment */
/*Added isCompleted parameter to redirect on review assessment */
export default function useNavigateToRecommendation({ id, category, isCompleted = false }) {
  const location = window.location.href.split('/')
  let path = location[location.length - 1]
  path = window.location.href.includes(pathName.playBooksCategory)
    ? `${pathName.playBooksCategory}/${path}`
    : path

  const navItemId = {
    assessment: isCompleted ? 'review' : 'assessmentQuestion',
    play: 'plays',
  }[category]

  const extension = {
    play: `/${id}/guide?returnTo=${path}`,
    assessment: isCompleted ? `/${id}/review?returnTo=${path}` : `/${id}?returnTo=${path}`,
  }[category]

  const basicNavigate = useNavigateTo(navItemId, extension)

  const navigate = useCallback(() => {
    basicNavigate()
  }, [basicNavigate])

  return navigate
}
