import React from 'react'

function CsvIcon({ className }) {
  return (
    <svg
      className={className}
      width="34"
      height="46"
      viewBox="0 0 34 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8333 0V11.5C19.8333 13.0902 21.0995 14.375 22.6667 14.375H34V40.25C34 43.4215 31.4589 46 28.3333 46H5.66667C2.53672 46 0 43.4215 0 40.25V5.75C0 2.57402 2.53672 0 5.66667 0H19.8333ZM7.08333 20.125C5.12745 20.125 3.54167 21.7332 3.54167 23.7188V30.9062C3.54167 32.8918 5.12745 34.5 7.08333 34.5H8.5C10.4568 34.5 12.0417 32.8918 12.0417 30.9062V30.1875C12.0417 29.3969 11.4042 28.75 10.625 28.75C9.84583 28.75 9.20833 29.3969 9.20833 30.1875V30.9062C9.20833 31.3016 8.88958 31.625 8.5 31.625H7.08333C6.69198 31.625 6.375 31.3016 6.375 30.9062V23.7188C6.375 23.3234 6.69198 23 7.08333 23H8.5C8.88958 23 9.20833 23.3234 9.20833 23.7188V24.4375C9.20833 25.2281 9.84583 25.875 10.625 25.875C11.4042 25.875 12.0417 25.2281 12.0417 24.4375V23.7188C12.0417 21.7332 10.4568 20.125 8.5 20.125H7.08333ZM15.5302 27.9055L17.7792 29.2082C18.1687 29.4418 18.4167 29.873 18.4167 30.3313C18.4167 31.05 17.85 31.625 17.1417 31.625H14.875C14.0958 31.625 13.4583 32.2719 13.4583 33.0625C13.4583 33.8531 14.0958 34.5 14.875 34.5H17.1417C19.4083 34.5 21.25 32.6313 21.25 30.3313C21.25 28.759 20.462 27.4562 19.1781 26.7195L16.9292 25.4168C16.5396 25.1832 16.2917 24.752 16.2917 24.2937C16.2917 23.575 16.8583 23 17.5667 23H19.125C19.9042 23 20.5417 22.3531 20.5417 21.5625C20.5417 20.7719 19.9042 20.125 19.125 20.125H17.5667C15.3 20.125 13.4583 21.9937 13.4583 24.2937C13.4583 25.7852 14.2464 27.1688 15.5302 27.9055ZM24.7917 21.5625C24.7917 20.7719 24.1542 20.125 23.375 20.125C22.5958 20.125 21.9583 20.7719 21.9583 21.5625V24.4016C21.9583 27.5191 22.8703 30.5738 24.5792 33.1703L25.0307 33.8621C25.2964 34.2574 25.7302 34.5 26.2083 34.5C26.6865 34.5 27.1203 34.2574 27.3859 33.8621L27.8375 33.1703C29.5464 30.5738 30.4583 27.5191 30.4583 24.4016V21.5625C30.4583 20.7719 29.8208 20.125 29.0417 20.125C28.2625 20.125 27.625 20.7719 27.625 21.5625V24.4016C27.625 26.468 27.138 28.4984 26.2083 30.3223C25.2786 28.4984 24.7917 26.468 24.7917 24.4016V21.5625ZM22.6667 0L34 11.5H22.6667V0Z"
        fill="black"
      />
    </svg>
  )
}
export default CsvIcon
