import React from 'react'

function SuccessTickIcon({ className }) {
  return (
    <svg
      className={className}
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.5" cy="25" r="25" fill="#059669" />
      <path
        d="M34.8174 16.6033L20.3317 31.0889L15.0589 25.8161C15.0589 25.8161 15.0589 25.8161 15.0588 25.8161C14.4485 25.2057 13.4588 25.2057 12.8484 25.8161C12.238 26.4265 12.238 27.4161 12.8484 28.0265L12.8484 28.0265L19.2265 34.4045C19.8369 35.0149 20.8265 35.0149 21.4369 34.4045L37.0277 18.8137C37.6381 18.2033 37.6381 17.2137 37.0277 16.6033C36.4173 15.9929 35.4278 15.9929 34.8174 16.6033L34.8174 16.6033Z"
        fill="white"
        stroke="white"
      />
    </svg>
  )
}
SuccessTickIcon.defaultProps = {
  className: '',
}
export default SuccessTickIcon
