import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class OWNER_DATA_API {
  getSalesChartData = ({
    businessId,
    chartName,
    startDate,
    endDate,
    userId,
  }: api.GetChartDataInput): Promise<any> => {
    return API.fetch(api['GetChartDataDocument'], {
      input: { businessId, chartName, startDate, endDate, userId },
    })
  }
  getOwnerBusinessData = (input: any): Promise<any> => {
    return API.fetch(api['GetOwnerBusinessDataDocument'], { input })
  }
  getOwnerAccountingData = <ChartOutputType>(
    input: api.QueryAccountingReportInput
  ): Promise<
    { getAccountingCharts: { status: api.ChartStatus; data: ChartOutputType } } | undefined
  > => {
    return API.fetch(api['GetAccountingChartsDocument'], { input })
  }
}

// eslint-disable-next-line
export default new OWNER_DATA_API()
