import React from 'react'

function Conversation({ isBlack = false }) {
  return (
    <svg
      width="30"
      height="36.37"
      viewBox="0 0 38 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-2"
    >
      <path
        d="M32.0895 7.20793C27.0143 3.85457 20.2686 3.09127 14.665 4.10262C8.39562 -1.94439 1.37024 0.836997 0 1.63658C0 1.63658 4.82125 5.73345 4.03816 9.32168C-1.66976 15.1404 1.04368 21.6189 4.03816 24.678C4.82125 28.2663 0 32.3631 0 32.3631C1.35705 33.1654 8.3633 35.9382 14.665 29.9222C20.2561 30.9269 27.0018 30.1702 32.0895 26.8109C39.9574 21.7878 39.9831 12.2568 32.0895 7.20727V7.20793ZM19.4077 26.7786C17.4456 26.7842 15.4913 26.532 13.5949 26.0285L12.2906 27.2879C11.5662 27.9905 10.755 28.5977 9.8767 29.0949C8.81115 29.6314 7.65359 29.9611 6.46528 30.0666C6.53125 29.9492 6.58931 29.8318 6.64802 29.721C7.95295 27.302 8.3048 25.1317 7.70358 23.2102C5.5575 21.5206 4.27302 19.3613 4.27302 17.0002C4.27302 11.5905 11.051 7.20793 19.4077 7.20793C27.7644 7.20793 34.5424 11.5905 34.5424 17.0002C34.5424 22.4099 27.7644 26.7786 19.4077 26.7786ZM12.1468 19.329C11.5476 19.3375 10.9696 19.1081 10.5392 18.6911C10.1089 18.2741 9.86139 17.7036 9.85097 17.1044C9.80479 14.1099 14.3259 14.0446 14.3714 17.0325V17.0662C14.3742 17.3609 14.3188 17.6534 14.2085 17.9268C14.0982 18.2001 13.935 18.4491 13.7284 18.6594C13.5218 18.8697 13.2758 19.0372 13.0044 19.1523C12.733 19.2674 12.4416 19.3279 12.1468 19.3303V19.329ZM17.0136 17.1044C16.9615 14.1099 21.4825 14.038 21.5347 17.0259V17.0662C21.5604 20.0402 17.0657 20.0725 17.0136 17.1044ZM26.4727 19.329C25.8734 19.3375 25.2953 19.1081 24.8649 18.6911C24.4344 18.2742 24.1868 17.7036 24.1762 17.1044C24.1307 14.1099 28.6517 14.0446 28.6973 17.0325V17.0662C28.701 17.3612 28.6463 17.6541 28.5363 17.9279C28.4264 18.2017 28.2633 18.451 28.0565 18.6615C27.8497 18.872 27.6033 19.0394 27.3314 19.1542C27.0596 19.269 26.7677 19.3289 26.4727 19.3303V19.329Z"
        fill={isBlack ? '#000' : '#FFFF'}
      />
    </svg>
  )
}

export default Conversation
