import React from 'react'

function CheckMark({ className = '' }) {
  return (
    <svg
      className={className}
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58781 5.54193C1.35594 5.31005 0.979993 5.31005 0.748122 5.54193C0.516251 5.77381 0.516251 6.14969 0.748122 6.38157L4.31062 9.94407C4.54249 10.176 4.91844 10.176 5.15031 9.94407L13.8586 1.23576C14.0905 1.00389 14.0905 0.627943 13.8586 0.396071C13.6267 0.1642 13.2509 0.1642 13.019 0.396071L4.73047 8.68453L1.58781 5.54193Z"
        fill=""
      />
    </svg>
  )
}

export default CheckMark
