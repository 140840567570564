import React, { FC, useEffect } from 'react'

interface IProgressBar {
  // TODO: rename this prop for clarity
  /** Represents 0-100% completion */
  width?: number
  height?: string
  totalSteps?: number
  bgColorClass?: string
  // TODO: rename this prop - it implies that the width does not change, but w-full is a dynamic class
  hasFixedWidth?: boolean
  startingIndex?: number
  totalStepsProgressbarAction?
}

const ProgressBar: FC<IProgressBar> = ({
  width,
  height = '',
  totalSteps = 0,
  bgColorClass,
  hasFixedWidth,
  startingIndex = 0,
  totalStepsProgressbarAction,
}) => {
  useEffect(() => {
    if (totalSteps) {
      totalStepsProgressbarAction({ totalSteps, startingIndex })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSteps, startingIndex])
  return (
    <>
      <div
        className={`min-h-full flex items-center justify-center ${!hasFixedWidth ? 'w-full' : ''}`}
      >
        <div className={`w-full ${hasFixedWidth ? 'xl:w-[700px]' : ''} w-full space-y-8`}>
          <div className={`w-full bg-cream-dark rounded-[21px] overflow-hidden`}>
            <div
              className={`${bgColorClass} rounded-[21px] ${height ? height : 'h-5'}`}
              style={{ width: `${width}%` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

ProgressBar.defaultProps = {
  width: 0,
  height: 'h-2',
  totalSteps: 0,
  bgColorClass: 'bg-primary',
  hasFixedWidth: true,
  startingIndex: 0,
}

export default ProgressBar
