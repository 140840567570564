import React, { FC } from 'react'
import { getInitials } from 'components/Chat/helper'

interface ITackleAvatarLogo {
  className?: string
  padding?: string
  logo?: string
  firstName: string
  lastName: string
}

const TackleAvatarLogo: FC<ITackleAvatarLogo> = ({
  className,
  padding,
  logo,
  firstName,
  lastName,
}) => {
  const initials = getInitials({ firstName, lastName })
  return (
    <div>
      <span
        className={`rounded-full inline-flex justify-center items-center ${
          logo ? '!bg-white' : ''
        } ${className}`}
      >
        {logo ? (
          <img src={logo} className="object-cover w-full h-full rounded-full" alt="profile-pic" />
        ) : (
          <span
            className={`rounded-full inline-flex justify-center items-center text-white font-bold uppercase text-xl`}
          >
            {initials}
          </span>
        )}
      </span>
    </div>
  )
}

TackleAvatarLogo.defaultProps = {
  padding: 'p-[6px]',
  className: 'bg-tertiary h-[50px] w-[50px]',
}
export default TackleAvatarLogo
