import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AdditionalDetailsFormLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="50" y="50" rx="2" ry="2" width="340" height="20" />
        <rect x="50" y="90" rx="2" ry="2" width="340" height="40" />

        <rect x="50" y="180" rx="14" ry="14" width="340" height="50" />
      </ContentLoader>
    </>
  )
}

export default AdditionalDetailsFormLoader
