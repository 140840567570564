import React, { FC } from 'react'

export interface IClockIconProps {
  className?: string
  id?: string
}
const ClockIcon: FC<IClockIconProps> = ({ className }) => {
  return (
    <svg
      className={` ${className} fill-primary hover:fill-primary-brand focus:fill-primary-active active:fill-primary-active h-8`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 10C18.125 12.1549 17.269 14.2215 15.7452 15.7452C14.2215 17.269 12.1549 18.125 10 18.125C7.84512 18.125 5.77849 17.269 4.25476 15.7452C2.73102 14.2215 1.875 12.1549 1.875 10C1.875 7.84512 2.73102 5.77849 4.25476 4.25476C5.77849 2.73102 7.84512 1.875 10 1.875C12.1549 1.875 14.2215 2.73102 15.7452 4.25476C17.269 5.77849 18.125 7.84512 18.125 10ZM0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10ZM9.0625 4.6875V10V10.5L9.48047 10.7773L13.2305 13.2773L14.0117 13.7969L15.0508 12.2383L14.2695 11.7188L10.9375 9.5V4.6875V3.75H9.0625V4.6875Z"
        fill=""
      />
    </svg>
  )
}
ClockIcon.defaultProps = {
  className: '',
}
export default ClockIcon
