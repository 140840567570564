import { USER_ROLES } from 'config'

export const getUserName = (user) => {
  return user?.firstName ? `${user.firstName || ''} ${user.lastName || ''}` : user?.name
}

export const getChannelName = (members) => {
  try {
    if (!members.length || members.length === 1) {
      return getUserName(members[0]?.user)
    }
    const memberNames = members.map((member) => getUserName(member?.user))

    return memberNames.join(', ')
  } catch (err) {
    console.log('***********************')
    return ''
  }
}

export const getBusinessName = (members) => {
  try {
    if (members && members.length === 1) {
      return members[0]?.user?.businessName
    } else {
      return ''
    }
  } catch (err) {
    return ''
  }
}

export const isChannelUserAdvisor = (members) => {
  try {
    if (
      members[1]?.user.glRoles.includes(USER_ROLES.BSO_ADMIN) ||
      members[1]?.user.glRoles.includes(USER_ROLES.BSO_ADVISOR)
    ) {
      return true
    }

    return false
  } catch (err) {
    console.log('***********************')
    return false
  }
}

export const getInitials = (input: { firstName: string; lastName: string }) => {
  try {
    const { firstName, lastName } = input
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`
    return initials
  } catch (err) {
    console.log('***********************')
    return ''
  }
}
