import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AttestationSummaryLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="22" y="5" rx="0" ry="0" width="2" height="20" />
        <rect x="22" y="5" rx="0" ry="0" width="408" height="2" />
        <rect x="22" y="25" rx="0" ry="0" width="408" height="2" />
        <rect x="428" y="5" rx="0" ry="0" width="2" height="20" />

        <rect x="40" y="11" rx="0" ry="0" width="100" height="10" />

        <rect x="22" y="35" rx="0" ry="0" width="4" height="65" />
        <rect x="22" y="35" rx="0" ry="0" width="192" height="4" />
        <rect x="22" y="100" rx="0" ry="0" width="192" height="4" />
        <rect x="426" y="35" rx="0" ry="0" width="4" height="65" />

        <rect x="95" y="50" rx="0" ry="0" width="50" height="8" />
        <rect x="95" y="67" rx="0" ry="0" width="50" height="8" />
        <rect x="95" y="83" rx="0" ry="0" width="50" height="8" />

        <rect x="230" y="35" rx="0" ry="0" width="4" height="65" />
        <rect x="230" y="35" rx="0" ry="0" width="200" height="4" />
        <rect x="230" y="100" rx="0" ry="0" width="200" height="4" />
        <rect x="210" y="35" rx="0" ry="0" width="4" height="65" />

        <rect x="310" y="50" rx="0" ry="0" width="50" height="8" />
        <rect x="310" y="67" rx="0" ry="0" width="50" height="8" />
        <rect x="310" y="83" rx="0" ry="0" width="50" height="8" />
      </ContentLoader>
    </>
  )
}

export default AttestationSummaryLoader
