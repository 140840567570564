import { GET_USER_GOAL, UPDATE_USER_GOAL } from 'store/types'

export const getUserGoalAction = {
  STARTED: () => ({ type: GET_USER_GOAL.STARTED }),
  FULLFILLED: (payload: any) => ({ type: GET_USER_GOAL.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_USER_GOAL.REJECTED }),
}

export const updateUserGoalAction = {
  STARTED: (payload: any) => ({ type: UPDATE_USER_GOAL.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_USER_GOAL.FULLFILLED, payload }),
  REJECTED: () => ({ type: UPDATE_USER_GOAL.REJECTED }),
}
