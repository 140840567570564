import React from 'react'

function CloudIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="23"
      viewBox="0 0 32 23"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M25 22.4277V22.4375H7.03125C3.14795 22.4375 0 19.2881 0 15.4062C0 12.3447 1.95654 9.69824 4.6875 8.77539V8.76562C4.6875 4.23437 8.35938 0.5625 12.8906 0.5625C15.376 0.5625 17.5977 1.66602 19.1064 3.40967C19.834 3.08594 20.6348 2.90625 21.4844 2.90625C24.7217 2.90625 27.3438 5.52832 27.3438 8.76562C27.3438 9.08789 27.3193 9.40039 27.2705 9.70801C29.6143 10.7334 31.25 13.0771 31.25 15.7969C31.25 19.332 28.4863 22.2227 25 22.4277ZM18.4668 6.26074L17.3291 4.94238C16.25 3.69141 14.6631 2.90625 12.8906 2.90625C9.65332 2.90625 7.03125 5.52832 7.03125 8.72168V10.4307L5.4248 10.9824C3.64453 11.6318 2.34375 13.3701 2.34375 15.4062C2.34375 17.9941 4.44238 20.0938 7.03125 20.0938H24.7363L24.8633 20.084C27.1191 19.957 28.9062 18.082 28.9062 15.7969C28.9062 14.0391 27.8516 12.5205 26.3281 11.8564L24.6631 11.1289L24.9561 9.33691C24.9854 9.15137 25 8.96094 25 8.76562C25 6.82227 23.4277 5.25 21.4844 5.25C20.9717 5.25 20.4883 5.35742 20.0586 5.55273L18.4668 6.26074Z" />
      </g>
    </svg>
  )
}

CloudIcon.defaultProps = {
  className: '',
}
export default CloudIcon
