import React from 'react'

function SinglePersonIcon({ className }) {
  return (
    <svg
      width="32"
      height="50"
      fill="#71717A"
      viewBox="0 0 32 50"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19820_618)">
        <path d="M10.9379 4.6875C10.9379 4.07193 11.0581 3.46239 11.2916 2.89367C11.5252 2.32496 11.8674 1.80821 12.2989 1.37294C12.7304 0.937662 13.2427 0.592384 13.8064 0.356815C14.3702 0.121246 14.9745 0 15.5847 0C16.1949 0 16.7991 0.121246 17.3629 0.356815C17.9267 0.592384 18.4389 0.937662 18.8704 1.37294C19.3019 1.80821 19.6442 2.32496 19.8777 2.89367C20.1112 3.46239 20.2314 4.07193 20.2314 4.6875C20.2314 5.30307 20.1112 5.91261 19.8777 6.48133C19.6442 7.05004 19.3019 7.56679 18.8704 8.00206C18.4389 8.43734 17.9267 8.78262 17.3629 9.01819C16.7991 9.25375 16.1949 9.375 15.5847 9.375C14.9745 9.375 14.3702 9.25375 13.8064 9.01819C13.2427 8.78262 12.7304 8.43734 12.2989 8.00206C11.8674 7.56679 11.5252 7.05004 11.2916 6.48133C11.0581 5.91261 10.9379 5.30307 10.9379 4.6875ZM14.0358 17.1973V29.6875H17.1336V17.1973C17.0658 17.1973 16.9884 17.1875 16.9109 17.1875H14.2584C14.181 17.1875 14.1132 17.1875 14.0358 17.1973ZM14.0358 34.375V47.6562C14.0358 48.9551 12.9999 50 11.7124 50C10.4249 50 9.38902 48.9551 9.38902 47.6562V21.8359L5.21664 29.2773C4.58739 30.4004 3.16433 30.8008 2.05104 30.166C0.937764 29.5312 0.540855 28.0957 1.1701 26.9727L6.84299 16.8652C8.35318 14.1699 11.1896 12.5 14.2584 12.5H16.9206C19.9894 12.5 22.8258 14.1699 24.336 16.8652L29.9992 26.9727C30.6285 28.0957 30.2413 29.5312 29.1183 30.166C27.9953 30.8008 26.582 30.4102 25.9527 29.2773L21.7803 21.8359V47.6562C21.7803 48.9551 20.7445 50 19.457 50C18.1694 50 17.1336 48.9551 17.1336 47.6562V34.375H14.0358Z" />
      </g>
      <defs>
        <clipPath id="clip0_19820_618">
          <rect width="30.9783" height="50" fill="white" transform="translate(0.871094)" />
        </clipPath>
      </defs>
    </svg>
  )
}

SinglePersonIcon.defaultProps = {
  className: '',
}
export default SinglePersonIcon
