import React, { useMemo } from 'react'

import { AppFC, IUser, RecommendationCategory } from 'types'

import Card from 'components/Common/Card'
import PlayCardCornerEllipse from 'components/Common/SvgIcons/PlayCardCornerEllipse'
import RightArrowIconButton from 'components/Common/RightArrowIconButton'
import CurvedRightTurnIcon from 'components/Common/SvgIcons/CurvedRightTurnIcon'
import DocumentStarIcon from 'components/Common/SvgIcons/DocumentStarIcon'
import RecommendationCategoryPillBox, {
  BusinessCategory,
} from 'components/Common/RecommendationCategoryPillBox'
import useNavigateToRecommendation from 'hooks/useNavigateToRecommendation'
import { trackAmpliEventForAssessmentAndPlay } from 'utils/helper'

export interface IRecommendationCardProps {
  category: RecommendationCategory
  businessTopic: string
  userSummary: IUser
  id: string
  title: string
  viewedCardFrom: string
  level: string
}

// todo - hook up correct play and assessment titles
const RecommendationCard: AppFC<IRecommendationCardProps> = ({
  level,
  businessTopic,
  viewedCardFrom,
  category,
  title,
  userSummary,
  id,
}) => {
  const navigate = useNavigateToRecommendation({ id, category })
  const businessCategory = useMemo(() => {
    return businessTopic?.replace(/\s/g, '') as BusinessCategory
  }, [businessTopic])

  return (
    <Card className="w-[226px] h-[314px] lg:h-[327px] xl:h-[314px] min-w-[215px] lg:min-w-0 lg:flex inline-block relative !p-0 !overflow-hidden">
      <CardBackgroundArt />
      <div className="px-5 py-6 w-full h-full flex-shrink-0">
        <div className="w-full h-full relative flex flex-col justify-between flex-grow">
          <div className="flex flex-col gap-5 w-full">
            <RecommendationCardHeader category={category} />
            <h3 className="font-secondary leading-[25px] text-2xl font-extrabold whitespace-normal flex-shrink">
              {title}
            </h3>
            {businessTopic ? (
              <div className="self-start">
                <RecommendationCategoryPillBox businessCategory={businessCategory}>
                  {businessTopic}
                </RecommendationCategoryPillBox>
              </div>
            ) : null}
          </div>
          <div className="absolute bottom-0 right-0">
            <RightArrowIconButton
              size="large"
              onClick={() => {
                navigate()
                trackAmpliEventForAssessmentAndPlay(
                  userSummary,
                  id,
                  category,
                  viewedCardFrom,
                  title,
                  businessTopic,
                  `${level}`
                )
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

interface IRecommendationCardHeaderProps {
  category: RecommendationCategory
}

export const RecommendationCardHeader: AppFC<IRecommendationCardHeaderProps> = ({ category }) => {
  const CategoryIcon = useMemo(() => {
    return {
      play: RightTurnBox,
      assessment: DocumentStarBox,
    }[category]
  }, [category])

  return (
    <div className="flex gap-2 items-center">
      <CategoryIcon />
      <p className="uppercase font-bold text-xs">{category}</p>
    </div>
  )
}

const CardBackgroundArt = () => {
  return (
    <div className="absolute bottom-0 right-0">
      <PlayCardCornerEllipse />
    </div>
  )
}

const IconBox: AppFC = ({ children }) => {
  return (
    <div className="border-black border rounded-[3px] flex justify-center items-center h-8 w-8">
      <div className="h-3/4 w-3/4 flex-shrink-0 flex-grow-0">{children}</div>
    </div>
  )
}

/** A box containing a right-turn icon. The unique shape of the icon requires that we adjust it's placement to trick the eye into thinking its centered. */
const RightTurnBox = () => {
  return (
    <IconBox>
      <CurvedRightTurnIcon className="w-full relative top-0.5 left-[1px]" />
    </IconBox>
  )
}

const DocumentStarBox = () => {
  return (
    <IconBox>
      <DocumentStarIcon className="w-full relative top-1 left-[2px]" />
    </IconBox>
  )
}

export default RecommendationCard
