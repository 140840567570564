import { useSelector } from 'react-redux'
import { API_TYPES } from 'config'

export function useErrors(errorTypes) {
  const errors = useSelector((state: any) => state.error)
  const errorMessages: { [key in keyof typeof API_TYPES]?: string } = {}

  errorTypes.forEach((errorType) => {
    errorMessages[errorType] = errors[errorType]?.message || ''
  })

  return { errorMessages }
}
