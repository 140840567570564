import React from 'react'
import { CircularLoadingWrapper } from './style'

const CircularLoading = ({ className }) => {
  return <CircularLoadingWrapper className={`${className}`}></CircularLoadingWrapper>
}

CircularLoading.defaultProps = {
  className: '',
}

export default CircularLoading
