import React, { Fragment } from 'react'
import InputField from 'components/Common/InputField'
import SelectField from 'components/Common/SelectField'
import { SelectFieldWrapper } from 'components/Assessment/AssessmentQuestion/styles'
import { IOptionTypes } from 'components/Assessment/AssessmentQuestion/utils'
import { cloneDeep, findIndex, get, isEmpty } from 'lodash'
import { yearOptions } from 'utils/helper'

const MultiFieldComponent = ({
  errors,
  selectedOption,
  isSubmitted,
  activeQuestion,
  multiFieldValue,
  setMultiFieldValue,
  isCompletedAssessment,
}) => {
  const isFieldEmpty = (multiFieldValue, option) => {
    return (
      multiFieldValue.every((field) => isEmpty(field.value)) ||
      (multiFieldValue.find((field) => field.id === option.id)?.value ?? '') === ''
    )
  }

  const handleChange = (
    value,
    id,
    optionType,
    multiFieldValue,
    setMultiFieldValue,
    label,
    regex
  ) => {
    if (optionType === IOptionTypes.INPUT) {
      if (value) {
        let regExp = new RegExp(regex)
        if (!regExp.test(value)) {
          return
        }
      }
    }
    const index = findIndex(multiFieldValue, (val: any) => val.id === id)
    const newData = cloneDeep(multiFieldValue)
    if (index >= 0) {
      newData[index] = { id, value, label }
      setMultiFieldValue(newData)
    } else {
      setMultiFieldValue([...multiFieldValue, { id, value, label }])
    }
  }
  return (
    <div className="sm:max-w-[510px] mb-8 ">
      {activeQuestion &&
        activeQuestion?.options?.map((option, index) => {
          const data = multiFieldValue
            ? multiFieldValue?.find((opti) => opti?.id === option?.id)
            : {}
          return (
            <Fragment key={index}>
              {option?.optionType === IOptionTypes.SELECT_FIELD && (
                <div className="mt-6">
                  <div className="text-left">
                    <label className="text-base font-primary font-medium">{option?.label}</label>
                  </div>
                  <SelectFieldWrapper className="!w-full">
                    <SelectField
                      options={option?.options ? option?.options : yearOptions()}
                      classes={'!bg-white !text-left rounded-[10px]'}
                      onChange={(value) =>
                        handleChange(
                          value,
                          option.id,
                          option.optionType,
                          multiFieldValue,
                          setMultiFieldValue,
                          option.label,
                          option?.regex
                        )
                      }
                      placeholder={option?.placeholder}
                      disable={isCompletedAssessment}
                      name={option?.name}
                      value={get(data, 'value', '')}
                      errors={
                        (isSubmitted && isFieldEmpty(multiFieldValue, option)
                          ? { message: 'Field should not be empty' }
                          : '') || errors(multiFieldValue, option)
                      }
                    />
                  </SelectFieldWrapper>
                </div>
              )}

              {option?.optionType === IOptionTypes.INPUT && (
                <div className={`${option?.infoText ? 'pt-0' : 'pt-6'}`}>
                  <InputField
                    index={index}
                    infoText={option?.infoText}
                    type={option?.type}
                    name={option?.name}
                    mask={option?.mask}
                    placeholder={option?.placeholder}
                    disabled={isCompletedAssessment}
                    className="text-left !rounded-[10px] !font-bold !text-base"
                    id={option.id}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        option.id,
                        option.optionType,
                        multiFieldValue,
                        setMultiFieldValue,
                        option.label,
                        option?.regex
                      )
                    }
                    key={`input-${index + 1}`}
                    label={option?.label}
                    value={get(data, 'value', '')}
                    errors={
                      isSubmitted && isFieldEmpty(multiFieldValue, option)
                        ? { message: 'Field should not be empty' }
                        : ''
                    }
                  />
                </div>
              )}
            </Fragment>
          )
        })}
    </div>
  )
}

export default MultiFieldComponent
