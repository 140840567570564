import React from 'react'

function ArrowLeftIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="27"
      viewBox="0 0 14 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.1331 24.289C13.5693 24.7655 13.531 25.4951 13.0449 25.9237C12.5579 26.3505 11.8062 26.3121 11.3683 25.8378L0.679183 14.273C0.27328 13.8304 0.27328 13.1666 0.679183 12.7236L11.3667 1.22616C11.805 0.751953 12.5564 0.713508 13.0433 1.14031C13.5567 1.59349 13.5467 2.32474 13.1315 2.77496L3.16032 13.4998L13.1331 24.289Z" />
    </svg>
  )
}

export default ArrowLeftIcon
