import React from 'react'

function Notifications() {
  return (
    <svg width="30" height="30" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.483 0.746094C14.4733 0.746094 16.3332 1.30554 17.911 2.2785C17.4409 2.78075 17.0774 3.38363 16.8563 4.05125C15.5878 3.26244 14.0887 2.80803 12.483 2.80803C7.91159 2.80803 4.22246 6.48413 4.2218 11.0558V17.1174L2.37133 21.3707H22.6065L20.7442 17.1187L20.7444 11.0735L20.7391 10.7639C20.7341 10.6149 20.725 10.467 20.7122 10.3202C20.9473 10.3554 21.1879 10.3737 21.4328 10.3737C21.8977 10.3737 22.3472 10.308 22.7725 10.1853C22.7872 10.3592 22.7977 10.5344 22.8037 10.7108L22.8095 11.0558V16.6877L24.7096 21.0259C24.8047 21.2431 24.8538 21.4775 24.8538 21.7144C24.8538 22.6633 24.0831 23.4326 23.1327 23.4326L16.6136 23.4347C16.6136 25.7122 14.7642 27.5586 12.483 27.5586C10.2832 27.5586 8.48506 25.8417 8.35942 23.6771L8.35179 23.4316L1.84653 23.4326C1.61061 23.4326 1.37722 23.3842 1.16085 23.2904C0.289013 22.9123 -0.110768 21.9002 0.26792 21.0298L2.15651 16.6889V11.0557C2.15732 5.34436 6.77189 0.746094 12.483 0.746094ZM14.5477 23.4316L10.4177 23.4347C10.4177 24.5734 11.3424 25.4966 12.483 25.4966C13.5566 25.4966 14.4388 24.6789 14.5389 23.6332L14.5477 23.4316ZM18.0454 4.94886C18.1763 4.22405 18.5347 3.57824 19.0443 3.08786C19.6633 2.49223 20.5053 2.12592 21.4328 2.12592C23.3339 2.12592 24.875 3.66452 24.875 5.56249C24.875 7.06521 23.9089 8.34265 22.5629 8.80953C22.209 8.93234 21.8287 8.99906 21.4328 8.99906C21.0913 8.99906 20.7614 8.9494 20.45 8.85692C19.0278 8.43463 17.9907 7.11947 17.9907 5.56249C17.9907 5.35305 18.0095 5.14798 18.0454 4.94886Z"
        fill="white"
      />
    </svg>
  )
}

export default Notifications
