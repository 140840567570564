import {
  GET_SALES_DATA,
  GET_PRODUCT_DATA,
  GET_FINANCE_DATA,
  GET_MARKETING_DATA,
  GET_CASH_BALANCE_DATA,
  GET_CASH_BALANCE_BY_MONTH_DATA,
  GET_LIQUID_CASH_DATA,
  GET_PROFIT_AND_LOSS_DATA,
} from 'store/types'
import { QueryAccountingReportInput } from '../../../../__generated__/api-types-and-hooks'

export const getSalesChartDataAction = {
  STARTED: (payload: any) => ({ type: GET_SALES_DATA.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_SALES_DATA.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_SALES_DATA.REJECTED }),
}

export const getProductDataAction = {
  STARTED: (payload: any) => ({ type: GET_PRODUCT_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_PRODUCT_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_PRODUCT_DATA.REJECTED }),
}

export const getFinanceDataAction = {
  STARTED: (payload: any) => ({ type: GET_FINANCE_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_FINANCE_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_FINANCE_DATA.REJECTED }),
}

export const getMarketingChartDataAction = {
  STARTED: (payload: any) => ({ type: GET_MARKETING_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_MARKETING_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_MARKETING_DATA.REJECTED }),
}

export const getCashBalanceChartDataAction = {
  STARTED: (payload: QueryAccountingReportInput) => ({
    type: GET_CASH_BALANCE_DATA.STARTED,
    payload,
  }),
  FULLFILLED: (payload: any) => ({
    type: GET_CASH_BALANCE_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_CASH_BALANCE_DATA.REJECTED }),
}

export const getCashBalanceByMonthChartDataAction = {
  STARTED: (payload: QueryAccountingReportInput) => ({
    type: GET_CASH_BALANCE_BY_MONTH_DATA.STARTED,
    payload,
  }),
  FULLFILLED: (payload: any) => ({
    type: GET_CASH_BALANCE_BY_MONTH_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_CASH_BALANCE_BY_MONTH_DATA.REJECTED }),
}
export const getLiquidCashChartDataAction = {
  STARTED: (payload: QueryAccountingReportInput) => ({
    type: GET_LIQUID_CASH_DATA.STARTED,
    payload,
  }),
  FULLFILLED: (payload: any) => ({
    type: GET_LIQUID_CASH_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_LIQUID_CASH_DATA.REJECTED }),
}
export const getProfitAndLossChartDataAction = {
  STARTED: (payload: QueryAccountingReportInput) => ({
    type: GET_PROFIT_AND_LOSS_DATA.STARTED,
    payload,
  }),
  FULLFILLED: (payload: any) => ({
    type: GET_PROFIT_AND_LOSS_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_PROFIT_AND_LOSS_DATA.REJECTED }),
}
