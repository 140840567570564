import React, { FC } from 'react'
import Button from 'components/Common/Button'
import ToolTip from 'components/Common/ToolTip'
import Label from 'components/Common/Label'

import { PlacesType } from 'react-tooltip'
import { FontSize, FontWeight } from 'types'

interface IToolTipWithButton {
  label?: string
  toolTipText?: string
  toolTipWidth?: string
  labelFontSize?: FontSize
  toolTipPlace?: PlacesType
  fontWeight?: FontWeight
  toolTipClass?: string
  toolTipOpacity?: number
  toolTipBackgroundColor?: string
}

const ToolTipWithButton: FC<IToolTipWithButton> = ({
  label,
  labelFontSize,
  toolTipText,
  toolTipWidth,
  toolTipPlace,
  fontWeight,
  toolTipClass,
  toolTipOpacity,
  toolTipBackgroundColor,
}) => {
  return (
    <div className="flex items-center gap-2">
      {label && (
        <Label fontSize={labelFontSize} title={label} fontWeight={fontWeight ?? 'font-medium'}>
          {label}
        </Label>
      )}

      {label && toolTipText && (
        <ToolTip
          name={label}
          text={toolTipText}
          width={toolTipWidth}
          place={toolTipPlace}
          className={toolTipClass}
          opacity={toolTipOpacity}
          backgroundColor={toolTipBackgroundColor}
        >
          <Button
            variant="text"
            leftIcon="tool-tip"
            className="!p-0 focus:!border-0 w-full"
            type="button"
          />
        </ToolTip>
      )}
    </div>
  )
}

export default ToolTipWithButton
