import styled from 'styled-components'
import tw from 'twin.macro'

export const UserManagementListWrapper = styled.div`
  ${tw`border-primary pb-4`}
`

export const TableActionWrapper = styled.div`
  ${tw`flex flex-row-reverse items-center gap-3`}
`

export const UserManagementModalBoxWrapper = styled.div`
  ${tw`flex justify-between pt-4 w-[100%]`}
`

export const UserAccountWrapper = styled.div`
  ${tw`border-b-[1px] border-primary-outline py-5`}
`

export const UserWrapper = styled.div`
  ${tw`flex flex-col gap-8`}
`

export const AdvisorWrapper = styled.div`
  ${tw`smd:w-1/2`}
`

export const AdvisorAndActionWrapper = styled.div`
  ${tw`flex flex-col smd:flex-row smd:justify-between gap-4`}
`

export const UserActionWrapper = styled.div`
  ${tw`flex items-center justify-between gap-4`}
`

export const LabelWrapper = styled.label`
  ${tw`font-primary font-bold text-primary text-sm`}
`

export const UserInfoWrapper = styled.div`
  ${tw`flex flex-col smd:flex-row smd:justify-between gap-4`}
`

export const UserNameWrapper = styled.p`
  ${tw`font-bold`}
`

export const UserBusinessNameWrapper = styled.p`
  ${tw`text-zinc`}
`

export const InlineLabelWrapper = styled.p`
  ${tw`smd:text-right`}
`
export const InfoWrapper = styled.div`
  ${tw``}
`
