import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class COMMON_API {
  getSignedUrl = (contentType: string, fileName: string): Promise<any> => {
    return API.fetch(api['GetSignedUrlDocument'], { contentType, fileName })
  }
  uploadFile = (url, data, type): Promise<any> => {
    return API.uploadFile(url, data, type)
  }
  getUserFiles = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetUserFilesDocument'], { userId, tenantId })
  }
  getFile = (id = undefined): Promise<any> => {
    return API.fetch(api['GetFileDocument'], { id })
  }
  saveUserFile = (input): Promise<any> => {
    return API.fetch(api['SaveUserFileDocument'], { input: input })
  }
}

// eslint-disable-next-line
export default new COMMON_API()
