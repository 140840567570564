/* eslint-disable react-hooks/rules-of-hooks */
import { parse, stringify } from 'querystring'
import decodeUriComponent from 'decode-uri-component'
import { useEffect, useState } from 'react'
import history from './history'
import { isEmpty, forEach } from 'lodash'
export function parseFlashParams<T>(): T | null {
  try {
    const queryString = window.location.search.slice(1)
    const queryParams = parse(queryString.slice(0))
    const flashParams = queryParams.flash
    if (flashParams) {
      const decodedParams = Array.isArray(flashParams)
        ? flashParams.map((param) => decodeUriComponent(param))
        : decodeUriComponent(flashParams)
      const pairs = decodedParams.split('&')
      const result: any = {}
      for (const pair of pairs) {
        const [key, value] = pair.split('=')
        result[key] = value
      }
      return result as T
    }
    return null
  } catch (error) {
    console.error('Failed to parse flash params:', error)
    return null
  }
}

export function useFlashParams<T>(fieldKey?: string) {
  const [flashParams, setFlashParams] = useState<T | null>(null)
  useEffect(() => {
    // Define the callback function
    const callback = () => {
      const parsedParams = parseFlashParams<T | null>()
      const hasParamsChanged = Object.keys(parsedParams || {}).includes(fieldKey || '')
      if ((hasParamsChanged || isEmpty(fieldKey)) && parsedParams) {
        setFlashParams(parsedParams)
        removeFlashParams(fieldKey)
      }
    }
    callback()
    let unlisten = history.listen(callback)
    return () => {
      unlisten()
    }
  }, [fieldKey])

  return [flashParams, setFlashParams] as const
}
export function removeFlashParams(fieldKey?: string) {
  const currentParams = parseFlashParams()
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  params.forEach((_, key) => {
    if (key.startsWith('flash')) {
      params.delete(key)
    }
  })
  let updatedParams = {}
  if (fieldKey) {
    forEach(Object.keys(currentParams!), (key) => {
      if (key !== fieldKey) updatedParams[key] = currentParams![key]
    })
  }

  const newUrl = params.toString()
    ? `${url.pathname}?${params.toString()}${url.hash}`
    : url.pathname
  window.history.replaceState({}, '', newUrl)

  if (!isEmpty(updatedParams)) setFlashUrlParams(updatedParams)
}

export function setFlashUrlParams(flashParams: Record<string, string>) {
  try {
    const url = new URL(window.location.href)
    const currentParams = parseFlashParams()
    const inputParams = { ...flashParams, ...(currentParams || {}) }
    const encodedParams = encodeURIComponent(stringify(inputParams))
    const urlSearchParams = new URLSearchParams(window.location.search)
    const hasQueryParams = urlSearchParams.toString().length > 0
    const pathName = url.pathname !== '/' ? url.pathname : ''
    const newUrl = `${pathName}${window.location.search ?? ''}${
      hasQueryParams ? '&' : '?'
    }flash=${encodedParams}`
    history.push(newUrl)
  } catch (err) {
    console.error('Failed to set flash params:', err)
  }
}
