import React from 'react'

import { AppFC } from 'types'
import Heading from 'components/Common/Heading'
import useDateLabeler from 'hooks/useDateLabeler'
import ActionCard from 'components/Common/ActionCard'
import history from 'utils/history'
import { useTenantId } from 'utils/useTenantId'
import { getRoleFromPath } from 'utils/helper'

export interface IEventCardProps {
  date: string
  description: string
  eventId: string
}

const EventCard: AppFC<IEventCardProps> = ({ date, description, eventId }) => {
  const labeler = useDateLabeler(date)
  const tenantId = useTenantId()
  return (
    <ActionCard
      className="hover:shadow-lg"
      onClick={() => history.push(`/${getRoleFromPath()}/${tenantId}/calendar?eventId=${eventId}`)}
      leftSlot={() => (
        <div className="w-[102px] min-w-[102px] flex items-center justify-center">
          <p className=" text-white leading-4 font-bold text-[14px]">{labeler.shortLabel}</p>
        </div>
      )}
    >
      <div className="flex flex-col" data-testid="upcomingEvent">
        <p className="text-sm font-normal whitespace-nowrap">{labeler.longLabel}</p>
        <Heading className="!text-base !leading-6">{description}</Heading>
      </div>
    </ActionCard>
  )
}

export default EventCard
