import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import { ModalBlock } from '../../Common/ModalBox/ModalBlock'
import { ModalBoxOverlay } from '../../Common/ModalBox/ModalBoxOverlay'
import Button from '../../Common/Button'
import MeetingCancelIcon from 'components/Common/SvgIcons/MeetingCancelIcon'
import SolidTrashIcon from '../../Common/SvgIcons/SolidTrashIcon'
import PencilIcon from '../../Common/SvgIcons/PencilIcon'
import {
  CALENDAR,
  CalendarTailwindColorsMap,
  MEETING_STEPPER,
  calendarLabelMap,
} from '../../../config'
import styled from 'styled-components'
import tw from 'twin.macro'

interface IEditMeetingModalProps {
  onClose?: (e) => void
  id?: string
  children?: React.ReactNode
  title?: string
  onEdit: (e) => void
  onCancel: (e) => void
  editMode: boolean
  eventType: CALENDAR
  stepType?: string
}

export const EditMeetingModalHeader = ({
  onClose,
  onEdit,
  onCancel,
  title,
  editMode,
  eventType,
}: {
  onClose?: (e: any) => void
  onEdit?: (e: any) => void
  onCancel?: (e: any) => void
  editMode: boolean
  title?: string
  eventType?: CALENDAR
}): JSX.Element => (
  <div className="flex flex-wrap flex-col w-full">
    <div className={`flex flex-row p-6 top-0 w-full`}>
      {eventType === CALENDAR.INTERNAL && (
        <>
          <Button
            variant="text"
            label={'Edit'}
            className="!border-0"
            type="button"
            leftContent={() => (
              <PencilIcon
                className={`${editMode ? 'fill-primary-disabledText' : 'fill-primary'} w-6`}
              />
            )}
            onClick={onEdit}
            disabled={editMode}
          />
          <Button
            variant="text"
            label={'Delete'}
            type="button"
            leftContent={() => <SolidTrashIcon className={'fill-primary w-6'} />}
            className="ml-10"
            onClick={onCancel}
          />
        </>
      )}

      {onClose && (
        <div onClick={onClose} className="cursor-pointer ml-auto">
          <MeetingCancelIcon className={'text-primary-text  h-[25px] w-[25px]'} />
        </div>
      )}
    </div>
    {eventType !== CALENDAR.INTERNAL && (
      <div className="w-full ml-6 flex items-start mb-6">
        <div
          className={`my-auto w-3 h-3 rounded-full bg-${CalendarTailwindColorsMap.get(
            eventType ?? CALENDAR.INTERNAL
          )}`}
        ></div>
        <p className="ml-2 text-primary-text">
          {calendarLabelMap[eventType ?? CALENDAR.INTERNAL]} Event
        </p>
      </div>
    )}

    {title && (
      <div className=" w-full ml-6 flex">
        <p className="mt-0 text-primary-text text-xl md:text-2xl font-secondary">{title}</p>
      </div>
    )}
  </div>
)

const ModalWrapper = styled.div`
  ${tw`h-full bg-white shadow-xl sm:h-fit flex flex-col items-center mx-auto my-auto rounded-b-xl rounded-tl-xl`};
`

export const EditMeetingModal = ({
  onClose,
  onEdit,
  onCancel,
  id,
  children,
  title,
  editMode,
  eventType,
  stepType,
}: IEditMeetingModalProps) => {
  const currentRef = useRef(null)

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        initialFocus={currentRef}
        id={id}
        as="div"
        className={`relative z-50`}
        onClose={() => undefined}
      >
        <ModalBoxOverlay bgColor={'bg-cream-dark'} />
        <div
          ref={currentRef}
          className={`overflow-y-auto overflow-x-hidden fixed inset-0 md:my-3 md:w-[800px] md:mx-auto top-0 rounded-b-xl rounded-t-xl`}
        >
          <ModalWrapper>
            {stepType !== MEETING_STEPPER.MEETING_CONFIRMATION && (
              <EditMeetingModalHeader
                onClose={onClose}
                onEdit={onEdit}
                title={title}
                editMode={editMode}
                onCancel={onCancel}
                eventType={eventType}
              />
            )}
            <ModalBlock isBorderWidthFull={true}>{children}</ModalBlock>
          </ModalWrapper>
        </div>
      </Dialog>
    </Transition>
  )
}
