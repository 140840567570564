import React, { FC, useMemo } from 'react'

import { ContentBoxWrapper, LabelWrapper, BubbleWrapper } from './styles'
import CheckIcon from 'components/Common/SvgIcons/CheckIcon'
import PadlockIconV2 from 'components/Common/SvgIcons/PadlockIconV2'

export enum RecommendableStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  LOCKED = 'Locked',
}

interface IRecommendationProgressBubbleProps {
  status?: RecommendableStatus
}

const statusLabel = {
  [RecommendableStatus.NOT_STARTED]: 'Not Started',
  [RecommendableStatus.IN_PROGRESS]: 'In Progress',
  [RecommendableStatus.COMPLETED]: 'Completed',
  [RecommendableStatus.LOCKED]: 'Locked',
}

const RecommendationProgressBubble: FC<IRecommendationProgressBubbleProps> = ({ status }) => {
  const label = useMemo(() => {
    return status && statusLabel[status]
  }, [status])

  return (
    <ContentBoxWrapper className="animate-fade-in">
      <LabelWrapper status={status}>{label}</LabelWrapper>
      <BubbleWrapper status={status}>
        {status === RecommendableStatus.COMPLETED && <CheckIcon className="fill-white" />}
        {status === RecommendableStatus.LOCKED && <PadlockIconV2 />}
      </BubbleWrapper>
    </ContentBoxWrapper>
  )
}

export default RecommendationProgressBubble
