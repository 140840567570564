import React from 'react'
import map from 'lodash/map'
import { formatNumber } from 'utils/helper'

const Row = ({ productName, grossSales, totalUnits }) => {
  return (
    <div className="tableData">
      <div className="flex justify-between py-4 border-b border-primary-outline gap-2">
        <div className="w-[60%] font-medium text-lg text-primary-text leading-7">{productName}</div>
        <div className="font-primary text-primary-text leading-7 w-[25%] text-end">
          ${formatNumber(grossSales) || 0}
        </div>
        <div className="font-primary text-primary-text leading-7 w-[15%] text-end">
          {formatNumber(totalUnits)}
        </div>
      </div>
    </div>
  )
}

const TopSellingProducts = ({ className, products }) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="table-header">
          <div className="flex justify-between pb-5 border-b border-primary-outline gap-2">
            <div className="w-[60%] leading-5 text-sm font-semibold text-primary-text">
              PRODUCT NAME
            </div>
            <div className="leading-5 text-sm font-semibold text-primary-text text-end w-[25%]">
              TOTAL SALES
            </div>
            <div className="leading-5 text-sm font-semibold text-primary-text w-[15%] text-end">
              UNITS SOLD
            </div>
          </div>
        </div>

        <div className={`overflow-y-auto  xs:max-h-[80vh] md:max-h-[70vh] ${className}`}>
          {map(products, (product: any) => (
            <Row
              key={product.sku}
              productName={product.productName}
              grossSales={product.sales}
              totalUnits={product.units}
            />
          ))}
        </div>
      </div>
    </>
  )
}

TopSellingProducts.defaultProps = {
  className: '',
}

export default TopSellingProducts
