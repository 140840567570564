import React from 'react'
import { GroupChatIcon } from 'components/Common/SvgIcons'

const GroupChatLogo = ({ className, padding }) => {
  return (
    <div>
      <span className={`rounded-full inline-flex justify-center items-center p-3 ${className}`}>
        <GroupChatIcon className={`${padding} fill-white w-full h-full`} />
      </span>
    </div>
  )
}

GroupChatLogo.defaultProps = {
  padding: 'p-[6px]',
  className: 'bg-tertiary h-[50px] w-[50px]',
}
export default GroupChatLogo
