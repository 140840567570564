import React, { Fragment, useEffect, useState } from 'react'
import SortIcon from 'components/Common/SvgIcons/SortDataIcon'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import { Columns } from 'types'
import { SortOrder } from '__generated__/api-types-and-hooks'

const SortComponent = ({
  colIndex,
  colField,
  colFieldIndex,
  sortHandler,
  fieldType,
  setSortedColumn,
  sortedColumn,
  tableColumns,
  defaultSort,
  setTableColumns,
  isApiDataPaginated = false,
  getRecords = (order: string, totalItems: number, currentPage: number) => {},
  setCurrentPage,
  totalItems,
  currentPage,
}) => {
  useEffect(() => {
    //setting default sortOrder
    if (defaultSort) {
      for (const [key, value] of Object.entries(defaultSort)) {
        handleSortingOrder(value, key)
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleSortingOrder = (sort, fieldName) => {
    for (let i = 0; i < tableColumns.length; i++) {
      for (let j = 0; j < tableColumns[i].colFields.length; j++) {
        if (tableColumns[i].colFields[j].field === fieldName) {
          tableColumns[i].colFields[j].sortingOrder = sort
        } else if (tableColumns[i].colFields[j].field !== fieldName) {
          tableColumns[i].colFields[j].sortingOrder = undefined
        }
      }
    }
    setTableColumns(tableColumns)
  }
  const toggleSortOrderHandler = (order) => {
    if (order === SortOrder.Ascending) {
      handleSortingOrder(SortOrder.Descending, colField.field)
      if (isApiDataPaginated) {
        setCurrentPage(1)
        getRecords(totalItems, order, currentPage)
      } else {
        sortHandler({
          sortField: colField.field,
          sortOrder: SortOrder.Ascending,
          fieldType,
        })
      }
      setSortedColumn(colField.field)
    } else {
      handleSortingOrder(SortOrder.Ascending, colField.field)
      if (isApiDataPaginated) {
        setCurrentPage(1)
        getRecords(totalItems, order, currentPage)
      } else {
        sortHandler({
          sortField: colField.field,
          sortOrder: SortOrder.Descending,
          fieldType,
        })
      }
      setSortedColumn(colField.field)
    }
  }

  return (
    <div>
      {!colField.sortingOrder && (
        <div
          onClick={() => {
            toggleSortOrderHandler(SortOrder.Ascending)
          }}
        >
          <SortIcon className={`cursor-pointer`} />
        </div>
      )}
      {(colField?.sortingOrder === SortOrder.Descending ||
        colField.sortingOrder === SortOrder.Ascending) && (
        <div
          onClick={() => {
            if (colField.sortingOrder === SortOrder.Ascending) {
              toggleSortOrderHandler(SortOrder.Ascending)
            } else {
              toggleSortOrderHandler(SortOrder.Descending)
            }
          }}
        >
          <SortIcon
            className={`cursor-pointer`}
            sortingOrder={
              colField.sortingOrder === SortOrder.Ascending
                ? SortOrder.Descending
                : SortOrder.Ascending
            }
          />
        </div>
      )}
    </div>
  )
}

interface ITableHeaderProps {
  columns: Columns
  sortHandler: any
  gridColumns?: number
  defaultSort: object
  isApiDataPaginated?: boolean
  getRecords?: (order?) => void
  setCurrentPage?: (page: number) => void
  totalItems?: number
  currentPage?: number
}

const TableHeader = ({
  columns,
  sortHandler,
  gridColumns,
  defaultSort,
  isApiDataPaginated = false,
  getRecords = (order) => {},
  setCurrentPage,
  totalItems,
  currentPage,
}: ITableHeaderProps) => {
  let [tableColumns, setTableColumns]: any = useState([])
  let [sortedColumn, setSortedColumn]: any = useState('')

  useEffect(() => {
    setTableColumns(columns)
  }, [columns])
  return (
    <>
      <thead className="xs:bg-white sm:bg-grey-light w-full bg-opacity-75">
        <hr className="xs:col-span-12 xs:border-t-[1px]  xs:border-primary-outline sm:hidden"></hr>
        <tr
          className={
            (gridColumns
              ? `grid grid-cols-${gridColumns}`
              : `${tableColumns[0]?.display || 'grid grid-cols-12'}`) + ' rounded-[20px] py-4'
          }
        >
          {tableColumns &&
            tableColumns.map((col, colIndex) => {
              return (
                <Fragment key={colIndex}>
                  <th
                    className={`${
                      col.padding ? col.padding : 'xs:px-0 sm:px-3 lg:px-8'
                    } text-base  font-bold tracking-wide text-right text-primary-text first:!rounded-tl-[10px] last:!rounded-tr-[10px] flex ${
                      col.direction ? col.direction : 'items-center '
                    } ${col.colSpan} w-[${col.width}px]`}
                    key={colIndex}
                  >
                    <div
                      className={` ${col.paddingLeft ? col.paddingLeft : ''} ${
                        isTablet ? 'whitespace-nowrap' : 'flex mdl:flex-wrap lg:flex-nowrap  gap-2'
                      }
                        ${col?.textDirection === 'right' ? 'justify-end' : 'justify-start'}

                      `}
                    >
                      {col.colFields?.map((colField, index) => {
                        return (
                          <div
                            className={`${isTablet ? colField.paddingTop : ''} flex`}
                            key={index}
                          >
                            {!isTablet && !isMobileOnly && colField.circle && (
                              <span className="font-bold text-gray-700 rounded-full flex items-center justify-center font-mono w-[5px] h-[5px] mt-[11px] mr-2 bg-zinc ml-2"></span>
                            )}
                            <div className="whitespace-nowrap text-primary-text font-primary">
                              {colField?.headerName}
                            </div>
                            <div>{colField?.headerButton}</div>

                            {colField?.sortable && (
                              <div className="mt-1 ml-1">
                                <SortComponent
                                  setCurrentPage={setCurrentPage}
                                  colIndex={colIndex}
                                  colFieldIndex={index}
                                  colField={colField}
                                  sortHandler={sortHandler}
                                  fieldType={colField?.type}
                                  setSortedColumn={setSortedColumn}
                                  sortedColumn={sortedColumn}
                                  tableColumns={tableColumns}
                                  defaultSort={defaultSort}
                                  setTableColumns={setTableColumns}
                                  isApiDataPaginated={isApiDataPaginated}
                                  getRecords={getRecords}
                                  totalItems={totalItems}
                                  currentPage={currentPage}
                                />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </th>
                </Fragment>
              )
            })}
        </tr>
        <tr>
          <td>
            <hr className="xs:border-t-[3px] xs:border-primary-outline xs:col-span-12 sm:hidden"></hr>
          </td>
        </tr>
      </thead>
    </>
  )
}

export default TableHeader
