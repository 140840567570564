import React, { useState, useEffect, useRef } from 'react'
import CustomSelectBoxIcon from '../SvgIcons/CustomSelectBoxIcon'

interface Option {
  value: string
  label: string
}

interface CustomSelectProps {
  options: Option[]
  defaultOption?: string
  onChange: (value: string) => void
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onChange, defaultOption }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options.find((option) => option.value === defaultOption) || options[0]
  )

  const selectRef = useRef<HTMLDivElement>(null)

  const toggleSelect = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option)
    setIsOpen(false)
    onChange(option.value)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      setSelectedOption(options[0])
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [options, selectedOption])

  useEffect(() => {
    if (isOpen && selectedOption) {
      const optionsList = document.querySelector('.options-list')
      const selectedElement = optionsList?.querySelector(`[data-value="${selectedOption.value}"]`)

      if (selectedElement) {
        const options = optionsList?.querySelectorAll('.option')
        options?.forEach((option) => option.classList.remove('selected'))
        selectedElement.classList.add('selected')
      }
    }
  }, [isOpen, selectedOption])

  return (
    <div className="relative min-w-[80px]]">
      <div
        className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer bg-[#F4F4F5]`}
        onClick={toggleSelect}
        ref={selectRef}
      >
        <span className="font-bold text-[16px] text-[#18181B]">
          {selectedOption ? selectedOption.label : 'Select an option'}
        </span>
        <CustomSelectBoxIcon />
      </div>
      {isOpen && (
        <ul className="options-list absolute left-0 right-0 bottom-0 py-2 px-0 border border-gray-300 bg-white rounded z-10">
          {options.map((option) => (
            <li
              key={option.value}
              data-value={option.value}
              className={`option px-4 py-2 cursor-pointer ${
                option === selectedOption ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CustomSelect
