import React, { useMemo, useState } from 'react'
import { get } from 'lodash'

import InputTextArea from 'components/Common/InputTextArea'
import Button from 'components/Common/Button'
import { TestIds } from 'types'
import {
  CalendarEvent,
  UpdateUserCalendarEventInput,
} from '../../../../__generated__/api-types-and-hooks'
import { ReduxAction } from '../../../../store/types'

interface CommentsProps {
  row: CalendarEvent
  ownerIds: string[]
  updateUserCalendarEventAction: ReduxAction<{ updateCommentPayload: UpdateUserCalendarEventInput }>
  clientComments: CalendarEvent[]
  showCommentsAction: ReduxAction<string | null | undefined>
}
const Comments = ({
  row,
  ownerIds,
  updateUserCalendarEventAction,
  clientComments,
  showCommentsAction,
}: CommentsProps) => {
  const updatedComment = useMemo(
    () => clientComments.find((comment) => comment?.id === row?.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientComments]
  )

  const [comment, setComment] = useState(updatedComment?.comment)

  const updateComment = (comment: string) => {
    let updateCommentPayload = {
      eventId: row?.eventId,
      tackleId: row?.id ?? '',
      eventType: row?.eventType,
      comment,
    }
    updateUserCalendarEventAction({
      updateCommentPayload,
    })
    showCommentsAction(row?.id)
  }

  function textAreaAdjust(element) {
    element.target.style.height = '1px'
    element.target.style.height = 25 + element.target.scrollHeight + 'px'
  }

  const handleInputChange = (e) => {
    let comment = e.target.value || ' '
    setComment(comment)
  }

  return (
    <>
      {ownerIds?.includes(row?.id ?? '') && (
        <div className="grid grid-cols-11 bg-cream-dark xs:px-[24px] sm:pl-[36px] sm:pr-[28px] md:px-0   pb-[25px] pt-6">
          <div className="xs:col-span-12 md:col-span-5 md:pl-[60px] lg:pl-[104px] pb-3">
            <div className="text-sm font-primary font-semibold text-primary-text leading-5 pb-[12px]">
              DESCRIPTION
            </div>
            <div className="text-lg  text-black-light leading-7 font-primary font-normal">
              {get(row, 'text', '')}
            </div>
          </div>
          <div className="xs:col-span-12 md:col-span-6  xs:px-0  lg:pl-[23px] lg:pr-[23px]">
            <div className="text-sm font-primary font-semibold text-primary-text leading-5 pb-[4px]">
              NOTES
            </div>
            <InputTextArea
              defaultValue={updatedComment?.comment}
              onFocus={(e) => textAreaAdjust(e)}
              className="overflow-hidden mr-2"
              onChange={(e) => handleInputChange(e)}
              name={'comment'}
            />
            <div className="mt-2 mr-2 flex justify-end">
              <Button
                label={'Cancel'}
                variant="text"
                onClick={() => {
                  showCommentsAction(row?.id)
                  setComment(updatedComment?.comment)
                }}
              />
              <Button
                className={'ml-6'}
                label={'Save'}
                onClick={() => {
                  updateComment(comment ?? '')
                  showCommentsAction(row?.id)
                }}
                testId={TestIds.SAVE_MEETING_COMMENT_BUTTON}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Comments
