import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { openConfirmationModalAction } from 'store/actions/user'
import UserAction from 'components/Admin/UserManagement/UserManagementList/UserActions'

/* --------------------------- User Action --------------------------- */
const userActionProps = () => {}

function userActionDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openConfirmationModalAction: openConfirmationModalAction,
    },
    dispatch
  )
}
export const UserActionContainer = connect(userActionProps, userActionDispatch)(UserAction)
