import { isEmpty } from 'lodash'
import { RESET_ERRORS } from 'store/types'

function reducer(state = {}, action: any) {
  if (
    action &&
    action.type &&
    typeof action.type === 'string' &&
    action.type.includes('_SET_ERROR')
  ) {
    return {
      ...state,
      [action.state]: action.payload,
    }
  } else if (action.type === RESET_ERRORS) {
    if (isEmpty(action.payload)) {
      return {
        state: {},
      }
    } else {
      const keysToRemove = Array.isArray(action.payload) ? action.payload : [action.payload]
      const newState = Object.keys(state).reduce((result, key) => {
        if (!keysToRemove.includes(key)) {
          result[key] = state[key]
        }
        return result
      }, {})
      return newState
    }
  }

  return state
}

export default reducer
