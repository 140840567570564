import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import InputField from 'components/Common/InputField'
import Button from 'components/Common/Button'

import validations from 'pages/Admin/validations'

import hookForms from 'utils/hookForms'
import { FORM } from 'config'
import { scrollToTop } from 'utils/helper'
import useFormContainer from 'hooks/useFormContainer'

enum FORM_TYPE {
  BUSINESS = 'business',
  ADVISOR = 'advisor',
  INITIAL = 'initial assessment',
}
export default function ProfileForm({
  onSubmit,
  type = 'normal',
  heading,
  headerStyle = 'text-center',
  fontStyle = 'font-primary',
  handleBack = () => {},
}) {
  useEffect(() => {
    scrollToTop()
  }, [])

  const handleSubmit = () => {
    const profileForm = hookForms.getForm(FORM.PROFILE_FORM)
    const businessForm = hookForms.getForm(FORM.BUSINESS_PROFILE)
    profileForm.handleSubmit(async (profile) => {
      if (type === FORM_TYPE.BUSINESS || type === FORM_TYPE.INITIAL) {
        businessForm.handleSubmit(async (businessProfile) => {
          if (type === FORM_TYPE.BUSINESS) {
            onSubmit({ ...profile, ...businessProfile })
          } else if (type === FORM_TYPE.INITIAL) {
            onSubmit({
              profileData: { ...profile },
              businessData: { ...businessProfile },
            })
          }
        })()
      }
      type !== FORM_TYPE.BUSINESS && type !== FORM_TYPE.INITIAL && onSubmit({ ...profile })
    })()
  }

  return (
    <>
      <h2 className={`text-4xl font-regular leading-10 font-body pb-8 ${headerStyle} ${fontStyle}`}>
        {heading}
      </h2>
      <UserProfileForm type={type} />
      <BusinessProfileForm type={type} />
      <div className="mt-8">
        {type === FORM_TYPE.INITIAL ? (
          <div className="w-[100%]">
            <div className="mt-8 flex justify-between items-center gap-x-2">
              <div className="w-[122px] cursor-pointer inline-block  h-16">
                {/* check-button - clean up styles */}
                <Button
                  className="w-full !text-2xl !h-16"
                  type="button"
                  label="Back"
                  variant="secondary"
                  onClick={handleBack}
                />
              </div>
              <div className="w-full max-w-[200px] cursor-pointer inline-block text-sm h-16">
                {/* check-button - clean up styles */}
                <Button
                  className="w-full !text-2xl !h-16"
                  variant="primary"
                  type="button"
                  label="Next"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        ) : (
          <Button
            type="button"
            testId="profileSubmitButton"
            label="CONTINUE"
            variant="primary"
            onClick={handleSubmit}
            className="w-full"
          />
        )}
      </div>
    </>
  )
}

const UserProfileForm = ({ type }) => {
  const getResolver = () => {
    if (type === FORM_TYPE.BUSINESS) {
      return yupResolver(validations.businessOwnerProfileForm)
    } else if (type === FORM_TYPE.INITIAL) {
      return yupResolver(validations.profileForm)
    } else {
      return yupResolver(validations.advisorProfileForm)
    }
  }

  const methods = useForm({
    mode: 'all',
    resolver: getResolver(),
  })
  const {
    register,
    formState: { errors },
  } = methods

  useFormContainer(FORM.PROFILE_FORM, methods)
  return (
    <form>
      <div className="profileWrap">
        <div className="mb-4">
          <InputField
            type="text"
            defaultValue=""
            label="First Name"
            errors={errors.firstName}
            {...register('firstName')}
          />
        </div>

        <div className="mb-4">
          <InputField
            type="text"
            defaultValue=""
            label="Last Name"
            errors={errors.lastName}
            {...register('lastName')}
          />
        </div>

        {type !== FORM_TYPE.INITIAL && (
          <InputField
            type="email"
            label="Email"
            defaultValue=""
            errors={errors.email}
            className="mb-4"
            {...register('email')}
          />
        )}
        <InputField
          type="text"
          placeholder="(XXX) XXX-XXXX"
          mask="(999) 999-9999"
          label="Mobile Number"
          className="mb-4"
          errors={errors.mobileContactNumber}
          {...register('mobileContactNumber')}
        />
        {type === FORM_TYPE.ADVISOR && (
          <div>
            This phone number is private and will not be shared with clients. It will only be used
            for account creation.
          </div>
        )}
      </div>
    </form>
  )
}

const BusinessProfileForm = ({ type }) => {
  const getResolver = () => {
    if (type === FORM_TYPE.BUSINESS || type === FORM_TYPE.INITIAL) {
      return yupResolver(validations.businessProfileForm)
    } else {
      return yupResolver(validations.advisorBusinessProfileForm)
    }
  }

  const methods = useForm({
    mode: 'all',
    resolver: getResolver(),
  })

  const {
    register,
    formState: { errors },
  } = methods

  useFormContainer(FORM.BUSINESS_PROFILE, methods)

  return (
    <form>
      <div className="BusinessWrapper">
        {type !== FORM_TYPE.ADVISOR && (
          <InputField
            type="text"
            defaultValue=""
            label="Business Name"
            className="mb-4"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
              }
            }}
            errors={errors.businessName}
            {...register('businessName')}
          />
        )}
      </div>
    </form>
  )
}
