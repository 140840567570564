/* --------------------------- Assessment (Index) --------------------------- */

import { RootState } from 'App'
import Announcement from 'components/Announcement'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getClientsAction } from 'store/actions/clients'
import { publishAnnouncementAction, setAnnouncementActiveStepAction } from 'store/actions/user'
import { getBusinessesAction } from 'store/actions/userManagement'
import { getBusinesses } from 'store/selectors/userManagement'

const announcementProps = (state: RootState) => {
  const { isFetchingResponse, isAllClientFetching, isOwnerDataLoading } = state.loadingStatus
  const { announcementError, announcementSteps, activeStep, user } = state.user

  return {
    activeStep,
    userId: user.id,
    isAllClientFetching,
    isOwnerDataLoading,
    announcementSteps,
    announcementError,
    isFetchingResponse,
    allClient: getBusinesses(state),
    assignedClients: state?.clients?.assignedOwnerList,
  }
}

function announcementDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setAnnouncementActiveStepAction: setAnnouncementActiveStepAction,
      getClientsAction: getClientsAction.STARTED,
      getBusinessesAction: getBusinessesAction.STARTED,
      publishAnnouncementAction: publishAnnouncementAction.STARTED,
    },
    dispatch
  )
}

export const AnnouncementContainer = connect(announcementProps, announcementDispatch)(Announcement)
