import React from 'react'

export default function GoTackleBrandIcon({ className = '' }) {
  return (
    <svg viewBox="0 0 45 35" xmlns="http://www.w3.org/2000/svg" className={`${className}`}>
      <path d="M41.9677 0.775937C41.2511 0.857852 28.0123 0.51083 27.1122 1.07977C25.9948 1.40445 25.1049 2.11785 24.2371 2.867C23.3781 1.48785 22.1682 0.774447 20.4722 0.847426C17.3387 0.798277 10.7898 0.780405 7.38609 0.76849C7.22311 0.76849 7.06012 0.933809 7.06012 1.12147C6.90153 1.57126 1.07651 27.7825 0.734375 29.0485C0.734375 29.2555 0.913516 29.4104 1.09266 29.4104C3.52575 29.6442 11.9968 28.6851 14.2713 30.6764C15.7147 31.6236 17.0171 33.9604 17.1082 34.084C17.2433 34.2672 17.5766 34.3268 17.8233 34.1436C18.0832 33.9485 19.9583 32.0749 21.5074 31.0472C24.0947 28.694 33.993 29.4566 35.6757 29.4566C35.8549 29.4566 36.059 29.3002 36.0839 29.0932L42.3245 1.13934C42.3495 0.957639 42.1968 0.775937 41.9662 0.775937H41.9677ZM32.8271 26.0459C28.4704 26.1487 23.8392 25.3504 20.1595 28.0655L21.0757 23.9042H18.2917L17.2638 28.3678C14.4181 25.477 10.1231 26.0653 5.59615 26.043L10.2744 4.63189C12.0966 4.63189 17.625 4.63189 19.8452 4.63189C20.8378 4.63189 21.3209 4.82253 21.7614 5.38551C22.3224 6.45934 21.7835 7.14147 21.4648 9.16253H24.3428C24.9273 6.62615 24.9214 5.44509 27.1357 4.71828C27.4602 4.67806 28.7347 4.61402 33.5451 4.61402C35.1662 4.61402 36.6933 4.62147 37.5479 4.62743L32.8256 26.0459H32.8271Z" />
      <path d="M12.731 19.0667C14.0364 19.0891 14.035 21.058 12.731 21.0804C11.4271 21.058 11.4271 19.0891 12.731 19.0667ZM12.731 17.0814C8.85454 17.1514 8.85601 22.9942 12.731 23.0642C16.6075 22.9942 16.6061 17.1514 12.731 17.0814Z" />
      <path d="M31.0456 10.1678L29.3467 9.38444C29.0163 9.09848 28.2087 14.1772 28.1015 14.2204C25.1985 14.2204 21.4791 14.2204 18.5732 14.2204C18.5189 14.2293 13.0727 14.2025 13.0213 14.2293C12.9582 14.2353 12.898 14.2844 12.8804 14.3664L12.0742 18.0019L14.1255 18.751C14.2533 18.1717 14.5161 16.9876 14.6439 16.4142C19.5541 16.4068 24.7448 16.4276 29.6536 16.4068C29.7461 16.4381 29.843 16.374 29.8577 16.2981L30.2512 14.5272C30.4803 13.4951 30.9443 11.404 31.1719 10.3764C31.188 10.3019 31.1366 10.2006 31.0456 10.1678Z" />
      <path d="M28.6941 9.10147C28.6501 9.02849 28.6603 8.90785 28.7352 8.8453L30.0582 7.49743C30.1228 7.44232 30.233 7.4587 30.2902 7.55104L33.2784 11.1285C33.3224 11.2015 33.296 11.3191 33.2152 11.3698L31.8922 12.6968C31.81 12.7489 31.7043 12.7147 31.6602 12.6432L28.6941 9.10147Z" />
      <path d="M31.3658 9.40375C31.4099 9.33077 31.3996 9.21013 31.3247 9.14758L30.0957 7.65673C30.0311 7.60162 29.9209 7.61801 29.8637 7.71035L26.1149 11.1284C26.0709 11.2014 26.0973 11.3191 26.1781 11.3697L27.4071 12.6967C27.4893 12.7489 27.595 12.7146 27.6391 12.6431L31.3658 9.40375Z" />
    </svg>
  )
}
