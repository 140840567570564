import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AvailabilityHoursLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 800"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="25" y="30" rx="2" ry="2" width="200" height="13" />

        <rect x="25" y="53" rx="2" ry="2" width="100" height="13" />
        <rect x="25" y="75" rx="2" ry="2" width="200" height="13" />

        <rect x="25" y="120" rx="2" ry="2" width="100" height="13" />
        <rect x="25" y="140" rx="2" ry="2" width="200" height="13" />

        <rect x="25" y="175" rx="2" ry="2" width="100" height="13" />

        <rect x="25" y="210" rx="2" ry="2" width="100" height="13" />

        <rect x="25" y="240" rx="2" ry="2" width="400" height="1" />

        <rect x="25" y="265" rx="2" ry="2" width="10" height="10" />
        <rect x="45" y="265" rx="2" ry="2" width="40" height="10" />

        <rect x="110" y="260" rx="2" ry="2" width="70" height="22" />
        <rect x="220" y="260" rx="2" ry="2" width="70" height="22" />

        <rect x="300" y="265" rx="2" ry="2" width="10" height="10" />

        <rect x="410" y="265" rx="2" ry="2" width="10" height="10" />

        <rect x="25" y="300" rx="2" ry="2" width="400" height="1" />

        <rect x="25" y="330" rx="2" ry="2" width="10" height="10" />
        <rect x="45" y="330" rx="2" ry="2" width="40" height="10" />

        <rect x="110" y="325" rx="2" ry="2" width="70" height="22" />
        <rect x="220" y="325" rx="2" ry="2" width="70" height="22" />

        <rect x="300" y="330" rx="2" ry="2" width="10" height="10" />

        <rect x="410" y="330" rx="2" ry="2" width="10" height="10" />

        <rect x="25" y="370" rx="2" ry="2" width="400" height="1" />
      </ContentLoader>
    </>
  )
}

export default AvailabilityHoursLoader
