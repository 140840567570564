import React from 'react'

function PlayCardCornerEllipse({ className = '' }) {
  return (
    <svg
      width="91"
      height="91"
      viewBox="0 0 91 91"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="70" cy="70" r="70" fill="url(#paint0_linear_18726_127873)" />
      <defs>
        <linearGradient
          id="paint0_linear_18726_127873"
          x1="0"
          y1="0"
          x2="168.99"
          y2="70.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF84FB" />
          <stop offset="1" stopColor="#A8D4FC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PlayCardCornerEllipse
