import { RootState } from 'App'
import { useSelector } from 'react-redux'

export function useUserInfo() {
  const user = useSelector((state: RootState) => state.user.user)
  const businessProfile = useSelector((state: RootState) => state.user.businessProfile)
  const roles = user?.roles
  const organizationName = user?.organizationName
  const businessName = businessProfile?.businessName
  const userName = user?.firstName + ' ' + user?.lastName
  const userId = user?.id

  return { roles, organizationName, businessName, userName, userId }
}
