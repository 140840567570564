import { Auth } from 'aws-amplify'
class AUTH_API {
  login = (email: string, password: string) => {
    return Auth.signIn(email, password)
  }
  signup = (user) => {
    return Auth.signUp(user)
  }
  forgotPassword = (email: string) => {
    return Auth.forgotPassword(email)
  }
  forgotPasswordSubmit = (email, code, password) => {
    return Auth.forgotPasswordSubmit(email, code, password)
  }
  changePassword = (user, password) => {
    return Auth.completeNewPassword(user, password)
  }
  logout = () => {
    return Auth.signOut()
  }
}

// eslint-disable-next-line
export default new AUTH_API()
