import React from 'react'

function AttestationMinusIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.848 0.664H5.424V3.064H0.848V0.664Z" />
    </svg>
  )
}

AttestationMinusIcon.defaultProps = {
  className: 'h-2 w-2 fill-black-light',
}
export default AttestationMinusIcon
