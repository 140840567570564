import React, { FC, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'

import UserAccounts from 'components/Admin/UserManagement/UserManagementList/UserAccounts'
import { UserManagementListWrapper } from 'components/Admin/UserManagement/UserManagementList/style'

import { EditAccountDetailsContainer } from 'pages/Common/container'

export interface IUser {
  userId?: string
  name?: string
  email?: string
  created?: string
  updated?: string
  activated?: string
  advisors?: string
  businessName?: string
  activatedAt?: string
  activatedSort?: string
}

interface IUserManagementListProps {
  userAccounts: IUser[]
  showAdminRightsToggle?: boolean
  nextPaginatedDataHandler: (...args: unknown[]) => unknown
  pageSize: number
  noOfItemsPerPageOptions?: { value: string; label: string }[]
  currentPage?: number
  noOfItemsPerPage?: number
  totalPages?: number
  moveNextHandler?: (pageNumber: number) => void
  movePrevHandler?: (pageNumber: number) => void
  updateNoOfItemPerPageHandler?: (itemsPerPage: string) => void
}

const UserManagementList: FC<IUserManagementListProps> = ({
  userAccounts,
  showAdminRightsToggle = false,
  noOfItemsPerPageOptions,
  currentPage,
  noOfItemsPerPage,
  totalPages,
  moveNextHandler,
  movePrevHandler,
  updateNoOfItemPerPageHandler,
}) => {
  let { isSelectedTab } = useParams()
  const [selectedUser, setSelectedUser] = useState<IUser>()
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [openUserManagementModal, setOpenUserManagementModal] = useState(false)

  const showAssociatedAdvisors = useMemo(() => {
    return userAccounts?.some((user) => Boolean(user?.advisors))
  }, [userAccounts])
  return (
    <UserManagementListWrapper data-testid="user-management-list">
      <UserAccounts
        userAccounts={userAccounts}
        setSelectedUser={setSelectedUser}
        showAdminRightsToggle={showAdminRightsToggle}
        showAssociatedAdvisors={showAssociatedAdvisors}
        setOpenResetPasswordModal={setOpenResetPasswordModal}
        setOpenUserManagementModal={setOpenUserManagementModal}
        noOfItemsPerPageOptions={noOfItemsPerPageOptions}
        currentPage={currentPage}
        noOfItemsPerPage={noOfItemsPerPage}
        totalPages={totalPages}
        moveNextHandler={moveNextHandler}
        movePrevHandler={movePrevHandler}
        updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
      />
      <EditAccountDetailsContainer
        selectedUser={selectedUser || {}}
        isSelectedTab={isSelectedTab}
        openResetPasswordModal={openResetPasswordModal}
        openUserManagementModal={openUserManagementModal}
        setOpenUserManagementModal={setOpenUserManagementModal}
        setOpenResetPasswordModal={setOpenResetPasswordModal}
      />
    </UserManagementListWrapper>
  )
}

export default React.memo(UserManagementList)
