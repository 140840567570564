import { Auth } from 'aws-amplify'

export const getAuthToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user?.signInUserSession?.accessToken?.jwtToken
  } catch (e) {
    return false
  }
}

export const refreshToken = async () => {
  return Auth.currentSession()
}
