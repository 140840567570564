import React from 'react'

function MsExcelIcon({ className }) {
  return (
    <svg
      className={className}
      width="33"
      height="44"
      viewBox="0 0 33 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 11V0H4.125C1.8468 0 0 1.8468 0 4.125V39.875C0 42.1523 1.8468 44 4.125 44H28.875C31.1532 44 33 42.1532 33 39.875V13.75H22.0773C20.4789 13.75 19.25 12.5211 19.25 11ZM23.3836 22.7219L19.25 29.5625L23.4601 36.404C24.0281 37.3227 23.3664 38.5 22.2922 38.5H20.0209C19.5433 38.5 19.1005 38.2522 18.8495 37.8454L16.5 34.0312L14.153 37.8452C13.9047 38.2508 13.4578 38.5 12.9852 38.5H10.7078C9.63188 38.5 8.97273 37.3201 9.53648 36.404L13.75 29.5625L9.53906 22.7219C8.97188 21.8023 9.63359 20.625 10.7078 20.625H12.9791C13.4567 20.625 13.8995 20.8728 14.1505 21.2792L16.5 25.0938L18.847 21.279C19.0953 20.8742 19.5422 20.625 20.0148 20.625H22.2862C23.3664 20.625 24.0281 21.8023 23.3836 22.7219ZM22 0V11H33L22 0Z"
        fill="black"
      />
    </svg>
  )
}
export default MsExcelIcon
