import React from 'react'

function SendIcon() {
  return (
    <svg width="29" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1855 0.329824C28.869 0.110801 28.4999 0 28.1249 0C27.8049 0 27.4839 0.0819727 27.1944 0.247441L0.94444 15.1947C0.323991 15.6035 -0.0403739 16.2773 0.00357137 16.9395C0.0475167 17.6016 0.494294 18.2789 1.15318 18.5537L7.20006 21.0738L10.8241 29.6578C10.9101 29.918 11.0858 30 11.1972 30C11.3178 30 11.4357 29.9523 11.5227 29.8688L16.5471 25.0406L23.6018 27.9797C23.8533 28.0847 24.0998 28.1245 24.3219 28.1245C24.7095 28.1245 25.0315 28.0019 25.2406 27.8845C25.7442 27.6016 26.0885 27.1046 26.1764 26.5339L29.9264 2.21162C30.0878 1.44844 29.7831 0.737109 29.1855 0.329824ZM1.87491 16.875L24.1464 4.14844L7.25967 19.1191L1.87491 16.875ZM11.7597 27.0879L8.85343 20.209L22.3007 8.29102L12.3691 21.9492C12.1988 22.1836 12.1466 22.4821 12.2262 22.7602C12.3067 23.0385 12.51 23.2637 12.7792 23.3701L14.7948 24.1764L11.7597 27.0879ZM24.4159 25.9746L14.5898 22.0746L27.8144 3.89297L24.4159 25.9746Z"
        fill="rgba(94, 6, 185, 1)"
      />
    </svg>
  )
}
export default SendIcon
