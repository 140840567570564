import React from 'react'

export default function ChatFilledIcon({ className = '' }) {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.47871 0.413788C1.88101 0.413788 0.582031 1.71277 0.582031 3.31046V12.0005C0.582031 13.5982 1.88101 14.8972 3.47871 14.8972H4.92705V17.0697C4.92705 17.3458 5.08093 17.5947 5.32534 17.7169C5.56975 17.8391 5.86394 17.812 6.08572 17.649L9.75635 14.8972H16.5138C18.1115 14.8972 19.4104 13.5982 19.4104 12.0005V3.31046C19.4104 1.71277 18.1115 0.413788 16.5138 0.413788H3.47871ZM16.5138 16.3455H12.1687V17.7938C12.1687 19.3915 13.4677 20.6905 15.0654 20.6905H20.3745L24.0451 23.4424C24.2624 23.6053 24.5566 23.6325 24.8055 23.5103C25.0544 23.3881 25.2038 23.1391 25.2038 22.863V20.6905H26.6521C28.2498 20.6905 29.5488 19.3915 29.5488 17.7938V9.10382C29.5488 7.50612 28.2498 6.20714 26.6521 6.20714H20.8588V12.0005C20.8588 14.3993 18.9126 16.3455 16.5138 16.3455Z"
        fill="white"
      />
    </svg>
  )
}
