import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { FIELD_NAME, FORM, LABEL } from 'config'
import InputTextArea from 'components/Common/InputTextArea'
import SelectField from 'components/Common/SelectField'
import { SelectFieldWrapper } from 'components/Common/StyledComponents/SelectFieldWrapper'
import Loader from 'components/Loaders'
import useFormContainer from 'hooks/useFormContainer'
import { BusinessProfileMultiSelectFieldsContainer } from 'pages/Admin/container'

const EditOtherDetails = ({ onSubmit, goals, detailsLoading, setIsFormSame }) => {
  const methods = useForm({
    mode: 'all',
  })
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    control,
  } = methods

  useEffect(() => {
    isDirty && setIsFormSame(false)
    // eslint-disable-next-line
  }, [isDirty])

  useFormContainer(FORM.BUSINESS_OTHER_DETAILS, methods)

  return (
    <>
      <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3 border-r border-grey-darker !pr-5">
        <div className="my-8">
          <label className="font-normal text-2xl">Other Details</label>
        </div>
        {detailsLoading ? (
          <Loader loader={'AdvisorEditOwnerDetail'} />
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid xs:grid-cols-12  gap-3">
                <div className="col-span-12">
                  <InputTextArea
                    label={LABEL.BUSINESS_VISION}
                    defaultValue={''}
                    {...register(FIELD_NAME.BUSINESS_VISION)}
                  />
                </div>
                <div className="col-span-12">
                  <InputTextArea
                    label={LABEL.CHALLENGES}
                    defaultValue={''}
                    {...register(FIELD_NAME.CHALLENGES)}
                  />
                </div>
                <div className="col-span-12">
                  <Controller
                    defaultValue={''}
                    name={FIELD_NAME.TOP_GOAL_OPTION_ID}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        placeholder="Select"
                        {...field}
                        applyStyle
                        label={'Top Goal'}
                        options={goals}
                        classes="font-primary text-silver-darker text-base font-normal"
                        isSearchable={false}
                      />
                    )}
                  />
                </div>
                <div className="col-span-12">
                  <SelectFieldWrapper>
                    <Controller
                      defaultValue={''}
                      name={FIELD_NAME.OTHER_GOALS_OPTION_IDS}
                      control={control}
                      render={({ field }) => (
                        <SelectField
                          placeholder="Select"
                          {...field}
                          isMulti={true}
                          applyStyle
                          label={'Other Goals'}
                          options={goals}
                          isSearchable={false}
                          classes="font-primary text-silver-darker text-base font-normal"
                        />
                      )}
                    />
                  </SelectFieldWrapper>
                </div>

                <div className="col-span-12">
                  <BusinessProfileMultiSelectFieldsContainer control={control} />
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}
export default EditOtherDetails
