import { STATUS } from 'config'
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, LOGIN } from 'store/types'

const initialState = {
  userState: {},
  user: null,
  error: '',
  disableLoginSubmit: false,
  status: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
        status: STATUS.LOADING,
      }
    case LOGIN.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
        status: STATUS.REJECTED,
      }
    case LOGIN.FULLFILLED:
      return {
        ...state,
        user: action.payload.user,
        disableLoginSubmit: false,
        error: '',
        status: STATUS.COMPLETED,
      }
    case FORGOT_PASSWORD.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
        status: STATUS.LOADING,
      }
    case FORGOT_PASSWORD.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
      }
    case FORGOT_PASSWORD.FULLFILLED:
      return {
        ...state,
        disableLoginSubmit: false,
        status: STATUS.COMPLETED,
      }
    case FORGOT_PASSWORD_SUBMIT.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
        status: STATUS.LOADING,
      }
    case FORGOT_PASSWORD_SUBMIT.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
      }
    case FORGOT_PASSWORD_SUBMIT.FULLFILLED:
      return {
        ...state,
        disableLoginSubmit: false,
        status: STATUS.COMPLETED,
      }

    default:
  }
  return state
}

export default reducer
