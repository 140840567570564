import React from 'react'
import { userStatus, userStatusPillColor } from 'config'
import PillTextBox from 'components/Common/PillTextBox'

const ProfileStatus = ({ profile }) => {
  return (
    <>
      <div className="text-sm font-normal"> Account Status: </div>
      <div className="mt-1 xs:w-1/4 sm:w-full">
        {profile.status && (
          <PillTextBox colorTheme={userStatusPillColor[profile.status]}>
            {userStatus[profile.status]}
          </PillTextBox>
        )}
      </div>
    </>
  )
}

export default ProfileStatus
