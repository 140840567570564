import React from 'react'

function SolidTrashIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M17.4173 3.66667H14.209L13.2923 2.75H8.70898L7.79232 3.66667H4.58398V5.5H17.4173M5.50065 17.4167C5.50065 17.9029 5.69381 18.3692 6.03762 18.713C6.38144 19.0568 6.84775 19.25 7.33398 19.25H14.6673C15.1535 19.25 15.6199 19.0568 15.9637 18.713C16.3075 18.3692 16.5007 17.9029 16.5007 17.4167V6.41667H5.50065V17.4167Z"
        fill="#5E06B9"
      />
    </svg>
  )
}
export default SolidTrashIcon
