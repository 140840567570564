import React, { useMemo } from 'react'
import { AppFC } from 'types'

import {
  ToastWrapper,
  HighlightColorWrapper,
  ChildrenWrapper,
  ButtonWrapper,
} from 'components/Common/Toast/styles'
import { CrossIcon } from '../SvgIcons'

export interface IToastProps {
  variant?: 'info' | 'warning' | 'success' | 'alert' | 'none' | 'overlay'
  onClose?: (...args: any[]) => any
  showCloseButton?: boolean
}

const Toast: AppFC<IToastProps> = ({
  children,
  variant = 'info',
  onClose = () => {},
  showCloseButton = true,
}) => {
  const iconClasses = useMemo(() => {
    return `cursor-pointer hover ${variant === 'overlay' ? 'fill-white' : ''}`
  }, [variant])
  return (
    <ToastWrapper variant={variant}>
      <div className="flex">
        <HighlightColorWrapper variant={variant} />
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </div>
      {showCloseButton && (
        <ButtonWrapper>
          {/* This should not be a button - additional flexibility may be needed for styling */}
          <CrossIcon className={iconClasses} onClick={onClose} />
        </ButtonWrapper>
      )}
    </ToastWrapper>
  )
}

export default Toast
