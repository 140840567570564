import { SHOW_BUSINESS_PROFILE, SHOW_MY_PROFILE } from 'store/types'

const initialState = {
  showMyProfile: false,
  isBusinessProfileEditMode: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MY_PROFILE:
      return {
        ...state,
        profile: action.paylaod,
        showMyProfile: !!action?.payload,
      }
    case SHOW_BUSINESS_PROFILE:
      return {
        ...state,
        isBusinessProfileEditMode: action?.payload,
      }
    default:
  }
  return state
}

export default reducer
