import React from 'react'

function EmojiIcon() {
  return (
    <svg width="29" height="23" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 24.3809C11.2617 24.3809 8.04492 22.1484 6.63281 18.9551C6.33398 18.2695 7.03125 17.6426 7.75195 17.8535C9.9375 18.5098 12.3984 18.873 15 18.873C17.6074 18.873 20.0684 18.5098 22.2539 17.8535C22.9746 17.6426 23.6719 18.2695 23.373 18.9551C21.9609 22.1484 18.7441 24.3809 15 24.3809ZM9.73242 20.2617C11.0566 21.6445 12.9258 22.5059 15 22.5059C17.0801 22.5059 18.9492 21.6445 20.2207 20.2617C18.5977 20.584 16.8281 20.748 15 20.748C13.1777 20.748 11.4082 20.584 9.73242 20.2617ZM11.7422 12.1875C11.7422 12.9668 11.1094 13.5938 10.3359 13.5938C9.55664 13.5938 8.92969 12.9668 8.92969 12.1875C8.92969 11.4082 9.55664 10.7812 10.3359 10.7812C11.1094 10.7812 11.7422 11.4082 11.7422 12.1875ZM18.3047 12.1875C18.3047 11.4082 18.9316 10.7812 19.7109 10.7812C20.4844 10.7812 21.1172 11.4082 21.1172 12.1875C21.1172 12.9668 20.4844 13.5938 19.7109 13.5938C18.9316 13.5938 18.3047 12.9668 18.3047 12.1875ZM30 15C30 23.2852 23.2852 30 15 30C6.71484 30 0 23.2852 0 15C0 6.71484 6.71484 0 15 0C23.2852 0 30 6.71484 30 15ZM15 1.875C7.75195 1.875 1.875 7.75195 1.875 15C1.875 22.248 7.75195 28.125 15 28.125C22.248 28.125 28.125 22.248 28.125 15C28.125 7.75195 22.248 1.875 15 1.875Z"
        fill="#71717A"
      />
    </svg>
  )
}
export default EmojiIcon
