import { useMemo } from 'react'
import DateLabeler from 'utils/DateLabeler'

/** A reactive wrapper around the DateLabeler utility class. A new DateLabeler instance is returned whenever date changes. */
const useDateLabeler = (date) => {
  const labler = useMemo(() => {
    return new DateLabeler(date)
  }, [date])
  return labler
}

export default useDateLabeler
