import React from 'react'

function ErrorIcon({ className }) {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 0.5C3.35742 0.5 0 3.85742 0 8C0 12.1426 3.35742 15.5 7.5 15.5C11.6426 15.5 15 12.1426 15 8C15 3.85742 11.6426 0.5 7.5 0.5ZM7.5 14.5625C3.88184 14.5625 0.9375 11.6182 0.9375 8C0.9375 4.38184 3.88184 1.4375 7.5 1.4375C11.1182 1.4375 14.0625 4.38184 14.0625 8C14.0625 11.6182 11.1182 14.5625 7.5 14.5625ZM7.5 9.40625C7.7591 9.40625 7.96875 9.1966 7.96875 8.9375V4.25C7.96875 3.9909 7.7591 3.78125 7.5 3.78125C7.2409 3.78125 7.03125 3.99219 7.03125 4.25V8.9375C7.03125 9.19531 7.24219 9.40625 7.5 9.40625ZM7.5 10.5781C7.11182 10.5781 6.79688 10.8931 6.79688 11.2812C6.79688 11.6694 7.11182 11.9844 7.5 11.9844C7.88818 11.9844 8.20312 11.6694 8.20312 11.2812C8.20312 10.8931 7.88965 10.5781 7.5 10.5781Z" />
    </svg>
  )
}

export default ErrorIcon
