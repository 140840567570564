import React from 'react'

import { ASSESSMENTS } from 'config/data'
import { isActiveStep } from 'utils/helper'

import CardView from 'components/Common/CardView'
import ProgressBar from 'components/Common/ProgressBar'
import assessmentHOC from 'components/Assessment/assessmentHOC'
import QuestionsContainerStyle from 'components/Common/QuestionBox'
import { QuestionsContainer, AssessmentSuccessContainer } from 'pages/Assessment/containers'
import { useSelector } from 'react-redux'
import { RootState } from 'App'

const AssessmentWidget = ({
  activeStep,
  currentUser,
  totalProgress,
  isCompleted,
  tagColor,
  assessmentTag,
  setIsLastQuestion,
  assessmentType,
}) => {
  const { assessmentBgColor } = useSelector((state: RootState) => state.assessment)
  return (
    <CardView
      type=""
      padding={`md:py-9 md:px-9`}
      classes={`md:shadow-lg rounded-[20px] ${assessmentBgColor}`}
    >
      <div className="xs:w-[90%] sm:mx-auto md:w-[full] flex sm:flex-row xs:flex-col sm:items-center">
        <div className="flex flex-row items-center">
          <p className="font-primary text-base font-normal">ASSESSMENTS</p>
          <p
            className={`mx-3 font-primary text-xs ${tagColor} font-normal text-primary-text hover:border-[1px] hover:border-zinc hover:px-[17px] hover:py-[5px] py-[6px] px-[18px]  mr-2 rounded-[31px] cursor-pointer`}
          >
            {assessmentTag}
          </p>
        </div>
        {!isCompleted && activeStep !== 'Terrific' && activeStep && (
          <div className="flex-1 flex justify-end xs:w-[100%] xs:mt-2 sm:mt-[0px]">
            <ProgressBar
              hasFixedWidth={false}
              bgColorClass=" bg-gradient-to-r from-[#52B4F9] to-[#8400F7]"
              width={totalProgress}
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 mb-2">
          <div className="relative h-[100%] w-[100%] overflow-hidden">
            <div className={`${activeStep !== 'Terrific' ? 'mt-7' : ''} flex flex-col`}>
              <h2 className="text-center font-medium text-4xl text-primary-text">
                {ASSESSMENTS?.[assessmentType]?.title || ''}
              </h2>
              {!isCompleted && (
                <div className={isActiveStep(activeStep, 'Questions')}>
                  <QuestionsContainerStyle>
                    <QuestionsContainer
                      userId={currentUser?.id}
                      assessmentType={assessmentType}
                      widget={true}
                      onSubmit={() => {
                        setIsLastQuestion(true)
                      }}
                    />
                  </QuestionsContainerStyle>
                </div>
              )}
              {(isCompleted || activeStep === 'Terrific') && (
                <div className="mt-2">
                  <AssessmentSuccessContainer
                    hideRecommendations={true}
                    allowNextPlayMessage={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CardView>
  )
}

export default assessmentHOC(AssessmentWidget)
