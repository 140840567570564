import React, { useMemo } from 'react'
import Button from 'components/Common/Button'
import { TestIds } from 'types'
import { ReduxAction } from '../../../../store/types'

interface AddCommentsProps {
  data: { id: string }
  showCommentsAction: ReduxAction<string>
  clientComments: { id: string; comment: string }[]
}

const AddComments = ({ data, showCommentsAction, clientComments }: AddCommentsProps) => {
  const updatedComment = useMemo(
    () => clientComments.find((comment) => comment.id === data.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientComments]
  )
  return (
    <>
      <td className="col-span-1 px-3 self-center">
        <div className="lg:px-[5px] mdl:w-full lg:w-auto mdl:flex justify-center">
          {/* check-button - clean up custom styles */}
          <Button
            testId={TestIds.ADD_MEETING_COMMENT_BUTTON}
            className="w-full md:px-[18px] md:py-[9px] md:w-[72px] lg:w-[80px]"
            label={`${
              updatedComment?.comment !== '' && updatedComment?.comment !== ' ' ? 'View' : 'Add'
            }`}
            variant="secondary"
            onClick={() => {
              showCommentsAction(data.id)
            }}
          />
        </div>
      </td>
    </>
  )
}

export default AddComments
