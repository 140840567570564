import React from 'react'
import { Columns } from 'types'
// import Button from 'components/Common/Button'
import UserTableHeader from 'components/Common/UserTableHeader'
import PdfViewer from 'components/DataReports/ClientReportManagement/PdfViewer'
import { ClientName, BusinessName } from 'components/DataReports/ClientReportManagement/styles'
import { SortOrder } from '__generated__/api-types-and-hooks'

export const unSignedColumn: Columns = [
  {
    text: 'records',
    tableType: 'User',
    width: 260,
    textDirection: 'left',
    colSpan: 'xs:col-span-6 sm:col-span-9 lg:col-span-10',
    direction: 'items-center',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
        sortingOrder: SortOrder.Descending,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="xs:col-span-7 sm:col-span-11">
          <ClientName>{params?.properName || '(No name provided)'}</ClientName>
          <BusinessName>{params?.businessName || '(No business name provided)'}</BusinessName>
        </UserTableHeader>
      )
    },
  },

  // {
  //   textDirection: 'right',
  //   colSpan: 'xs:col-span-6 sm:col-span-3 lg:col-span-2 justify-center',
  //   direction: 'items-end',
  //   colFields: [
  //     {
  //       field: '',
  //       type: 'string',
  //       headerButton: <Button variant="text" label="Send reminder to all" />,
  //       paddingTop: 'pt-4',
  //     },
  //   ],
  //   renderCell: (params) => {
  //     return <></>
  //   },
  // },
]

export const signedColumn: Columns = [
  {
    text: 'records',
    tableType: 'User',
    width: 260,
    textDirection: 'left',
    colSpan: 'col-span-10',
    direction: 'items-center',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
        sortingOrder: SortOrder.Descending,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-11">
          <ClientName>{params?.properName || '(No name provided)'}</ClientName>
          <BusinessName>{params?.businessName || '(No business name provided)'}</BusinessName>
        </UserTableHeader>
      )
    },
  },

  {
    textDirection: 'right',
    colSpan: 'col-span-2 justify-center',
    direction: 'items-end',
    colFields: [],
    renderCell: (params) => {
      return (
        <UserTableHeader className={'col-span-1'}>
          {params?.name ? <PdfViewer src={params?.signedDocumentUrl} /> : ''}
        </UserTableHeader>
      )
    },
  },
]
