import { RESET_PROGRESSBAR, TOTAL_STEPS_PROGRESSBAR, UPDATE_PROGRESSBAR } from 'store/types'

const initialState = {
  width: 15,
  totalSteps: 0,
  startingIndex: 0,
}

function reducer(state = initialState, action) {
  let increment = 0
  switch (action.type) {
    case TOTAL_STEPS_PROGRESSBAR:
      increment = action?.paylaod?.totalSteps ? 85 / action?.paylaod?.totalSteps : 0

      return {
        ...state,
        totalSteps: action?.paylaod?.totalSteps,
        width: action?.paylaod?.startingIndex
          ? action?.paylaod?.startingIndex * increment + 15
          : 15,
      }
    case UPDATE_PROGRESSBAR:
      increment = state?.totalSteps ? 85 / state?.totalSteps : 0
      return {
        ...state,
        width: state.width + increment,
      }
    case RESET_PROGRESSBAR:
      return {
        ...state,
        ...initialState,
      }
    default:
  }
  return state
}

export default reducer
