import React from 'react'

import CardComponent from './Cards.component'
import useDragElement from 'hooks/useDragElement'
import useClientStatsCards from './ClientStatsCards.hooks'
import SectionTitleComponent from '../SectionTitle/SectionTitle.component'
import DraggableContentArea from 'components/Common/DraggableContentArea'

const ClientStatsCards = () => {
  const { showNoActiveClientsMessage, isFetching, summary, total } = useClientStatsCards()

  useDragElement('#drag-area')
  /* use of overflow hidden here is duct-tape to prevent stat cards from overreaching the side of the screen until drag/scrolling is implemented */
  return (
    <section className="flex flex-col gap-3 lg:overflow-hidden">
      {/* negative padding helps prevent box shadows from forming a sort of "box" with outlines */}
      <div className="-pb-3 flex flex-col gap-2 sm:flex-row sm:gap-9 items-baseline">
        <SectionTitleComponent>Client Stats</SectionTitleComponent>
        {showNoActiveClientsMessage && (
          <p className="italic text-black-light text-lg sm:text-xl">You have no active clients.</p>
        )}
      </div>
      <DraggableContentArea>
        <CardComponent
          loading={isFetching}
          title="Signed In"
          description="Percentage of clients who have signed in at least 1 time."
          value={summary?.userSignIns}
          total={total}
        />
        <CardComponent
          loading={isFetching}
          title="Welcome Assessment"
          description="Percentage of clients who have completed the welcome assessment."
          value={summary?.completedInitialAssessmentCount}
          total={total}
        />
        <CardComponent
          loading={isFetching}
          title="Business Baseline"
          description="Percentage of clients who have completed the business baseline assessment."
          value={summary?.baselineAssessmentCompleted}
          total={total}
        />
        <CardComponent
          loading={isFetching}
          title="First Play"
          description="Percentage of clients who have completed at least 1 play."
          value={summary?.completedPlayCount}
          total={total}
        />
      </DraggableContentArea>
    </section>
  )
}

export default ClientStatsCards
