import React, { FC, useEffect } from 'react'
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import Button from 'components/Common/Button'
import { getTenantId } from 'utils/getTenantId'
import { getRoleFromPath } from 'utils/helper'
import history from 'utils/history'

interface IPdfViewer {
  fileUrl: string
  handleClose: () => void
}
const PDFViewer: FC<IPdfViewer> = ({ fileUrl, handleClose }) => {
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    // hide toolbar items
    ...slot,
    Download: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
    SwitchTheme: () => <></>,
    ShowProperties: () => <></>,
    EnterFullScreen: () => <></>,
    ShowPropertiesMenuItem: () => <></>,
  })

  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <>
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      <Button
        label="Go back to search"
        variant="text"
        className=" w-5/12 sm:w-3/12 md:w-2/12 lg:w-1/12"
        onClick={() => handleClose()}
      />
    </>
  )
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar, // Pass renderToolbar function directly
  })
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance
  const pluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Thumbnails tab
    ],
    toolbarPlugin: {
      zoomPlugin: {},
    },
  })

  const tenantId = getTenantId()
  const role = getRoleFromPath()

  useEffect(() => {
    window.onpopstate = () => {
      handleClose()
      history.push(`/${role}/${tenantId}/data-reports/industry_report`)
    }
  }, [role, tenantId, handleClose])

  return (
    <div className="h-screen">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
        <Viewer
          theme={'white'}
          defaultScale={SpecialZoomLevel.PageFit}
          fileUrl={fileUrl}
          plugins={[pluginInstance]}
          tw="hover:!bg-white"
        />
      </Worker>
    </div>
  )
}

export default PDFViewer
