import React, { PropsWithChildren } from 'react'
import { ChannelListMessengerProps } from 'stream-chat-react'
import './MessagingChannelList.css'
import { SkeletonLoader } from './SkeletonLoader'

const MessagingChannelList = (props: PropsWithChildren<ChannelListMessengerProps>) => {
  const { children, error = false, loading } = props

  if (error) {
    return (
      <div className="messaging__channel-list__message">
        Error loading your chats - please try again in a little bit.
      </div>
    )
  }

  if (loading) {
    return (
      <div className="messaging__channel-list__message">
        <SkeletonLoader />
      </div>
    )
  }

  return <>{children}</>
}

export default React.memo(MessagingChannelList)
