import { SET_FORM_DATA, RESET_FORM_DATA, SET_PROFILE_FORM } from 'store/types'

export const setFormData = (payload) => ({
  type: SET_FORM_DATA,
  payload,
})

export const resetFormData = (payload) => ({
  type: RESET_FORM_DATA,
  payload,
})

export const setProfileAction = (payload: any) => ({
  type: SET_PROFILE_FORM,
  payload,
})
