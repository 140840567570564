import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FIELD_NAME, FORM, LABEL } from 'config'

import InputField from 'components/Common/InputField'
import Loader from 'components/Loaders'
import {
  AdditionalBusinessProfileWrapper,
  BusinessProfileWrapper,
  FormWrapper,
} from 'components/Common/EditBusinessProfile/style'

import { businessSchema } from 'pages/Owner/validation'
import { AdditionalBusinessProfileContainer } from 'pages/Admin/container'

import hookForms from 'utils/hookForms'
import { useCheckInitialAssessmentFormValid } from 'hooks/useCheckInitialAssessmentFormValid'
import useFormContainer from 'hooks/useFormContainer'
import { formatValue } from 'utils/helper'

const EditBusinessProfile = ({
  className = '',
  setIndustryChanged,
  checkErrors,
  onSubmit,
  isBusinessProfileFormSet,
  setIsFormValid,
  businessClassificationOptionId,
  detailsLoading,
}) => {
  const formValues = hookForms.getForm(FORM.CLIENT_BUSINESS_PROFILE)?.getValues()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(businessSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    unregister,
    setValue,
    formState: { errors, isValid, isDirty },
  } = methods

  useEffect(() => {
    checkErrors(errors, isValid)
    // eslint-disable-next-line
  }, [Object.keys(errors)?.length])

  useCheckInitialAssessmentFormValid(
    isDirty,
    formValues,
    setIsFormValid,
    setIndustryChanged,
    businessClassificationOptionId
  )

  useFormContainer(FORM.CLIENT_BUSINESS_PROFILE, methods)
  return (
    <>
      <BusinessProfileWrapper>
        <div className="my-8">
          <label className="font-normal text-2xl">Business Profile</label>
        </div>
        {detailsLoading ? (
          <Loader loader="AdvisorEditOwnerDetail" />
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormWrapper>
                <div className={className}>
                  <InputField
                    errors={errors.businessName}
                    type={'text'}
                    label={LABEL.BUSINESS_NAME}
                    defaultValue={''}
                    {...register(FIELD_NAME.BUSINESS_NAME)}
                  />
                </div>
                <div className={className}>
                  <Controller
                    name={FIELD_NAME.YEARS_IN_BUSINESS}
                    control={control}
                    render={({ field }) => (
                      <InputField
                        type={'text'}
                        label={LABEL.YEARS_IN_BUSINESS}
                        value={field.value}
                        errors={errors.yearsInBusiness}
                        onChange={(e) => {
                          const { value } = formatValue(e.target.value)
                          field.onChange(value)
                        }}
                      />
                    )}
                  />
                </div>
                <AdditionalBusinessProfileWrapper>
                  <AdditionalBusinessProfileContainer
                    control={control}
                    formValues={formValues}
                    isEditClient={true}
                    unregister={unregister}
                    setValue={setValue}
                    isDirty={isDirty}
                  />
                </AdditionalBusinessProfileWrapper>
              </FormWrapper>
            </form>
          </>
        )}
      </BusinessProfileWrapper>
    </>
  )
}
export default EditBusinessProfile
