import React from 'react'

function HoldIcon({ className }) {
  return (
    <svg
      className={className}
      width="56"
      height="55"
      viewBox="0 0 56 55"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50.1587 27.5C50.1587 21.5741 47.8046 15.8908 43.6144 11.7006C39.4241 7.51032 33.7409 5.15625 27.8149 5.15625C21.889 5.15625 16.2058 7.51032 12.0155 11.7006C7.82526 15.8908 5.47119 21.5741 5.47119 27.5C5.47119 33.4259 7.82526 39.1091 12.0155 43.2994C16.2058 47.4897 21.889 49.8438 27.8149 49.8438C33.7409 49.8438 39.4241 47.4897 43.6144 43.2994C47.8046 39.1091 50.1587 33.4259 50.1587 27.5ZM0.314941 27.5C0.314941 20.2065 3.21226 13.2118 8.3695 8.05456C13.5268 2.89731 20.5215 0 27.8149 0C35.1084 0 42.1031 2.89731 47.2604 8.05456C52.4176 13.2118 55.3149 20.2065 55.3149 27.5C55.3149 34.7935 52.4176 41.7882 47.2604 46.9454C42.1031 52.1027 35.1084 55 27.8149 55C20.5215 55 13.5268 52.1027 8.3695 46.9454C3.21226 41.7882 0.314941 34.7935 0.314941 27.5ZM24.3774 19.7656V35.2344C24.3774 36.6631 23.228 37.8125 21.7993 37.8125C20.3706 37.8125 19.2212 36.6631 19.2212 35.2344V19.7656C19.2212 18.3369 20.3706 17.1875 21.7993 17.1875C23.228 17.1875 24.3774 18.3369 24.3774 19.7656ZM36.4087 19.7656V35.2344C36.4087 36.6631 35.2593 37.8125 33.8306 37.8125C32.4019 37.8125 31.2524 36.6631 31.2524 35.2344V19.7656C31.2524 18.3369 32.4019 17.1875 33.8306 17.1875C35.2593 17.1875 36.4087 18.3369 36.4087 19.7656Z" />
    </svg>
  )
}

HoldIcon.defaultProps = {
  className: '',
}
export default HoldIcon
