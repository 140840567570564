import { useEffect, useState } from 'react'
import { checkIndustry } from 'utils/helper'

const useCheckHasSubIndustry = (
  assessmentList,
  profileFormValues,
  subIndustryQuestion,
  foodIndustryQuestionOption,
  industryQuestion,
  servicesSubIndustryQuestion,
  serviceIndustryQuestionOption
) => {
  //let hasSubIndustry = false
  const [hasSubIndustry, setHasSubIndustry] = useState([])
  useEffect(() => {
    let isSubIndustry = assessmentList?.map((question) =>
      checkIndustry(
        question,
        profileFormValues,
        subIndustryQuestion,
        foodIndustryQuestionOption,
        industryQuestion,
        servicesSubIndustryQuestion,
        serviceIndustryQuestionOption
      )
    )
    if (isSubIndustry) {
      setHasSubIndustry(isSubIndustry)
    }
    // eslint-disable-next-line
  }, [assessmentList])

  return hasSubIndustry
}
export default useCheckHasSubIndustry
