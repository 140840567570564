import { RESET_PROGRESSBAR, TOTAL_STEPS_PROGRESSBAR, UPDATE_PROGRESSBAR } from 'store/types'

export const updateProgressbarAction = () => ({
  type: UPDATE_PROGRESSBAR,
})

export const totalStepsProgressbarAction = (totalSteps) => ({
  type: TOTAL_STEPS_PROGRESSBAR,
  paylaod: totalSteps,
})

export const resetProgressbarAction = () => ({
  type: RESET_PROGRESSBAR,
})
