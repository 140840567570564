import React, { useEffect, useState, useMemo, FC } from 'react'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'
import find from 'lodash/find'
import pick from 'lodash/pick'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'
import { assessmentsStepper } from 'config'
import { ASSESSMENTS, playColors } from 'config/data'
import history from 'utils/history'
import { useTenantId } from 'utils/useTenantId'

// const allowRedirection = false

const assessmentHOC = (Content) => {
  const Component: FC<any> = ({
    setActiveStepAction,
    logUserActivityAction,
    addEditAssessmentAction,
    initializeOwnerStepperAction,
    resetAssessment,
    activeQuestion,
    ..._
  }) => {
    const [assessmentType, setAssessmentType] = useState('')
    const [isLastQuestion, setIsLastQuestion] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const params = useParams()
    const tenantId = useTenantId()
    const assessmentTag = get(ASSESSMENTS, `${assessmentType}.tag`)
    const assessmentsRedirection = (url: string) => {
      history.push(url)
    }

    const tagColor = useMemo(() => {
      const tag = find(playColors, ({ value }) => includes(toLower(assessmentTag), toLower(value)))
      return get(tag, 'color', 'bg-cream-dark')
    }, [assessmentTag])

    useEffect(() => {
      if (params?.assessmentStatus === 'review') return
      if (_.recommendations && _.recommendations.length === 1) {
        if (_.recommendations[0].meta.type === 'assessment') {
          let currentAssessment = assessmentType?.slice(0, -1)
          let nextAssessment = _.recommendations[0].id?.slice(0, -1)

          if (currentAssessment.trim() !== nextAssessment.trim()) {
            setActiveStepAction('Terrific')
          } else {
            setActiveStepAction('Questions')
            initializeOwnerStepperAction(assessmentsStepper)
            addEditAssessmentAction({ type: _.recommendations[0]?.id })
            setAssessmentType(_.recommendations[0]?.id)
            if (!window.location.pathname.includes('playbooks')) {
              assessmentsRedirection(
                `/owner/${tenantId}/assessmentQuestion/${_.recommendations[0].id}`
              )
            }
          }
        } else if (_.recommendations[0].meta.type === 'play') {
          setIsCompleted(true)
        }
      } else if (_.recommendations?.length > 1 && isLastQuestion) {
        const indexofActiveStep = _.steps.findIndex((step) => step?.title === _.activeStep)
        setActiveStepAction(_.steps[indexofActiveStep + 1]?.title)
        setIsLastQuestion(false)
        setIsCompleted(true)
      } else if (_.recommendations?.length === 0 && isLastQuestion) {
        assessmentsRedirection(`/owner/${tenantId}/dashboard?lastquestion=1`)
        setIsCompleted(true)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.recommendations])

    useEffect(() => {
      resetAssessment()
      initializeOwnerStepperAction(assessmentsStepper)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetAssessment, initializeOwnerStepperAction])

    useEffect(() => {
      const assessmentType = _.assessmentType || params.assessmentType
      if (assessmentType) {
        addEditAssessmentAction({ type: assessmentType })
        setAssessmentType(assessmentType.trim())
      }
    }, [params, _.assessmentType, addEditAssessmentAction])

    useEffect(() => {
      if (_.stepIndex > 1) {
        setActiveStepAction(_.steps[_.stepIndex]?.title)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.stepIndex])

    const payload = {
      isCompleted,
      tagColor,
      assessmentTag,
      setIsLastQuestion,
      assessmentType,
      activeQuestion,
      tenantId: get(_, 'tenantId', tenantId),
      ...pick(_, ['activeStep', 'currentUser', 'totalProgress', 'isCompletedAssessment']),
    }

    return <Content {...payload} />
  }
  return Component
}

export default assessmentHOC
