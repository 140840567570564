import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import {
  getBusinessAndAssessmentResponseAction,
  openUserManagementEditModalAction,
  getUserProfileAndAdvisorsAction,
  updateAdvisorProfileAction,
  updateOwnerProfileAction,
  enableDisableUserAction,
  addUserToGroupAction,
} from 'store/actions/userManagement'

import {
  getAssessmentsSelector,
  getRemainingClients,
  getSelectedClients,
  getAllClient,
} from 'store/selectors/userManagement'

import { getAdvisorsList } from 'store/selectors/admin'
import { getClientsAction } from 'store/actions/clients'

import AdminRightsToggle from 'components/Admin/UserManagement/AdminRightsToggle'
import AssignOwnerToAdvisor from 'components/Admin/UserManagement/AssignOwnerToAdvisor'
import AssignOwnerToAdvisorMobileView from 'components/Admin/UserManagement/AssignOwnerToAdvisorMobileView'
import EditAccount from 'components/Admin/UserManagement/EditAccount'
import EditBusinessProfile from 'components/Admin/UserManagement/EditBusinessProfile'
import EditUserProfile from 'components/Admin/UserManagement/EditUserProfile'
import UserManagement from 'components/Admin/UserManagement'
import { resetErrorAction } from 'store/actions/common'
import { RootState } from 'App'
import { getAssessmentOptionsAction } from 'store/actions/owner/initialAssessment'
import { getAssessmentOptionsSelector } from 'store/selectors/owner'
import { resetErrorsAction } from 'store/actions/errors'

/* --------------------------- User Management  --------------------------- */
const userManagementProps = (state: RootState) => {
  const { openUserManagementEditModal } = state.userManagement
  return {
    openUserManagementEditModal,
  }
}
function userManagementDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openUserManagementEditModalAction: openUserManagementEditModalAction,
      getAssessmentOptionsAction: getAssessmentOptionsAction.STARTED,
    },
    dispatch
  )
}
export const UserManagementContainer = connect(
  userManagementProps,
  userManagementDispatch
)(UserManagement)

/* --------------------------- AdminRightsToggle --------------------------- */
const adminRightsProps = (state: RootState) => {
  const { isUserAddedToGroup } = state.userManagement
  return {
    isUserAddedToGroup,
  }
}
function adminRightsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      addUserToGroupAction: addUserToGroupAction.STARTED,
      resetIsUserAddedAction: addUserToGroupAction.RESET,
    },
    dispatch
  )
}
export const AdminRightsContainer = connect(
  adminRightsProps,
  adminRightsDispatch
)(AdminRightsToggle)

/* --------------------------- EditAccount --------------------------- */
const editAccountProps = (state: RootState) => {
  const {
    error,
    disableButton,
    mobileContactError,
    isUserProfileFormSet,
    openUserManagementEditModal,
  } = state.userManagement
  const { userProfile } = state.user
  return {
    error,
    userProfile,
    disableButton,
    mobileContactError,
    isUserProfileFormSet,
    openUserManagementEditModal,
    advisors: getAdvisorsList(state),
  }
}
function editAccountDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrorsAction: resetErrorsAction,
      getClientsAction: getClientsAction.STARTED,
      enableDisableUserAction: enableDisableUserAction.STARTED,
      updateOwnerProfileAction: updateOwnerProfileAction.STARTED,
      updateAdvisorProfileAction: updateAdvisorProfileAction.STARTED,
      getUserProfileAndAdvisorsAction: getUserProfileAndAdvisorsAction.STARTED,
    },
    dispatch
  )
}
export const EditAccountContainer = connect(editAccountProps, editAccountDispatch)(EditAccount)
/* --------------------------- EditUserProfile --------------------------- */
const editUserProfileProps = (state: RootState) => {
  const { error, mobileContactError } = state.userManagement

  return {
    mobileContactError,
    error,
  }
}
function editUserProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrorAction: resetErrorAction,
    },
    dispatch
  )
}
export const EditUserProfileContainer = connect(
  editUserProfileProps,
  editUserProfileDispatch
)(EditUserProfile)

/* --------------------------- EditBusinessProfile --------------------------- */
const editBusinessProfileProps = (state: RootState) => {
  const { isBusinessProfileFormSet } = state.userManagement

  return {
    options: getAssessmentOptionsSelector(state),
    isBusinessProfileFormSet,
    assessment: getAssessmentsSelector(state),
    userBusinessProfile: state.user.businessProfile,
    businessClassificationOptionId: state.user.businessProfile?.businessClassificationOptionId,
  }
}
function editBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBusinessAndAssessmentResponseAction: getBusinessAndAssessmentResponseAction.STARTED,
    },
    dispatch
  )
}
export const EditBusinessProfileContainer = connect(
  editBusinessProfileProps,
  editBusinessProfileDispatch
)(EditBusinessProfile)

/* --------------------------- AssignOwnerToAdvisor --------------------------- */
const assignOwnerToAdvisortProps = (state: RootState) => {
  const { userProfile } = state.user
  const { isUserProfileFormSet } = state.userManagement

  return {
    isUserProfileFormSet,
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
  }
}
function assignOwnerToAdvisorDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorContainer = connect(
  assignOwnerToAdvisortProps,
  assignOwnerToAdvisorDispatch
)(AssignOwnerToAdvisor)

/* --------------------------- AssignOwnerToAdvisor Mobile View --------------------------- */
const assignOwnerToAdvisorMobileViewProps = (state: RootState) => {
  const { userProfile } = state.user

  return {
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
    remainingOwnerList: getRemainingClients(state),
  }
}
function assignOwnerToAdvisorMobileViewDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorMobileViewContainer = connect(
  assignOwnerToAdvisorMobileViewProps,
  assignOwnerToAdvisorMobileViewDispatch
)(AssignOwnerToAdvisorMobileView)
