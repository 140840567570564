import { ApiResponseError } from 'utils/error-handling/api-response-error'
import { setFlashUrlParams } from 'utils/flashParameters'

export const handleValidationError = (validationError: ApiResponseError) => {
  const flashParams = {
    validationErrorMessage: validationError.message,
  }

  setFlashUrlParams(flashParams)
  throw new Error(validationError.message)
}
