import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class Goal_API {
  getGoalDetails = (id): Promise<any> => {
    return API.fetch(api['GetGoalDetailsDocument'], { id })
  }
  getUserGoals = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetUserGoalsDocument'], { userId, tenantId })
  }
  updateUserGoal = (assessmentReponse) => {
    return API.fetch(api['SaveAssessmentResponseDocument'], { input: assessmentReponse })
  }
}

// eslint-disable-next-line
export default new Goal_API()
