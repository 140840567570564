import React from 'react'
import Link from 'components/Common/Link'

import { ROLES } from 'config'
import { navigateTo } from 'utils/helper'
import {
  BreadCrumbWrapper,
  BreadCrumbLabelWrapper,
  StyledChevronRightIconWrapper,
} from 'components/Clients/style'

const BreadCrumbs = () => {
  const navigateToClientsHandler = () => {
    if (window.location.href.includes(ROLES.BSO_ADVISOR)) {
      navigateTo(ROLES.BSO_ADVISOR, 'clients')
    } else {
      navigateTo(ROLES.BSO_ADMIN, 'clients')
    }
  }

  return (
    <>
      <BreadCrumbWrapper>
        <Link className="xs:text-base sm:text-xl" onClick={() => navigateToClientsHandler()}>
          All Clients
        </Link>
        <StyledChevronRightIconWrapper />
        <BreadCrumbLabelWrapper>Client Details</BreadCrumbLabelWrapper>
      </BreadCrumbWrapper>
    </>
  )
}

export default BreadCrumbs
