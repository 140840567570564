import React from 'react'

function SignInIcon({ className }) {
  return (
    <svg
      className={className}
      width="104"
      height="80"
      viewBox="0 0 104 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.5965 79.0407C66.1454 77.2511 63.5547 74.0949 62.6628 73.2666C61.8051 72.4452 60.9975 71.573 60.2444 70.6547C60.2444 70.6547 61.7771 73.6264 59.1651 75.9693C56.5532 78.3122 48.8019 76.5104 47.0909 75.2498C45.3798 73.9891 45.1984 70.7454 46.7281 65.9689C48.2578 61.1924 50.4254 52.3649 50.3347 49.2995C50.244 46.2341 52.2271 37.585 53.128 35.2421C54.0289 32.8992 57.7231 31.1882 61.508 30.378C65.2929 29.5678 75.4747 33.8001 75.4747 33.8001L77.5455 34.6103C77.5455 34.6103 86.0102 39.2961 88.2685 41.1886C90.5267 43.081 89.4414 48.3079 89.9795 53.3535C90.5176 58.399 90.5207 64.2578 90.5207 64.2578C90.5207 64.2578 95.7476 68.8529 99.5325 74.0768C101.346 76.592 102.401 78.1247 103 79.0407H67.5965Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M49.0135 69.2793C49.0135 69.2793 52.0366 68.5689 55.2048 70.6941C58.373 72.8194 57.5084 76.0027 54.6758 76.7101C51.8432 77.4175 48.1278 77.4175 46.1809 74.4095C44.234 71.4016 47.5957 69.101 49.0135 69.2793Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M48.2609 69.252C49.9555 68.983 51.6886 69.3795 53.0978 70.3584C55.5828 72.0151 54.8935 74.5031 52.6836 75.0563C50.4738 75.6095 47.5716 75.6095 46.051 73.2606C44.5304 70.9116 47.1574 69.1159 48.2609 69.252Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M55.2562 49.1002C55.1565 47.4556 55.093 46.334 55.093 46.2343"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M60.2292 70.6547C60.2292 70.6547 56.9855 67.5016 56.4474 63.895C56.1118 61.673 55.6765 55.6178 55.3923 51.2131"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M51.423 55.2067C52.2392 57.8307 53.7508 61.7698 56.0846 64.4362"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6793 52.4526C50.6793 52.4526 50.8063 53.0573 51.0753 54.0246"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M50.9635 62.7281C52.5568 64.5535 54.439 66.1048 56.535 67.3202"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M49.4186 60.742C49.7488 61.2397 50.1072 61.7181 50.4918 62.175"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M46.2626 68.4025C49.2692 67.4913 52.4868 67.5538 55.4558 68.5809C60.5013 70.295 59.9602 74.1675 59.5098 74.7994"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M59.9391 5.6098C59.9391 5.6098 59.9391 4.54869 61.3992 2.28439C62.8594 0.0200965 70.2932 0.957255 72.8144 3.47852C75.3357 5.99978 71.7533 9.18612 73.3465 11.0363C74.9397 12.8864 80.2482 11.9643 81.9744 15.2837C83.7006 18.6031 79.3201 20.1932 78.389 21.3873C77.4579 22.5815 79.9822 22.9805 79.7162 24.1746C79.4501 25.3687 75.3387 25.5018 75.3387 25.5018C75.3387 25.5018 77.993 26.2999 76.6658 27.361C74.837 28.744 72.5842 29.4483 70.2932 29.3532C67.6389 29.2202 68.304 24.0416 68.567 20.1932C68.83 16.3448 65.3837 12.0974 61.3992 12.4934C57.4148 12.8894 53.1703 17.4059 53.8415 19.3981C54.5126 21.3904 52.7804 18.9991 49.195 16.7408C45.6096 14.4826 47.2028 9.83911 47.9857 8.91102C48.7687 7.98293 51.4351 7.71689 51.9672 5.99071C52.4992 4.26452 53.6933 2.54136 55.9486 2.54136C58.2038 2.54136 57.6838 5.47678 59.9391 5.6098Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M71.3059 29.474C71.3059 29.474 73.017 29.9245 73.7244 30.8284C74.4318 31.7323 77.3521 33.3587 77.5305 34.6103C77.7089 35.8618 76.6236 42.3615 74.4681 44.9735C72.3126 47.5854 71.2243 47.7668 71.2243 47.7668C71.2243 47.7668 70.5925 45.2455 70.142 45.0641C69.6916 44.8828 65.5469 47.4826 65.5469 47.4826C65.5469 47.4826 64.8274 43.2503 64.374 43.2503C63.9205 43.2503 62.4845 44.6923 62.4845 44.6923C62.4845 44.6923 61.9434 41.0857 62.3032 38.2924C62.6629 35.4991 65.0058 32.0769 66.7169 30.904C68.428 29.731 69.7944 28.6638 71.3059 29.474Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M70.4262 26.052L73.7516 34.0148C73.7516 34.0148 72.8961 37.7242 71.7715 40.1094C70.2387 43.3532 66.4508 44.898 66.4508 44.898C66.4751 43.0166 66.0666 41.1548 65.2567 39.4564C64.5001 37.9223 63.612 36.4567 62.6024 35.0759C62.6024 35.0759 68.567 29.2383 70.4262 26.052Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M69.5676 29.072C69.5676 29.072 68.041 37.7996 65.8583 40.5265L63.7875 36.5964L69.229 28.0864L69.5676 29.072Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M70.8645 54.9771C70.8645 54.9771 65.5469 41.0981 64.8183 40.2879C64.0897 39.4777 62.4754 38.0357 61.7529 37.0441C61.0304 36.0525 59.0503 33.5312 58.3277 33.5312C57.6052 33.5312 55.9093 34.1601 55.0839 34.3414C54.2586 34.5228 53.7326 34.7919 53.645 36.1553C53.5573 37.5187 54.7242 37.5973 54.7242 37.5973L55.1746 37.0562C55.1746 37.0562 58.4184 41.9234 59.407 42.5522C59.9975 42.9322 60.6333 43.2369 61.2994 43.4591L65.6255 59.5903L70.8645 54.9771Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M52.1092 14.7335C52.1092 14.7335 50.8486 15.7855 51.689 19.3528C52.5294 22.92 52.738 24.7067 52.738 26.8077C52.738 28.9088 53.3668 31.8472 55.1565 33.7367C56.9461 35.6261 59.1439 38.356 61.768 38.2501C65.9247 38.0839 67.9623 32.3702 69.3257 29.7462C70.6891 27.1221 71.0035 23.5519 71.0035 22.0826C71.0035 20.6134 70.7949 17.1489 68.9055 14.5249C67.0161 11.9009 61.8708 10.8518 59.6669 11.1662C57.4631 11.4806 54.7332 11.795 52.1092 14.7335Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M70.0604 24.1836C70.0604 24.1836 69.6402 22.7144 67.7507 21.8739C65.8613 21.0335 64.7065 19.5643 65.3323 18.1979C65.9581 16.8314 65.6346 15.4771 62.9138 14.7334C60.193 13.9897 59.4493 16.7286 56.8253 16.3084C54.2012 15.8882 53.4666 14.8392 52.1032 15.468C50.7398 16.0968 51.3686 17.8865 51.3686 17.8865C51.3686 17.8865 49.6877 14.8422 52.9406 11.3777C56.1935 7.91328 64.0686 6.65265 69.5283 10.6431C74.988 14.6336 71.838 23.975 71.838 23.975C71.838 23.975 71.5296 24.3922 70.0604 24.1836Z"
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M56.9371 25.9673L57.2515 32.0558L59.4584 31.2154"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M58.6088 34.2658C58.6088 34.2658 60.7068 34.5681 61.6531 33.111"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M60.9337 25.9038C61.0304 26.5085 61.366 26.9498 61.6804 26.8984C61.9948 26.8471 62.1701 26.318 62.0734 25.7164C61.9766 25.1148 61.6411 24.6704 61.3267 24.7218C61.0123 24.7732 60.8369 25.3023 60.9337 25.9038Z"
        fill="#263238"
      />
      <path
        d="M54.2072 26.0731C54.3039 26.6777 54.6395 27.1191 54.9539 27.0677C55.2683 27.0163 55.4467 26.4873 55.3469 25.8887C55.2472 25.2901 54.9146 24.8397 54.6002 24.8911C54.2858 24.9425 54.1105 25.4715 54.2072 26.0731Z"
        fill="#263238"
      />
      <path
        d="M63.6151 23.162C63.6151 23.162 61.3569 21.1939 59.537 22.7236"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M55.6765 22.7235C55.6765 22.7235 54.1468 21.4145 52.8348 22.651"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M58.0799 24.4738C58.0799 24.4738 61.2843 23.9629 66.2422 24.7762C66.2422 24.7762 66.6775 28.1258 64.3467 29.5829C62.0159 31.04 59.7577 29.6554 59.1772 28.8543C58.0043 27.2581 58.0799 24.4738 58.0799 24.4738Z"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M50.7246 24.0144C50.7246 24.0144 53.0161 23.5277 56.5592 24.2926C56.5592 24.2926 56.8615 27.4971 55.2049 28.8877C53.5482 30.2783 51.9218 28.9572 51.5046 28.1924C50.6823 26.6778 50.7246 24.0144 50.7246 24.0144Z"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M58.0798 24.4738C58.0798 24.4738 57.7352 23.5306 56.5683 24.2924"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M71.1184 22.7961L66.2391 24.7642"
        stroke="#263238"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M69.6401 24.7068C69.6401 24.7068 70.3747 20.4049 72.3609 22.5029C74.3471 24.6009 72.5695 26.702 71.6263 27.6422C70.6831 28.5824 69.5101 27.6422 69.5101 27.6422"
        fill="#86888A"
      />
      <path
        d="M69.6401 24.7068C69.6401 24.7068 70.3747 20.4049 72.3609 22.5029C74.3471 24.6009 72.5695 26.702 71.6263 27.6422C70.6831 28.5824 69.5101 27.6422 69.5101 27.6422"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M77.5062 45.9167C76.2126 48.3167 75.3375 50.9196 74.9184 53.6135C74.2866 57.9396 74.3773 58.7528 74.3773 58.7528L71.2242 54.9709C71.2242 54.9709 66.0849 58.5744 65.2778 59.7444C64.4706 60.9143 64.3708 61.2771 64.3708 61.2771C64.3708 61.2771 64.3708 68.3058 67.3456 72.5411C70.3203 76.7765 71.7623 76.7735 74.6433 76.7735C77.5243 76.7735 81.2216 74.0678 83.2047 69.5634C85.1879 65.059 86.8113 58.4898 87.171 57.8459C87.5308 57.202 88.7037 53.6135 88.7037 53.6135"
        fill="#5BBAB9"
      />
      <path
        d="M77.5062 45.9167C76.2126 48.3167 75.3375 50.9196 74.9184 53.6135C74.2866 57.9396 74.3773 58.7528 74.3773 58.7528L71.2242 54.9709C71.2242 54.9709 66.0849 58.5744 65.2778 59.7444C64.4706 60.9143 64.3708 61.2771 64.3708 61.2771C64.3708 61.2771 64.3708 68.3058 67.3456 72.5411C70.3203 76.7765 71.7623 76.7735 74.6433 76.7735C77.5243 76.7735 81.2216 74.0678 83.2047 69.5634C85.1879 65.059 86.8113 58.4898 87.171 57.8459C87.5308 57.202 88.7037 53.6135 88.7037 53.6135"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M78.6882 64.0402C79.0178 64.4272 79.2929 64.7507 79.5135 64.9774"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M74.3773 58.7527C74.3773 58.7527 76.0612 60.8688 77.5728 62.6978"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M73.9269 58.3083C71.9671 58.8741 70.0891 59.6921 68.3402 60.7419C65.2778 62.5558 65.0148 64.5268 64.1078 63.8073C63.2009 63.0878 62.4875 60.8326 63.3883 59.575C64.2892 58.3174 67.0826 55.3427 69.0627 54.4357C71.0428 53.5288 71.856 54.073 72.4879 55.4273C73.1197 56.7817 74.5587 57.4981 73.9269 58.3083Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M74.0266 65.6848C74.003 66.543 73.873 67.395 73.6397 68.2212"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M73.927 58.3084C73.927 58.3084 74.1416 61.3315 74.0962 64.146"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M70.8645 59.8411C70.8645 59.8411 70.2327 63.357 69.5102 64.8867"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M49.5063 68.5808C49.5063 68.5808 52.5718 73.9891 52.1213 74.627C51.6709 75.2649 41.6675 74.627 41.6675 74.627L41.1293 72.4655C41.1293 72.4655 39.0555 73.2757 37.4321 73.2757C35.8087 73.2757 37.0723 72.8252 37.6135 72.2841C38.1546 71.743 41.3077 69.7628 41.5798 69.5814C41.8519 69.4001 47.0758 68.0397 49.5063 68.5808Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M42.9795 67.9582C42.9795 67.9582 41.5314 67.5229 41.1445 68.4419C40.7575 69.3609 40.951 73.2788 41.0961 74.2825C41.2412 75.2862 41.9154 75.2469 42.2056 74.2825C42.4958 73.3181 43.173 68.1033 43.173 68.1033L42.9795 67.9582Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M46.0208 68.3935C46.0208 68.3935 43.8471 66.897 43.2183 67.6679C42.5895 68.4388 42.0091 74.5243 41.8187 74.9596C41.6282 75.395 42.6409 75.7305 43.0279 75.2952C43.4148 74.8599 44.5243 72.6409 44.8599 71.8217C45.1954 71.0024 46.0208 68.3935 46.0208 68.3935Z"
        fill="white"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M50.3165 70.035C50.3165 70.035 49.1073 67.813 48.0946 67.1872C47.0818 66.5615 45.9784 67.7163 45.4403 68.5869C44.9022 69.4576 44.4759 71.7249 44.231 72.8344C43.9862 73.9438 43.3241 75.1984 43.699 75.3435C44.0738 75.4887 45.5824 74.2341 46.0177 73.5599C46.453 72.8858 47.5141 70.2768 47.5141 70.2768"
        fill="#86888A"
      />
      <path
        d="M50.3165 70.035C50.3165 70.035 49.1073 67.813 48.0946 67.1872C47.0818 66.5615 45.9784 67.7163 45.4403 68.5869C44.9022 69.4576 44.4759 71.7249 44.231 72.8344C43.9862 73.9438 43.3241 75.1984 43.699 75.3435C44.0738 75.4887 45.5824 74.2341 46.0177 73.5599C46.453 72.8858 47.5141 70.2768 47.5141 70.2768"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M48.3304 74.2341C48.3304 74.2341 50.0687 72.5442 50.6491 72.0121C51.2295 71.48 52.0488 71.2412 52.6262 71.87C53.2036 72.4988 53.4969 73.2697 52.6262 74.089C51.7555 74.9082 49.6878 75.9845 48.3304 75.9845C46.973 75.9845 47.2209 75.3073 47.5595 74.9233C47.7884 74.664 48.0471 74.4326 48.3304 74.2341Z"
        fill="#86888A"
        stroke="#263238"
        strokeMiterlimit="10"
      />
      <path
        d="M65.5349 79.0106L2.18591 79.0106V77.1997L65.5349 77.1997V79.0106Z"
        fill="#B8B8B8"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5349 79.0106H43.297V77.1997H65.5349V79.0106Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0897 77.1937H3.95749L1.58133 49.8377C1.54869 49.5371 1.58039 49.2329 1.67433 48.9455C1.76826 48.6581 1.92229 48.3939 2.12616 48.1706C2.33003 47.9473 2.57909 47.7699 2.85678 47.6503C3.13448 47.5306 3.43446 47.4714 3.7368 47.4766H40.15C40.792 47.4889 41.4073 47.7358 41.8797 48.1708C42.352 48.6059 42.6487 49.1988 42.7136 49.8377L45.0897 77.1937Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3007 77.1937H3.16843L0.792278 49.8377C0.759636 49.5371 0.79134 49.2329 0.885273 48.9455C0.97921 48.6581 1.13322 48.3939 1.3371 48.1706C1.54097 47.9473 1.79003 47.7699 2.06773 47.6503C2.34543 47.5306 2.64541 47.4714 2.94775 47.4766H39.3609C40.0029 47.4889 40.6183 47.7358 41.0906 48.1708C41.5629 48.6059 41.8596 49.1988 41.9245 49.8377L44.3007 77.1937Z"
        fill="#B8B8B8"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4594 61.4736C23.3885 60.7754 23.0642 60.1274 22.5478 59.6522C22.0314 59.177 21.3587 58.9076 20.657 58.8949C20.3267 58.8891 19.999 58.9537 19.6956 59.0843C19.3922 59.2149 19.1201 59.4087 18.8973 59.6526C18.6746 59.8965 18.5063 60.185 18.4037 60.499C18.301 60.813 18.2664 61.1452 18.302 61.4736C18.3729 62.1718 18.6972 62.8198 19.2136 63.295C19.7301 63.7702 20.4028 64.0396 21.1044 64.0523C21.4347 64.0581 21.7624 63.9935 22.0658 63.8629C22.3692 63.7322 22.6413 63.5385 22.8641 63.2946C23.0868 63.0507 23.2551 62.7622 23.3578 62.4482C23.4604 62.1342 23.4951 61.802 23.4594 61.4736Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SignInIcon
