import React, { ReactNode } from 'react'

export const ModalBlock = ({
  isBorderWidthFull,
  children,
}: {
  isBorderWidthFull?: boolean
  children: ReactNode
}): JSX.Element => (
  <div
    className={`modalBlock rounded-bl-xl overflow-y-auto bg-white ${
      isBorderWidthFull ? 'w-full' : 'mx-6'
    }`}
  >
    <div>{children}</div>
  </div>
)
