import React from 'react'

function HomeIcon({ className }) {
  return (
    <svg
      className={className}
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M28.4205 11.2158C28.9263 11.6357 28.9914 12.373 28.5607 12.8662C28.13 13.3594 27.3738 13.4229 26.868 13.0029L25.641 11.9873V21.0938C25.641 23.252 23.8482 25 21.6346 25H7.21154C4.999 25 3.20513 23.252 3.20513 21.0938V11.9873L1.97967 13.0029C1.47336 13.4229 0.715142 13.3594 0.285455 12.8662C-0.143982 12.373 -0.0818331 11.6357 0.424327 11.2158L13.6468 0.27832C14.0925 -0.0927734 14.7536 -0.0927734 15.1993 0.27832L28.4205 11.2158ZM7.21154 22.6562H9.61538V15.2344C9.61538 14.1553 10.5118 13.2812 11.6186 13.2812H17.2276C18.3343 13.2812 19.2308 14.1553 19.2308 15.2344V22.6562H21.6346C22.521 22.6562 23.2372 21.958 23.2372 21.0938V10L14.4231 2.7085L5.60897 10V21.0938C5.60897 21.958 6.32512 22.6562 7.21154 22.6562ZM12.0192 22.6562H16.8269V15.625H12.0192V22.6562Z" />
      </g>
    </svg>
  )
}

HomeIcon.defaultProps = {
  className: '',
}
export default HomeIcon
