import { IClient } from 'components/Clients'

export const getFilteredList = (value: string, clientList: IClient[]) => {
  const searchValue = value.toLowerCase()
  if (searchValue) {
    let data: IClient[] = []
    data = clientList.filter((item: IClient) => {
      return (
        item?.firstName
          ?.toLowerCase()
          .concat(' ' + item?.lastName?.toLowerCase())
          .includes(searchValue) || item?.businessName?.toLowerCase().includes(searchValue)
      )
    })
    return data
  } else {
    return clientList
  }
}
