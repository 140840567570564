import {
  ADD_USER_TO_GROUP,
  ENABLE_DISABLE_USER,
  GET_ADVISORS,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES,
  GET_ADVISORS_ACCOUNT_DETAILS_UM,
  GET_BUSINESSES_UM,
  GET_BUSINESS_AND_ASSESSMENT_RESPONSE,
  GET_USER_PROFILE_AND_ADVISOR,
  UPDATE_OWNER_PROFILE,
  UPDATE_ADVISOR_PROFILE,
  OPEN_USER_MANAGEMENT_EDIT_MODAL,
  CHECK_BUSINESS_PROFILE,
  CHECK_USER_PROFILE,
  SET_CURRENT_PAGE,
  SET_PER_PAGE,
  SET_TOTAL_PAGES,
  SET_FILTERS,
  SET_SORT_VALUE,
} from 'store/types'

export const addUserToGroupAction = {
  STARTED: (payload: { userId: string; remove: boolean }) => ({
    type: ADD_USER_TO_GROUP.STARTED,
    payload,
  }),
  FULLFILLED: () => ({ type: ADD_USER_TO_GROUP.FULLFILLED }),
  REJECTED: (error: any) => ({ type: ADD_USER_TO_GROUP.REJECTED, payload: error }),
  RESET: () => ({ type: ADD_USER_TO_GROUP.RESET }),
}

export const getBusinessesAction = {
  STARTED: (payload: any) => ({ type: GET_BUSINESSES.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_BUSINESSES.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_BUSINESSES.REJECTED, payload: error }),
  RESET: () => ({ type: GET_BUSINESSES.RESET }),
}

export const getBusinessesUMAction = {
  STARTED: (payload: any) => ({ type: GET_BUSINESSES_UM.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_BUSINESSES_UM.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_BUSINESSES_UM.REJECTED, payload: error }),
}
export const getAdvisorsAccountDetailsAction = {
  STARTED: (payload: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.REJECTED, payload: error }),
}

export const getAdvisorsAccountDetailsUMAction = {
  STARTED: (payload: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS_UM.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS_UM.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS_UM.REJECTED, payload: error }),
}

export const getAdvisorsAction = {
  STARTED: (payload: any) => ({ type: GET_ADVISORS.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_ADVISORS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_ADVISORS.REJECTED, payload: error }),
}

export const updateOwnerProfileAction = {
  STARTED: (payload: any) => ({ type: UPDATE_OWNER_PROFILE.STARTED, payload }),
  FULLFILLED: () => ({ type: UPDATE_OWNER_PROFILE.FULLFILLED }),
  REJECTED: (payload) => ({ type: UPDATE_OWNER_PROFILE.REJECTED, payload }),
}
export const getUserProfileAndAdvisorsAction = {
  STARTED: (payload: any) => ({ type: GET_USER_PROFILE_AND_ADVISOR.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_USER_PROFILE_AND_ADVISOR.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_USER_PROFILE_AND_ADVISOR.REJECTED, payload: error }),
}
export const getBusinessAndAssessmentResponseAction = {
  STARTED: (payload: any) => ({ type: GET_BUSINESS_AND_ASSESSMENT_RESPONSE.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_BUSINESS_AND_ASSESSMENT_RESPONSE.FULLFILLED, payload }),
  REJECTED: (error: any) => ({
    type: GET_BUSINESS_AND_ASSESSMENT_RESPONSE.REJECTED,
    payload: error,
  }),
}

export const enableDisableUserAction = {
  STARTED: (payload: any) => ({ type: ENABLE_DISABLE_USER.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: ENABLE_DISABLE_USER.FULLFILLED, payload }),
  REJECTED: (error: any) => ({
    type: ENABLE_DISABLE_USER.REJECTED,
    payload: error,
  }),
}

export const updateAdvisorProfileAction = {
  STARTED: (payload: any) => ({ type: UPDATE_ADVISOR_PROFILE.STARTED, payload }),
  FULLFILLED: () => ({ type: UPDATE_ADVISOR_PROFILE.FULLFILLED }),
  REJECTED: (payload) => ({
    type: UPDATE_ADVISOR_PROFILE.REJECTED,
    payload,
  }),
}

export const openUserManagementEditModalAction = (payload) => ({
  type: OPEN_USER_MANAGEMENT_EDIT_MODAL,
  payload,
})
export const checkUserProfileAction = (payload) => ({
  type: CHECK_USER_PROFILE,
  payload,
})
export const checkBusinessProfileAction = (payload) => ({
  type: CHECK_BUSINESS_PROFILE,
  payload,
})
export const setCurrentPageAction = (payload: number) => ({
  type: SET_CURRENT_PAGE,
  payload,
})

export const setPerPageAction = (payload: number) => ({
  type: SET_PER_PAGE,
  payload,
})

export const setTotalPagesAction = (payload: number) => ({
  type: SET_TOTAL_PAGES,
  payload,
})

export const setFiltersAction = (payload: any) => ({
  type: SET_FILTERS,
  payload,
})

export const setSortValueAction = (payload: string) => ({
  type: SET_SORT_VALUE,
  payload,
})
