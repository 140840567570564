import React from 'react'

function VerticalBarIcon({ className }) {
  return (
    <svg
      className={className}
      width="6"
      height="26"
      viewBox="0 0 6 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="3" y1="1.31134e-07" x2="3" y2="26" stroke="#5E06B9" strokeWidth="6" />
    </svg>
  )
}

export default VerticalBarIcon
