import styled from 'styled-components'
import tw from 'twin.macro'

export const CardWrapper = styled.div`
  ${tw`bg-white shadow-base rounded-[20px] w-full lg:flex inline-block relative p-0 overflow-hidden`}
`

export const PaddingBoxWrapper = styled.div`
  ${tw`px-3 py-4 sm:px-5 sm:py-6 w-full h-full flex-shrink-0`}
`

export const ContentBoxWrapper = styled.div`
  ${tw`w-full h-full relative flex flex-col justify-between flex-grow`}
`

export const VerticalSpacerWrapper = styled.div`
  ${tw`flex flex-col gap-8 w-full`}
`

export const TopContentRowWrapper = styled.div`
  ${tw`flex items-center justify-between`}
`

export const ButtonContainerWrapper = styled.div`
  ${tw`absolute bottom-0 right-0`}
`

export const TitleWrapper = styled.h3`
  ${tw`font-secondary sm:text-lg font-extrabold whitespace-normal flex-shrink mr-12 text-left`}
`
