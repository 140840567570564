import { useMemo } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IToastProps } from '.'

export type Variant = Required<Pick<IToastProps, 'variant'>>

export const ToastWrapper = styled.div((props: Variant) => {
  const classes = useMemo(() => {
    const toastBackgroundColor = {
      info: tw`bg-info-light`,
      warning: tw`bg-yellow-light`,
      success: tw`bg-green-lighter`,
      alert: tw`bg-alert-light`,
      overlay: tw`bg-transparent`,
    }[props.variant]

    return [tw`flex rounded-lg justify-between items-stretch relative`, toastBackgroundColor]
  }, [props.variant])

  return classes
})

export const HighlightColorWrapper = styled.div((props: Variant) => {
  const classes = useMemo(() => {
    const leftHighlightColor = {
      info: tw`bg-info`,
      warning: tw`bg-yellow-dark`,
      success: tw`bg-green`,
      alert: tw`bg-alert`,
      overlay: tw`bg-transparent`,
    }[props.variant]

    return [
      tw`rounded-tl-lg rounded-bl-lg w-[6px] min-w-[6px] flex-grow-0 flex-shrink-0`,
      leftHighlightColor,
    ]
  }, [props.variant])

  return classes
})

export const ChildrenWrapper = styled.div`
  ${tw`p-7 pr-0 flex justify-self-start gap-3 text-primary-text`}
`

export const ButtonWrapper = styled.div`
  ${tw`flex flex-col items-center justify-start p-7`}
`
