import { handleUpdateUserProfileError } from '../update-user-profile'

export const apiErrorHandler = (error) => {
  const handler = errorHandler[error.apiName]
  if (handler) {
    return handler(error)
  } else {
    throw error
  }
}

const errorHandler = {
  updateMyProfile: handleUpdateUserProfileError,
}
