import { LOGIN, SIGNUP, FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, LOGOUT } from 'store/types'

export const loginAction = {
  STARTED: (email: string, password: string, next?: string) => ({
    type: LOGIN.STARTED,
    payload: { email, password, next },
  }),
  FULLFILLED: (payload) => ({ type: LOGIN.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: LOGIN.REJECTED, payload: error }),
}

export const signupAction = {
  STARTED: (email: string, password: string) => ({
    type: SIGNUP.STARTED,
    payload: { email, password },
  }),
}

export const logoutAction = (next?: string) => ({
  type: LOGOUT,
  payload: { next },
})

export const forgotPasswordAction = {
  STARTED: (email: string) => ({
    type: FORGOT_PASSWORD.STARTED,
    payload: { email },
  }),
  FULLFILLED: (disbale: any) => ({ type: FORGOT_PASSWORD.FULLFILLED, payload: false }),
  REJECTED: (error: any) => ({ type: FORGOT_PASSWORD.REJECTED, payload: error }),
}

export const resetPasswordAction = {
  STARTED: (email, code, password) => ({
    type: FORGOT_PASSWORD_SUBMIT.STARTED,
    payload: { email, code, password },
  }),
  FULLFILLED: (disbale: any) => ({ type: FORGOT_PASSWORD_SUBMIT.FULLFILLED, payload: false }),
  REJECTED: (error: any) => ({ type: FORGOT_PASSWORD_SUBMIT.REJECTED, payload: error }),
}
