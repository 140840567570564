import styled from 'styled-components'

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
tura Std;
  .recharts-label {
    color: #888888;
    font-size: 18px;
  }
  .recharts-cartesian-axis-tick-value {
    font-size: 18px;
    color: #3f3f46;
  }
`
export default ChartWrapper
