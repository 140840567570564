import React from 'react'

function GreenCheck() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#5BBAB9" />
      <path d="M5 9.41026L8.38983 13L15 6" stroke="#FAFAFA" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default GreenCheck
