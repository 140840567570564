import React from 'react'
import Link from 'components/Common/Link'
import { InfoIcon } from 'components/Common/SvgIcons'

const Info = ({ goToClientDetails }) => {
  return (
    <div className="flex gap-2 p-4 sm:p-5 xs:items-start sm:items-center mt-1">
      <InfoIcon className="fill-blue-light xs:h-7 xs:w-7 sm:h-5 sm:w-5" />
      <p className="font-primary xs:text-xl sm:text-base font-medium text-black-light">
        Individual client impact statements can be found in
        <Link onClick={goToClientDetails}> client details.</Link>
      </p>
    </div>
  )
}

export default Info
