import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import TermsAndConditions from 'components/TermsAndConditions'

/* --------------------------- Terms And Conditions(Index) --------------------------- */

const termsAndConditionsProps = (state: any) => {
  let { user } = state.user
  return { user }
}

function termsAndConditionsDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const TermsAndConditionsContainer = connect(
  termsAndConditionsProps,
  termsAndConditionsDispatch
)(TermsAndConditions)
