import React, { useEffect, useState, useMemo } from 'react'

import { SearchIcon } from 'components/Common/SvgIcons'
import DirectoryList from 'components/Clients/DirectoryList'
import ClientActivity from 'components/Clients/ClientActivity'
import InputField from 'components/Common/InputField'
import { TopActiveClientsContainer } from 'pages/Clients/container'
import GrowingContainer from 'components/Common/GrowingContainer'
import Loader from 'components/Loaders'
import {
  ClientsWrapper,
  GrowingContainerWrapper,
  MarginWrapper,
  TextWrapper,
} from 'components/Clients/style'
import { getFilteredList } from './helper'
import Button from 'components/Common/Button'
import useNavigateTo from 'hooks/useNavigateTo'
import redirectTo from 'utils/redirectTo'
import { getTenantId } from 'utils/getTenantId'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { getRoleFromPath } from 'utils/helper'

export interface IClient {
  id: string
  status: string
  email: string
  firstName: string
  lastName: string
  createdAt: string
  industry?: string
  updatedAt: string
  businessName?: string
}

function compareClients(a: IClient, b: IClient): number {
  const firstNameComparison = a?.firstName?.localeCompare(b.firstName)
  return firstNameComparison ? firstNameComparison : a?.lastName?.localeCompare(b.lastName)
}

export default function Clients({
  userId,
  clientList,
  clientData,
  isAllClientFetching,
  getAdvisorDashboardAction,
  isAdvisorDashboardLoading,
}) {
  const userRole = getRoleFromPath()
  const tenantId = getTenantId()

  const isV2FeatureFlagOrgEnabled = useFeatureFlag(userId, 'release-v2-org')
  const isV2FeatureFlagUserEnabled = useFeatureFlag(userId, 'release-v2-user')
  const isV2FlagEnabled = isV2FeatureFlagOrgEnabled && isV2FeatureFlagUserEnabled

  const [filteredList, setFilteredList] = useState<any>(undefined)
  useEffect(() => {
    getAdvisorDashboardAction()
  }, [getAdvisorDashboardAction])

  const setClientsList = (array: IClient[]) => {
    setFilteredList(array.slice().sort(compareClients))
  }

  useEffect(() => {
    if (clientList && clientList.length > 0) {
      setClientsList(clientList)
    } else {
      setClientsList([])
    }
  }, [clientList])

  const alphabetListData = useMemo(
    () => filteredList?.map((item) => (item.firstName || '') + item.id),
    [filteredList]
  )
  const navigate = useNavigateTo('createBusinessOwner', '?returnTo=clients')
  return (
    <ClientsWrapper>
      <GrowingContainerWrapper>
        <GrowingContainer className="sm:px-2 mdl:pr-4 lg:pr-4">
          <div>
            <div className="mb-12 sm:mb-2">
              <div className="space-y-4 sm:flex sm:justify-between sm:space-y-0">
                <TextWrapper className="text-[44px] sm:text-3xl">My Clients</TextWrapper>
                {/* <div className='w-1/2 sm:w-auto'> */}
                <Button
                  type="button"
                  label="Add a new client"
                  variant="primary"
                  onClick={() => {
                    if (isV2FlagEnabled) {
                      redirectTo(
                        `/${userRole + '-v2'}/${tenantId}/create-business-owner?returnTo=clients`
                      )
                    } else {
                      navigate()
                    }
                  }}
                  className=""
                />
                {/* </div> */}
              </div>
            </div>
            <MarginWrapper className="sm:mb-2">
              <TextWrapper className="text-xl"> Top 3 Active Clients</TextWrapper>
            </MarginWrapper>

            <div className="mb-4">
              <p className="font-primary text-base font-normal text-zinc italic">
                Based on activity on the platform.
              </p>
            </div>
          </div>

          <TopActiveClientsContainer />
          <ClientActivity
            clientData={clientData}
            getAdvisorDashboardAction={getAdvisorDashboardAction}
            isAdvisorDashboardLoading={isAdvisorDashboardLoading}
          />
        </GrowingContainer>
      </GrowingContainerWrapper>
      {isAllClientFetching ? (
        <Loader loader="ClientDirectoryLoader" />
      ) : (
        <div
          id="client-directory"
          className="xs:col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-5 xl:col-span-4 xxl:col-span-4 border-l border-b-secondary-text border-secondary-text mdl:col-span-5  mdl:mb-7 mb-3"
        >
          <GrowingContainer className="mdl:pl-4 lg:pl-4">
            <div>
              <div className="flex items-center justify-between xs:w-full">
                <div>
                  <label className="text-primary-text text-2xl sm:text-xl font-normal font-primary">
                    Client Directory
                  </label>
                </div>

                <div className="xs:flex xs:justify-between items-center">
                  <div>
                    <span className="mr-3 text-base">Current Clients</span>
                    <label className="text-2xl font-bold black-light font-primary">
                      {clientList?.length}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div className={`flex justify-between my-5 items-center`}>
                  <InputField
                    type={'search'}
                    placeholder="Search"
                    onChange={(e) => {
                      let list = getFilteredList(e.target.value, clientList)
                      setClientsList(list || [])
                    }}
                    className="w-full"
                    name="search"
                  />
                  <SearchIcon className="xs:ml-3 mdl:ml-5 lg:ml-7 fill-primary cursor-pointer" />
                </div>
              </div>
            </div>
          </GrowingContainer>
          <GrowingContainer
            id="directory-list-container"
            className="xs:pl-0 xs:pr-0 sm:pl-0 sm:pt-0 mdl:pt-0 mdl:pl-0 lg:pt-0 lg:pl-0"
          >
            <div className="relative">
              <DirectoryList
                alphabetListData={alphabetListData}
                filteredList={filteredList}
                clientList={clientList}
              />
            </div>
          </GrowingContainer>
        </div>
      )}
    </ClientsWrapper>
  )
}
