import React from 'react'

function OtherIcon({ className }) {
  return (
    <svg
      width="56"
      height="55"
      viewBox="0 0 56 55"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21141_9254)">
        <path
          d="M48.7578 8.59375C49.7031 8.59375 50.4766 9.36719 50.4766 10.3125V17.1875H5.78906V10.3125C5.78906 9.36719 6.5625 8.59375 7.50781 8.59375H48.7578ZM5.78906 44.6875V22.3438H16.1016V46.4062H7.50781C6.5625 46.4062 5.78906 45.6328 5.78906 44.6875ZM21.2578 46.4062V22.3438H50.4766V44.6875C50.4766 45.6328 49.7031 46.4062 48.7578 46.4062H21.2578ZM7.50781 3.4375C3.71582 3.4375 0.632812 6.52051 0.632812 10.3125V44.6875C0.632812 48.4795 3.71582 51.5625 7.50781 51.5625H48.7578C52.5498 51.5625 55.6328 48.4795 55.6328 44.6875V10.3125C55.6328 6.52051 52.5498 3.4375 48.7578 3.4375H7.50781Z"
          fill="#71717A"
        />
      </g>
      <defs>
        <clipPath id="clip0_21141_9254">
          <rect width="55" height="55" fill="white" transform="translate(0.632812)" />
        </clipPath>
      </defs>
    </svg>
  )
}

OtherIcon.defaultProps = {
  className: '',
}
export default OtherIcon
