import React, { useEffect, useMemo, useState } from 'react'

import { UserProfile } from '__generated__/api-types-and-hooks'
import { ampli } from 'ampli'
import {
  AccountIcon,
  ChatUpdatedVersionIcon,
  NotificationBellIcon,
} from 'components/Common/SvgIcons'
import HelpDesk from 'components/Zendesk/HelpDesk'
import { APP_STREAM_KEY } from 'config'
import useIsNavigationItemSelected from 'hooks/useIsNavigationItemSelected'
import useNavigationItems from 'hooks/useNavigationItems'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { NotificationPanelContainer } from 'pages/Common/container'
import { DefaultGenerics, StreamChat } from 'stream-chat'
import { AppFC } from 'types'
import { goToNextPath } from 'utils/helper'

interface INavbarActionIconButtons {
  chatUnreadMessageCount?: number
  chatUnreadNotificationsCount?: number
  chatUnreadMessageCountAction: any
  resetUserNoticationsAction: (bool) => {}
  getUnreadNotificationsAction: any
  user: UserProfile
}

const NavbarActionIconButtons: AppFC<INavbarActionIconButtons> = ({
  chatUnreadNotificationsCount = 0,
  chatUnreadMessageCountAction,
  getUnreadNotificationsAction,
  resetUserNoticationsAction,
  chatUnreadMessageCount = 0,
  user,
}) => {
  const [client, setClient] = useState<StreamChat<DefaultGenerics>>()

  const [chatUser] = useState({ id: user?.id })
  const notificationEnabled = useFeatureFlag(user?.id, 'release-notifications')
  const helpDeskWidgetEnabled = useFeatureFlag(user?.id, 'release-helpdesk-rollout')

  useEffect(() => {
    if (user) {
      const newClient = new StreamChat(APP_STREAM_KEY, {
        enableInsights: true,
        enableWSFallback: true,
      })

      newClient
        .connectUser(chatUser, user?.streamAccessToken)
        .catch((e) => {
          console.error(`Failed to connect user`, e)
        })
        .then(() => {
          setClient(newClient)
          chatUnreadMessageCountAction(newClient.user?.total_unread_count)
        })
      getUnreadNotificationsAction()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getUnreadNotificationsAction])

  if (client) {
    client.on((event) => {
      if (event.total_unread_count !== undefined) {
        chatUnreadMessageCountAction(event.total_unread_count)
      }
    })
  }

  const [showNotificationsTooltip, setShowNotificationTooltip] = useState(false)

  const toggleShowNotifications = () => {
    const nextState = !showNotificationsTooltip
    if (nextState) ampli.notificationsViewed()
    setShowNotificationTooltip(nextState)
  }

  const iconNavItems = useIconNavItems()

  const items = {
    account: iconNavItems.find((item) => item.id === 'account') || null,
    chat: iconNavItems.find((item) => item.id === 'chat') || null,
  }

  const chatSelected = useIsNavigationItemSelected(items.chat)
  const accountSelected = useIsNavigationItemSelected(items.account)

  return (
    <div className="flex items-center gap-5">
      {helpDeskWidgetEnabled && <HelpDesk className={'xs:hidden sm:flex'} />}
      {items.chat && (
        <NavbarActionIconButton
          className="hidden sm:flex"
          uncheckedActivityCount={chatUnreadMessageCount}
          onClick={() => goToNextPath(items.chat)}
          Icon={ChatUpdatedVersionIcon}
          selected={chatSelected}
        />
      )}
      {notificationEnabled && (
        <NavbarActionIconButton
          uncheckedActivityCount={chatUnreadNotificationsCount}
          selected={showNotificationsTooltip}
          onClick={() => {
            resetUserNoticationsAction({ isDashboard: true })
            toggleShowNotifications()
          }}
          Icon={NotificationBellIcon}
        ></NavbarActionIconButton>
      )}
      {showNotificationsTooltip && (
        <NotificationPanelContainer
          handleClose={() => toggleShowNotifications()}
          setShowNotificationTooltip={setShowNotificationTooltip}
        />
      )}
      {items.account && (
        <NavbarActionIconButton
          className="hidden sm:flex"
          onClick={() => goToNextPath(items.account)}
          selected={accountSelected}
          Icon={AccountIcon}
        />
      )}
    </div>
  )
}

interface INavbarActionIconButton {
  uncheckedActivityCount?: number
  selected: boolean
  Icon: any
}

const NavbarActionIconButton: AppFC<INavbarActionIconButton> = ({
  selected,
  Icon,
  onClick,
  children,
  className = '',
  ...props
}) => {
  return (
    <div
      className={`flex flex-col items-center w-auto font-semibold cursor-pointer ${className}`}
      onClick={onClick}
    >
      <CircularIconContainer selected={selected}>
        <Icon className={`fill-primary hover:fill-primary-brand w-full`} />
        {props?.uncheckedActivityCount ? (
          <NewActivityIcon count={props.uncheckedActivityCount} />
        ) : null}
      </CircularIconContainer>
      {children}
    </div>
  )
}

/** A little red circle with a count */
const NewActivityIcon: AppFC<{ count?: number }> = ({ count }) => {
  return (
    <div className="absolute h-5 w-5 -right-4 -top-2.5 md:-top-1 md:-right-1.5 flex justify-center items-center bg-alert rounded-full">
      {typeof count === 'number' && <span className="text-white text-xs">{count}</span>}
    </div>
  )
}

const CircularIconContainer: AppFC<{ selected: boolean }> = ({ children, selected }) => (
  <div
    className={`flex justify-center items-center rounded-[50%] h-5 w-5 sm:h-8 sm:w-8 md:h-11 md:w-11 relative ${
      selected ? 'bg-sky' : ''
    }`}
  >
    {children}
  </div>
)

const useIconNavItems = () => {
  const navigationItems = useNavigationItems()
  const iconNavItems = useMemo(
    () => navigationItems.filter((item) => item.isHeader),
    [navigationItems]
  )
  return iconNavItems
}

export default NavbarActionIconButtons
