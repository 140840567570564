import React, { FC, useEffect, useState } from 'react'

import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import { isTablet } from 'mobile-device-detect'
import { actionOption, ADVISOR_STATUS, API_TYPES, LABEL, ROLES, USER_MANAGEMENT_TABS } from 'config'

import ResetPasswordConfirmationModalContainer, {
  ConfirmationModalContainer,
} from 'pages/Common/container'
import Button from 'components/Common/Button'
import SelectField from 'components/Common/SelectField'
import { NAMES } from 'components/Admin/UserManagement/config/enums/index'
import { TableActionWrapper } from 'components/Admin/UserManagement/UserManagementList/style'
import EditAccountModalBox from 'components/Admin/UserManagement/TableActions/EditAccountModalBox'

import { navigateTo, onSelectActionsOptions, scrollToTop } from 'utils/helper'
import { useFlashParams } from 'hooks/useFlashParams'

interface IUserData {
  activated: string
  userId: string
  email: string
  name: string
}

interface Payload {
  openConfirmationModal: boolean
  email?: string
  name?: string
  userId?: string
}
type ResendUserInvitePayload = Pick<Payload, Exclude<keyof Payload, 'openConfirmationModal'>>

interface ITableActionsProps {
  name: string
  email: string
  userData: IUserData
  resetErrorsAction: (p?: string[]) => void
  getBSOProfileAction: () => void
  userManagementError: boolean | string
  openConfirmationModal: boolean
  resendUserInviteAction: (payload: ResendUserInvitePayload) => void
  openConfirmationModalAction: (payload: Payload) => void
}

const TableActions: FC<ITableActionsProps> = ({
  name,
  email,
  userData,
  resetErrorsAction,
  getBSOProfileAction,
  userManagementError,
  openConfirmationModal,
  resendUserInviteAction,
  openConfirmationModalAction,
}) => {
  let { isSelectedTab } = useParams()
  const [activatedStatus, setActivatedStatus] = useState('')
  const [openUserManagementModal, setOpenUserManagementModal] = useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)

  useEffect(() => {
    if (userData && userData?.activated) {
      setActivatedStatus(userData?.activated)
    }
  }, [userData])

  useEffect(() => {
    if (userManagementError) {
      scrollToTop()
    }
  }, [userManagementError])
  const { removeFlashParams } = useFlashParams<{ validationErrorMessage?: string }>()

  const handleClose = () => {
    getBSOProfileAction()
    setOpenUserManagementModal(false)
    removeFlashParams()
  }
  return (
    <TableActionWrapper>
      <div>
        <Button
          label={LABEL.EDIT}
          variant="text"
          className="w-full"
          onClick={() => {
            setOpenUserManagementModal(true)
          }}
        />
      </div>
      {!isTablet && (
        <>
          {activatedStatus === ADVISOR_STATUS.INVITED && (
            <div>
              <Button
                variant="text"
                label="Resend Invitation"
                onClick={() =>
                  openConfirmationModalAction({
                    openConfirmationModal: true,
                    email: userData.email,
                    name: userData.name,
                  })
                }
              />
            </div>
          )}
          {activatedStatus === ADVISOR_STATUS.INACTIVE && (
            <div>
              {/* check-button -- clean up custom styles */}
              <Button label={'Send Invitation'} variant="secondary" />
            </div>
          )}

          {activatedStatus === ADVISOR_STATUS.ACTIVE && (
            <>
              <div>
                <Button
                  label="Chat"
                  variant="secondary"
                  onClick={() => {
                    navigateTo(ROLES.BSO_ADMIN, `chat/${userData.userId}`)
                  }}
                  className="w-full"
                />
              </div>
              <div>
                <Button
                  className="w-full"
                  variant="secondary"
                  label="Reset Password"
                  onClick={() => setOpenResetPasswordModal(true)}
                />
              </div>
            </>
          )}
        </>
      )}
      {isTablet && (
        <span>
          <SelectField
            name={NAMES.ACTION}
            placeholder={`${capitalize(NAMES.ACTION)}s`}
            classes={'min-w-[110px]'}
            options={actionOption
              .filter((o) => o.action === activatedStatus)
              .map((opt) => {
                return { label: opt.label, value: opt.value }
              })}
            onChange={(value) => {
              onSelectActionsOptions(
                value,
                userData,
                openConfirmationModalAction,
                setOpenResetPasswordModal
              )
            }}
          />
        </span>
      )}
      {openUserManagementModal && (
        <EditAccountModalBox
          userData={userData}
          isBusiness={isSelectedTab === USER_MANAGEMENT_TABS.LINK_BUSINESSES}
          handleClose={() => {
            handleClose()
            resetErrorsAction([API_TYPES.UPDATE_ADVISOR_PROFILE, API_TYPES.UPDATE_OWNER_PROFILE])
          }}
        />
      )}
      {openResetPasswordModal && (
        <ResetPasswordConfirmationModalContainer
          email={userData.email}
          onClose={() => setOpenResetPasswordModal(false)}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModalContainer
          title="Resend user invitation"
          onConfirm={() => resendUserInviteAction({ name, email, userId: userData.userId })}
          description={`Resending the invitation will send an email to ${email}`}
          successDescription={`The invitation has been successfully resent to  ${email}`}
          onClose={() => openConfirmationModalAction({ openConfirmationModal: false })}
        />
      )}
    </TableActionWrapper>
  )
}

export default TableActions
