import React, { FC, useState } from 'react'

import { isEmpty } from 'lodash'
import { AddValuesType } from 'types'
import { FILE_ACTION, ROLES, SUBTITLE } from 'config'

import Loader from 'components/Loaders'
import Toast from 'components/Common/Toast'
import Button from 'components/Common/Button'
import Heading from 'components/Common/Heading'
import MessageBox from 'components/Common/MessageBox'
import ReportHistory from 'pages/DataReports/ReportHistory'
import ComingSoonPlaceholder from 'components/Common/ComingSoonPlaceholder'
import ClientReportManagement from 'components/DataReports/ClientReportManagement'
import { ExtendedITabOption } from 'components/DataReports/ClientReportManagement/utils'
import AttestationSignatureDetailCards from 'components/DataReports/AttestationSignatureDetailCards'

import { downloadFile } from 'pages/DataReports/helper'
import { AttestationSummary } from '__generated__/api-types-and-hooks'
import { PageWrapper, ReportButtonWrapper } from 'pages/DataReports/styles'
import { IReportHistoryList } from 'store/selectors/attestationSummary'
import { ampli } from 'ampli'
import moment from 'moment'
import { getRoleFromPath, helpDesk } from 'utils/helper'

interface SummaryData {
  variant: 'success' | 'info' | 'warning' | 'alert' | 'none'
  infoText: string
  message: string
  date: string
  showReportButton: boolean
  showCountCard: boolean
  signedDocumentUrl: string
  reportHistoryList: IReportHistoryList[]
  showViewDetailsButton: boolean
  downloadUrl: string
  isClientAssigned: boolean
  submissionReportDate: string
  currentDate: string
  fileName: string
}

interface IReportsProps {
  organizationName: string
  userName: string
  summaryData: SummaryData
  reportsEnabled: boolean
  isAttestationSummaryLoading: boolean
  attestationSummary: AddValuesType<Required<Omit<AttestationSummary, '__typename'>>, null>
  timezone: string
}

const Reports: FC<IReportsProps> = ({
  userName,
  summaryData,
  reportsEnabled,
  organizationName,
  attestationSummary,
  isAttestationSummaryLoading,
  timezone,
}) => {
  const [filter, setFilter] = useState<ExtendedITabOption>()
  const currentDate = moment.tz(timezone).startOf('day').toDate()
  const submissionReportDate = moment(summaryData?.submissionReportDate)
    .tz(timezone)
    .startOf('day')
    .toDate()

  const userRole = getRoleFromPath()
  return (
    <PageWrapper>
      {!reportsEnabled ? (
        <ComingSoonPlaceholder
          title="Monthly Impact Reports"
          description="As GoTackle gets to know your clients, this area will offer you time-saving reports and critical data insights."
          className="flex flex-col items-center justify-center gap-3"
        />
      ) : (
        <>
          {!filter && (
            <>
              <Heading size="small" className="mt-12">
                {SUBTITLE}
              </Heading>
              {!summaryData?.isClientAssigned &&
              userRole !== ROLES.BSO_ADVISOR &&
              currentDate > submissionReportDate ? (
                <MessageBox
                  label={summaryData.infoText}
                  message={summaryData.message}
                  date={summaryData.date}
                />
              ) : userRole === ROLES.BSO_ADVISOR && !summaryData?.isClientAssigned ? (
                <div className="border border-grey-lighter4x p-9 rounded-lg mt-9 bg-white">
                  <div className="font-primary text-primary-text inline-flex gap-1 ">
                    <p>
                      You do not have any clients assigned to you on GoTackle. Please contact your
                      admin or the
                    </p>
                    <Button
                      variant="text"
                      label="Help Desk"
                      className="inline-flex underline"
                      onClick={() => {
                        helpDesk()
                      }}
                    />
                  </div>
                  <span>.</span>
                </div>
              ) : null}

              {summaryData?.isClientAssigned && (
                <>
                  {isAttestationSummaryLoading ? (
                    <Loader loader="AttestationSummaryLoader" />
                  ) : (
                    <>
                      {summaryData.variant !== 'none' ? (
                        <Toast variant={summaryData?.variant} showCloseButton={false}>
                          <div className="pr-1">
                            {summaryData?.infoText}
                            <span className="text-base text-black-light font-bold">
                              {summaryData?.date}
                            </span>
                          </div>
                          {summaryData?.showReportButton &&
                            !isEmpty(summaryData?.signedDocumentUrl) && (
                              <ReportButtonWrapper>
                                <Button
                                  label="Download TSV"
                                  variant="text"
                                  onClick={() => {
                                    ampli.impactReportLinkClicked({
                                      userName,
                                      organizationName,
                                      fileName: summaryData?.fileName,
                                      fileAction: FILE_ACTION.DOWNLOAD,
                                    })
                                    downloadFile(summaryData?.downloadUrl)
                                  }}
                                />
                                <Button
                                  label="View PDF"
                                  variant="text"
                                  onClick={() => {
                                    ampli.impactReportLinkClicked({
                                      userName,
                                      organizationName,
                                      fileName: summaryData?.fileName,
                                      fileAction: FILE_ACTION.VIEW,
                                    })
                                    window.open(summaryData?.signedDocumentUrl, '_blank')
                                  }}
                                />
                              </ReportButtonWrapper>
                            )}
                        </Toast>
                      ) : (
                        <MessageBox
                          label={summaryData.infoText}
                          message={summaryData.message}
                          date={summaryData.date}
                        />
                      )}
                    </>
                  )}
                  {/* {summaryData?.showCountCard && ( */}
                  {summaryData?.showCountCard && (
                    <AttestationSignatureDetailCards
                      setFilter={setFilter}
                      signedCount={attestationSummary.signedCount || 0}
                      unsignedCount={attestationSummary.unsignedCount || 0}
                      isDisabled={!summaryData?.showCountCard}
                      showViewDetailsButton={summaryData?.showViewDetailsButton}
                    />
                  )}
                  {/* )} */}
                </>
              )}
            </>
          )}
          {filter && summaryData?.isClientAssigned && (
            <ClientReportManagement
              filter={filter}
              setFilter={setFilter}
              signedCount={attestationSummary.signedCount || 0}
              unsignedCount={attestationSummary.unsignedCount || 0}
            />
          )}
          {!filter || !summaryData?.isClientAssigned ? (
            <>
              <ReportHistory
                reportHistoryList={summaryData.reportHistoryList}
                userName={userName}
                organizationName={organizationName}
                fileName={summaryData.fileName}
              />
            </>
          ) : null}
        </>
      )}
    </PageWrapper>
  )
}

export default Reports
