import API from 'api'
import * as api from '__generated__/api-types-and-hooks'

class ASSESSMENT_API {
  getAssessmentList = (
    userId: string,
    tenantId: string
  ): Promise<api.ListAssessmentResponse | undefined> => {
    return API.fetch(api['ListAssessmentsDocument'], { userId, tenantId })
  }
  getAssessments = (type): Promise<any> => {
    return API.fetch(api['GetAssessmentsDocument'], { type })
  }
  saveAssessmentResponse = (input) => {
    return API.fetch(api['SaveAssessmentResponseDocument'], { input: input })
  }
  getUserAssessmentResponse = (type: string = '', userId = undefined) => {
    return API.fetch(api['GetUserAssessmentResponseDocument'], { type, userId })
  }
  getAssessmentResponseByQuestion = (question, type, userId) => {
    return API.fetch(api['GetAssessmentResponseByQuestionDocument'], { question, type, userId })
  }
  getUserRecommendations = (input): Promise<any> => {
    return API.fetch(api['GetUserRecommendationsDocument'], { input: input })
  }
  getAssessmentOptions = (optionTypes): Promise<any> => {
    return API.fetch(api['GetOptionsDocument'], { optionTypes })
  }
}

// eslint-disable-next-line
export default new ASSESSMENT_API()
