import React, { FC } from 'react'

interface IWarningIconV1Props {
  className?: string
}
const WarningIconV1: FC<IWarningIconV1Props> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="card-warning">
        <path
          id="Rectangle 4896"
          d="M0 10C0 4.47715 4.47715 0 10 0H56C61.5229 0 66 4.47715 66 10V56C66 61.5229 61.5228 66 56 66H10C4.47715 66 0 61.5228 0 56V10Z"
          fill="#FBE9C8"
        />
        <path
          id="Subtract"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.969 18.9883C32.3162 16.6549 35.684 16.655 37.0312 18.9883L49.5885 40.7383C50.9357 43.0716 49.2518 45.9883 46.5575 45.9883H21.4427C18.7484 45.9883 17.0645 43.0716 18.4116 40.7383L30.969 18.9883ZM34 23.9983C34.8284 23.9983 35.5 24.6699 35.5 25.4983V32.4983C35.5 33.3267 34.8284 33.9983 34 33.9983C33.1716 33.9983 32.5 33.3267 32.5 32.4983L32.5 25.4983C32.5 24.6699 33.1716 23.9983 34 23.9983ZM34 41.9983C35.1046 41.9983 36 41.1029 36 39.9983C36 38.8937 35.1046 37.9983 34 37.9983C32.8954 37.9983 32 38.8937 32 39.9983C32 41.1029 32.8954 41.9983 34 41.9983Z"
          fill="#FFA500"
        />
      </g>
    </svg>
  )
}

export default WarningIconV1
