import { setFlashUrlParams } from 'utils/flashParameters'
import { ApiResponseError } from 'utils/error-handling/api-response-error'

export const handleDisconnectedToolsError = (error: ApiResponseError) => {
  const disconnectedToolError = error?.errorInfo
  if (!disconnectedToolError?.disconnectedTools) return
  const disconnectedTools = disconnectedToolError.disconnectedTools.map((tool: string) => {
    return tool
      .trim()
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
  })
  const flashParams = {
    disconnectedTools: disconnectedTools.join(', '),
  }
  setFlashUrlParams(flashParams)
  return
}
