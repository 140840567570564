import { setFlashUrlParams } from 'utils/flashParameters'
import { ApiResponseError } from 'utils/error-handling/api-response-error'

export const handleUpdateUserCalendarEventError = (meetingId) => (errors: ApiResponseError[]) => {
  const flashParams = {
    meetingEditStatus: 'failed',
    toastVisibilityTimeout: '5000',
    toastVariant: 'alert',
    meetingId,
  }
  setFlashUrlParams(flashParams)
  const messages = errors.map(({ message }) => message).join(', ')
  throw new Error(messages)
}
