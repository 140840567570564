import React, { Fragment, useEffect, useState } from 'react'

import { orderBy } from 'lodash'

import { CrossIcon } from 'components/Common/SvgIcons'
import ClientSearchOption from 'components/Admin/UserManagement/ClientSearchOption'
import SelectField from 'components/Common/SelectField'
import InputTextButton from 'components/Common/InputTextButton'
import { SelectFieldWrapper } from 'components/Admin/UserManagement/style'

const AssignOwnerToAdvisorMobileView = ({
  isChanged = (e: boolean) => {},
  remainingOwnerList,
  assignedOwnerList,
  firstName,
  onSubmit,
  methods,
  owners,
  padding,
}) => {
  const [assignedOwners, setAssignedOwners]: any = useState()
  const [remainingOwners, setRemainingOwners]: any = useState()

  const { register, handleSubmit, setValue } = methods

  useEffect(() => {
    register('owners')
  }, [register])

  useEffect(() => {
    setValue(
      'owners',
      assignedOwners?.map((owner) => owner.value)
    )
  }, [assignedOwners, setValue])

  useEffect(() => {
    setAssignedOwners(assignedOwnerList || [])
    setRemainingOwners(remainingOwnerList || [])

    return () => {
      setAssignedOwners([])
      setRemainingOwners([])
    }
  }, [assignedOwnerList, owners, remainingOwnerList])

  const addOwner = (owner) => {
    owner.isNew = true
    let sortedOwner = [owner, ...assignedOwners]
    sortedOwner = orderBy(sortedOwner, ['lastName'], ['asc'])

    const remaining = remainingOwners.filter(
      (remainingOwner) => remainingOwner.value !== owner.value
    )
    setAssignedOwners(sortedOwner)
    setRemainingOwners(remaining)
    isChanged(true)
  }

  const removeOwner = (owner) => {
    const owners = assignedOwners?.filter((item) => item.value !== owner.value)
    let remainingOwner = [owner, ...remainingOwners]
    remainingOwner = orderBy(remainingOwner, ['lastName'], ['asc'])
    setAssignedOwners(owners)
    setRemainingOwners(remainingOwner)
    isChanged(true)
  }

  const addAllOwner = () => {
    remainingOwners.map((owner) => (owner.isNew = true))
    setAssignedOwners([...remainingOwners, ...assignedOwners])
    setRemainingOwners([])
  }

  const removeAllOwner = () => {
    setRemainingOwners([...remainingOwners, ...assignedOwners])
    setAssignedOwners([])
  }

  const filterOption = (option, inputValue) => {
    if (inputValue === '') return true
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.email.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`bg-white ${padding} relative`}>
          <div className=" pt-6 w-full">
            <label className="text-2xl font-primary text-primary-text w-full">
              Client Assignment
            </label>
            <div className="pt-3">
              <label className="text-sm font-primary text-primary-text font-semibold">
                ADD CLIENTS
              </label>
            </div>
          </div>
          <div></div>

          <div className="grid grid-cols-1 gap-3 bg-white pb-2">
            <SelectFieldWrapper>
              <SelectField
                placeholder="Search by name or email"
                name="search"
                isSearchable={true}
                options={remainingOwners}
                components={{
                  Option: (props) => <ClientSearchOption {...props} addOwner={addOwner} />,
                }}
                filterOption={filterOption}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                menuPortalTarget={false}
              />
            </SelectFieldWrapper>
          </div>
          <InputTextButton
            title={'Add All Clients'}
            fontSize="text-lg"
            position="text-center bg-white !mt-0 py-6"
            className="static bg-white leading-[22px]"
            fontWeight="font-semibold"
            action={() => addAllOwner()}
          />
          <div className="border-t-[1px] border-b-[1px] border-primary-outline">
            <div className="mt-[15px] flex justify-between">
              <label className="font-primary text-primary-text font-semibold text-base">
                {firstName ? `${firstName}’s Clients` : 'Assigned Clients'}
              </label>
              <label className="font-primary font-normal text-black-light text-lg leading-6">
                {assignedOwners?.length}
              </label>
            </div>
            <div className="mt-[15px] mb-[13px]">
              {assignedOwners?.map((owner, index) => {
                return (
                  <OwnerAssignment
                    owner={owner}
                    assignedOwners={assignedOwners}
                    removeOwner={removeOwner}
                    index={index}
                    isAssignedOwner={true}
                  />
                )
              })}
            </div>
          </div>
          <InputTextButton
            title={'Remove All Assigned Clients'}
            fontSize="text-lg"
            position="text-center bg-white !mt-0 pt-[30px] pb-[38px]"
            className="static bg-white leading-[22px]"
            fontWeight="font-semibold"
            action={() => removeAllOwner()}
          />
        </div>
      </form>
    </>
  )
}
AssignOwnerToAdvisorMobileView.defaultProps = {
  padding: 'px-7 ',
}
export default AssignOwnerToAdvisorMobileView

export const OwnerAssignment = ({ owner, assignedOwners, removeOwner, index, isAssignedOwner }) => {
  const [display, setDisplay] = useState(false)

  const [isNew, setIsNew] = useState(false)

  useEffect(() => {
    if (owner?.isNew) {
      setIsNew(true)
      setDisplay(true)
      setTimeout(() => {
        setIsNew(false)
        delete owner.isNew
      }, 3000)
    } else {
      setDisplay(true)
    }
  }, [assignedOwners, owner.isNew])

  return (
    <Fragment key={index}>
      {display && (
        <div className="flex justify-between">
          <div
            className={` rounded-[53px] px-3 py-[3px] mb-[12px] flex items-center
        ${
          isAssignedOwner && assignedOwners?.find((selected) => selected.value === owner.value)
            ? `${isNew ? ' bg-gerberared/[0.5] !border-0' : ' bg-primary/[0.5] !border-0'} `
            : ''
        }
        bg-primary bg-opacity-60
        `}
          >
            <div className="w-full p-1">
              <div className="flex flex-col">
                <label className="font-primary text-base text-primary-text break-all">
                  {owner.label},
                </label>
                <label className="font-primary text-base text-primary-text break-all">
                  {owner.email}
                </label>
              </div>
            </div>
            {isAssignedOwner && (
              <span
                className={` inline-flex pl-3 cursor-pointer`}
                key={index}
                onClick={() => removeOwner(owner)}
              >
                <CrossIcon className={'h-[15px] w-[15px] fill-primary-text cursor-pointer'} />
              </span>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}
