import moment from 'moment-timezone'

type TimeZoneFormats = 'short' | 'long'

export const formatTimeZone = (timeZone: string, format: TimeZoneFormats = 'long') => {
  const fullTimeZoneName =
    Intl.DateTimeFormat('en-US', { timeZoneName: format, timeZone })
      .formatToParts(Date.now())
      .find((part) => part.type === 'timeZoneName')?.value ?? ''

  return fullTimeZoneName
}

export const getTimeZoneCityName = (timezone: string) => {
  const parts = timezone.split('/')
  if (!parts.length) return ''
  return parts[parts.length - 1].replace('_', ' ')
}

export const getTimeZoneDisplayRepresentation = (timezone: string | undefined) => {
  if (!timezone) timezone = moment.tz.guess()
  const formattedTimeZone = formatTimeZone(timezone)
  const formattedOffset = 'GMT' + moment.tz(timezone).format('Z')
  const cityName = getTimeZoneCityName(timezone)
  const formattedCityName = cityName && cityName.includes('GMT') ? '' : `- ${cityName}`
  const label = `(${formattedOffset}) ${
    formattedTimeZone.includes('GMT') ? '' : formattedTimeZone
  } ${formattedCityName}`
  return label
}

export const getTimeZoneOffset = (timeZoneOffsetString: string) => {
  const match = timeZoneOffsetString.match(/-?\d+/) ?? ['0']
  const offset = parseInt(match[0])
  return offset
}

export const isTimeZoneNameValid = (timezone: string) => {
  return !getTimeZoneCityName(timezone).includes('GMT') && !formatTimeZone(timezone).includes('GMT')
}

export const checkTimeZone = (
  user: { timezone: string | undefined; interimTimezone: string | undefined },
  currentTimezone: string
): boolean => {
  if (!user.timezone) return false
  const userTimeZoneOffset = getTimeZoneOffset(getTimeZoneDisplayRepresentation(user.timezone))
  const currentTimeZoneOffset = getTimeZoneOffset(getTimeZoneDisplayRepresentation(currentTimezone))
  const isTimeZoneInADifferentOffset =
    user?.timezone && userTimeZoneOffset !== currentTimeZoneOffset
  const userIsNotInTheInterimTimeZone =
    !user?.interimTimezone || user?.interimTimezone !== currentTimezone
  return !!(isTimeZoneInADifferentOffset && userIsNotInTheInterimTimeZone)
}
