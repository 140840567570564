import React, { useState, useEffect, FC } from 'react'

import { CREATE_BUSINESS_ACCOUNT_STEPS } from 'config'
import { isEmpty } from 'lodash'

import {
  CreateAccountContainer,
  AccountCreatedContainer,
  AssignAdvisorOrClientFormContainer,
} from 'pages/Admin/container'
import { StepperWrapper } from 'components/Common/StyledComponents/StepperWrapper'
import UserCreationContainer from 'components/Common/UserCreationContainer'
import ProfileForm from 'components/Common/ProfileForm'
import { LicenseLimitModalBoxContainer, ProgressbarContainer } from 'pages/Common/container'
import CloseButton from 'components/Common/CloseButton'
import {
  AdvisorFormWrapper,
  ProgressbarWrapper,
  UserStepperWrapper,
  FormHeadingTextWrapper,
} from 'components/Admin/AdvisorStepper/style'
import { ProfileFormWrapper } from 'components/Admin/BusinessOwnerStepper/style'
import AdditionalBusinessProfileForm from 'components/Admin/BusinessOwnerStepper/AdditionalBusinessProfileForm'

import { getReturnToPath, getRoleFromPath, navigateTo, scrollToTop } from 'utils/helper'
import { useFlashParams } from 'hooks/useFlashParams'

export interface IBusinessOwnerStepperProps {
  steps: { title: string }[]
  activeStep: string
  getUsersAction: ({ roles }) => void
  resetProgressbarAction: () => void
  updateProgressbarAction: () => void
  addBusinessAccountAction: ({ data }) => void
  resetAccountErrorsAction: () => void
  setBusinessOwnerActiveStepAction: (step) => void
  resetAdvisorAndBusinessFormsAction: () => void
}

const BusinessOwnerStepper: FC<IBusinessOwnerStepperProps> = ({
  steps,
  activeStep,
  getUsersAction,
  resetProgressbarAction,
  updateProgressbarAction,
  addBusinessAccountAction,
  resetAccountErrorsAction,
  setBusinessOwnerActiveStepAction,
  resetAdvisorAndBusinessFormsAction,
}) => {
  const [formData, setFormData] = useState<any>()
  const role = getRoleFromPath()
  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || 'dashboard'

  useEffect(() => {
    // returned function will be called on component unmount
    getUsersAction({ roles: ['BSO_ADVISOR'] })
    return () => {
      resetProgressbarAction()
      resetAccountErrorsAction()
      setBusinessOwnerActiveStepAction(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM)
      resetAdvisorAndBusinessFormsAction()
    }
    // eslint-disable-next-line
  }, [])

  const handleOnSubmits = (data) => {
    if (data) {
      setFormData({ ...formData, ...data })
    }

    if (data) {
      if (!isEmpty(data?.businessClassificationOptionId)) {
        setFormData({ ...formData, businessProfile: { ...data } })
      } else {
        setFormData({ ...formData, ...data })
      }
    }

    if (activeStep === CREATE_BUSINESS_ACCOUNT_STEPS.ACCOUNT_CREATED) {
      navigateTo(role, `${redirectPath}`)
    }

    if (activeStep === CREATE_BUSINESS_ACCOUNT_STEPS.CREATE_ACCOUNT) {
      addBusinessAccountAction({
        ...formData,
        yearsInBusiness: formData.yearsInBusiness?.toString(),
      })
    }

    if (activeStep !== CREATE_BUSINESS_ACCOUNT_STEPS.CREATE_ACCOUNT) {
      const indexofActiveStep = steps.findIndex((step) => step?.title === activeStep)
      setBusinessOwnerActiveStepAction(steps[indexofActiveStep + 1]?.title)
      updateProgressbarAction()
    }
    scrollToTop()
  }

  const { flashParams, removeFlashParams } = useFlashParams<{ validationErrorMessage?: string }>()

  const onClose = () => {
    if (flashParams?.validationErrorMessage) {
      removeFlashParams(['validationErrorMessage'])
    }
    navigateTo(role, `${redirectPath}`)
  }

  const getClass = (title) => {
    return activeStep === title ? 'block' : 'hidden'
  }

  return (
    <>
      <StepperWrapper>
        <UserCreationContainer>
          <UserStepperWrapper>
            <ProgressbarWrapper>
              <ProgressbarContainer totalSteps={steps.length - 1} />
            </ProgressbarWrapper>
            <CloseButton onClick={onClose} />
          </UserStepperWrapper>
          <LicenseLimitModalBoxContainer isOwner={true} />
          <AdvisorFormWrapper>
            <FormHeadingTextWrapper> CREATE A NEW BUSINESS ACCOUNT </FormHeadingTextWrapper>
            <ProfileFormWrapper>
              <div className={getClass(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM)}>
                <ProfileForm
                  onSubmit={handleOnSubmits}
                  type="business"
                  heading={'Create a Profile'}
                />
              </div>
              {activeStep === CREATE_BUSINESS_ACCOUNT_STEPS.ADDITIONAL_DETAILS && (
                <div>
                  <AdditionalBusinessProfileForm handleOnSubmit={handleOnSubmits} />
                </div>
              )}
              <div className={getClass(CREATE_BUSINESS_ACCOUNT_STEPS.ASSIGN_AN_ADVISOR)}>
                <AssignAdvisorOrClientFormContainer handleOnSubmits={handleOnSubmits} />
              </div>
              <div className={getClass(CREATE_BUSINESS_ACCOUNT_STEPS.CREATE_ACCOUNT)}>
                <CreateAccountContainer
                  userEmail={formData?.email}
                  handleOnSubmits={handleOnSubmits}
                />
              </div>
              <div className={getClass(CREATE_BUSINESS_ACCOUNT_STEPS.ACCOUNT_CREATED)}>
                <AccountCreatedContainer
                  handleOnSubmits={handleOnSubmits}
                  accountName="business owner"
                  setFormData={setFormData}
                />
              </div>
            </ProfileFormWrapper>
          </AdvisorFormWrapper>
        </UserCreationContainer>
      </StepperWrapper>
    </>
  )
}

export default BusinessOwnerStepper
