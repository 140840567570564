import { GET_PLAYS } from 'store/types'
import { GetPlaysInput } from '__generated__/api-types-and-hooks'

export const getPlaysAction = {
  STARTED: (payload: GetPlaysInput) => ({
    type: GET_PLAYS.STARTED,
    payload,
  }),
  FULLFILLED: (payload: any) => ({ type: GET_PLAYS.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_PLAYS.REJECTED }),
}

/** Types describing the actions returned from each getPlaysAction callbacks */
export namespace GetPlaysAction {
  export type STARTED = ReturnType<typeof getPlaysAction['STARTED']>
  export type FULLFILLED = ReturnType<typeof getPlaysAction['FULLFILLED']>
  export type REJECTED = ReturnType<typeof getPlaysAction['REJECTED']>
}
