import React from 'react'

function ClipboardPen(props) {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9862 1.99944C11.8616 0.87472 10.9079 0 9.75 0H6.25C5.09205 0 4.13841 0.87472 4.01379 1.99944L2.25 2C1.00736 2 0 3.00736 0 4.25V17.75C0 18.9926 1.00736 20 2.25 20H7.0014C6.9942 19.8075 7.0139 19.6088 7.0642 19.4075L7.2911 18.5H2.25C1.83579 18.5 1.5 18.1642 1.5 17.75V4.25C1.5 3.83579 1.83579 3.5 2.25 3.5L4.37902 3.5002C4.78267 4.1031 5.46997 4.5 6.25 4.5H9.75C10.53 4.5 11.2174 4.10307 11.621 3.50016L13.75 3.5C14.1642 3.5 14.5 3.83579 14.5 4.25V9.8606C14.9371 9.4604 15.4561 9.1998 16 9.0788V4.25C16 3.00736 14.9926 2 13.75 2L11.9862 1.99944ZM11.9948 2.09595L12 2.25C12 2.19822 11.9983 2.14685 11.9948 2.09595ZM6.25 1.5H9.75C10.1642 1.5 10.5 1.83579 10.5 2.25C10.5 2.66421 10.1642 3 9.75 3H6.25C5.83579 3 5.5 2.66421 5.5 2.25C5.5 1.83579 5.83579 1.5 6.25 1.5ZM9.356 15H4C3.58579 15 3.25 15.3358 3.25 15.75C3.25 16.1642 3.58579 16.5 4 16.5H7.983C8.1278 16.2712 8.298 16.058 8.4912 15.8648L9.356 15ZM8 12.5H4C3.58579 12.5 3.25 12.1642 3.25 11.75C3.25 11.3358 3.58579 11 4 11H8C8.4142 11 8.75 11.3358 8.75 11.75C8.75 12.1642 8.4142 12.5 8 12.5ZM12 8.5H4C3.58579 8.5 3.25 8.1642 3.25 7.75C3.25 7.33579 3.58579 7 4 7H12C12.4142 7 12.75 7.33579 12.75 7.75C12.75 8.1642 12.4142 8.5 12 8.5ZM15.0999 10.6695L9.1974 16.5719C8.8533 16.916 8.6092 17.3472 8.4911 17.8194L8.0334 19.6501C7.8344 20.4462 8.5556 21.1674 9.3517 20.9683L11.1824 20.5106C11.6545 20.3926 12.0857 20.1485 12.4299 19.8043L18.3323 13.9019C19.2249 13.0093 19.2249 11.5621 18.3323 10.6695C17.4397 9.7768 15.9925 9.7768 15.0999 10.6695Z"
        fill="#212121"
      />
    </svg>
  )
}

export default ClipboardPen
