import React from 'react'
function LaunchIcon({ className }) {
  return (
    <svg
      className={className}
      width="45"
      height="59"
      viewBox="0 0 45 59"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M31.7068 29.6563C30.613 35.3229 27.0658 44.6582 25.0453 49.6867H19.7282C17.776 44.6354 14.3503 35.3153 13.2641 29.6563C11.0309 17.9739 17.0468 10.4236 22.4931 6.25345C27.9469 10.416 33.9552 17.9739 31.7144 29.6487L31.7068 29.6563ZM14.4794 50.3779C14.8136 51.259 15.1175 52.049 15.3757 52.7022C15.8846 54.0011 17.138 54.8443 18.5356 54.8443L26.2378 54.8443C27.6203 54.8443 28.866 54.0239 29.3825 52.7478C29.6712 52.049 30.013 51.2058 30.3852 50.2563L37.6924 57.5636C38.3456 58.2168 39.3027 58.4751 40.199 58.232C41.0954 57.9889 41.7866 57.2825 42.0144 56.3862L44.749 45.676C45.425 43.0251 44.6578 40.2222 42.7285 38.2928L36.4771 32.0414C36.591 31.5477 36.6897 31.0844 36.7809 30.6286C39.7357 15.2318 30.7726 5.66097 24.2249 1.14902C23.1843 0.427412 21.8094 0.435011 20.7612 1.14902C14.2136 5.66097 5.25042 15.2318 8.20521 30.6286C8.29636 31.0844 8.38752 31.5553 8.50905 32.0414L2.27284 38.2777C0.343487 40.207 -0.431292 43.0175 0.252336 45.6608L2.98685 56.371C3.21473 57.2673 3.90595 57.9738 4.80227 58.2168C5.69858 58.4599 6.64806 58.2092 7.30131 57.556L14.4794 50.3779ZM25.5314 17.4498C24.7256 16.6439 23.6327 16.1912 22.4931 16.1912C21.3535 16.1912 20.2605 16.6439 19.4547 17.4498C18.6489 18.2556 18.1962 19.3485 18.1962 20.4881C18.1962 21.6277 18.6489 22.7206 19.4547 23.5265C20.2605 24.3323 21.3535 24.785 22.4931 24.785C23.6327 24.785 24.7256 24.3323 25.5314 23.5265C26.3372 22.7206 26.7899 21.6277 26.7899 20.4881C26.7899 19.3485 26.3372 18.2556 25.5314 17.4498Z" />
    </svg>
  )
}

LaunchIcon.defaultProps = {
  className: '',
}
export default LaunchIcon
