import React from 'react'
import { AppFC } from 'types'
import useDragElement from 'hooks/useDragElement'

/**
 * A container which allows user to scroll by dragging left/right with mouse rather than with scrollbar
 *
 * CURRENT LIMITATION: ONLY ONE OF THESE COMPONENTS CAN EXIST IN THE DOM AT ONE TIME. The current implementation
 * uses an element id selector, which is also related to hard-coded CSS rules.
 * */
const DraggableContentArea: AppFC = ({ children }) => {
  useDragElement('#drag-area')
  /* use of overflow hidden here is duct-tape to prevent stat cards from overreaching the side of the screen until drag/scrolling is implemented */
  return (
    <div
      id="drag-area"
      className="relative flex gap-[12px] min-w-full w-full overflow-x-scroll overflow-y-hidden whitespace-nowrap transition-all duration-200 will-change-transform user-select-none py-3"
    >
      {children}
    </div>
  )
}

export default DraggableContentArea
