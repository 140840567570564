import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'
import colors from 'colors'

const BusinessProfileLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 168"
        backgroundColor={`${get(colors, 'cream.dark', '')}`}
        foregroundColor={`${colors.grey.lighter}`}
      >
        <rect x="0" y="12" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="25" rx="2" ry="2" width="50" height="8" />
        <rect x="0" y="50" rx="2" ry="2" width="100" height="8" />

        <rect x="0" y="72" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="85" rx="2" ry="2" width="80" height="8" />

        <rect x="0" y="108" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="120" rx="2" ry="2" width="80" height="8" />

        <rect x="0" y="144" rx="2" ry="2" width="100" height="8" />
        <rect x="0" y="156" rx="2" ry="2" width="80" height="8" />
      </ContentLoader>
    </>
  )
}

export default BusinessProfileLoader
