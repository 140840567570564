import React, { FC } from 'react'
import MeetingConfirmation from './MeetingConfirmation'
import ModalBox from 'components/Common/ModalBox'
import MeetingCancelIcon from 'components/Common/SvgIcons/MeetingCancelIcon'
import { UserBasicInfo } from 'types'

interface IMeetingUpdatedModalBoxProps {
  advisor: UserBasicInfo
  errorMessage: string
  modalHandler: () => void
  isMeetingDeletion: boolean
  openMeetingConfirmationModalBoxAction: (param: {
    error: string
    isMeetingDeletion: boolean
    stepType: string
  }) => void
}
const MeetingUpdatedModalBox: FC<IMeetingUpdatedModalBoxProps> = ({
  advisor,
  errorMessage,
  modalHandler,
  isMeetingDeletion,
  openMeetingConfirmationModalBoxAction,
}) => {
  const handleClose = () => {
    openMeetingConfirmationModalBoxAction({ error: '', isMeetingDeletion: false, stepType: '' })
    modalHandler()
  }
  const heading =
    isMeetingDeletion && errorMessage
      ? 'Sorry'
      : isMeetingDeletion && !errorMessage
      ? 'Meeting Deleted'
      : !isMeetingDeletion && !errorMessage
      ? 'Meeting Updated'
      : 'Sorry'

  const successMessage =
    !errorMessage && !isMeetingDeletion
      ? `${advisor.firstName}
${advisor.lastName} has received a notification that the meeting has been updated.`
      : !errorMessage && isMeetingDeletion
      ? `${advisor.firstName}
${advisor.lastName} has received a notification that the meeting has been deleted.`
      : ''

  const errors = errorMessage
    ? `We were unable to create the meeting with ${advisor.firstName}
  ${advisor.lastName}. It’s probably us, not you!`
    : ''

  return (
    <ModalBox
      height={'xs:h-full sm:h-auto max-h-full'}
      margin="sm:mx-5"
      onClose={handleClose}
      Icon={MeetingCancelIcon}
    >
      <MeetingConfirmation
        Heading={heading}
        handleClose={handleClose}
        errorMessage={errors}
        successMessage={successMessage}
      />
    </ModalBox>
  )
}

export default MeetingUpdatedModalBox
