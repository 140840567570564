import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const EditAdvisorUserProfileLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 115"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="15" y="0" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="0" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="18" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="18" rx="2" ry="2" width="205" height="25" />

        <rect x="15" y="55" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="55" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="75" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="75" rx="2" ry="2" width="205" height="25" />
      </ContentLoader>
    </>
  )
}

EditAdvisorUserProfileLoader.defaultProps = {
  isMobile: false,
}

export default EditAdvisorUserProfileLoader
