import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const MeetingLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 800"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="36" rx="2" ry="2" width="170" height="20" />

        <rect x="0" y="70" rx="0" ry="0" width="1" height="135" />
        <rect x="0" y="204" rx="0" ry="0" width="448" height="1" />
        <rect x="0" y="70" rx="0" ry="0" width="448" height="1" />
        <rect x="448" y="70" rx="0" ry="0" width="1" height="135" />

        <rect x="40" y="130" rx="2" ry="2" width="85" height="25" />
        <rect x="168" y="130" rx="2" ry="2" width="198" height="25" />

        <rect x="0" y="220" rx="0" ry="0" width="1" height="135" />
        <rect x="0" y="355" rx="0" ry="0" width="448" height="1" />
        <rect x="0" y="220" rx="0" ry="0" width="448" height="1" />
        <rect x="448" y="220" rx="0" ry="0" width="1" height="135" />

        <rect x="40" y="275" rx="2" ry="2" width="85" height="25" />
        <rect x="168" y="275" rx="2" ry="2" width="198" height="25" />

        <rect x="0" y="393" rx="2" ry="2" width="170" height="20" />

        <rect x="0" y="430" rx="0" ry="0" width="1" height="135" />
        <rect x="0" y="565" rx="0" ry="0" width="448" height="1" />
        <rect x="0" y="430" rx="0" ry="0" width="448" height="1" />
        <rect x="448" y="430" rx="0" ry="0" width="1" height="135" />

        <rect x="40" y="485" rx="2" ry="2" width="85" height="25" />
        <rect x="168" y="485" rx="2" ry="2" width="198" height="25" />
      </ContentLoader>
    </>
  )
}

export default MeetingLoader
