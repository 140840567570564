import React from 'react'
import { reschedule } from 'config/data'

const ClientActivityScoreCard = ({ scoreDetail }) => {
  return (
    <div
      className={`border border-primary-disabled rounded-t min-h-[115px] pt-[14px] pl-[20px] pr-[27px] h-full ${
        scoreDetail?.header?.trim() === reschedule.trim() ? 'hidden' : ''
      }`}
    >
      <div className="text-white h-full">
        <div className="text-sm uppercase"> {scoreDetail?.header}</div>
        <div className="flex justify-between ">
          <div className={scoreDetail?.textSize}> {scoreDetail?.value}</div>
          {/* <div className="pt-5 leading-4 text-xs">
            {scoreDetail?.avg && (
              <div className="flex flex-col flex-wrap justify-end">
                <div className="self-end"> Average: </div>
                <div className="self-end text-right w-[65px]">{scoreDetail?.avg}</div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ClientActivityScoreCard
