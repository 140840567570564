import React, { ElementType, FC } from 'react'
import { AlertWrapper, AlertSubWrapper, TextWrapper } from 'components/Common/Alert/style'

export interface IAlertProps {
  text: string
  Icon: ElementType
  heading?: string
}

const Alert: FC<IAlertProps> = ({ text, Icon, heading }) => {
  return (
    <AlertWrapper>
      <AlertSubWrapper>
        <Icon />
        <div>
          {heading && <TextWrapper>{heading}</TextWrapper>}
          <TextWrapper>{text}</TextWrapper>
        </div>
      </AlertSubWrapper>
    </AlertWrapper>
  )
}

export default Alert
