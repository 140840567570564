import { useCallback, useMemo } from 'react'
import { getFinalPathExtension } from 'utils/helper'
import history from 'utils/history'

const useIsNavigationItemSelected = (item) => {
  const path = history.location.pathname
  const pathExtensionsMatch = (path1, path2) =>
    getFinalPathExtension(path1) === getFinalPathExtension(path2)

  const isNavigationItemSelected = useCallback(
    (item) => (item?.path && pathExtensionsMatch(path, item.path)) || false,
    [path]
  )

  const selected = useMemo(() => {
    return isNavigationItemSelected(item)
  }, [item, isNavigationItemSelected])

  return selected
}

export default useIsNavigationItemSelected
