import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import {
  resetAssessment,
  setActiveQuestion,
  setActiveStepAction,
  previousQuestionAction,
  addEditAssessmentAction,
  saveAssessmentResponseAction,
  initializeOwnerStepperAction,
} from 'store/actions/owner/initialAssessment'
import { logUserActivityAction } from 'store/actions/user'
import { updateProgressbarAction } from 'store/actions/common/progressbar'
import {
  uploadFileAction,
  resetImageUrlAction,
  inputFieldbgColorAction,
} from 'store/actions/common'

import { getAssessmentOptionsSelector, getAssessmentProgressSelector } from 'store/selectors/owner'
import {
  getPlaysFromRecommendationsSelector,
  getRecommendationsSelector,
} from 'store/selectors/assessments'
import { getRecommendedPlaysAssessments } from 'store/actions/owner/account'

import Assessment from 'components/Assessment'
import AssessmentTimeline from 'components/Assessment/Timeline'
import { assessmentBgColorAction } from 'store/actions/assessments'
import AssessmentWidget from 'components/Assessment/AssessmentWidget'
import AssessmentSuccess from 'components/Assessment/AssessmentSuccess'
import AssessmentQuestion from 'components/Assessment/AssessmentQuestion'
import AssessmentRecommendations from 'components/Assessment/AssessmentRecommendations'
import PlayBooksRecommendationCard from 'components/Owner/PlayBooksCategoryDetails/PlayBooksRecommendationCard'

import RecommendationCard from 'components/Common/RecommendationCard'
import { ReviewAssessment } from 'components/Assessment/ReviewAssessment'
import { ReviewAssessmentResponses } from 'components/Assessment/ReviewAssessment/ReviewAssessmentResponses'
import { resetErrorsAction } from 'store/actions/errors'

/* --------------------------- Assessment (Index) --------------------------- */

const assessmentProps = (state: any) => {
  const { steps, activeStep, activeQuestion, assessmentResponse } = state.owner
  const { user } = state.user
  return {
    recommendations: getRecommendationsSelector(state),
    steps: steps,
    currentUser: user,
    activeStep: activeStep,
    totalProgress:
      (get(assessmentResponse, 'totalAttemptedItems', 0) /
        (get(assessmentResponse, 'totalItems', 0) + 1)) *
      100,
    isCompletedAssessment:
      !isNil(assessmentResponse.completedAt) && assessmentResponse.completedAt !== '',
    ...getAssessmentProgressSelector(state),
    activeQuestion: activeQuestion,
  }
}

function assessmentDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAssessment,
      initializeOwnerStepperAction,
      setActiveStepAction: setActiveStepAction,
      addEditAssessmentAction: addEditAssessmentAction.STARTED,
      logUserActivityAction: logUserActivityAction,
    },
    dispatch
  )
}

export const AssessmentContainer = connect(assessmentProps, assessmentDispatch)(Assessment)
export const AssessmentWidgetContainer = connect(
  assessmentProps,
  assessmentDispatch
)(AssessmentWidget)

/*---------------------------Review Assessment------------------------------------*/
const ReviewAssessmentProps = (state: any) => {
  const { user } = state.user

  return {
    userId: user?.id,
    businessName: state.user.businessProfile?.businessName,
  }
}

function ReviewAssessmentDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}

export const ReviewAssessmentContainer = connect(
  ReviewAssessmentProps,
  ReviewAssessmentDispatch
)(ReviewAssessment)

/*----------------------------------ReviewAssessmentResponses---------------------------*/
const ReviewAssessmentResponsesProps = (state: any) => {
  const { user } = state.user
  return {
    userName: user.firstName,
  }
}
function ReviewAssessmentResponsesDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAssessment,
    },
    dispatch
  )
}

export const ReviewAssessmentResponsesContainer = connect(
  ReviewAssessmentResponsesProps,
  ReviewAssessmentResponsesDispatch
)(ReviewAssessmentResponses)

/* --------------------------- Questions Assessment (Index) --------------------------- */

const questionsProps = (state: any) => {
  const { activeQuestion, assessmentResponse, isLoadingAssessmentResponse, assessmentError } =
    state.owner
  const { uploadedFile, uploadFileLoading } = state.common
  const { businessProfile, user } = state.user

  return {
    assessmentError,
    assessmentOptions: getAssessmentOptionsSelector(state),
    recommendations: getRecommendationsSelector(state),
    user,
    businessProfile,
    uploadFileLoading,
    uploadedFile,
    assessmentResponse,
    isLoadingAssessmentResponse,
    isCompletedAssessment:
      !isNil(assessmentResponse.completedAt) && assessmentResponse.completedAt !== '',
    activeQuestion: activeQuestion ?? {},
  }
}

function questionsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      uploadFileAction: uploadFileAction.STARTED,
      updateProgressbarAction: updateProgressbarAction,
      saveAssessmentResponseAction: saveAssessmentResponseAction.STARTED,
      setActiveQuestion,
      assessmentBgColorAction,
      previousQuestionAction,
      inputFieldbgColorAction,
      logUserActivityAction: logUserActivityAction,
      resetImageUrlAction: resetImageUrlAction,
      resetUploadFileAction: uploadFileAction.RESET,
      resetErrorsAction: resetErrorsAction,
      resetAssessment,
      saveAssessmentResponseRejectedAction: saveAssessmentResponseAction.REJECTED,
    },
    dispatch
  )
}
export const QuestionsContainer = connect(questionsProps, questionsDispatch)(AssessmentQuestion)

/* --------------------------- Assessment Progress (Index) --------------------------- */

const assessmentProgressProps = (state: any) => {
  return {
    ...getAssessmentProgressSelector(state),
  }
}

function assessmentProgressDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssessmentProgressContainer = connect(
  assessmentProgressProps,
  assessmentProgressDispatch
)(AssessmentTimeline)

/* --------------------------- Assessment Progress (Index) --------------------------- */

const assessmentSuccessProps = (state: any) => {
  return {
    recommendations: getRecommendationsSelector(state),
  }
}

function assessmentSuccessDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssessmentSuccessContainer = connect(
  assessmentSuccessProps,
  assessmentSuccessDispatch
)(AssessmentSuccess)

/* --------------------------- PlayBooksRecommendationCardsContainer --------------------------- */
const PlayBooksRecommendationCardProps = (state: any) => {
  const { user } = state.user
  const businessName = state.user.businessProfile?.businessName
  const industry = state.user.businessProfile?.businessClassification

  return {
    userSummary: { industry, businessName, userName: user.firstName + ' ' + user.lastName },
  }
}

export const PlayBooksRecommendationCardContainer = connect(
  PlayBooksRecommendationCardProps,
  {}
)(PlayBooksRecommendationCard)

/* --------------------------- AssessmentRecommendationsContainer --------------------------- */

const assessmentRecommendationsProps = (state: any) => {
  const { isFetchingRecommendations } = state.owner
  const { user } = state.user
  const businessName = state.user.businessProfile?.businessName
  const industry = state.user.businessProfile?.businessClassification

  return {
    userSummary: { industry, businessName, userName: user.firstName + ' ' + user.lastName },
    recommendations: {
      isLoading: isFetchingRecommendations,
      data: getPlaysFromRecommendationsSelector(state),
    },
  }
}

function assessmentRecommendationsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    { getRecommendedPlaysAssessmentsAction: getRecommendedPlaysAssessments.STARTED },
    dispatch
  )
}

export const AssessmentRecommendationsContainer = connect(
  assessmentRecommendationsProps,
  assessmentRecommendationsDispatch
)(AssessmentRecommendations)

/* --------------------------- PlayBooksRecommendationCardsContainer --------------------------- */
const RecommendationCardProps = (state: any) => {
  const { user } = state.user
  const businessName = state.user.businessProfile?.businessName
  const industry = state.user.businessProfile?.businessClassification

  return {
    userSummary: { industry, businessName, userName: user.firstName + ' ' + user.lastName },
  }
}

export const RecommendationCardContainer = connect(RecommendationCardProps, {})(RecommendationCard)
