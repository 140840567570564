import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const PlayBookPlayAssessmentLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="22" y="5" rx="0" ry="0" width="2" height="20" />
        <rect x="22" y="5" rx="0" ry="0" width="230" height="2" />
        <rect x="22" y="25" rx="0" ry="0" width="230" height="2" />
        <rect x="250" y="5" rx="0" ry="0" width="2" height="20" />

        <rect x="40" y="11" rx="0" ry="0" width="22" height="11" />
        <rect x="80" y="11" rx="0" ry="0" width="22" height="11" />
        <rect x="120" y="11" rx="0" ry="0" width="22" height="11" />
        <rect x="160" y="11" rx="0" ry="0" width="22" height="11" />
        <rect x="200" y="11" rx="0" ry="0" width="22" height="11" />

        <rect x="22" y="30" rx="0" ry="0" width="2" height="165" />
        <rect x="22" y="30" rx="0" ry="0" width="197" height="2" />
        <rect x="22" y="195" rx="0" ry="0" width="197" height="2" />
        <rect x="218" y="30" rx="0" ry="0" width="2" height="166" />

        <rect x="35" y="50" rx="0" ry="0" width="50" height="8" />
        <rect x="35" y="70" rx="0" ry="0" width="100" height="12" />
        <circle cx="180" cy="110" r="25" />

        <rect x="230" y="30" rx="0" ry="0" width="2" height="98" />
        <rect x="230" y="30" rx="0" ry="0" width="198" height="2" />
        <rect x="230" y="126" rx="0" ry="0" width="198" height="2" />
        <rect x="426" y="30" rx="0" ry="0" width="2" height="98" />

        <rect x="250" y="45" rx="0" ry="0" width="50" height="8" />
        <rect x="250" y="65" rx="0" ry="0" width="100" height="13" />
        <circle cx="390" cy="85" r="17" />
      </ContentLoader>
    </>
  )
}

export default PlayBookPlayAssessmentLoader
