import useNavigationItems from 'hooks/useNavigationItems'
import React, { useMemo } from 'react'
import { AppFC } from 'types'
import navigateTo from 'utils/navigateTo'

export default function NavigationTabs() {
  const navigationItems = useNavigationItems()
  const navigationTabItems = navigationItems.filter((item) => item.isNav)

  return (
    <div className="hidden sm:flex">
      <div className="inline-flex gap-16">
        {navigationTabItems.map((item, index) => {
          return (
            <NavigationTab
              onClick={() => navigateTo(item)}
              className={index === 0 ? 'pl-6' : ''}
              selected={item.selected}
              key={index}
            >
              {item.label}
            </NavigationTab>
          )
        })}
      </div>
    </div>
  )
}

export interface INavigationTabProps {
  selected: boolean
}

const useTextStyle = (selected) => {
  const textStyle = useMemo(() => {
    const selectedStyle = ''
    const notSelectedStyle = ' '
    return `${
      selected ? selectedStyle : notSelectedStyle
    } flex flex-col items-center relative w-auto cursor-pointer text-grey font-base font-[650] pb-4 pt-2 `
  }, [selected])
  return textStyle
}

const NavigationTab: AppFC<INavigationTabProps> = ({ className, onClick, selected, children }) => {
  const textStyle = useTextStyle(selected)
  return (
    <div className={`hover:cursor-pointer ${className}`}>
      <p className={textStyle} onClick={onClick}>
        {children}
      </p>
    </div>
  )
}
