import PlayApi from 'api/play'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getPlaysAction, GetPlaysAction } from 'store/actions/managePlays'
import { GET_PLAYS } from 'store/types'
import { Play } from '__generated__/api-types-and-hooks'

function* getPlays(action: GetPlaysAction.STARTED) {
  const playsRes = yield call(PlayApi.getPlays, action.payload)
  const plays = playsRes.getPlays.data as Play[]
  plays.forEach((play) => {
    Object.keys(play).forEach((key) => {
      try {
        play[key] = JSON.parse(play[key])
      } catch (error) {
        // do nothing - the value does not need to be parsed
      }
    })
  })

  yield put(getPlaysAction.FULLFILLED({ plays }))
}

export function* watcherManagePlays() {
  yield takeLatest(GET_PLAYS.STARTED, getPlays)
}
