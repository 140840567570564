import { createStore, applyMiddleware, compose as reduxCompose, StoreEnhancer } from 'redux'
import createSagaMiddleware from 'redux-saga'
import sagaMonitor from '@redux-saga/simple-saga-monitor'
import rootSaga from 'store/sagas'
import rootReducer from 'store/reducers'
import { enhancer as withReduxEnhancer } from 'addon-redux'
import { getRoleFromPath } from 'utils/helper'
import { getTenantId } from 'utils/getTenantId'
import * as Sentry from '@sentry/browser'
import { ErrorBoundaryException } from 'utils/error-handling/error-handlers/error-boundary-exception'

export default function configureStore() {
  const devToolsCompose = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as
    | typeof reduxCompose
    | undefined

  const compose = devToolsCompose || reduxCompose

  const onError = (error, context) => {
    try {
      throw new ErrorBoundaryException(context.sagaStack)
    } catch (err) {
      Sentry.setExtra('context', context)
      Sentry.captureException(err)
    }
    const userRole = getRoleFromPath()
    setTimeout(() => {
      window.location.href = `/${userRole}/${getTenantId()}/error?errorMessage=${error}`
    }, 1000)
  }
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor, onError })
  const middleware = applyMiddleware(sagaMiddleware)

  const usingReduxAddon =
    process.env.NODE_ENV !== 'production' || process.env.STORYBOOK_ENABLE_REDUX_ADDON_ENHANCER
  if (usingReduxAddon) {
    // console.log('Using Storybook redux addon.')
  }

  const typedWithReduxEnhancer: StoreEnhancer<typeof middleware> = withReduxEnhancer

  const enhancers = usingReduxAddon
    ? /** The order of these arguments is required. Storybook stories will not properly load data unless withReduxEnhancer is first */
      compose(typedWithReduxEnhancer, middleware)
    : compose(middleware)

  // todo - type this. Solution not obvious
  // @ts-ignore
  const store = createStore(
    rootReducer,
    {},
    // @ts-ignore
    enhancers
  )

  sagaMiddleware.run(rootSaga)
  return store
}
