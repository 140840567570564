import React, { useEffect, useState } from 'react'
import Button from 'components/Common/Button'
import { useForm, Controller } from 'react-hook-form'
import SelectField from 'components/Common/SelectField'
import { getAssessmentResponse } from 'utils/helper'
import { FORM } from 'config'
import useFormContainer from 'hooks/useFormContainer'
import useIndustryContainer from 'hooks/useIndustryContainer/useIndustryContainer'
import hookForms from 'utils/hookForms'
import useSubIndustryContainer from 'hooks/useSubIndustryContainer'
import useCheckHasSubIndustry from 'hooks/useCheckHasSubIndustry'
import Loader from 'components/Loaders'
import {
  Heading,
  SkipButton,
  SkipButtonWrapper,
  AssessmentListWrapper,
  ContinueButtonWrapper,
} from 'components/Admin/BusinessOwnerStepper/style'

export default function AdditionalDetailsForm({
  assessment,
  handleOnSubmits,
  isAssessmentQuestionLoading,
  getAssessmentQuestionAction,
}) {
  const methods = useForm({
    mode: 'onBlur',
  })
  const { control, handleSubmit, reset } = methods
  const [isIndustryChanged, setIndustryChanged] = useState(false)
  // eslint-disable-next-line
  const [isSubIndustrySelected, setIsSubIndustrySelected] = useState(false)
  const [assessmentList, setAssessmentList]: any = useState([])
  useEffect(() => {
    getAssessmentQuestionAction({ type: 'initial' })
  }, [getAssessmentQuestionAction])
  const onSubmit = (value) => {
    let assessmentResponse: any = []
    assessmentResponse = getAssessmentResponse({
      keys: value,
      assessment_questions: assessment.questions,
    })
    handleOnSubmits({ assessmentResponse: assessmentResponse })
  }
  useIndustryContainer({
    methods,
    assessment: assessment?.questions,
    setIndustryChanged,
    title: 'industry',
    titlePath: 'ownerDetails.title',
  })
  const {
    industryQuestion,
    foodIndustryQuestionOption,
    subIndustryQuestion,
    profileFormValues,
    servicesSubIndustryQuestion,
    serviceIndustryQuestionOption,
  } = useSubIndustryContainer({
    reset,
    assessment: assessment?.questions,
    isIndustryChanged,
    profileForm: hookForms.getForm(FORM.BUSINESS_ASSESSMENT_FORM),
    setIsSubIndustrySelected,
    setAssessmentList,
  })
  useEffect(() => {
    setAssessmentList(
      assessment?.questions?.filter((question) => question.id === industryQuestion?.id)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  const hasSubIndustry = useCheckHasSubIndustry(
    assessmentList,
    profileFormValues,
    subIndustryQuestion,
    foodIndustryQuestionOption,
    industryQuestion,
    servicesSubIndustryQuestion,
    serviceIndustryQuestionOption
  )
  useFormContainer(FORM.BUSINESS_ASSESSMENT_FORM, methods)
  return (
    <>
      <Heading>Additional Details</Heading>
      {isAssessmentQuestionLoading ? (
        <Loader loader="AdditionalDetailsFormLoader" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <AssessmentListWrapper>
            {assessmentList?.map((question, index) => {
              return (
                hasSubIndustry[index] && (
                  <Controller
                    name={`${question.id}`}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        placeholder="Select"
                        {...field}
                        isMulti={question?.meta?.allowMultiSelect}
                        classes="mt-4"
                        label={question?.content?.admin?.description}
                        options={question.options.map((opt) => ({
                          label: opt.value,
                          value: opt.id,
                        }))}
                      />
                    )}
                  />
                )
              )
            })}
          </AssessmentListWrapper>
          <ContinueButtonWrapper>
            <Button
              type="submit"
              testId="submit"
              label="CONTINUE"
              variant="primary"
              className="w-full"
              // disable={!isIndustryChanged}
            />
          </ContinueButtonWrapper>
          <SkipButtonWrapper>
            <SkipButton>
              <Button
                label="SKIP"
                variant="text"
                type="button"
                onClick={() => handleOnSubmits({})}
              />
            </SkipButton>
          </SkipButtonWrapper>
        </form>
      )}
    </>
  )
}
