import React from 'react'
import { AppFC } from 'types'
import Button from 'components/Common/Button'
import Heading from 'components/Common/Heading'
// import InputField from 'components/Common/InputField'
import BackButtonArrowIcon from 'components/Common/SvgIcons/BackButtonArrowIcon'
import {
  CLIENT_STATUS,
  ExtendedITabOption,
} from 'components/DataReports/ClientReportManagement/utils'
// import SearchIcon from 'assets/svgs/search-icon.svg'

import { ClientReportTableContainer } from 'pages/Clients/container'
import {
  ClientReportsWrapper,
  HeadingWrapper,
  // SearchFieldWrapper,
  // SearchField,
} from 'components/DataReports/ClientReportManagement/styles'
import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'

interface IClientReportManagementProps {
  filter: ExtendedITabOption | undefined
  setFilter: (value: ExtendedITabOption | undefined) => void
  signedCount: number
  unsignedCount: number
}

const ClientReportManagement: AppFC<IClientReportManagementProps> = ({
  filter,
  setFilter,
  signedCount,
  unsignedCount,
}) => {
  return (
    <ClientReportsWrapper>
      <Button
        className="!gap-4"
        label="Back"
        leftContent={() => <BackButtonArrowIcon className={'h-[11px] w-[13px]'} />}
        onClick={() => {
          setFilter(undefined)
        }}
        variant="text"
      />

      <HeadingWrapper>
        <Heading size="small">{filter?.link}</Heading>
        <div>
          <PillTextBox
            colorTheme={
              filter?.label === CLIENT_STATUS.SIGNED ? PillColorTheme.GREEN : PillColorTheme.RED
            }
            size="small"
          >
            {filter?.label === CLIENT_STATUS.SIGNED ? signedCount : unsignedCount}
          </PillTextBox>
        </div>
      </HeadingWrapper>

      {/* Search hidden until algolia implementation */}
      {/* <SearchFieldWrapper>
        <SearchField>
          <InputField
            type={'search'}
            icon={SearchIcon}
            placeholder="Search by name or business"
            className="w-full"
            name="search"
            data-testid="search"
          />
        </SearchField>
      </SearchFieldWrapper> */}
      <ClientReportTableContainer filter={filter} />
    </ClientReportsWrapper>
  )
}

export default ClientReportManagement
