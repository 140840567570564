import React from 'react'

function AdvisorTickIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 16 24"
    >
      <path d="M13.7608 6.7247C14.0797 7.02621 14.0797 7.51378 13.7608 7.78643L5.5062 16.2546C5.24042 16.5818 4.76516 16.5818 4.47125 16.2546L0.219779 11.8922C-0.0732596 11.6196 -0.0732596 11.132 0.219779 10.8305C0.512786 10.5322 0.988051 10.5322 1.28103 10.8305L5.00279 14.6508L12.7259 6.7247C13.0198 6.4251 13.495 6.4251 13.7608 6.7247Z" />
    </svg>
  )
}
AdvisorTickIcon.defaultProps = {
  className: 'cursor-pointer',
}
export default AdvisorTickIcon
