import useNavigationItems from 'hooks/useNavigationItems'
import { useMemo } from 'react'

/** Select a nav item by providing a function that returns the first matching item */
export default function useNavItem(find: (item: any) => boolean) {
  const navItems = useNavigationItems()
  const navItem = useMemo(() => {
    return navItems.find(find)
  }, [navItems, find])
  return navItem
}
