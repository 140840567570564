import React from 'react'

export default function CustomSelectBoxIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13" viewBox="0 0 21 13" fill="none">
      <path
        d="M9.44013 12.0594C10.0205 12.6453 10.963 12.6453 11.5433 12.0594L20.4576 3.05938C21.038 2.47344 21.038 1.52188 20.4576 0.935944C19.8773 0.350006 18.9348 0.350006 18.3544 0.935944L10.4894 8.87657L2.62442 0.940631C2.04406 0.354693 1.10156 0.354693 0.521205 0.940631C-0.0591518 1.52657 -0.0591518 2.47813 0.521205 3.06407L9.43549 12.0641L9.44013 12.0594Z"
        fill="#5E06B9"
      />
    </svg>
  )
}
