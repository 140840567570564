import styled from 'styled-components'
import tw from 'twin.macro'

export const PageHeading = styled.div`
  ${tw`xs:text-[25px] sm:text-3xl leading-9 text-black font-primary`}
`

export const StatValue = styled.h2`
  ${tw`xs:text-[40px] md:text-[45px] leading-[92px] font-secondary lg:text-[50px] font-black text-primary-text tracking-wide`}
`

export const SectionTitle = styled.h5`
  ${tw` font-semibold leading-7 text-lg font-primary text-primary-text mb-16 uppercase`}
`

export const InprocessChart = styled.div`
  ${tw`bg-black/40 absolute top-0 left-0 bottom-0 right-0 flex z-10 flex-1 justify-center items-center font-bold text-center text-white shadow-xl`}
`
