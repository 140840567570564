import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const DataTableLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="10" y="10" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="20" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="40" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="50" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="70" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="80" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="100" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="110" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="130" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="140" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="160" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="170" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="190" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="200" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="220" rx="2" ry="2" width="140" height="2" />
        <rect x="10" y="230" rx="2" ry="2" width="140" height="10" />
        <rect x="10" y="250" rx="2" ry="2" width="140" height="2" />
      </ContentLoader>
    </>
  )
}

export default DataTableLoader
