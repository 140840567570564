import React, { FC, useMemo } from 'react'
import GoTackleLogo from 'assets/images/GoTackle-Logo.svg'
// import history from 'utils/history'
import { AppFC } from 'types'
// import { useParams } from 'react-router-dom'

export interface INavbarProps {
  leftContent?: any
  centerContent?: any
  rightContent?: any | undefined
  bottomContent?: any
  className?: string
  noCenterContent?: boolean
  noRightContent?: boolean
  logoPlacement?: 'left' | 'center' | 'right'
  /** The inclusion of a logo prop is for dependency injection purposes with storybook testing */
  logo?: string
}

/** Navigates the user to the Dashboard if logged in, or the login screen if not. */
// const useNavigateFromLogo = () => {
//   const params = useParams()

//   const navigate = useCallback(() => {
//     const path = params.tenantId ? `/${params.tenantId}/dashboard` : '/auth/login'
//     history.push(path)
//   }, [params.tenantId])

//   return navigate
// }

const LogoContent: AppFC<{ logo: string }> = ({ logo }) => {
  // const navigate = useNavigateFromLogo()
  return (
    <div onClick={() => null} className="justify-self-center h-full hover:cursor-pointer">
      <div className="relative top-1 h-full">
        <img src={logo} alt="GoTackle icon" className="h-full" />
      </div>
    </div>
  )
}

/** This is a base component that acts as scaffolding for various implementations of navbars in the app */
const Navbar: FC<INavbarProps> = ({
  leftContent,
  centerContent,
  rightContent,
  bottomContent,
  className = '',
  logoPlacement,
  noCenterContent = false,
  noRightContent = false,
  logo = GoTackleLogo,
}) => {
  const Content = useMemo(() => {
    const content = {
      left: leftContent?.() || <div />,
      center: centerContent?.() || <div />,
      right: rightContent?.() || <div />,
      bottom: bottomContent?.() || null,
    }

    if (logoPlacement) {
      content[logoPlacement] = <LogoContent logo={logo} />
    }
    return content
  }, [leftContent, centerContent, logo, logoPlacement, bottomContent, rightContent])

  return (
    <>
      <nav
        className={`bg-white grid ${
          noCenterContent && noRightContent ? 'grid-cols-1' : 'grid-cols-3'
        } z-10 px-5 py-2.5 sm:py-3.5 h-[52px] max-h-[52px] sm:h-[75px] sm:max-h-[75px] overflow-hidden ${
          !Content.bottom ? 'shadow-md relative' : ''
        } ${className}`}
      >
        <div className="flex items-center">{Content.left}</div>
        {!noCenterContent && (
          <div className="flex justify-center items-center">{Content.center}</div>
        )}
        {!noRightContent && <div className="flex justify-end items-center">{Content.right}</div>}
      </nav>
      {Content.bottom && <div>{Content.bottom}</div>}
    </>
  )
}

export default Navbar
