import React from 'react'

export default function GroupChatIcon({ className = '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="83" height="63" viewBox="0 0 83 63" fill="none">
      <path
        d="M58 54.1252V62.3752H0.25V54.1252C0.25 54.1252 0.25 37.6252 29.125 37.6252C58 37.6252 58 54.1252 58 54.1252ZM43.5625 14.9377C43.5625 12.0822 42.7158 9.29091 41.1293 6.91667C39.5429 4.54244 37.2881 2.69195 34.65 1.59921C32.0119 0.506467 29.109 0.220556 26.3084 0.77763C23.5078 1.3347 20.9353 2.70974 18.9161 4.72886C16.897 6.74798 15.522 9.3205 14.9649 12.1211C14.4078 14.9217 14.6937 17.8246 15.7865 20.4627C16.8792 23.1008 18.7297 25.3556 21.104 26.9421C23.4782 28.5285 26.2695 29.3752 29.125 29.3752C32.9541 29.3752 36.6263 27.8541 39.3339 25.1466C42.0414 22.439 43.5625 18.7668 43.5625 14.9377ZM57.7525 37.6252C60.2883 39.5877 62.3633 42.082 63.8314 44.9327C65.2995 47.7834 66.1251 50.9211 66.25 54.1252V62.3752H82.75V54.1252C82.75 54.1252 82.75 39.1515 57.7525 37.6252ZM53.875 0.500217C51.0357 0.484407 48.2589 1.33328 45.9137 2.93397C48.4194 6.43501 49.7667 10.6324 49.7667 14.9377C49.7667 19.243 48.4194 23.4404 45.9137 26.9415C48.2589 28.5421 51.0357 29.391 53.875 29.3752C57.7041 29.3752 61.3763 27.8541 64.0839 25.1466C66.7914 22.439 68.3125 18.7668 68.3125 14.9377C68.3125 11.1087 66.7914 7.43642 64.0839 4.72886C61.3763 2.02131 57.7041 0.500217 53.875 0.500217Z"
        fill="white"
      />
    </svg>
  )
}
