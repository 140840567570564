import React, { memo, useCallback, useMemo } from 'react'
import { AppFC } from 'types'

import RightDirectionArrowIcon from 'components/Common/SvgIcons/RightDirectionArrowIcon'

const doNothing = () => {}

interface IRightArrowIconButtonProps {
  size?: 'default' | 'large'
}

const RightArrowIconButton: AppFC<IRightArrowIconButtonProps> = ({
  className = '',
  size = 'default',
  ...props
}) => {
  const handler = useCallback(() => {
    props?.onClick ? props.onClick() : doNothing()
  }, [props])

  /*
  Hover and active classes are only applied if a click listener is present
  This allows the component to be used presentationally (not as a button)
  This is only done as a short-term measure to prevent duplicated code
  related to right arrow icons inside a purple circle, which have already
  been defined in multiple places at time of writing.
  */
  const classes = useMemo(() => {
    const dimensions = {
      default: 'h-6 w-6 min-h-6 min-w-6',
      large: 'h-8 w-8 min-h-8 min-w-8',
    }[size]

    const interactionStates = props?.onClick
      ? 'hover:bg-primary-brand active:bg-primary-brand hover:cursor-pointer'
      : ''

    return `${dimensions} ${interactionStates} bg-primary rounded-full flex-shrink-0 flex justify-center items-center hover:bg-primary-brand ${className}`
  }, [className, props?.onClick, size])

  return (
    <div onClick={handler} className={classes}>
      <RightDirectionArrowIcon className="w-5/6" />
    </div>
  )
}

export default memo(RightArrowIconButton)
