import React from 'react'

function CircleNotchIcon({ className, width = '21', height = '22' }) {
  return (
    <svg
      data-testid="svg-element"
      className={`${className} animate-spin align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.13418 1.81863C9.33926 2.51303 8.94551 3.24393 8.24824 3.45065C4.9957 4.41903 2.625 7.43572 2.625 10.9631C2.625 15.3476 6.11543 18.8381 10.5 18.8381C14.8477 18.8381 18.375 15.3476 18.375 10.9631C18.375 7.43572 16.0043 4.41903 12.7518 3.45065C12.0545 3.24393 11.6607 2.51303 11.8658 1.81863C12.075 1.12383 12.8051 0.728111 13.4982 0.934748C17.8377 2.22551 21 6.20526 21 10.9631C21 16.7996 16.2996 21.4631 10.5 21.4631C4.70039 21.4631 0 16.7996 0 10.9631C0 6.20526 3.16436 2.22551 7.50176 0.934748C8.19492 0.728111 8.925 1.12383 9.13418 1.81863Z" />
    </svg>
  )
}
export default CircleNotchIcon
