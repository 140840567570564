import React, { useMemo } from 'react'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import Piovt from 'assets/images/piovt.png'
import { BarContainer } from './styles'

const TopSearchResults = ({ title, value, updatedDate, handleRefresh }) => {
  const pivotPosition = useMemo(() => {
    if (value <= 6) return `calc(${value}% + 20px)`
    if (value > 7 && value <= 10) return `calc(${value}% + 40px)`
    if (value > 10 && value <= 50) return `calc(${value}% + 40px)`
    if (value > 50 && value <= 92) return `calc(${value}% + 40px)`
    if (value > 92) return `100%`
    return `calc(${value || 0}% + 20px)`
  }, [value])

  return (
    <div className="totalSale bg-white xs:p-4 xxl:p-6 h-[450px] flex flex-col">
      <h5 className="font-semibold leading-7 text-lg font-primary text-primary-text mb-16">
        {title}
      </h5>
      <div className="flex flex-col items-center h-full">
        <h2 className="xs:pb-2 md:pb-4 :xs:text-[40px] md:text-[60px] leading-[92px] font-secondary lg:text-[80px] font-black text-primary-text tracking-wide">
          #{value || 0}
        </h2>
        <div className="font-primary xs:pb-3 md:pb-6 text-primary-text text-sm ">
          average position
        </div>

        <BarContainer className="barContainer">
          <div className="slider">
            <div style={{ width: `${pivotPosition}` }}>
              <p>
                <img className="absolute left-0 top-[-10px]" src={Piovt} alt="Piovt" />
              </p>
            </div>
          </div>
          <div className="bar">
            <div className="interval-1-5"></div>
            <div className="interval-6-10"></div>
            <div className="interval-11-50"></div>
            <div className="interval-50-100"></div>
          </div>
          <div className="value">
            <div className="interval-1-5">
              <p>Top 1-5</p>
            </div>
            <div className="interval-6-10">
              <p>6-10</p>
            </div>
            <div className="interval-11-50">
              <p>11-50</p>
            </div>
            <div className="interval-50-100">
              <p>Greater than 50</p>
            </div>
          </div>
        </BarContainer>
        <SectionFooter lastUpdated={updatedDate || ''} getChartDataAction={handleRefresh} />
      </div>
    </div>
  )
}

export default TopSearchResults
