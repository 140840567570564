import React, { useMemo } from 'react'
import { AppFC } from 'types'

type HTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
export interface IHeadingProps {
  tag?: HTag
  size?: 'smallest' | 'small' | 'smaller' | 'default' | 'large'
  variant?: 'primary' | 'secondary'
}

type HTagRenderFunction = (props: { children: any; className: string }) => JSX.Element

const hTags: { [key in HTag]: HTagRenderFunction } = {
  h1: ({ children, className }) => <h1 className={className}>{children}</h1>,
  h2: ({ children, className }) => <h2 className={className}>{children}</h2>,
  h3: ({ children, className }) => <h3 className={className}>{children}</h3>,
  h4: ({ children, className }) => <h4 className={className}>{children}</h4>,
  h5: ({ children, className }) => <h5 className={className}>{children}</h5>,
}

const sizes = {
  smallest: 'text-lg',
  small: 'text-xl',
  smaller: 'text-2xl',
  default: 'text-4xl',
  large: 'text-5xl',
}
const variants = {
  primary: 'font-primary font-medium leading-8',
  secondary: 'font-secondary font-extrabold',
}

export const useTag = (tag: HTag) => {
  const Tag = useMemo(() => hTags[tag], [tag])
  return Tag
}

const Heading: AppFC<IHeadingProps> = ({
  children,
  tag = 'h1',
  className = '',
  size = 'default',
  variant = 'secondary',

  ...props
}) => {
  const Tag = useTag(tag)
  const classes = useMemo(
    () => ` ${sizes[size]} ${variants[variant]} ${className}`,
    [className, size, variant]
  )
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  )
}

export default Heading
