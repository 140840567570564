import React from 'react'
import { Columns } from 'types'
import { TableActionsContainer } from 'pages/Common/container'
import UserTableHeader from 'components/Common/UserTableHeader'

export const licenseLabels = {
  filled: 'SEATS\nFILLED',
  unFilled: 'SEATS\nUNFILLED',
  total: 'TOTAL\nLICENSES',
  advisorCountsLabel: 'ADVISORS',
  adminCountsLabel: 'ADMIN',
}

export const modalBoxText = {
  heading: "You've reached your license limit.",
  text: 'Add extra seats to your license to create a new business owner account or re-enable a business owner account.',
}

export const defaultPaginationValues = {
  noOfItemsPerPage: 5,
  currentPage: 1,
  search: 'all',
  filterTypes: {
    status: 'status',
    advisor: 'advisor',
    search: 'search',
  },
}

export const noOfItemsPerPageOptions = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
]

export const sortValues = {
  createdAtDesc: 'createdAt_desc',
  createdAtAsc: 'createdAt_asc',
  activatedAtDesc: 'activatedAt_desc',
  activatedAtAsc: 'activatedAt_asc',
  nameDesc: 'name_desc',
  nameAsc: 'name_asc',
  businessNameDesc: 'businessName_desc',
  businessNameAsc: 'businessName_asc',
}

export const licenseErrorMessage = 'License limit has been reached'

export const sortFields = [
  { label: 'Name', value: 'name' },
  { label: 'Business Name', value: 'businessName' },
  { label: 'Date Created', value: 'createdAt', fieldType: 'date' },
  { label: 'Status', value: 'status' },
]

export const columns: Columns = [
  {
    text: 'records',
    tableType: 'user',
    width: 260,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'businessName',
        type: 'string',
        headerName: 'Business Name',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <p className="font-semibold"> {params?.name || '(No name provided)'}</p>
          <div className="break-words">{params?.businessName || '(No business name provided)'}</div>
        </UserTableHeader>
      )
    },
  },
  {
    width: 160,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'created',
        type: 'date',
        headerName: 'Created',
        sortable: true,
      },
      {
        field: 'activatedSort',
        type: 'string',
        headerName: 'Activated',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <div>{params?.created}</div>
          <div className="capitalize">
            {params?.activatedSort?.startsWith('active') ? params?.activatedAt : params?.activated}
          </div>
        </UserTableHeader>
      )
    },
  },
  {
    width: 200,
    textDirection: 'left',
    colSpan: 'col-span-2',
    direction: 'items-center',
    colFields: [
      {
        field: 'advisors',
        type: 'string',
        headerName: 'Advisor',
        paddingTop: 'pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className={'!whitespace-pre-wrap col-span-2'}>
          {params?.activated === 'inactive' ? '' : params?.advisors}
        </UserTableHeader>
      )
    },
  },
  {
    textDirection: 'right',
    colSpan: 'col-span-4',
    direction: 'justify-end',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="p-3 text-sm text-grey whitespace-nowrap self-center text-right col-span-4">
          <TableActionsContainer userData={params} />
        </td>
      )
    },
  },
]
