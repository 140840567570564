import React from 'react'

const UnlockedIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="20.5" fill="#F4F4F5" stroke="#27272A" strokeWidth="2" />
      <g clipPath="url(#clip0_4220_32184)">
        <path
          d="M24.0635 18.625H24.7937C26.8107 18.625 28.4444 20.2332 28.4444 22.2188V29.4062C28.4444 31.3918 26.8107 33 24.7937 33H11.6508C9.63464 33 8 31.3918 8 29.4062V22.2188C8 20.2332 9.63464 18.625 11.6508 18.625H22.6032V15.75C22.6032 12.5745 25.2181 10 28.4444 10C31.6708 10 34.2857 12.5745 34.2857 15.75V19.3438C34.2857 19.7391 33.9571 20.0625 33.5556 20.0625C33.154 20.0625 32.8254 19.7391 32.8254 19.3438V15.75C32.8254 13.3682 30.8631 11.4375 28.4444 11.4375C25.9847 11.4375 24.0635 13.3682 24.0635 15.75V18.625ZM11.6508 20.0625C10.441 20.0625 9.46032 21.0283 9.46032 22.2188V29.4062C9.46032 30.5967 10.441 31.5625 11.6508 31.5625H24.7937C26.003 31.5625 26.9841 30.5967 26.9841 29.4062V22.2188C26.9841 21.0283 26.003 20.0625 24.7937 20.0625H11.6508Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4220_32184">
          <rect width="26.2857" height="23" fill="white" transform="translate(8 10)" />
        </clipPath>
      </defs>
    </svg>
  )
}

UnlockedIcon.defaultProps = {
  className: '',
}

export default UnlockedIcon
