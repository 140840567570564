import React, { FC, ReactNode } from 'react'

import { FontSize, FontWeight } from 'types'
import styled from 'styled-components'

const StyledLabel = styled.label``

interface ILabelProps {
  title?: string
  className?: string
  children: ReactNode
  fontSize?: FontSize
  fontWeight?: FontWeight
}

const Label: FC<ILabelProps> = ({ title, className, children, fontSize, fontWeight }) => {
  return (
    <StyledLabel
      title={title}
      className={`${className} text-primary-text font-primary ${fontWeight ?? 'font-medium'} ${
        fontSize ? `text-${fontSize}` : `xs:text-base sm:text-xl`
      }`}
    >
      {children}
    </StyledLabel>
  )
}

Label.defaultProps = {
  className: '',
  title: '',
}

export default Label
