import React, { FC, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordSchema } from 'pages/Auth/validation'
import { scrollToTop } from 'utils/helper'

import Button from 'components/Common/Button'
import PasswordFields from 'components/Common/PasswordFields'
import AuthCardContainer from 'components/Common/AuthCardContainer'
import { getWatchFields } from 'components/Common/PasswordFields/helper'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { STATUS } from 'config'
import ErrorMessage from 'components/Common/ErrorMessage'

interface IChangePassword {
  changePasswordAction: (password: string) => void
  status: string
  errorMessage: string
}
const ChangePassword: FC<IChangePassword> = ({ changePasswordAction, status, errorMessage }) => {
  useEffect(() => {
    scrollToTop()
  }, [])

  const history = useHistory()

  useEffect(() => {
    if (status === STATUS.COMPLETED && !errorMessage) {
      const accountPath =
        history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/')) + '/account'

      history.push({
        pathname: '/auth/success',
        state: { type: 'passwordChanged', next: { title: 'Account Settings', url: accountPath } },
      })
    }
    // eslint-disable-next-line
  }, [status, history])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(resetPasswordSchema, { abortEarly: false }),
  })

  const {
    watch,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = methods

  const Fields = getWatchFields(watch)

  const onSubmit = (password) => {
    delete password.confirmPassword
    changePasswordAction(password)
  }

  return (
    <div className="grid-flow-row auto-rows-max pt-6 w-full">
      <div className="xs:ml-0 md:ml-12">
        <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
          <div className="flex-initial">
            <ChevronLeftIcon className={`h-7 w-7 inline mt-1 mr-2 text-primary`} />
          </div>
          <div className="flex-initial pl-2">
            <p className="text-[18px] mt-1 text-primary font-primary">ACCOUNT SETTINGS</p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="max-w-[400px] items-center justify-center bg-transparent sm:bg-transparent md:bg-transparent">
          <div className="w-full">
            <div className="xs:text-2xl sm:text-4xl text-black-light font-secondary font-black text-center pb-4">
              Change Password
            </div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <AuthCardContainer passwordCheck={Fields.passwordCheck} showPasswordRules={true}>
                    <div className="pb-3">
                      <PasswordFields
                        errors={errors}
                        trigger={trigger}
                        register={register}
                        confirmPasswordCheck={Fields.confirmPasswordCheck}
                      />
                      {errorMessage && (
                        <ErrorMessage id="errorMessage">{errorMessage}</ErrorMessage>
                      )}
                      <div className="mt-3">
                        {/* check-button -- clean up custom styles */}
                        <Button
                          disabled={!isEmpty(errors)}
                          type="submit"
                          status={status}
                          label="Reset Password"
                          variant={'primary'}
                          className="xs:text-2xl sm:text-3xl w-full"
                          fontSize="3xl"
                        />
                      </div>
                      <div className="mt-[6px]">
                        <Button
                          onClick={() => history.goBack()}
                          type="button"
                          label="Cancel"
                          variant="secondary"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </AuthCardContainer>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
