import PadLockIcon from 'components/Common/SvgIcons/PadLockIcon'
import React from 'react'

import { ContainerWrapper } from './styles'

const RecommendableLockedIcon = () => {
  return (
    <ContainerWrapper>
      <PadLockIcon />
    </ContainerWrapper>
  )
}

export default RecommendableLockedIcon
