import React from 'react'

function PreLaunchIcon({ className }) {
  return (
    <svg
      width="55"
      height="60"
      viewBox="0 0 55 60"
      className={className}
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19820_5418)">
        <path d="M36.7055 30.6563C35.6117 36.3229 32.0644 45.6582 30.0439 50.6867H24.7268C22.7747 45.6354 19.3489 36.3153 18.2627 30.6563C16.0295 18.9739 22.0455 11.4236 27.4917 7.25345C32.9456 11.416 38.9539 18.9739 36.7131 30.6487L36.7055 30.6563ZM19.4781 51.3779C19.8123 52.259 20.1161 53.049 20.3744 53.7022C20.8833 55.0011 22.1366 55.8443 23.5343 55.8443L31.2365 55.8443C32.6189 55.8443 33.8647 55.0239 34.3812 53.7478C34.6698 53.049 35.0116 52.2058 35.3838 51.2563L42.6911 58.5636C43.3443 59.2168 44.3014 59.4751 45.1977 59.232C46.094 58.9889 46.7852 58.2825 47.0131 57.3862L49.7476 46.676C50.4237 44.0251 49.6565 41.2222 47.7271 39.2928L41.4757 33.0414C41.5897 32.5477 41.6884 32.0844 41.7795 31.6286C44.7343 16.2318 35.7712 6.66097 29.2236 2.14902C28.1829 1.42741 26.8081 1.43501 25.7599 2.14902C19.2122 6.66097 10.2491 16.2318 13.2039 31.6286C13.295 32.0844 13.3862 32.5553 13.5077 33.0414L7.2715 39.2777C5.34214 41.207 4.56737 44.0175 5.25099 46.6608L7.98551 57.371C8.21338 58.2673 8.90461 58.9738 9.80092 59.2168C10.6972 59.4599 11.6467 59.2092 12.3 58.556L19.4781 51.3779ZM30.5301 18.4498C29.7242 17.6439 28.6313 17.1912 27.4917 17.1912C26.3521 17.1912 25.2592 17.6439 24.4534 18.4498C23.6475 19.2556 23.1948 20.3485 23.1948 21.4881C23.1948 22.6277 23.6475 23.7206 24.4534 24.5265C25.2592 25.3323 26.3521 25.785 27.4917 25.785C28.6313 25.785 29.7242 25.3323 30.5301 24.5265C31.3359 23.7206 31.7886 22.6277 31.7886 21.4881C31.7886 20.3485 31.3359 19.2556 30.5301 18.4498Z" />
      </g>
      <defs>
        <clipPath id="clip0_19820_5418">
          <rect width="55" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

PreLaunchIcon.defaultProps = {
  className: '',
}
export default PreLaunchIcon
