import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DataTable from 'components/Common/DataTable'

import {
  AddCommentsContainer,
  DateTimeContainer,
  CommentsContainer,
  MeetingEventsContainer,
} from 'pages/Clients/container'

const previousMeetingColumns = [
  {
    text: 'previous meetings',
    tableType: 'Event',
    display: 'grid grid-cols-12',
    width: 10,
    textDirection: 'left',
    paddingLeft: 'md:pl-[48px]  lg:pl-[90px]',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'WHEN',
        type: 'string',
        headerName: 'WHEN',
      },
    ],
    renderCell: (params) => {
      return <DateTimeContainer data={params} />
    },
  },
  {
    width: 200,
    textDirection: 'left',
    paddingLeft: 'pl-[18px]',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'TITLE',
        type: 'string',
        headerName: 'TITLE',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="pb-3 pt-[15px] pl-[34px] font-primary text-lg text-black-light col-span-4 break-words capitalize">
          {params?.title}
        </td>
      )
    },
  },
  {
    width: 50,
    textDirection: 'left',
    colSpan: 'col-span-1',
    colFields: [
      {
        field: 'NOTES',
        type: 'string',
        headerName: 'NOTES',
      },
    ],
    renderCell: (params) => {
      return <AddCommentsContainer data={params} />
    },
  },
]

const upcomingMeetingColumns = [
  {
    text: 'upcoming meetings',
    tableType: 'Event',
    display: 'grid grid-cols-12',
    width: 10,
    textDirection: 'left',
    paddingLeft: 'pl-[90px]',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'WHEN',
        type: 'string',
        headerName: 'WHEN',
      },
    ],
    renderCell: (params) => {
      return <DateTimeContainer data={params} />
    },
  },
  {
    width: 200,
    textDirection: 'left',
    paddingLeft: 'pl-[18px]',
    colSpan: 'col-span-3',
    colFields: [
      {
        field: 'TITLE',
        type: 'string',
        headerName: 'TITLE',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="pb-3 pt-[15px] pl-[34px] font-primary text-lg text-black-light col-span-3 break-words capitalize">
          {params?.title}
        </td>
      )
    },
  },
  {
    width: 50,
    textDirection: 'left',
    colSpan: 'col-span-1',
    colFields: [
      {
        field: 'NOTES',
        type: 'string',
        headerName: 'NOTES',
      },
    ],
    renderCell: (params) => {
      return <AddCommentsContainer data={params} />
    },
  },
  {
    textDirection: 'right',
    colSpan: 'col-span-4 justify-end',
    padding: 'py-3 pl-3 pr-[23px]',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end items-center',
      },
    ],
    renderCell: (params) => {
      return <MeetingEventsContainer data={params} />
    },
  },
]

const UpcomingMeeting = ({ meeting, getTackleMeetingEventsAction, filter, meetingDataLoading }) => {
  let { clientId } = useParams()

  const [pageSize, setPageSize] = useState(5)

  useEffect(() => {
    getTackleMeetingEventsAction({ filter: filter, ownerId: clientId })
  }, [filter, getTackleMeetingEventsAction, clientId])

  return (
    <div className=" w-full">
      <DataTable
        pageSize={pageSize}
        columns={filter.includes('upcoming') ? upcomingMeetingColumns : previousMeetingColumns}
        rows={meeting}
        pagination={true}
        setPageSize={setPageSize}
        getRecords={() => {}}
        ExpandedComponent={CommentsContainer}
        loaderType="MeetingLoader"
        isLoading={meetingDataLoading}
      />
    </div>
  )
}

export default UpcomingMeeting
