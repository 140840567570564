import styled from 'styled-components'
import tw from 'twin.macro'

export const CardWrapper = styled.div`
  ${tw`p-3 sm:p-6 rounded-[20px] flex flex-col gap-2`}
`

export const TitleWrapper = styled.section`
  ${tw`font-secondary text-xl relative left-0.5 sm:left-0 -top-0.5 sm:static`}
`
