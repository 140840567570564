import React from 'react'

function NotePadSimpleIcon({ className }) {
  return (
    <svg
      className={className}
      width="19"
      height="25"
      viewBox="0 0 19 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9752 6.11817L12.7347 0.917974C12.1393 0.329204 11.3421 4.88281e-06 10.5056 4.88281e-06L3.14922 0C1.40977 0 0 1.39893 0 3.125L0.000319843 21.875C0.000319843 23.6011 1.41009 25 3.14954 25H15.7461C17.4856 25 18.8953 23.6011 18.8953 21.875V8.3252C18.8953 7.49512 18.5657 6.70411 17.9752 6.11817ZM11.0223 1.66407C11.2426 1.74053 11.4487 1.85186 11.6187 2.02046L16.8592 7.22066C17.0304 7.3877 17.1436 7.59278 17.2174 7.81251H11.8096C11.3766 7.81251 11.0223 7.46094 11.0223 7.03126V1.66407ZM17.3207 21.875C17.3207 22.7363 16.6141 23.4375 15.7461 23.4375H3.14922C2.28122 23.4375 1.57461 22.7363 1.57461 21.875V3.125C1.57461 2.26368 2.28122 1.5625 3.14922 1.5625H9.44767V7.03126C9.44767 8.3252 10.5056 9.375 11.8096 9.375H17.3207V21.875ZM4.72384 13.2813C4.72384 13.7109 5.07813 14.0625 5.51114 14.0625H13.3842C13.8172 14.0625 14.1715 13.7109 14.1715 13.2813C14.1715 12.8516 13.8172 12.5 13.3842 12.5H5.51114C5.07813 12.5 4.72384 12.8516 4.72384 13.2813ZM13.3842 15.625H5.51114C5.07813 15.625 4.72384 15.9766 4.72384 16.4063C4.72384 16.8359 5.07813 17.1875 5.51114 17.1875H13.3842C13.8191 17.1875 14.1715 16.8377 14.1715 16.4063C14.1715 15.9748 13.8172 15.625 13.3842 15.625ZM13.3842 18.75H5.51114C5.07813 18.75 4.72384 19.1016 4.72384 19.5313C4.72384 19.9609 5.07813 20.3125 5.51114 20.3125H13.3842C13.8191 20.3125 14.1715 19.9627 14.1715 19.5313C14.1715 19.0998 13.8172 18.75 13.3842 18.75Z"
        fill="#7600ED"
      />
    </svg>
  )
}

export default NotePadSimpleIcon
