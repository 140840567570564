import React from 'react'
import { getRoleFromPath, navigateTo } from 'utils/helper'
import BackButtonArrowIcon from 'components/Common/SvgIcons/BackButtonArrowIcon'

const ClientDetailHeader = () => {
  const userRole = getRoleFromPath()

  return (
    <>
      <div className="bg-white w-full p-0 flex items-center justify-between fixed top-0 z-40 border-b border-primary-disabled gap-10 text-sm xs:h-[55px] sm:h-[72px] xs:left-0 md:h-[80px] mdl:invisible lg:invisible">
        <div className="flex items-center w-full xs:px-3">
          <span onClick={() => navigateTo(userRole, 'clients')}>
            <BackButtonArrowIcon />
          </span>
          <div className="ml-[36px] text-xl text-normal">Client Details</div>
          {/* May be we need it later */}
          {/* <div>
            <img
              src={CrossIcon}
              alt="cross-icon"
              onClick={() => {
                if (window.location.href.includes('advisor')) {
                  history.push(`/advisor/${tenantId}/clients`)
                } else {
                  history.push(`/admin/${tenantId}/clients`)
                }
              }}
            />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ClientDetailHeader
