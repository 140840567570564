import React from 'react'

import { isEmpty } from 'lodash'
import { Controller } from 'react-hook-form'
import { ANNOUNCEMENT_NAMES, ANNOUNCEMENT_FIELD, BUTTON_NAME, FORM } from 'config'

import InputTextArea from 'components/Common/InputTextArea'
import { DropDownArrowIcon } from 'components/Common/SvgIcons'
import {
  ModalBoxHeaderWrapper,
  TextWrapper,
  ReceiverButtonWrapper,
  HorizontalBreak,
} from 'components/NotificationPanel/style'
import Button from 'components/Common/Button'
import hookForms from 'utils/hookForms'

const PublishAnnouncement = ({ control, handleSubmit, onSubmit, isFetchingResponse }) => {
  const formValues = hookForms.getForm(FORM.ANNOUNCEMENT_FORM)?.getValues()

  return (
    <>
      <ModalBoxHeaderWrapper>
        <TextWrapper>To:</TextWrapper>
        <ReceiverButtonWrapper onClick={() => {}}>
          <Button
            variant="text"
            label={ANNOUNCEMENT_NAMES.SEND_TO_ALL_BO}
            className="text-tiny leading-[18px] font-[650] ml-6 focus:!border-b-0 hover:cursor-default"
          />
          <DropDownArrowIcon />
        </ReceiverButtonWrapper>
      </ModalBoxHeaderWrapper>
      <div className="px-6">
        <Controller
          name={ANNOUNCEMENT_FIELD.TEXT}
          control={control}
          render={({ field }) => (
            <InputTextArea
              {...field}
              disabled={isFetchingResponse}
              className="border-none resize-none h-44"
              placeholder={ANNOUNCEMENT_NAMES.PLACEHOLDER}
            />
          )}
        />
      </div>
      <HorizontalBreak />
      <div className="flex justify-end px-8 py-7">
        <Button
          label={BUTTON_NAME.PUBLISH}
          disabled={isEmpty(formValues[ANNOUNCEMENT_FIELD.TEXT]) ? true : false}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </>
  )
}

export default PublishAnnouncement
