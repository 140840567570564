import React from 'react'

function FreshBooksIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
    >
      <rect width="50.9091" height="50" fill="url(#patternfreshBook)" />
      <defs>
        <pattern id="patternfreshBook" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image0_19089_141441"
            transform="matrix(0.00444444 0 0 0.00452525 0 -0.00909092)"
          />
        </pattern>
        <image
          id="image0_19089_141441"
          width="225"
          height="225"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEUAdd3///8Ac90AcdwAbtz7/f+av+4AbNsAadujxO/F2/VWmOUee96WvO7r8/wggeDy+P0AeN7N4Pdjneauy/F4qum10PLC2PXl7/u+1vSGsetroufz+v5FkOPW5/kvheAAYtmMtuw9iOFQleRhoed7rOnC5f5NAAAE8ElEQVR4nO3d23LaMBAGYEcSSIAJtjkkgQAlad7/FVsyTZtkir2SVtKuZ/9rZuQP2zrZlqpKIpFIJBKJRCKRSCQSiUQikUgkEolEwj1KG2Odc9boxCmA08ba6rhd7uan+0n6ZNe5+jDr7u/yReXk2Wq9yYm7ZpJNqK3azjPrcgqNWZfg5RIqW02bMr4sQuXOu1K8HEJlVw8FfemFpi55/tILtVkW9iUWukWx+iWL0LSF2oevSSd0h0lp3HtSCZUpXcN8JJHQrB5Lyz6SRmjXpV3/kkToLqVZn5JC6DalVZ+TQOhINBJ/gy+0XWnT16AL7ak06VuwhY7YGUQXAu7BbnYr/xklN91uc/P3JYSQWnTqzI38+DZJ1b2drbW3fm0srFeIKrQXQIlTc/P/+SycTCun+w8OBEQVGlBPBiacqps/KydUK1CJEOFpZYfLa7MLDayzDRBuHOSosgsdcLg0LNw7UIG5heYAK3BYuAVcoRX4psATttAR/ZDwAjuDlXrJK4R3tweET0BgpYFjUCQhtLhBYQN+oGkueYXPSMIDWGiBU+k4QgvsIw4KT9Br9PevgXOxKEJotTYsPIIPRx2B5aEInc+zlx5h8wg/hRY6VYIhhNbbw8L9cGf0b6CtE4bQb9TbJ2zBZZoptDwEod8p7BHaOaw3c40Gl4cgtE9IQr0ENxUOXiaCENgDHhaqA/RgYCNRLKHxaAv7hdUZWKSqPcpDEHo+Be0RAisapX3eOooWauioCSCEAr2ea0ULvSdIY4Wm9ntvLFroWc9EC93a89FyrNDsswpN5dc0IQj9n8OEC5XV4J4MntD7Ig0TKm2sOQY9l4wUetekYcJ2tb7MA9/NiRQGvHHR1/MOM/QnUhhwUMDnFmiJE3oN7v+ElzDgNmQmBE8lfAozYcBDe15C33HFNbyEPuO0j7ASqkVAiayE2rvbfcdM6DuB8R5WQujDkS/hJQx5AYqV0IW8w8ZLGDIYYCUEvnf1NayEZvRCPXqhQhbSG+MjC9Xroj8/uQsr1R8dMKNATDhUnAhFKEIRilCEIhShCEUoQhGKUIQiFKEI8wjbujftmbswzWoMpIRJFtQQ4QiEMUu08hDGvJPMQwj/Ho6psBm90OObRqbCDv5BHFPh0+iFs9HXpfvRt4eL0fdp6gggC+Fz1IdkHISnmKqUhXAzeuE2avcHDsJV3EdB9IVR/W4Wwl3UbchBCF/Rh6sQvpYIU6HHmkVMhT4L3vAURl6k9IVd5EVKXxhZk9IXNrGLjJAXzuKaewbC2HqGvDBqlo2F8AVhHTPSwnlsU0FeeMZYTpCyEOEuJC6MG9wzEE4xTiFlYXx3hrpwjbPBKl0heEVprsJnrC1yyQoXYxfOkK5RssJHnHo0WLi8vQsL0naeNeKeHSHCbnkrF5zd6F4Rd+IOEqbOG0pnhrAQr5YhKtyhAgkKH3CB9ITRM8DUhdhnkJwQ+R6kJ5ziA2kJ3xIAKQknR8yGnqDwVCF21SgKZzbV1tQ0hM0ixS1ISLjTaa5QKsL712QnkIZwadOdQArCXY03YUFR+HBOVYXSED6dQXt5chU2szb5+SspnB9M4vuvqLDbVy5p/VlU+Lw7tGmbh+/CnLjTZvvisvKumaRP83iaby7r2lg7sE13kujksc5d91cvgZNIJBKJRCKRSCQSiUQikUgkEolEIpHg5heELIM2mN2eaAAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

FreshBooksIcon.defaultProps = {
  className: '',
}
export default FreshBooksIcon
