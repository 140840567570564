import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const MeetingLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 150"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="0" rx="0" ry="0" width="450" height="18" />
        <rect x="8" y="33" rx="0" ry="0" width="5" height="5" />
        <rect x="37" y="26" rx="0" ry="0" width="66" height="6" />
        <rect x="37" y="39" rx="0" ry="0" width="66" height="6" />
        <rect x="304" y="26" rx="0" ry="0" width="28" height="18" />

        <rect x="0" y="49" rx="0" ry="0" width="450" height="1" />
        <rect x="8" y="63" rx="0" ry="0" width="5" height="5" />

        <rect x="37" y="57" rx="0" ry="0" width="66" height="6" />
        <rect x="37" y="69" rx="0" ry="0" width="66" height="6" />

        <rect x="304" y="56" rx="0" ry="0" width="28" height="18" />

        <rect x="0" y="80" rx="0" ry="0" width="450" height="1" />
        <rect x="8" y="96" rx="0" ry="0" width="5" height="5" />
        <rect x="37" y="87" rx="0" ry="0" width="66" height="6" />
        <rect x="37" y="100" rx="0" ry="0" width="66" height="6" />
        <rect x="304" y="87" rx="0" ry="0" width="28" height="18" />

        <rect x="0" y="112" rx="0" ry="0" width="450" height="1" />
        <rect x="8" y="126" rx="0" ry="0" width="5" height="5" />
        <rect x="37" y="120" rx="0" ry="0" width="66" height="6" />
        <rect x="37" y="132" rx="0" ry="0" width="66" height="6" />
        <rect x="304" y="118" rx="0" ry="0" width="28" height="18" />

        <rect x="0" y="142" rx="0" ry="0" width="450" height="1" />
      </ContentLoader>
    </>
  )
}

export default MeetingLoader
