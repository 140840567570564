import React from 'react'

export default function AssessmentIcon() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="35" height="31" rx="2" fill="#18181B" />
      <rect x="0.5" y="0.5" width="33" height="30" rx="1.5" fill="white" stroke="#18181B" />
      <path
        d="M23 22.1L25.5 23.6L24.8 20.8L27 18.9L24.1 18.7L23 16L21.9 18.6L19 18.9L21.2 20.8L20.5 23.6L23 22.1ZM9 17H17V19H9V17ZM9 9H21V11H9V9ZM9 13H21V15H9V13Z"
        fill="black"
      />
    </svg>
  )
}
