import { ITabOption } from 'components/Common/ButtonTabs'

export enum CLIENT_STATUS {
  SIGNED = 'Signed',
  NOT_SIGNED = 'Not Signed',
  UNSIGNED = 'UNSIGNED',
}

export interface ExtendedITabOption extends ITabOption {
  value: string
}

export const tabsOptions: ExtendedITabOption[] = [
  { label: CLIENT_STATUS.NOT_SIGNED, value: CLIENT_STATUS.UNSIGNED, link: 'Clients Not Signed' },
  { label: CLIENT_STATUS.SIGNED, value: CLIENT_STATUS.SIGNED, link: 'Clients Signed' },
]
