import React, { FC } from 'react'
import { includes } from 'lodash'

import { EllipseIcon, TickIcon } from 'components/Common/SvgIcons'
import * as Icons from 'components/Common/SvgIcons'
import { sanitize } from 'dompurify'

interface ITileSelectProps {
  value: string[] | string
  options: {
    value?: string
    icon?: string
    title?: string
    label?: string
    description?: string
  }[]
  onChange: (input) => void
  isMulti: boolean
  id?: string
}

const TileSelect: FC<ITileSelectProps> = ({ value, options, onChange, isMulti }) => {
  const handleSelect = (option) => {
    onChange(option)
  }
  return (
    <>
      {options?.map((option, index) => {
        const OptionIcon = option.icon && Icons[option.icon]
        return (
          <div className="w-full" key={index}>
            {option?.title && (
              <div className="text-xs font-bold	text-left pt-8 pb-2 ">
                <label className="text-primary-brandBlueLight">{option?.title}</label>
              </div>
            )}
            <div
              key={option.value}
              className={`p-6 min-h-[68px] group bg-cream-dark active:border active:border-tertiary rounded-[20px] h-full my-2 hover:border-1 cursor-pointer flex flex-col justify-center ${
                includes(value, option.value)
                  ? ' bg-opacity-25 border border-tertiary focus:border focus:border-tertiary active:border active:border-tertiary bg-tertiary  shadow-md rounded-[20px] text-black-light'
                  : 'text-silver  border border-cream-dark  hover:border-tertiary hover:border hover:border-1'
              }`}
              onClick={() => handleSelect(option.value)}
            >
              <div className="flex justify-between gap-8">
                {OptionIcon && (
                  <OptionIcon
                    className={` ${
                      includes(value, option.value) ? 'fill-tertiary' : 'fill-silver'
                    }`}
                  />
                )}
                {/* {isMulti && (
                  <span className="inline-flex">
                    <EllipseIcon
                      className={`rounded-full h-[25px] w-[25px] ${
                        includes(value, option.value)
                          ? 'fill-tertiary'
                          : 'fill-primary-disabledText opacity-50'
                      }`}
                    />
                  </span>
                )} */}
                <span
                  className={`inline-flex w-full flex-col pr-8 ${
                    OptionIcon && <OptionIcon /> ? 'pl-4' : ''
                  } gap-y-2`}
                >
                  <span
                    className={`font-primary text-base peer-checked:block ${
                      includes(value, option.value) ? 'text-black-light' : 'text-silver'
                    } leading-6 text-left font-semibold`}
                  >
                    {option.label}
                  </span>
                  {/* {isMulti && ( */}
                  <p
                    className="font-primary text-sm font-normal peer-checked:block leading-6 text-left text-silver"
                    dangerouslySetInnerHTML={{ __html: sanitize(option.description) }}
                  />
                  {/* )} */}
                </span>

                {isMulti && (
                  <span className="relative h-[25px] w-[25px]">
                    <EllipseIcon
                      className={`rounded-full h-[25px] w-[25px] ${
                        includes(value, option.value)
                          ? 'fill-tertiary'
                          : 'fill-primary-disabledText opacity-50'
                      }`}
                    />
                    <TickIcon
                      className={`${
                        includes(value, option.value)
                          ? 'stroke-[12px] stroke-white w-[15px]'
                          : 'stroke-0 w-0'
                      } absolute top-[5px] left-1/2  -translate-x-2/4`}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

TileSelect.defaultProps = {
  isMulti: false,
}
export default TileSelect
