import React from 'react'

const TickCircleIcon = ({ className }) => {
  return (
    <svg
      width="43"
      height="43"
      className={className}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="20.5" fill="#F4F4F5" stroke="#27272A" strokeWidth="2" />
      <g clipPath="url(#clip0_8379_80014)">
        <path
          d="M32.7587 14.1139C33.0821 14.4287 33.0821 14.9467 32.7587 15.2616L18.7944 29.0741C18.4761 29.394 17.9525 29.394 17.6342 29.0741L10.2406 21.7616C9.91981 21.4467 9.91981 20.9288 10.2406 20.6139C10.5611 20.294 11.0812 20.294 11.4021 20.6139L18.2143 27.3526L31.5984 14.1139C31.9167 13.796 32.4404 13.796 32.7587 14.1139Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_8379_80014">
          <rect width="23" height="26" fill="white" transform="translate(10 9)" />
        </clipPath>
      </defs>
    </svg>
  )
}

TickCircleIcon.defaultProps = {
  className: '',
}

export default TickCircleIcon
