import tw from 'twin.macro'
import styled from 'styled-components'

export const GoalCenterWrapper = styled.div`
  ${tw`w-full max-w-full`}
`
export const GoalCenterHeading = styled.h1`
  ${tw`font-bold text-3xl`}
`
export const GoalCenterSubHeading = styled.h4`
  ${tw`font-normal text-base`}
`
