import styled from 'styled-components'

export const MultipleWrapper = styled.div`
  input[type='radio'] {
    width: 14px;
    height: 28px;
  }
  .paymentCard {
    &:hover {
      p {
        color: white;
      }
    }
  }
`

export const EarnedSectionWrapper = styled.div`
  background: linear-gradient(180deg, #5bbab9 0%, #52b4f9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  padding: 40px 20px 80px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  // margin: 3px 2.5rem;
`

export const UploadFileWrapper = styled.div`
  input[type='file'] {
    display: none;
  }
  .questionLabel {
    padding: 10px;
    background: white;
    color: #5e06b9;
  }
`
