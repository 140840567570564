import React, { FC, useEffect, useState } from 'react'

import moment from 'moment'
import { ConnectedProps } from 'react-redux'
import { launchUsetifulIfNeeded } from 'utils/helper'
import { UserTimeZoneConnector } from './UserTimeZone.connector'
import TimezoneModal from 'components/Common/UserTimeZone/TimeZoneModal/TimeZoneModal.component'
import { checkTimeZone } from 'utils/dates'

interface IUserTimeZoneConnector extends ConnectedProps<typeof UserTimeZoneConnector> {}

const UserTimeZone = UserTimeZoneConnector<FC<IUserTimeZoneConnector>>(
  ({ user, updateUserProfileAction }) => {
    const [showTimezoneModal, setShowTimezoneModal] = useState(false)

    useEffect(() => {
      if (checkTimeZone(user, moment.tz.guess())) {
        setShowTimezoneModal(true)
      }
      launchUsetifulIfNeeded()
    }, [user])

    const onTimezoneModalClose = async () => {
      updateUserProfileAction({
        interimTimezone: moment.tz.guess(),
      })
      setShowTimezoneModal(false)
      await launchUsetifulIfNeeded()
    }

    return showTimezoneModal ? (
      <TimezoneModal
        onClose={onTimezoneModalClose}
        setShowTimezoneModal={setShowTimezoneModal}
        updateUserProfileAction={updateUserProfileAction}
        defaultTimezone={user?.timezone}
      />
    ) : null
  }
)

export default UserTimeZone
