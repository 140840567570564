import {
  SHOW_BUSINESS_PROFILE,
  SHOW_MY_PROFILE,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
} from 'store/types'

export const showMyProfileAction = (myProfile) => ({
  type: SHOW_MY_PROFILE,
  payload: myProfile,
})

export const showBusinessProfileEditModeAction = (businessProfile) => ({
  type: SHOW_BUSINESS_PROFILE,
  payload: businessProfile,
})

// delete upcoming events of calender
export const getRecommendedPlaysAssessments = {
  STARTED: (payload) => ({
    type: GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_RECOMMENDED_PLAYS_ASSSESSMENTS.REJECTED, payload: error }),
  RESET: () => ({ type: GET_RECOMMENDED_PLAYS_ASSSESSMENTS.RESET }),
}
