import { GET_GOAL_DETAILS } from 'store/types'

const initialState = {
  details: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GOAL_DETAILS.FULLFILLED:
      return {
        ...state,
        details: action?.payload,
      }

    default:
  }
  return state
}

export default reducer
