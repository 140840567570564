import React, { useEffect } from 'react'
import get from 'lodash/get'
import { Channel, logChatPromiseExecution } from 'stream-chat'
import {
  MessageToSend,
  MessageInput,
  Window,
  Thread,
  useChatContext,
  useChannelActionContext,
} from 'stream-chat-react'
import { MessageList } from 'components/Chat/components/MessageList'
import { MessagingChannelHeader, MessagingInput } from 'components/Chat/components'
import { useGiphyContext } from 'components/Chat/Giphy'
import type { StreamChatGenerics } from 'components/Chat/types'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'
import { IChatAttachment } from 'components/Chat'
import { getFileSizeFromUrl, guessImageFileTypeFromFileName } from 'utils/file-utils'
import { useUserInfo } from 'hooks/useUserInfo'
import { getChatRecipientData } from 'utils/helper'
import { IFileInfo } from 'types'
import { ampli } from 'ampli'

export type ChannelInnerProps = {
  usersAssessmentResponseByQuestion: any
  chatUnreadMessageCountAction: any
  setShowChannelPreview: (showChannelPreview) => void
  theme: string
  showChannelPreview: boolean
  setShowDesktopUserProfile: (showDesktopUserProfile) => void
  showDesktopUserProfile
  saveUserFileAction: (streamMsgId: string, attachment: IChatAttachment) => void
}

export const ChannelInner = (props: ChannelInnerProps) => {
  const {
    theme,
    setShowChannelPreview,
    showChannelPreview,
    setShowDesktopUserProfile,
    showDesktopUserProfile,
    chatUnreadMessageCountAction,
    saveUserFileAction,
  } = props
  const { userName, userId, roles, organizationName } = useUserInfo()

  const { giphyState, setGiphyState } = useGiphyContext()
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>()
  const { channel, client } = useChatContext<StreamChatGenerics>()
  const { chatsUnreadCount, setChatsUnreadCount, setChannelMessageCount } = useUnreadContext()
  const logSentMessage = (
    messageToSend: MessageToSend<StreamChatGenerics>,
    channel: Channel<StreamChatGenerics>
  ) => {
    const recipientData = getChatRecipientData(channel.state.members, userId)

    ampli.messageSent({
      senderId: userId,
      content: messageToSend.text || '',
      senderName: userName,
      senderRoles: roles,
      recipientIds: recipientData.recipientIds,
      recipientNames: recipientData.recipientNames,
      recipientRoles: recipientData.recipientRoles,
      fileType: fileInfoArray.map((fileInfo) => fileInfo.fileType),
      fileSize: fileInfoArray.map((fileInfo) => fileInfo.fileSize),
      organizationName,
    })
  }

  const fileInfoArray: IFileInfo[] = []

  const saveAttachmentsIfNeeded = async (resp) => {
    if ((resp.message.attachments?.length || 0) > 0) {
      for (const attachment of resp.message.attachments || []) {
        let fileInfo: IFileInfo = {
          fileType: '',
          fileSize: '',
        } // Initialize fileInfo object for this attachment

        if (!['file', 'image'].includes(attachment.type || 'unknown')) continue

        if (attachment.type === 'file') {
          saveUserFileAction(resp.message.id, {
            title: attachment.title,
            fileSize: attachment.file_size?.toString() || '0',
            fileType: attachment.mime_type,
            url: attachment.asset_url,
          })

          fileInfo.fileType = attachment.mime_type
          fileInfo.fileSize = attachment.file_size?.toString() || '0'
        } else {
          const fileSize = await getFileSizeFromUrl(attachment.image_url!)
          saveUserFileAction(resp.message.id, {
            title: attachment.fallback,
            fileSize: fileSize.toString(),
            fileType: guessImageFileTypeFromFileName(attachment.fallback),
            url: attachment.image_url,
          })

          fileInfo.fileType = guessImageFileTypeFromFileName(attachment.fallback)
          fileInfo.fileSize = fileSize.toString()
        }

        fileInfoArray.push(fileInfo)
      }
    }
  }

  const overrideSubmitHandler = async (message: MessageToSend<StreamChatGenerics>) => {
    let updatedMessage

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '')
      updatedMessage = { ...message, text: updatedText }
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`
      updatedMessage = { ...message, text: updatedText }
    }

    if (channel) {
      const newMessage = updatedMessage || message
      const parentMessage = newMessage.parent

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      }

      if (get(channel, 'data.frozen')) {
        const payload = { ...messageToSend, attachments: [] }
        const sendMessagePromise = sendMessage(payload)
        logChatPromiseExecution(sendMessagePromise, 'send message')
        return
      }

      if (channel) {
        const messageResponse = await channel.sendMessage(messageToSend)
        await saveAttachmentsIfNeeded(messageResponse)
        logSentMessage(messageToSend, channel)
      }
    }

    setGiphyState(false)
  }

  const actions = ['delete', 'edit', 'react', 'reply']

  useEffect(() => {
    const handlerNewMessageEvent: any = (event) => {
      try {
        const { message, user } = event

        if (user?.id !== client?.userID && channel?.cid !== message?.cid) {
          setChannelMessageCount((state) => ({
            ...state,
            [message.cid]: state[message.cid] ? state[message.cid] + 1 : 1,
          }))
          setChatsUnreadCount(chatsUnreadCount + 1)
          chatUnreadMessageCountAction(chatsUnreadCount + 1)
        }
      } catch (error: any) {
        console.log('error: ', error)
      }
    }

    client?.on('message.new', handlerNewMessageEvent)

    return () => client?.off('message.new', handlerNewMessageEvent)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, client, chatsUnreadCount, setChatsUnreadCount])

  return (
    <>
      <Window>
        <MessagingChannelHeader
          theme={theme}
          setShowChannelPreview={setShowChannelPreview}
          showChannelPreview={showChannelPreview}
          setShowDesktopUserProfile={setShowDesktopUserProfile}
          showDesktopUserProfile={showDesktopUserProfile}
          usersAssessmentResponseByQuestion={props.usersAssessmentResponseByQuestion}
        />
        <MessageList messageActions={actions} />
        <div className="mt-24 md:mt-2">
          <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} />
        </div>
      </Window>
      <Thread Input={MessagingInput} />
    </>
  )
}
