import React from 'react'
import HelpIcon from 'components/Common/SvgIcons/HelpIcon'
import { helpDesk } from 'utils/helper'
import { HelpDeskWrapper } from 'components/Owner/Tools/style'
const HelpDesk = ({ className }) => {
  return (
    <HelpDeskWrapper>
      <div id="help-desk" className={className} onClick={helpDesk}>
        <HelpIcon />
      </div>
    </HelpDeskWrapper>
  )
}
HelpDesk.defaultProps = {
  className: '',
}
export default HelpDesk
