import { useMemo } from 'react'
import { PathNames } from 'config'
import history from 'utils/history'
import NAVIGATION from 'routes/navigation'
import { useParams } from 'react-router-dom'
import { copy, getRoleFromPath } from 'utils/helper'

const useNavigationItems = () => {
  const params = useParams()
  const tenantId = useMemo(() => params?.tenantId, [params])
  const path = history.location.pathname

  const items: any[] = useMemo(() => {
    const role = getRoleFromPath()
    if (!role || !(role in NAVIGATION)) return []
    return NAVIGATION[role].map((it) => {
      const item = copy(it)
      if (item.path && tenantId) {
        item.path = item.path.replace(':tenantId', tenantId)
      }

      return {
        ...item,
        selected: path.includes(PathNames[item.label]),
      }
    })
  }, [path, tenantId])

  return items
}

export default useNavigationItems
