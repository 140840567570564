import { ErrorType } from '../../../../__generated__/api-types-and-hooks'
import { setFlashUrlParams } from '../../../flashParameters'
import { ApiResponseError } from '../../api-response-error'

export const handleGetUserAvailabilityError = (errors: ApiResponseError[]) => {
  for (const error of errors) {
    if (error.errorType === ErrorType.DisconnectedToolError) {
      const disconnectedToolError = error?.errorInfo
      if (!disconnectedToolError?.disconnectedTools) return
      const disconnectedTools = disconnectedToolError.disconnectedTools.map((tool: string) => {
        return tool
          .trim()
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
      })
      const flashParams = {
        availabilityConnectionError: disconnectedTools.join(', '),
      }
      setFlashUrlParams(flashParams)
      const messages = errors.map(({ message }) => message).join(', ')
      throw new Error(messages)
    }
  }
}
