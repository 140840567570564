import algoliasearch from 'algoliasearch'

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || ''
const stage = process.env.REACT_APP_ENV

const fetchAlgoliaData = async ({
  page,
  perPage,
  searchQuery = '',
  filters = '',
  userAlgoliaSearchKey = '',
  sortValue = '',
}) => {
  let ALGOLIA_INDEX_NAME = `${stage}_usersTable_index`
  const searchClient = algoliasearch(ALGOLIA_APP_ID, userAlgoliaSearchKey)

  if (sortValue) ALGOLIA_INDEX_NAME = `${ALGOLIA_INDEX_NAME}_${sortValue}`
  const index = searchClient.initIndex(ALGOLIA_INDEX_NAME)

  page--
  try {
    const response = await index.search(searchQuery, {
      page,
      hitsPerPage: perPage,
      filters,
    })

    return {
      totalRows: response.nbHits,
      data: response.hits,
    }
  } catch (error) {
    throw new Error('Error fetching data from Algolia')
  }
}

export default fetchAlgoliaData
