import API from 'api'
import * as api from '__generated__/api-types-and-hooks'
import { handleSetUserMeetingError } from 'utils/error-handling/error-handlers/set-user-meeting'
import { handleUpdateUserCalendarEventError } from '../utils/error-handling/error-handlers/update-user-calendar-event'
import { handleGetUserCalendarEventsError } from '../utils/error-handling/error-handlers/get-user-calendar-events'
import { handleGetUserAvailabilityError } from '../utils/error-handling/error-handlers/get-user-availability'

class Calendar_API {
  getUserConnectors = (connectorName): Promise<any> => {
    return API.fetch(api['GetUserConnectorsDocument'], { connectorName })
  }
  getUserCalendarEvents = (calendarName, timeMax, timeMin, tenantId): Promise<any> => {
    return API.fetch(
      api['GetUserCalendarEventsDocument'],
      {
        calendarName,
        timeMax,
        timeMin,
        tenantId,
      },
      handleGetUserCalendarEventsError
    )
  }
  getOAuthUrl = (
    connectorName: string,
    targetOrigin: string,
    inputProperties?: api.OAuthConnectionProperty[],
    authType?: api.ToolAuthType
  ) => {
    return API.fetch(api['GetOAuthUrlDocument'], {
      input: {
        connectorName,
        oAuthConnectionProperties: inputProperties ?? [],
        authType: authType ?? api.ToolAuthType.Oauth,
        targetOrigin,
      },
    })
  }
  getCalendlyUser = () => {
    return API.fetch(api['GetCalendlyUserDocument'], null)
  }
  deleteCalendarEvent = (calendarEvent) => {
    return API.fetch(api['DeleteCalendarEventDocument'], { input: calendarEvent })
  }
  subscribeCalendlyWebhook = ({ tenantId = undefined, email = undefined }): Promise<any> => {
    return API.fetch(api['SubscribeCalendlyWebhookDocument'], { tenantId, email })
  }
  subscribeGoogleCalendarWebhook = (tenantId): Promise<any> => {
    return API.fetch(api['SubscribeGoogleCalendarWebhookDocument'], { tenantId })
  }
  getBoAdvisors = (tenantId = undefined, userId = undefined): Promise<any> => {
    return API.fetch(api['GetBoAdvisorsDocument'], { tenantId, userId })
  }
  getUserMeeting = (advisorId): Promise<any> => {
    return API.fetch(api['GetUserMeetingDocument'], { advisorId })
  }
  deleteGoogleCalendarEvent = (payload): Promise<any> => {
    return API.fetch(api['DeleteGoogleCalendarEventDocument'], { input: payload })
  }
  updateGoogleCalendarEvent = (payload): Promise<any> => {
    return API.fetch(api['UpdateGoogleCalendarEventDocument'], { input: payload })
  }
  cancelOutlookEvent = (payload): Promise<any> => {
    return API.fetch(api['CancelOutlookEventDocument'], { input: payload })
  }
  getMeetingTypes = ({ tenantId }) => {
    return API.fetch(api['GetMeetingTypesDocument'], { tenantId })
  }
  setUserAvailability = (payload) => {
    return API.fetch(api['SetUserAvailabilityDocument'], { input: payload })
  }
  getUserAvailability = (payload) => {
    return API.fetch(
      api['GetUserAvailabilityDocument'],
      { input: payload },
      handleGetUserAvailabilityError
    )
  }
  setUserMeeting = (payload) => {
    return API.fetch(api['SetUserMeetingDocument'], { input: payload }, handleSetUserMeetingError)
  }
  updateUserCalendarEvent = (payload) => {
    return API.fetch(
      api['UpdateUserCalendarEventDocument'],
      { input: payload },
      handleUpdateUserCalendarEventError(payload?.tackleId)
    )
  }
  cancelUserCalendarEvent = (payload) => {
    return API.fetch(api['CancelUserCalendarEventDocument'], { input: payload })
  }
  getUserAvailabilityHours = ({ userId }) => {
    return API.fetch(api['GetUserAvailabilityHoursDocument'], { userId })
  }
  updateUserAvailability = (payload) => {
    return API.fetch(api['UpdateUserAvailabilityDocument'], { input: payload })
  }
  getAdvisorProfile = (payload: { advisorId: string; tenantId: string }) => {
    return API.fetch(api['GetAdvisorProfileDocument'], { input: payload })
  }
  getUserMeetingById = (payload) => {
    return API.fetch(api['GetUserMeetingByIdDocument'], { input: payload })
  }
}
// eslint-disable-next-line
export default new Calendar_API()
