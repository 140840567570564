import { ILinkProps } from 'components/Common/Link/index'
import { useMemo } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export type ILinkWrapperProps = Pick<Required<ILinkProps>, 'variant' | 'size' | 'showUnderline'> & {
  className?: string
}

const screenModifiers = ['sm', 'smd', 'md', 'mdl', 'lg', 'xl', 'xxl', '2xl']

const fontSizeModifiers = [
  'xs',
  'sm',
  'tiny',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
]

const fontSizeVariations = (() => {
  const combinations: string[] = []
  screenModifiers.forEach((screenSize) => {
    fontSizeModifiers.forEach((fontSize) => {
      combinations.push(`${screenSize}:text-${fontSize}`)
    })
  })
  return combinations
})()

const allowFontSizeOverride = (className?: string) => {
  if (!className) return false
  return fontSizeVariations.some((twClass) => className.includes(twClass))
}

export const LinkWrapper = styled.span((props: ILinkWrapperProps) => {
  const classes = useMemo(() => {
    const textColor = {
      primary: tw`text-primary`,
      basic: tw`text-black`,
      white: tw`text-white`,
    }[props.variant]

    const size = allowFontSizeOverride(props?.className)
      ? tw``
      : {
          xs: tw`text-xs`,
          sm: tw`text-sm`,
          base: tw`text-base`,
          lg: tw`text-lg`,
          xl: tw`text-xl`,
          '2xl': tw`text-2xl`,
          '3xl': tw`text-3xl`,
          '4xl': tw`text-4xl`,
        }[props.size]

    const underlineClasses = {
      hover: {
        primary: tw`border-transparent hover:border-primary`,
        basic: tw`border-transparent hover:border-black`,
        white: tw`border-transparent hover:border-white`,
      },
      always: {
        primary: tw`border-primary`,
        basic: tw`border-black`,
        white: tw`border-white`,
      },
    }[props.showUnderline][props.variant]

    return [
      tw`font-primary cursor-pointer border-b font-[650]`,
      textColor,
      size,
      underlineClasses,
      props?.className || '',
    ]
  }, [props?.className, props.showUnderline, props.size, props.variant])

  return classes
})
