import { LabelWrapper, StatusWrapper } from 'components/Admin/UserManagement/style'
import Button from 'components/Common/Button'
import { USER_STATUS } from 'config'
import React from 'react'
import { getLabel } from 'utils/helper'

const UserStatus = ({ userProfile, onToggleUserAccess }) => {
  return (
    <div className="flex gap-12 justify-between">
      <div>
        <LabelWrapper className="mr-2"> Status:</LabelWrapper>
        <StatusWrapper> {userProfile?.status}</StatusWrapper>
      </div>
      <div>
        <Button
          label={getLabel(userProfile)}
          variant="text"
          onClick={onToggleUserAccess}
          disabled={getLabel(userProfile) === USER_STATUS.NA}
          className={`focus:!border-none ${
            getLabel(userProfile) === USER_STATUS.NA ? '' : 'hover:!border-b'
          }`}
        />
      </div>
    </div>
  )
}

export default UserStatus
