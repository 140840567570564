import React from 'react'

export default function StripeIcon() {
  return (
    <svg width="77" height="32" viewBox="0 0 77 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26573_8164)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M76.6289 16.512C76.6289 11.0507 74.0111 6.74133 69.0078 6.74133C63.9833 6.74133 60.9434 11.0507 60.9434 16.4693C60.9434 22.8907 64.5322 26.1333 69.6833 26.1333C72.1956 26.1333 74.0956 25.5573 75.5311 24.7467V20.48C74.0956 21.2053 72.4489 21.6533 70.3589 21.6533C68.3111 21.6533 66.4956 20.928 66.2634 18.4107H76.5867C76.5867 18.1333 76.6289 17.024 76.6289 16.512ZM66.2 14.4853C66.2 12.0747 67.6567 11.072 68.9867 11.072C70.2745 11.072 71.6467 12.0747 71.6467 14.4853H66.2ZM52.7945 6.74133C50.7256 6.74133 49.3956 7.72267 48.6567 8.40533L48.3822 7.08267H43.7378V31.9573L49.0156 30.8267L49.0367 24.7893C49.7967 25.344 50.9156 26.1333 52.7733 26.1333C56.5522 26.1333 59.9934 23.0613 59.9934 16.2987C59.9722 10.112 56.4889 6.74133 52.7945 6.74133ZM51.5278 21.44C50.2822 21.44 49.5434 20.992 49.0367 20.4373L49.0156 12.5227C49.5645 11.904 50.3245 11.4773 51.5278 11.4773C53.4489 11.4773 54.7789 13.6533 54.7789 16.448C54.7789 19.3067 53.47 21.44 51.5278 21.44ZM36.4756 5.48267L41.7745 4.33067V0L36.4756 1.13067V5.48267ZM36.4756 7.104H41.7745V25.7707H36.4756V7.104ZM30.7967 8.68267L30.4589 7.104H25.8989V25.7707H31.1767V13.12C32.4222 11.4773 34.5334 11.776 35.1878 12.0107V7.104C34.5122 6.848 32.0422 6.37867 30.7967 8.68267ZM20.2411 2.47467L15.09 3.584L15.0689 20.672C15.0689 23.8293 17.4122 26.1547 20.5367 26.1547C22.2678 26.1547 23.5345 25.8347 24.2311 25.4507V21.12C23.5556 21.3973 20.22 22.3787 20.22 19.2213V11.648H24.2311V7.104H20.22L20.2411 2.47467ZM5.97002 12.5227C5.97002 11.6907 6.64557 11.3707 7.76446 11.3707C9.36891 11.3707 11.3956 11.8613 13 12.736V7.72267C11.2478 7.01867 9.51668 6.74133 7.76446 6.74133C3.47891 6.74133 0.628906 9.00267 0.628906 12.7787C0.628906 18.6667 8.65113 17.728 8.65113 20.2667C8.65113 21.248 7.80668 21.568 6.62446 21.568C4.87224 21.568 2.63446 20.8427 0.861128 19.8613V24.9387C2.82446 25.792 4.80891 26.1547 6.62446 26.1547C11.0156 26.1547 14.0345 23.9573 14.0345 20.1387C14.0134 13.7813 5.97002 14.912 5.97002 12.5227Z"
          fill="#635BFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_26573_8164">
          <rect width="76" height="32" fill="white" transform="translate(0.628906)" />
        </clipPath>
      </defs>
    </svg>
  )
}
