import React from 'react'
import moment from 'moment'
import { ASSESSMENTS } from 'config'
import CompletedIcon from 'components/Common/SvgIcons/CompletedIcon'
import { AppFC, IReviewAssessmentHeader } from 'types'

export const ReviewAssessmentHeader: AppFC<IReviewAssessmentHeader> = ({
  assessmentType,
  completedAssessmentAt,
}) => {
  return (
    <>
      <div className="text-lg font-futura font-normal text-base  leading-5">
        ASSESSMENT
        <div className="font-semibold pt-2"> Your {ASSESSMENTS[assessmentType]?.title}</div>
      </div>
      <div className=" flex pt-6 pb-12 gap-3">
        <CompletedIcon />
        <span>
          Completed on
          {` ${completedAssessmentAt ? moment(completedAssessmentAt).format('MMMM DD, YYYY') : ''}`}
        </span>
      </div>
    </>
  )
}
