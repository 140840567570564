import styled from 'styled-components'
import tw from 'twin.macro'

export const CreateEventWrapper = styled.div`
  ${tw`xs:px-6 sm:pt-9`}
`
export const CreateEventHeading = styled.div`
  ${tw`font-primary text-primary-text text-xl font-medium sm:px-4 xs:py-6 sm:pt-2 sm:pb-4`}
`
export const CreateEventMeetingTitle = styled.div`
  ${tw`font-primary text-primary-text text-base font-bold sm:px-4 `}
`
export const CreateEventMeetingDescription = styled.div`
  ${tw`font-primary text-primary-text text-base font-medium sm:px-4 sm:py-1`}
`
export const MeetingOptionsWrapper = styled.div`
  ${tw`flex justify-start justify-items-center gap-y-5`}
`
export const MeetingOptions = styled.span`
  ${tw`flex justify-evenly !justify-items-center w-36 h-16 p-2 rounded-2xl bg-cream-dark`}
`
export const CreateEventGridSpan = styled.div`
  ${tw`xs:col-span-7 sm:col-span-4 sm:px-4 `}
`
export const CreateEventTitle = styled.div`
  ${tw`font-primary text-primary-text text-base font-bold sm:px-4 pt-10`}
`
export const CreateEventSlots = styled.div`
  ${tw`col-span-3 xs:col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3 overflow-y-auto`}
`
export const CreateEventSlotsSpan = styled.div`
  ${tw`col-span-12 sm:px-4`}
`
export const CreateEventTimezoneSpan = styled.div`
  ${tw`col-span-12 sm:px-4`}
`
export const CreateEventCheckBoxWrapper = styled.div`
  ${tw`flex items-center sm:col-span-2 xs:col-span-6 xs:mb-5 sm:mb-6`}
`
export const CreateEventCheckBoxLabel = styled.div`
  ${tw`inline-flex xs:mt-0 xs:font-bold sm:mt-0 sm:font-normal`}
`
export const CreateEventSlotsWrapper = styled.div`
  ${tw` xs:mb-5 sm:mb-6 xs:col-span-12 sm:col-span-10`}
`
export const CreateEventButtonWrapper = styled.div`
  ${tw`flex items-end xs:mt-10 xs:mb-20 gap-5 sm:my-10 me-2`}
`

export const TimeSlotWrapper = styled.div`
  ${tw`sm:relative min-h-[50px]`}
`
export const TimeSlotBody = styled.div`
  ${tw`grid grid-cols-12 flex justify-center items-center py-1`}
`
export const TimeSlotUnavailable = styled.div`
  ${tw`xs:col-span-5 sm:col-span-3 min-h-[52px] flex items-center sm:ml-6`}
`
export const TimeSlotUnavailableTitle = styled.label`
  ${tw`text-base font-primary text-silver-darker font-medium`}
`
// `
export const TimeSlotSelectFieldWrapper = styled.div`
  ${tw`xs:col-span-5 sm:col-span-3`}
  .select__single-value {
    font-weight: 500 !important;
    font-size: 16px !important;
  }
`
export const TimeSlotTrashButton = styled.div`
  ${tw`sm:relative sm:col-span-1 cursor-pointer`}
`
export const TimeSlotPlusButton = styled.span`
  ${tw`absolute xs:right-0 xs:top-0 sm:right-0 sm:top-3 cursor-pointer`}
`

export const ToolTipWrapper = styled.div`
  .react-tooltip {
    background-color: #d5e0fa;
    border-radius: 6px;
    right: 100px !important;
    color: #3f3f46;
    font-family: 'Gill Sans';
    font-size: '14px';
  }
`

export const DropDownWrapper = styled.div`
  .select__control:focus {
    box-shadow: none !important;
  }
  .select__control:hover {
    box-shadow: none !important;
    border: none !important;
    padding: 0px;
  }
  .select__control {
    padding: 0px;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }
  .select__dropdown-indicator {
    display: none !important;
  }

  .css-1dimb5e-singleValue {
    /* color: #5e06b9 !important; */
    font-weight: 500;
  }
  .select__menu-portal {
    width: '300px';
    border: '1px solid #D4D4D8';

    box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';
  }
  .select__menu {
    border-radius: '4px' !important;
    min-width: 300px !important;
  }
  .select__menu-list {
    border-radius: '4px' !important;
    box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' !important;
  }
  .css-1n6sfyn-MenuList {
    border-radius: '4px' !important;
    box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' !important;
    width: 300px !important;
  }
  .select__menu-portal .css-1mkpm0j-menu {
    min-width: 300px !important;
  }

  .select__option {
    font-weight: 500 !important;
  }
`
export const DropDownSelectedValueWrapper = styled.div`
  .css-1dimb5e-singleValue {
    color: #3f3f46 !important;
  }
`
export const DropDownSelectedValueColorWrapper = styled.div`
  .css-1dimb5e-singleValue {
    color: #5e06b9 !important;
  }
`
