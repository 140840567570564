import styled from 'styled-components'
import tw from 'twin.macro'
import { InfoIcon } from 'components/Common/SvgIcons'

export const InfoTextWrapper = styled.div<{ index: number }>(({ index }) => {
  return [tw`inline-flex gap-2 items-start`, index !== 0 ? tw`mt-14 mb-6` : tw`my-6`]
})

export const InfoIconWrapper = styled(InfoIcon)`
  ${tw`fill-primary-brandBlueLight inline-flex w-[22px] flex-shrink-0`}
`
export const InfoTextLabel = styled.label`
  ${tw`fill-purple-dark text-base font-medium mr-2`}
`
