import React from 'react'

function spreadSheetIcon({ className }) {
  return (
    <svg
      className={className}
      width="39"
      height="50"
      viewBox="0 0 39 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19089_141444)">
        <path
          d="M5.32031 43.75V6.25C5.32031 5.39062 6.02344 4.6875 6.88281 4.6875H22.5078V12.5C22.5078 14.2285 23.9043 15.625 25.6328 15.625H33.4453V43.75C33.4453 44.6094 32.7422 45.3125 31.8828 45.3125H6.88281C6.02344 45.3125 5.32031 44.6094 5.32031 43.75ZM6.88281 0C3.43555 0 0.632812 2.80273 0.632812 6.25V43.75C0.632812 47.1973 3.43555 50 6.88281 50H31.8828C35.3301 50 38.1328 47.1973 38.1328 43.75V15.0879C38.1328 13.4277 37.4785 11.8359 36.3066 10.6641L27.459 1.82617C26.2871 0.654297 24.7051 0 23.0449 0H6.88281ZM17.8203 25V29.6875H11.5703V25H17.8203ZM11.5703 32.8125H17.8203V37.5H11.5703V32.8125ZM20.9453 32.8125H27.1953V37.5H20.9453V32.8125ZM19.3828 40.625H20.9453H27.1953C28.9238 40.625 30.3203 39.2285 30.3203 37.5V32.8125V31.25V29.6875V25C30.3203 23.2715 28.9238 21.875 27.1953 21.875H20.9453H19.3828H17.8203H11.5703C9.8418 21.875 8.44531 23.2715 8.44531 25V29.6875V31.25V32.8125V37.5C8.44531 39.2285 9.8418 40.625 11.5703 40.625H17.8203H19.3828ZM20.9453 29.6875V25H27.1953V29.6875H20.9453Z"
          fill="#71717A"
        />
      </g>
      <defs>
        <clipPath id="clip0_19089_141444">
          <rect width="37.5" height="50" fill="white" transform="translate(0.632812)" />
        </clipPath>
      </defs>
    </svg>
  )
}

spreadSheetIcon.defaultProps = {
  className: '',
}
export default spreadSheetIcon
