import { ApiResponseError } from 'utils/error-handling/api-response-error'
import { setFlashUrlParams } from 'utils/flashParameters'

export const handleLicenseLimitReached = (error: ApiResponseError) => {
  const flashParams = {
    licenseErrorMessage: error.message,
  }

  setFlashUrlParams(flashParams)
}
