import map from 'lodash/map'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import moment from 'moment'
import { CommonSalesData, SalesSummary } from '../utils'

export const getDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone()
  const dates: any[] = []

  while (now.isSameOrBefore(moment(endDate))) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

export interface TopSellingProduct extends CommonSalesData {
  product_name: string
  total_units_as_of_date: number
  daily_sales_rank: number
  daily_units_rank: number
  total_units_as_of_week: number
  weekly_sales_rank: number
  weekly_units_rank: number
  total_units_as_of_month: number
  monthly_sales_rank: number
  monthly_units_rank: number
  total_units_as_of_quarter: number
  quarterly_sales_rank: number
  quarterly_units_rank: number
  total_units_as_of_year: number
  yearly_sales_rank: number
  yearly_units_rank: number
}

type IntervalKey = 'month' | 'date' | 'year' | 'week' | 'day'

const determineReferenceDates = (
  startDate: string,
  endDate: string,
  intervalKey: IntervalKey = 'year'
) => {
  const referenceDates: string[] = []

  // if (intervalKey === 'year') {
  //   const possibleMoments = [moment().startOf('day'), moment(endDate)]
  //   referenceDates.push(moment.min(possibleMoments).format('YYYY-MM-DD'))
  // } else if (intervalKey === 'month') {
  //   // for ()
  //   const possibleMoments = [moment().startOf('day'), moment(endDate)]
  //   referenceDates.push((moment.min(possibleMoments)).format('YYYY-MM-DD'))
  // }
  const possibleMoments = [moment().startOf('day').utc(), moment(endDate)]
  referenceDates.push(moment.min(possibleMoments).format('YYYY-MM-DD'))

  return referenceDates
}

export const getIntervalChartData = (
  data: TopSellingProduct[] | undefined,
  startDate,
  endDate,
  intervalKey: IntervalKey = 'month'
) => {
  const intervalDetails = {}
  const referenceDates = determineReferenceDates(startDate, endDate, intervalKey)
  const chartData = data?.filter((x) => referenceDates.includes(x.reference_date.value))
  for (const row of chartData || []) {
    const sku = row.product_name
    intervalDetails[sku] = {
      sku, // TODO: Get this value from backend when available
      productName: sku,
      sales: Number(row[`total_sales_as_of_${intervalKey}`] || 0),
      units: Number(row[`total_units_as_of_${intervalKey}`] || 0),
    }
  }
  // const chartdata = sortBy(data, (row) => row.reference_date.value)
  // each(chartdata, (row) => {
  //   const date = row.reference_date.value
  //   const sku = row.product_name
  //   if (moment(date).isBetween(startDate, endDate, undefined, '[]'))
  //     intervalDetails[sku] = {
  //       sku,
  //       productName: row.product_name,
  //       sales: Number(row[`total_sales_as_of_${intervalKey}`] || 0),
  //       units: Number(row[`total_units_as_of_${intervalKey}`] || 0)
  //     }
  // })
  return reverse(sortBy(values(intervalDetails), 'sales'))
}

export type TopSellingProductChannel = {
  business_id: string
  location: string
  reference_date: {
    value: string
  }
  product_name: string
  total_sales_as_of_date: number
  total_units_as_of_date: number
  daily_sales_rank: number
  daily_units_rank: number
  total_sales_as_of_week: number
  total_units_as_of_week: number
  weekly_sales_rank: number
  weekly_units_rank: number
  total_sales_as_of_month: number
  total_units_as_of_month: number
  monthly_sales_rank: number
  monthly_units_rank: number
  total_sales_as_of_quarter: number
  total_units_as_of_quarter: number
  quarterly_sales_rank: number
  quarterly_units_rank: number
  total_sales_as_of_year: number
  total_units_as_of_year: number
  yearly_sales_rank: number
  yearly_units_rank: number
}

export const getIntervalChartDataForChannel = (
  data: TopSellingProductChannel[] | undefined,
  startDate,
  endDate,
  intervalKey: IntervalKey = 'month'
): { source: string; totalSales: number }[] => {
  const intervalDetails: { [key: string]: { source: string; totalSales: number } } = {}
  const referenceDates = determineReferenceDates(startDate, endDate, intervalKey)
  const chartData = data?.filter((x) => referenceDates.includes(x.reference_date.value))
  for (const row of chartData || []) {
    const source = row.location

    intervalDetails[source] = {
      source,
      totalSales: Number(row[`total_sales_as_of_${intervalKey}`] || 0),
    }
  }
  // const chartdata = sortBy(data?.chartData || [], (row) => row.reference_date.value)
  // each(chartdata, (row) => {
  //   const date = row.reference_date.value
  //   const source = row.location
  //   if (moment(date).isBetween(startDate, endDate, undefined, '[]'))
  //     intervalDetails[source] = {
  //       source,
  //       totalSales:
  //         Number(row[`total_sales_as_of_${intervalKey}`] || 0)
  //     }
  // })
  return reverse(sortBy(values(intervalDetails), 'totalSales'))
}

export const getLastUpdatedData = (data) => {
  const updatedDates = map(data, (row) => {
    return moment(row.last_updated_at?.value || new Date())
  })
  return moment.max(updatedDates).format('YYYY-MM-DD')
}

const growthIntervalFieldMapping = {
  month: 'mom_change_pct',
  year: 'yoy_change_pct',
  week: 'wow_change_pct',
  day: 'dod_change_pct',
}

export const getSalesGrowth = (
  data: SalesSummary[],
  startDate,
  endDate,
  intervalKey: IntervalKey = 'month'
) => {
  const field = growthIntervalFieldMapping[intervalKey]
  if (!field) return 0

  const referenceDates = determineReferenceDates(startDate, endDate, intervalKey)
  const chartData = data?.filter((x) => referenceDates.includes(x.reference_date.value))

  if (chartData?.length !== 1) return 0
  return chartData[0][field] || null
}
