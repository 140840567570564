import styled from 'styled-components'
import tw from 'twin.macro'

export const BarContainer = styled.div`

  position: relative;
  width: 100%;
  margin: 50px auto;
  .bar{
    height: 30px;
    overflow: hidden;
    div{
      height: 100%;
      float:left;
    }
    .interval-1-5{
      ${tw`bg-tertiary`}
    }
    .interval-6-10{
      ${tw`bg-tertiary/70`}
    }
    .interval-11-50{
      ${tw`bg-tertiary/50`}
    }
    .interval-50-100{
      ${tw`bg-tertiary/25`}
    }
  }
  .interval-1-5 {
    width: calc(5% + 20px);
    // width: 8%;
  }
  .interval-6-10 {
    width: calc(5% + 20px);
    // width: 8%;
  }
  .interval-11-50 {
    width: 40%;
    // width: 34%;
  }
  .interval-50-100 {
    width: calc(50% - 40px)
    // width: 50%;
  }
  .value{
    div{
      height: 100%;
      float:left;
      font-size: 12px;
      line-height: 20px;
      p{

      }
    }
  }
  .slider{
    div{
      // width: 45%;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      position: absolute;
      // background: rgba(#000, 0.5);
      p{
        position: absolute;
        right: 0;
        width: 12px;
        img {
          height: 40px;
        }
      }
    }


`
