import React, { useEffect, useMemo } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import compact from 'lodash/compact'
import last from 'lodash/last'
import moment from 'moment'
import BarChart from 'components/Common/BarChart'
import DatePicker, { useDatePickerHandler } from 'components/Common/DatePicker'
import ArrowHead from 'components/Common/SvgIcons/ArrowHead'
import {
  intervalTypes,
  getYearlyChartData,
  getLastUpdatedData,
  getIntervalChartData,
} from 'components/Owner/Data/utils'
import colors from 'colors'
import { formatNumber } from 'utils/helper'
import GrowingContainer from 'components/Common/GrowingContainer'
import ExclamationMarkIcon from 'components/Common/SvgIcons/ExclamationMarkIcon'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import { StatValue, SectionTitle } from 'components/Owner/Data/styles'
// import { useChartStatus } from 'components/Owner/Data/useChartStatus'
import Loader from 'components/Loaders'

const Sales = ({ isLoading, salesChartData, getSalesChartDataAction }) => {
  // const { isChartInprocess } = useChartStatus(get(salesChartData, 'chartStatus'))
  const { selectedDate, handleClick, formattedDate, handleNextDate, handlePreviousDate } =
    useDatePickerHandler()

  useEffect(() => {
    getSalesChartDataAction()
  }, [getSalesChartDataAction])

  const chartData = useMemo(() => {
    if (selectedDate.type === 'year') {
      return getYearlyChartData(
        `${moment(selectedDate.startDate).format('YYYY')}`,
        get(salesChartData, 'chartData')
      )
    }

    const data = getIntervalChartData(
      salesChartData,
      selectedDate.startDate,
      selectedDate.endDate,
      selectedDate.type
    )
    return data
  }, [selectedDate, salesChartData])

  const lastUpdated = useMemo(() => {
    return getLastUpdatedData(get(salesChartData, 'chartData'))
  }, [salesChartData])

  const aggValue: any = useMemo(() => {
    return last(compact(map(chartData, 'aggValue')))
  }, [chartData])

  const percentageVal = useMemo(() => {
    const value: any = last(compact(map(chartData, 'percentageVal')))
    const resp = { value, className: '', arrowColor: '', arrowClass: '' }
    if (value < 0)
      return {
        ...resp,
        className: 'text-primary-error',
        arrowColor: colors.primary.error,
        arrowClass: 'rotate-180',
      }
    if (value > 0) return { ...resp, className: 'text-green', arrowColor: colors.green.DEFAULT }
    return resp
  }, [chartData])

  return (
    <GrowingContainer>
      <div className="grid col-span-12">
        <h3 className="xs:text-[25px] sm:text-3xl leading-9 text-black p-7 font-primary">
          Sales Overview
        </h3>
        <div className="bg-secondary p-7 w-full">
          <div className="flex xs:flex-col lg:flex-row gap-9">
            <div className="totalSale">
              <SectionTitle>WHAT ARE THE TOTAL SALES OF MY BUSINESS?</SectionTitle>
              {isLoading && <Loader loader="TableLoader" />}
              <div className={`flex gap-5 flex-col items-center ${isLoading && 'hidden'}`}>
                <p className="text-lg leading-7 font-semibold text-primary-text font-primary">
                  Total Gross Sales {formattedDate}
                </p>
                <StatValue>${formatNumber(aggValue) || 0}</StatValue>
                <div className="flex gap-3 text-2xl font-primary items-center">
                  {percentageVal.value !== 0 && (
                    <ArrowHead
                      color={percentageVal.arrowColor}
                      className={`cursor-pointer ${percentageVal.arrowClass}`}
                    />
                  )}
                  <span className={percentageVal.className}>{percentageVal.value || 0}%</span>
                  <span> {intervalTypes[selectedDate.type]}</span>
                  <ExclamationMarkIcon className="fill-primary hover:fill-primary-brand h-[32px] w-[32px]" />
                </div>
              </div>
            </div>
            <div className="grow flex flex-col gap-12">
              <div>
                <div className="flex flex-col">
                  <div className="flex justify-end mb-6">
                    <DatePicker
                      handleClick={handleClick}
                      value={formattedDate}
                      onNextDate={handleNextDate}
                      onPreviousDate={handlePreviousDate}
                    />
                    <div className="clearfix"></div>
                  </div>
                  {isLoading && <Loader loader="ChartLoader" />}
                  <div className={`h-[300px] relative ${isLoading && 'hidden'} `}>
                    {/* {isChartInprocess && (
                      <InprocessChart>
                        <Message lineBreak={true} />
                      </InprocessChart>
                    )}
                    <BarChart
                      data={!isChartInprocess ? chartData : []}
                      xDataKey="name"
                      yDataKey="value"
                      barName="Value"
                    /> */}
                    <BarChart
                      data={chartData || []}
                      xDataKey="name"
                      yDataKey="value"
                      barName="Value"
                    />
                  </div>
                </div>
              </div>
              <SectionFooter
                lastUpdated={lastUpdated}
                getChartDataAction={getSalesChartDataAction}
              />
            </div>
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default Sales
