const isModalOpen = async () => {
  await delay(1000) // Wait 1 second
  return checkIfAnyModalOpen()
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

const checkIfAnyModalOpen = () => {
  // This assumes we are using <ModalBox> and Headless UI to open modals.
  return document.querySelectorAll('[data-headlessui-state="open"]').length > 0
}

export default isModalOpen
