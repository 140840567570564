import {
  UPDATE_PLAY_STEP,
  GET_PLAY,
  AUTHORIZE_CONNECTOR,
  UPDATE_PLAY_STAGE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  HANDLE_COMPLETE_PLAY,
  GET_CONNECTED_PLAY,
  SAVE_PLAY_DATA,
  GET_PLAY_RESPONSE,
  SAVE_PLAY_RESPONSE,
  SAVE_USER_PLAY_FILE,
} from 'store/types'
import { get, isEmpty } from 'lodash'
import PlayApi from 'api/play'
import {
  updatePlayStepId,
  getPlayAction,
  authorizeConnectorAction,
  updatePlayStageAction,
  getPlayAssessmentResponseAction,
  resetPlayAction,
  savePlayDataAction,
  getPlayResponseAction,
  savePlayResponseAction,
  saveUserPlayFileAction,
  GetPlayResponsePayload,
  SaveUserFilePayload,
  SavePlayResponsePayload,
  getConnectedPlayAction,
} from 'store/actions/play'
import { handleCyclrOAuthAction, resetOAuthUrlAction } from 'store/actions/calendar'

import { LOG_STATUS, PathNames } from 'config'
import UserApi from 'api/user'
import history from 'utils/history'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import AssessmentApi from 'api/assessment'
import {
  getReturnToPath,
  getPlayStepResponse,
  checkPlayButtonDisabled,
  getPlayStageAndAssessmentResponse,
} from 'utils/helper'
import {
  getAssessmentResponseByQuestionAction,
  saveAssessmentResponseAction,
} from 'store/actions/owner/initialAssessment'
import { ampli } from 'ampli'
import { deleteKeys, isPlayStarted } from 'components/Owner/Plays/utils'
import { uploadUserFile } from 'store/sagas/common'
import CommonApi from 'api/common'
import { Action } from 'types'
import { PlayResponse, TabResponse } from '__generated__/api-types-and-hooks'

function* updatePlayStep(action) {
  yield put(updatePlayStepId.FULLFILLED({ payload: 'payload' }))
}

function* getPlay(action) {
  yield put(resetPlayAction())

  const playRes = yield call(PlayApi.getPlay, action?.payload?.playId)

  const response = JSON.parse(playRes.getPlay.data)
  let play = response.play
  play.recommendedBy = response.recommendedBy

  let res = yield call(AssessmentApi.getUserAssessmentResponse, play.id)
  const getAssessments = get(res, 'getUserAssessmentResponse.data', '')

  let { playStage, assessmentResponse } = getPlayStageAndAssessmentResponse({
    assessments: getAssessments,
    play,
  })

  let payload = {
    play,
    playStage,
    assessmentResponse,
    isPlayCompleted: play.isCompleted,
    disableButton: checkPlayButtonDisabled(playStage),
  }
  yield put(getPlayAction.FULLFILLED(payload))
}

function* fulFillPlayResponse(
  playId: string,
  playResponse: PlayResponse,
  isUpdateStage?: boolean,
  isInReview?: boolean
) {
  let getPlayData = yield select((state) => state.play)

  if (isEmpty(getPlayData?.play)) {
    getPlayData = yield call(PlayApi.getPlay, playId)
    getPlayData = JSON.parse(getPlayData.getPlay.data)
  }

  const { playSteps } = getPlayStepResponse(
    getPlayData?.play?.playTabs,
    playResponse.playTabResponse as TabResponse[]
  )
  let isPlayInReview = false
  const isTabsCompleted = playSteps.every((step) => step.isCompleted)
  if (isInReview && playResponse.isCompleted && isTabsCompleted) {
    isPlayInReview = true
  }
  let payload = {
    play: getPlayData?.play,
    playStage: playSteps,
    isPlayCompleted: playResponse?.isCompleted,
    isUpdateStage,
    isPlayInReview,
  }

  yield put(getPlayAction.FULLFILLED(payload))
  yield put(getPlayResponseAction.FULLFILLED(playResponse))
}

function* getPlayResponse(action: Action<string, GetPlayResponsePayload>) {
  delete action?.payload?.getResponse
  const { playId } = action.payload
  const tenantId = yield select((state) => state.user.tenantId)

  const playResponse = yield call(PlayApi.getPlayResponse, {
    playId: action.payload.playId,
    tenantId,
  })

  yield fulFillPlayResponse(
    playId,
    playResponse?.getPlayResponse[playResponse?.getPlayResponse?.length - 1],
    false,
    true
  )
}
function* savePlayResponse(action: Action<string, SavePlayResponsePayload>) {
  const { playId } = action.payload
  const submitActivity = action?.payload?.submitActivity
  const name = action?.payload?.name
  const isCompleted = action?.payload?.isCompleted
  const redirectToDashboard = action.payload?.redirectToDashboard
  const tabIndex = action.payload?.tabIndex
  const isUpdateStage = action.payload?.isUpdateStage
  deleteKeys(action)
  const isStarted = tabIndex === 0

  let payload = { ...action.payload, isCompleted, stepData: action?.payload?.stepData }

  const tenantId = yield select((state) => state.user.tenantId)

  const data = yield call(PlayApi.savePlayResponse, { ...payload, tenantId })

  yield logActivity(
    isCompleted || false,
    isStarted,
    submitActivity || false,
    tenantId,
    playId,
    name || ''
  )

  if (isStarted) {
    ampli.playStarted({
      id: playId,
      name: name || '',
    })
  }
  yield fulFillPlayResponse(playId, data?.savePlayResponse, isUpdateStage, false)

  if (isUpdateStage) {
    yield put(saveUserPlayFileAction.FULLFILLED())
  }
  if (redirectToDashboard) {
    history.push(`/owner/${tenantId}/dashboard`)
  }
}

function* authorizeConnector(action) {
  let record = yield call(PlayApi.authorizeConnector, action?.payload)
  let payload = JSON.parse(record.authorizeConnector.data)
  yield put(authorizeConnectorAction.FULLFILLED(payload))
}

function* updatePlayState(action) {
  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || PathNames.Dashboard
  const playData = yield select((state) => state.play?.play?.playData)
  const { submitActivity, name, isCompleted, type } = action.payload
  delete action.payload.submitActivity
  delete action.payload.name
  const tenantId = yield select((state) => state.user.tenantId)

  const redirectToDashboard = action.payload?.redirectToDashboard
  delete action.payload?.redirectToDashboard
  let record = yield call(AssessmentApi.saveAssessmentResponse, { ...action.payload, tenantId })

  let playStage = JSON.parse(record.saveAssessmentResponse?.playStage)
  if (redirectToDashboard) {
    ampli.playFinished({
      id: type,
      name: playData?.title,
    })
    history.push(`/owner/${tenantId}/${redirectPath}`)
  }

  yield put(updatePlayStageAction.FULLFILLED({ playStage }))

  yield logActivity(isCompleted, isPlayStarted(playStage), submitActivity, tenantId, type, name)
}
function* logActivity(
  isCompleted: boolean,
  isStarted: boolean,
  submitActivity: boolean,
  tenantId: string,
  type: string,
  name: string
) {
  if ((isCompleted || isStarted) && submitActivity) {
    const sessionToken = localStorage.getItem('sessionToken')
    yield call(UserApi.logActivity, {
      action: `${isStarted ? LOG_STATUS.STARTED : LOG_STATUS.COMPLETED} ${name}`,
      logStatus: isStarted ? LOG_STATUS.STARTED : LOG_STATUS.COMPLETED,
      accessToken: sessionToken,
      tenantId,
      showClientActivity: true,
      type: type || '',
    })
  }
}

function* getPlayAssessmentResponse(action) {
  let { play_id } = action.payload

  let res = yield call(AssessmentApi.getUserAssessmentResponse, play_id)
  let getAssessments = get(res, 'getUserAssessmentResponse.data', '')

  if (getAssessments?.assessmentResponse) {
    getAssessments.assessmentResponse = getAssessments.assessmentResponse || []
  } else {
    getAssessments = { assessmentResponse: {} }
  }

  if (getAssessments) {
    yield put(getPlayAssessmentResponseAction.FULLFILLED({ assessmentResponse: getAssessments }))
  }
}

function* handleCompletePlay(action) {
  const tenantId = yield select((state) => state.user.tenantId)
  const play = yield select((state) => state.play.play)
  const playStage = yield select((state) => state.play.playStage)
  const user = yield select((state) => state.user.user)

  if (play?.meta?.playType === 'EIN') {
    history.push(`/owner/${tenantId}/dashboard`)
  } else if (play?.meta?.playType !== 'EIN') {
    playStage.use.isCompleted = true
    let assessmentResponseObj = {
      userId: user.id,
      type: play.id,
      isCompleted: true,
      tenantId,
      assessmentResponse: {},
      playStage: JSON.stringify(playStage),
    }
    try {
      let record = yield call(AssessmentApi.saveAssessmentResponse, assessmentResponseObj)

      let playStage = JSON.parse(record.saveAssessmentResponse?.playStage)

      yield put(updatePlayStageAction.FULLFILLED({ playStage }))
    } catch (error: any) {
      console.log('error: updatePlayStage: ', error.message)
    }
    history.push(`/owner/${tenantId}/dashboard`)
  }
}

function* getConnectedPlay(action) {
  const user = yield select((state) => state.user.user)
  const playId = yield select((state) => state.play.play.id)
  const { type, question, url } = action.payload
  try {
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: false }))
    const res = yield call(AssessmentApi.getAssessmentResponseByQuestion, question, type, user.id)
    const playData = res?.getAssessmentResponseByQuestion.data
      ? JSON.parse(res?.getAssessmentResponseByQuestion.data.meta)
      : ''
    yield put(
      getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
    )
    yield put(getConnectedPlayAction.FULLFILLED())
    if (Object.keys(playData.playResponse || {}).includes(playId)) {
      yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: true }))
    } else {
      yield put(handleCyclrOAuthAction.FULLFILLED({ url: url, isConnected: false }))
    }
  } catch (error) {
    yield put(getConnectedPlayAction.REJECTED())
  }
}

function* savePlayData(action) {
  const user = yield select((state) => state.user.user)
  const tenantId = yield select((state) => state.user.tenantId)

  const { question, assessmentResponseObj } = action.payload
  let assessmentPlayLoad = {
    ...assessmentResponseObj,
    tenantId,
  }
  try {
    const response = yield call(AssessmentApi.saveAssessmentResponse, assessmentPlayLoad)
    yield put(saveAssessmentResponseAction.FULLFILLED(response))
    const res = yield call(
      AssessmentApi.getAssessmentResponseByQuestion,
      question,
      assessmentResponseObj.type,
      user.id
    )
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: true }))
    resetOAuthUrlAction()
    yield put(
      getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
    )
    yield put(savePlayDataAction.FULLFILLED())
  } catch (error) {
    console.log('save play data error ', error)
    yield put(savePlayDataAction.REJECTED('Error while saving data'))
  }
}

function* saveUserPlayFile(action: Action<string, SaveUserFilePayload>) {
  try {
    const { files, tabIndex, stepIndex, stepDataIndex, stepData } = action.payload
    const play = yield select((state) => state.play?.play)

    const { fileData } = yield uploadUserFile(files!)

    yield call(CommonApi.saveUserFile, {
      sendNotification: false,
      files: fileData,
    })

    delete action.payload.files

    let data = {
      ...stepData,
      businessProfileFields: {
        [play.playTabs[tabIndex].tabData.steps[stepIndex].stepData[stepDataIndex].meta
          .businessProfileFields]: fileData,
      },
      value: fileData.url,
    }

    const payload = {
      businessProfileFields: {
        [play.playTabs[tabIndex].tabData.steps[stepIndex].stepData[stepDataIndex].meta
          .businessProfileFields]: fileData.id,
      },
      stepData: data,
      stepIndex,
      stepDataIndex,
      playId: play.id,
      getResponse: false,
      tabId: play.playTabs[tabIndex].id,
      tenantId: '',
      isUpdateStage: true,
    }
    yield put(
      savePlayResponseAction.STARTED({
        ...payload,
      })
    )
  } catch (error) {
    console.log('Could not upload file:', error)
    yield put(saveUserPlayFileAction.REJECTED())
  }
}

/* -------------------------------- Watchers -------------------------------- */
export function* watcherPlay() {
  yield takeLatest(UPDATE_PLAY_STEP.STARTED, updatePlayStep)
  yield takeLatest(GET_PLAY.STARTED, getPlay)
  yield takeLatest(AUTHORIZE_CONNECTOR.STARTED, authorizeConnector)
  yield takeLatest(UPDATE_PLAY_STAGE.STARTED, updatePlayState)
  yield takeLatest(GET_PLAY_ASSESSMENT_RESPONSE.STARTED, getPlayAssessmentResponse)
  yield takeLatest(HANDLE_COMPLETE_PLAY.STARTED, handleCompletePlay)
  yield takeLatest(GET_CONNECTED_PLAY.STARTED, getConnectedPlay)
  yield takeLatest(SAVE_PLAY_DATA.STARTED, savePlayData)
  yield takeLatest(GET_PLAY_RESPONSE.STARTED, getPlayResponse)
  yield takeLatest(SAVE_PLAY_RESPONSE.STARTED, savePlayResponse)
  yield takeLatest(SAVE_USER_PLAY_FILE.STARTED, saveUserPlayFile)
}
