import React, { Fragment, useMemo } from 'react'

import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { ROLES, ANNOUNCEMENT_NAMES, FORM } from 'config'

import Loader from 'components/Loaders'
import ModalBox from 'components/Common/ModalBox'
import PublishAnnouncement from 'components/Announcement/PublishAnnouncement'
import AnnouncementSuccessErrorScreen from 'components/Announcement/AnnouncementSuccessErrorScreen'
import {
  AnnouncementButtonWrapper,
  NotificationPanelCount,
} from 'components/NotificationPanel/style'
import Button from 'components/Common/Button'
import { PlusAnnouncementIcon } from 'components/Common/SvgIcons'

import useFormContainer from 'hooks/useFormContainer'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { useGetBusinessesAndClients } from 'hooks/useGetBusinessesAndClients'

import { getRoleFromPath } from 'utils/helper'

const Announcement = ({
  userId,
  allClient,
  totalCount,
  activeStep,
  assignedClients,
  announcementSteps,
  announcementError,
  isFetchingResponse,
  isAllClientFetching,
  isOwnerDataLoading,
  getClientsAction,
  getBusinessesAction,
  setShowNotificationTooltip,
  setAnnouncementActiveStepAction,
  publishAnnouncementAction,
}) => {
  const role = getRoleFromPath()

  useGetBusinessesAndClients(role, userId, getBusinessesAction, getClientsAction)

  const methods = useForm({
    mode: 'all',
  })

  const { control, reset, handleSubmit } = methods

  const showAnnouncementButton = useMemo(() => {
    return role === ROLES.BUSINESS_OWNER || (isEmpty(allClient) && isEmpty(assignedClients))
      ? false
      : true
  }, [allClient, assignedClients, role])

  const onSubmit = (data) => {
    let receiverIds = []
    if (role === ROLES.APP_ADMIN || role === ROLES.BSO_ADMIN) {
      receiverIds = allClient?.map((business) => business?.userId)
    } else if (role === ROLES.BSO_ADVISOR) {
      receiverIds = assignedClients?.map((client) => client?.id)
    }
    data.receiverIds = receiverIds
    publishAnnouncementAction(data)
    reset()
  }
  const handleModalBoxClose = () => {
    reset()
    setShowNotificationTooltip(true)
    setAnnouncementActiveStepAction({ title: '' })
  }

  const isAnnouncementEnabled = useFeatureFlag(userId, 'release-announcement')
  useFormContainer(FORM.ANNOUNCEMENT_FORM, methods)

  return (
    <div>
      {isAnnouncementEnabled && (
        <Fragment>
          {isAllClientFetching ||
          role === ROLES.APP_ADMIN ||
          (role === ROLES.BSO_ADMIN && isOwnerDataLoading) ? (
            <Loader loader="AnnouncementLoader" />
          ) : (
            <AnnouncementButtonWrapper
              showAnnouncementButton={showAnnouncementButton}
              onClick={() => setAnnouncementActiveStepAction(announcementSteps[0])}
            >
              <PlusAnnouncementIcon />
              <Button
                variant="text"
                label={ANNOUNCEMENT_NAMES.NEW}
                className="focus:!border-none"
              />
            </AnnouncementButtonWrapper>
          )}
        </Fragment>
      )}
      <NotificationPanelCount>({totalCount ? totalCount : 0})</NotificationPanelCount>

      {!isEmpty(activeStep) && (
        <ModalBox
          onClose={handleModalBoxClose}
          bgColor="bg-transparent"
          margin="xs:px-3 mdl:px-0"
          title={activeStep === ANNOUNCEMENT_NAMES.MAKE ? ANNOUNCEMENT_NAMES.MAKE : ''}
        >
          {activeStep === ANNOUNCEMENT_NAMES.MAKE && (
            <PublishAnnouncement
              control={control}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              isFetchingResponse={isFetchingResponse}
            />
          )}

          {activeStep === ANNOUNCEMENT_NAMES.AFTER_PUBLISH && !isFetchingResponse && (
            <AnnouncementSuccessErrorScreen
              announcementError={announcementError}
              reset={reset}
              setAnnouncementActiveStepAction={setAnnouncementActiveStepAction}
              announcementSteps={announcementSteps}
            />
          )}
        </ModalBox>
      )}
    </div>
  )
}

export default Announcement
