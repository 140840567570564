import React, { useMemo } from 'react'
import { CrossIcon, TickIcon } from 'components/Common/SvgIcons'

interface IToggleButtonProps {
  isToggled: boolean
  toggleButtonHandler: () => void
  label?: string
  setIsToggled?: (bool) => void
  size?: 'small' | 'large'
  disabled?: boolean
}

const ToggleButton = ({
  label,
  isToggled,
  setIsToggled,
  size = 'small',
  toggleButtonHandler,
  disabled = false,
}: IToggleButtonProps) => {
  const textStyles = useMemo(() => {
    return {
      small: 'text-sm',
      large: 'text-base font-medium',
    }[size]
  }, [size])

  const iconStyles = useMemo(() => {
    return {
      small: 'h-[10px] w-[10px]',
      large: 'h-[18px] w-[18px]',
    }[size]
  }, [size])

  const tickIconStyles = useMemo(() => {
    return {
      small: 'top-[8px] left-[27px]',
      large: 'top-[10px] left-[36px]',
    }[size]
  }, [size])
  const buttonStyles = useMemo(() => {
    return {
      small: ' w-11 h-6 after:h-5 after:w-5 ',
      large: '!w-[60px] !h-8 after:h-[28px] after:w-[28px] ',
    }[size]
  }, [size])

  return (
    <div className="relative flex items-center">
      {!isToggled && (
        <span className={`absolute top-[7px] z-10 left-[7px]`}>
          <CrossIcon className={`${iconStyles} fill-zinc`} />
        </span>
      )}
      {isToggled && (
        <span className={`absolute  z-10 ${tickIconStyles} `}>
          <TickIcon className={`${iconStyles} fill-zinc`} />
        </span>
      )}
      <label
        className={`inline-flex relative items-center smd:mr-0 md:mr-5 cursor-pointer ${
          disabled ? 'cursor-wait' : 'cursor-pointer'
        }`}
      >
        <input
          type="checkbox"
          className="sr-only peer"
          checked={isToggled}
          readOnly
          disabled={disabled}
        />
        <div
          onClick={() => {
            setIsToggled && setIsToggled(true)
            toggleButtonHandler()
          }}
          className={`${isToggled ? 'bg-primary' : 'bg-primary-disabled'}
          
          ${
            disabled ? 'pointer-events-none' : 'pointer-events-auto	'
          }${buttonStyles}  rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white  after:rounded-full  after:transition-all peer-checked:bg-green-600`}
        ></div>
        {label ? (
          <span className={`ml-2 text-gray-900 ${textStyles}`}>{label}</span>
        ) : // todo: move this out of a common component
        isToggled ? (
          <span className="ml-2 text-sm text-gray-900">Admin On</span>
        ) : null}
      </label>
    </div>
  )
}

export default ToggleButton
