import React from 'react'

export default function PlaybooksStarOrangeIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23354_111759)">
        <g filter="url(#filter0_d_23354_111759)">
          <path
            d="M63.1774 60.66C75.9507 47.9068 75.9507 27.2299 63.1774 14.4767C50.404 1.72358 29.6944 1.72358 16.9211 14.4767C4.14778 27.2299 4.14779 47.9068 16.9211 60.66C29.6944 73.4131 50.404 73.4131 63.1774 60.66Z"
            fill="url(#paint0_radial_23354_111759)"
          />
          <path
            d="M63.1774 60.66C75.9507 47.9068 75.9507 27.2299 63.1774 14.4767C50.404 1.72358 29.6944 1.72358 16.9211 14.4767C4.14778 27.2299 4.14779 47.9068 16.9211 60.66C29.6944 73.4131 50.404 73.4131 63.1774 60.66Z"
            stroke="url(#paint1_linear_23354_111759)"
            strokeWidth="2.5"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M40.0424 61.696C53.381 61.696 64.1941 50.9 64.1941 37.5824C64.1941 24.2648 53.381 13.4688 40.0424 13.4688C26.7037 13.4688 15.8906 24.2648 15.8906 37.5824C15.8906 50.9 26.7037 61.696 40.0424 61.696Z"
          fill="url(#paint2_radial_23354_111759)"
        />
        <path
          d="M40.0414 13.4681C53.3607 13.4681 64.1932 24.2835 64.1932 37.5817C64.1932 50.88 53.3607 61.6953 40.0414 61.6953C26.7221 61.6953 15.8897 50.88 15.8897 37.5817C15.8897 24.2835 26.7221 13.4681 40.0414 13.4681ZM40.0414 12.416C26.132 12.416 14.8359 23.6943 14.8359 37.5817C14.8359 51.4691 26.132 62.7474 40.0414 62.7474C53.9508 62.7474 65.2469 51.4691 65.2469 37.5817C65.2469 23.6943 53.9508 12.416 40.0414 12.416Z"
          fill="url(#paint3_linear_23354_111759)"
        />
        <g filter="url(#filter1_d_23354_111759)">
          <path
            d="M49.2712 42L51.3787 54.3304L40.3355 48.5229L29.2501 54.3304L31.3576 42L22.4219 33.2888L34.7717 31.5213L40.3355 20.2852L45.8571 31.5213L58.2069 33.2888L49.2712 42Z"
            fill="url(#paint4_radial_23354_111759)"
          />
          <path
            d="M49.2712 42L51.3787 54.3304L40.3355 48.5229L29.2501 54.3304L31.3576 42L22.4219 33.2888L34.7717 31.5213L40.3355 20.2852L45.8571 31.5213L58.2069 33.2888L49.2712 42Z"
            stroke="url(#paint5_linear_23354_111759)"
            strokeWidth="3.3"
            strokeLinejoin="round"
          />
        </g>
        <path
          style={{ mixBlendMode: 'screen' }}
          opacity="0.8"
          d="M40.3355 20.2852V37.3288L22.4219 33.2888L34.7717 31.4793L40.3355 20.2852Z"
          fill="url(#paint6_linear_23354_111759)"
        />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
          <path
            d="M51.3786 54.3297L40.3354 48.5222L29.25 54.3297L40.3354 37.3281L51.3786 54.3297Z"
            fill="url(#paint7_linear_23354_111759)"
          />
        </g>
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.5"
          d="M49.2717 42.0003L51.3791 54.3306L40.3359 37.329L58.2074 33.2891L49.2717 42.0003Z"
          fill="url(#paint8_linear_23354_111759)"
        />
        <path
          d="M49.2717 42.0003L40.3359 37.329L58.2074 33.2891L49.2717 42.0003Z"
          fill="url(#paint9_linear_23354_111759)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.3"
          d="M40.3339 48.5222V37.3281L29.375 54.1193L40.3339 48.5222Z"
          fill="url(#paint10_linear_23354_111759)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          opacity="0.8"
          d="M40.3355 37.3281L34.7717 31.4785L22.4219 33.2881L40.3355 37.3281Z"
          fill="url(#paint11_linear_23354_111759)"
        />
        <g filter="url(#filter2_d_23354_111759)">
          <path
            d="M70.7235 37.0759C70.7235 38.0017 69.9648 38.7592 69.0375 38.7592C68.1103 38.7592 67.3516 38.0017 67.3516 37.0759C67.3516 36.1501 68.1103 35.3926 69.0375 35.3926C69.9648 35.3926 70.7235 36.1501 70.7235 37.0759Z"
            fill="url(#paint12_radial_23354_111759)"
          />
          <path
            d="M70.7235 37.0759C70.7235 38.0017 69.9648 38.7592 69.0375 38.7592C68.1103 38.7592 67.3516 38.0017 67.3516 37.0759C67.3516 36.1501 68.1103 35.3926 69.0375 35.3926C69.9648 35.3926 70.7235 36.1501 70.7235 37.0759Z"
            stroke="url(#paint13_linear_23354_111759)"
            strokeWidth="1.3"
            strokeLinejoin="round"
          />
        </g>
        <g filter="url(#filter3_d_23354_111759)">
          <path
            d="M12.7274 37.0759C12.7274 38.0017 11.9687 38.7592 11.0415 38.7592C10.1142 38.7592 9.35547 38.0017 9.35547 37.0759C9.35547 36.1501 10.1142 35.3926 11.0415 35.3926C11.9687 35.3926 12.7274 36.1501 12.7274 37.0759Z"
            fill="url(#paint14_radial_23354_111759)"
          />
          <path
            d="M12.7274 37.0759C12.7274 38.0017 11.9687 38.7592 11.0415 38.7592C10.1142 38.7592 9.35547 38.0017 9.35547 37.0759C9.35547 36.1501 10.1142 35.3926 11.0415 35.3926C11.9687 35.3926 12.7274 36.1501 12.7274 37.0759Z"
            stroke="url(#paint15_linear_23354_111759)"
            strokeWidth="1.3"
            strokeLinejoin="round"
          />
        </g>
        <g style={{ mixBlendMode: 'hue' }}>
          <path
            d="M39.997 71.247C58.6199 71.247 73.7168 56.174 73.7168 37.5806C73.7168 18.9871 58.6199 3.91406 39.997 3.91406C21.3742 3.91406 6.27734 18.9871 6.27734 37.5806C6.27734 56.174 21.3742 71.247 39.997 71.247Z"
            fill="#FF9E80"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_23354_111759"
          x="-3.71016"
          y="-0.437891"
          width="87.518"
          height="87.4125"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.7" />
          <feGaussianBlur stdDeviation="4.9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23354_111759"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_23354_111759"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_23354_111759"
          x="10.9734"
          y="14.5348"
          width="58.682"
          height="56.9457"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.7" />
          <feGaussianBlur stdDeviation="4.9" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.498039 0 0 0 0 0.266667 0 0 0 0 0.0588235 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23354_111759"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_23354_111759"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_23354_111759"
          x="64.1031"
          y="34.0422"
          width="9.87187"
          height="9.86797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.9" />
          <feGaussianBlur stdDeviation="1.3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.498039 0 0 0 0 0.266667 0 0 0 0 0.0588235 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23354_111759"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_23354_111759"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_23354_111759"
          x="6.10703"
          y="34.0422"
          width="9.87187"
          height="9.86797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.9" />
          <feGaussianBlur stdDeviation="1.3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.498039 0 0 0 0 0.266667 0 0 0 0 0.0588235 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23354_111759"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_23354_111759"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_23354_111759"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.2329 6.12596) scale(96.7334 96.5807)"
        >
          <stop stopColor="#FFC966" />
          <stop offset="0.4" stopColor="#FFC74A" />
          <stop offset="0.8" stopColor="#FFC638" />
          <stop offset="1" stopColor="#FFC632" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_23354_111759"
          x1="40.0642"
          y1="70.7687"
          x2="40.0642"
          y2="4.40367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F33" />
          <stop offset="0.4" stopColor="#FFB759" />
          <stop offset="0.6" stopColor="#FFD475" />
          <stop offset="0.8" stopColor="#FFE686" />
          <stop offset="1" stopColor="#FFED8D" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_23354_111759"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.6356 13.9317) scale(71.393 71.2804)"
        >
          <stop stopColor="#FFC966" />
          <stop offset="0.4" stopColor="#FFC74A" />
          <stop offset="0.8" stopColor="#FFC638" />
          <stop offset="1" stopColor="#FFC632" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_23354_111759"
          x1="40.0414"
          y1="62.7474"
          x2="40.0414"
          y2="12.4581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED8D" />
          <stop offset="0.2" stopColor="#FFE686" />
          <stop offset="0.4" stopColor="#FFD475" />
          <stop offset="0.6" stopColor="#FFB759" />
          <stop offset="0.9" stopColor="#FF8F33" />
          <stop offset="1" stopColor="#FF8327" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_23354_111759"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.1616 20.1421) scale(51.3635 51.2825)"
        >
          <stop stopColor="#FFC966" />
          <stop offset="0.4" stopColor="#FFC74A" />
          <stop offset="0.8" stopColor="#FFC638" />
          <stop offset="1" stopColor="#FFC632" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_23354_111759"
          x1="40.3355"
          y1="55.0037"
          x2="40.3355"
          y2="19.6118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F33" />
          <stop offset="0.4" stopColor="#FFB759" />
          <stop offset="0.6" stopColor="#FFD475" />
          <stop offset="0.8" stopColor="#FFE686" />
          <stop offset="1" stopColor="#FFED8D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_23354_111759"
          x1="39.4503"
          y1="26.1347"
          x2="27.1645"
          y2="40.1255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC96A" stopOpacity="0.9" />
          <stop offset="0.4" stopColor="#FFC859" stopOpacity="0.5" />
          <stop offset="0.7" stopColor="#FFC74D" stopOpacity="0.2" />
          <stop offset="0.9" stopColor="#FFC745" stopOpacity="0" />
          <stop offset="1" stopColor="#FFC743" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_23354_111759"
          x1="40.3354"
          y1="54.3297"
          x2="40.3354"
          y2="37.3281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC746" stopOpacity="0" />
          <stop offset="0.1" stopColor="#FFC142" stopOpacity="0.2" />
          <stop offset="0.3" stopColor="#FFB83D" stopOpacity="0.4" />
          <stop offset="0.5" stopColor="#FFB239" stopOpacity="0.6" />
          <stop offset="0.6" stopColor="#FFAC36" stopOpacity="0.8" />
          <stop offset="0.8" stopColor="#FFA933" stopOpacity="0.9" />
          <stop offset="0.9" stopColor="#FFA632" />
          <stop offset="1" stopColor="#FFA632" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_23354_111759"
          x1="49.4403"
          y1="46.5873"
          x2="49.2302"
          y2="32.4053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC746" stopOpacity="0" />
          <stop offset="0.1" stopColor="#FFC642" stopOpacity="0.2" />
          <stop offset="0.3" stopColor="#FFC63D" stopOpacity="0.4" />
          <stop offset="0.5" stopColor="#FFC639" stopOpacity="0.6" />
          <stop offset="0.6" stopColor="#FFC636" stopOpacity="0.8" />
          <stop offset="0.8" stopColor="#FFC633" stopOpacity="0.9" />
          <stop offset="0.9" stopColor="#FFC632" />
          <stop offset="1" stopColor="#FFC632" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_23354_111759"
          x1="44.7617"
          y1="24.3674"
          x2="50.0161"
          y2="37.1665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC746" stopOpacity="0" />
          <stop offset="0.1" stopColor="#FFC142" stopOpacity="0.2" />
          <stop offset="0.3" stopColor="#FFB83D" stopOpacity="0.4" />
          <stop offset="0.5" stopColor="#FFB239" stopOpacity="0.6" />
          <stop offset="0.6" stopColor="#FFAC36" stopOpacity="0.8" />
          <stop offset="0.8" stopColor="#FFA933" stopOpacity="0.9" />
          <stop offset="0.9" stopColor="#FFA632" />
          <stop offset="1" stopColor="#FFA632" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_23354_111759"
          x1="26.9303"
          y1="26.5548"
          x2="34.665"
          y2="45.2905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC746" stopOpacity="0" />
          <stop offset="0.1" stopColor="#FFC142" stopOpacity="0.2" />
          <stop offset="0.3" stopColor="#FFB83D" stopOpacity="0.4" />
          <stop offset="0.5" stopColor="#FFB239" stopOpacity="0.6" />
          <stop offset="0.6" stopColor="#FFAC36" stopOpacity="0.8" />
          <stop offset="0.8" stopColor="#FFA933" stopOpacity="0.9" />
          <stop offset="0.9" stopColor="#FFA632" />
          <stop offset="1" stopColor="#FFA632" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_23354_111759"
          x1="32.0741"
          y1="30.1319"
          x2="31.0237"
          y2="37.9177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC96A" stopOpacity="0.9" />
          <stop offset="0.4" stopColor="#FFC859" stopOpacity="0.5" />
          <stop offset="0.7" stopColor="#FFC74D" stopOpacity="0.2" />
          <stop offset="0.9" stopColor="#FFC745" stopOpacity="0" />
          <stop offset="1" stopColor="#FFC743" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_23354_111759"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(64.8942 33.8902) scale(5.00738 4.99947)"
        >
          <stop stopColor="#FFC966" />
          <stop offset="0.4" stopColor="#FFC74A" />
          <stop offset="0.8" stopColor="#FFC638" />
          <stop offset="1" stopColor="#FFC632" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_23354_111759"
          x1="69.0375"
          y1="39.0538"
          x2="69.0375"
          y2="35.1401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F33" />
          <stop offset="0.4" stopColor="#FFB759" />
          <stop offset="0.6" stopColor="#FFD475" />
          <stop offset="0.8" stopColor="#FFE686" />
          <stop offset="1" stopColor="#FFED8D" />
        </linearGradient>
        <radialGradient
          id="paint14_radial_23354_111759"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.7011 33.8902) scale(5.00738 4.99947)"
        >
          <stop stopColor="#FFC966" />
          <stop offset="0.4" stopColor="#FFC74A" />
          <stop offset="0.8" stopColor="#FFC638" />
          <stop offset="1" stopColor="#FFC632" />
        </radialGradient>
        <linearGradient
          id="paint15_linear_23354_111759"
          x1="11.0415"
          y1="39.0538"
          x2="11.0415"
          y2="35.1401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F33" />
          <stop offset="0.4" stopColor="#FFB759" />
          <stop offset="0.6" stopColor="#FFD475" />
          <stop offset="0.8" stopColor="#FFE686" />
          <stop offset="1" stopColor="#FFED8D" />
        </linearGradient>
        <clipPath id="clip0_23354_111759">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
