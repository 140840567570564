import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { AppFC } from 'types'
import Button from '../Button'
import ModalBox from '../ModalBox'

interface IIdleTrackingProps {
  logoutAction: (next?: string) => void
  timeoutMs?: number
  warningTimeoutMs?: number
  onTimeChangedUntilPrompt?: (secondsRemaining: number) => void
}

const DEFAULT_TIMEOUT = 1000 * 60 * 15 // 15 min
const DEFAULT_WARNING_TIMEOUT = 1000 * 60 * 5 // 5 min

const formatMinutesText = (minutesText: number) => {
  return minutesText > 1 ? 'minutes' : 'minute'
}

const IdleTracking: AppFC<IIdleTrackingProps> = ({
  logoutAction,
  timeoutMs = DEFAULT_TIMEOUT,
  warningTimeoutMs = DEFAULT_WARNING_TIMEOUT,
  onTimeChangedUntilPrompt,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [remainingSeconds, setRemainingSeconds] = useState<number>(timeoutMs / 1000)

  const timeoutInMinutes = timeoutMs / 1000 / 60
  const timeInSecondsUntilPrompt = Math.max(remainingSeconds - warningTimeoutMs / 1000, 0)
  const logoutRemainingTimeMinutes = Math.max(Math.floor(remainingSeconds / 60), 1)
  const logoutMinutesText = formatMinutesText(logoutRemainingTimeMinutes)

  const onIdle = () => {
    logoutAction(window.location.pathname)
    setModalVisible(false)
  }

  const onActive = () => {
    setModalVisible(false)
  }

  const onPrompt = () => {
    setModalVisible(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: timeoutMs,
    promptBeforeIdle: warningTimeoutMs,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(
      () => {
        setRemainingSeconds(Math.ceil(getRemainingTime() / 1000))
        if (onTimeChangedUntilPrompt && timeInSecondsUntilPrompt > 0)
          onTimeChangedUntilPrompt(timeInSecondsUntilPrompt / 60)
      },
      timeoutMs < 120_000 ? 500 : 1000 * 60
    )

    return () => {
      clearInterval(interval)
    }
  })

  const closeModal = () => {
    activate()
    setModalVisible(false)
  }

  return (
    <>
      {modalVisible && (
        <ModalBox title="Session Timeout" onClose={closeModal}>
          <div className="p-7">
            <p className="mb-2">
              As a security measure, we will log you out of GoTackle after you have been inactive
              for {timeoutInMinutes} {formatMinutesText(timeoutInMinutes)}. Please extend your
              session if you would like to continue.
            </p>
            <p className="mb-8">
              Logging out in {logoutRemainingTimeMinutes} {logoutMinutesText}...
            </p>
            <div className="flex">
              <div className="flex-auto mr-2">
                <Button variant="primary" label="Extend" onClick={closeModal} className="w-full" />
              </div>
              <div className="flex-auto ml-2">
                <Button variant="secondary" label="Log Out" onClick={onIdle} className="w-full" />
              </div>
            </div>
          </div>
        </ModalBox>
      )}
    </>
  )
}

export default IdleTracking
