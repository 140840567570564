import React, { FC } from 'react'
// import InfiniteScroll from 'react-infinite-scroll-component'
import UserAccountDetails from 'components/Admin/UserManagement/UserManagementList/UserAccountDetails'
import { IUser } from 'components/Admin/UserManagement/UserManagementList'
import TablePaginationV2 from 'components/Common/TablePaginationV2'

interface IUserAccountsProps {
  userAccounts: IUser[]
  setSelectedUser: (user: IUser | undefined) => void
  showAdminRightsToggle: boolean
  showAssociatedAdvisors: boolean
  setOpenResetPasswordModal: (value: boolean) => void
  setOpenUserManagementModal: (value: boolean) => void
  noOfItemsPerPageOptions?: { value: string; label: string }[]
  currentPage?: number
  noOfItemsPerPage?: number
  totalPages?: number
  moveNextHandler?: (pageNumber: number) => void
  movePrevHandler?: (pageNumber: number) => void
  updateNoOfItemPerPageHandler?: (itemsPerPage: string) => void
}

const UserAccounts: FC<IUserAccountsProps> = ({
  userAccounts,
  setSelectedUser,
  showAdminRightsToggle,
  showAssociatedAdvisors,
  setOpenResetPasswordModal,
  setOpenUserManagementModal,
  noOfItemsPerPageOptions,
  currentPage,
  noOfItemsPerPage,
  totalPages,
  moveNextHandler,
  movePrevHandler,
  updateNoOfItemPerPageHandler,
}) => {
  const emptyFunction = () => {}

  return (
    <>
      {userAccounts && (
        <div>
          {/* <InfiniteScroll loader="" next={() => {}} dataLength={userAccounts?.length} hasMore={true}> */}
          {userAccounts.map((user, index) => {
            return (
              <UserAccountDetails
                user={user}
                index={index}
                setSelectedUser={setSelectedUser}
                showAdminRightsToggle={showAdminRightsToggle}
                showAssociatedAdvisors={showAssociatedAdvisors}
                setOpenResetPasswordModal={setOpenResetPasswordModal}
                setOpenUserManagementModal={setOpenUserManagementModal}
              />
            )
          })}
          {/* </InfiniteScroll> */}
        </div>
      )}
      {userAccounts.length > 0 && (
        <TablePaginationV2
          options={noOfItemsPerPageOptions || []}
          noOfItemsPerPage={noOfItemsPerPage || 5}
          currentPage={currentPage || 1}
          totalPages={totalPages || 0}
          moveNextHandler={moveNextHandler || emptyFunction}
          movePreviousHandler={movePrevHandler || emptyFunction}
          updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler || emptyFunction}
        />
        // <div className='border h-24'></div>
      )}
    </>
  )
}

export default UserAccounts
