import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import Navbar from 'components/Common/Navbar'
import BackButton from 'components/Common/BackButton'
import { AppFC } from 'types'

export const BANNER_IMG_TEST_ID = 'BANNER_IMG_TEST_ID'
export const OVERLAY_TEST_ID = 'OVERLAY_TEST_ID'

export interface IOverlayPageProps {
  bannerImageSrc?: string
  children?: ReactNode
  user?: Object
  setShow?: Dispatch<SetStateAction<boolean>>
}

/**
 * A full page psuedo-modal designed to look like it is stacked on top of the previous screen. The "X" cross button
 * simulates "closing" the modal, but in fact just navigates to the previous page.
 * */
const OverlayPage: AppFC<IOverlayPageProps> = ({
  bannerImageSrc = '',
  children = null,
  user,
  setShow,
}) => {
  return (
    <div
      className="  h-[100dvh] w-screen max-w-screen overflow-x-hidden"
      data-testid={OVERLAY_TEST_ID}
    >
      <Navbar leftContent={() => <BackButton setShow={setShow} user={user} />} />
      <div className="max-w-[1352px] overflow-x-hidden m-auto bg-white">
        {bannerImageSrc && (
          <div className="max-w-none flex justify-center">
            <img
              className="max-w-none"
              src={bannerImageSrc}
              alt="banner"
              data-testid={BANNER_IMG_TEST_ID}
            ></img>
          </div>
        )}
        <main className="p-5">
          <>{children}</>
        </main>
      </div>
    </div>
  )
}

export default OverlayPage
