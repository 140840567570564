import React, { useEffect, useState } from 'react'

import { orderBy } from 'lodash'

import OwnerAssignment from 'components/Admin/UserManagement/OwnerAssignment'
import InputField from 'components/Common/InputField'
import Loader from 'components/Loaders'
import SearchIcon from 'assets/svgs/search-icon.svg'

const AssignOwnerToAdvisor = ({
  height,
  owners,
  methods,
  padding,
  onSubmit,
  firstName,
  isUserCreation,
  assignedOwnerList,
  isUserProfileFormSet,
  isChanged = (e: boolean) => {},
}) => {
  const [allFilteredOwners, setAllFilteredOwners]: any = useState([])
  const [filteredAssignedOwners, setFilteredAssignedOwners]: any = useState([])
  const [assignedOwners, setAssignedOwners]: any = useState()
  const [allOwners, setAllOwners]: any = useState()

  const { register, handleSubmit, setValue } = methods

  useEffect(() => {
    register('owners')
  }, [register])

  useEffect(() => {
    setValue(
      'owners',
      filteredAssignedOwners?.map((owner) => owner.value)
    )
  }, [filteredAssignedOwners, setValue])

  useEffect(() => {
    setFilteredAssignedOwners(assignedOwnerList || [])
    setAssignedOwners(assignedOwnerList || [])
    setAllFilteredOwners(owners)
    setAllOwners(owners)

    return () => {
      setFilteredAssignedOwners([])
      setAllFilteredOwners([])
      setAssignedOwners([])
      setAllOwners([])
    }
  }, [assignedOwnerList, owners])

  const addOwner = (owner) => {
    const isAlreadyAdded = filteredAssignedOwners?.find((user) => user?.value === owner.value)
    if (isAlreadyAdded) return
    owner.isNew = true
    let sortedClient = [owner, ...filteredAssignedOwners]
    sortedClient = orderBy(sortedClient, ['lastName'], ['asc'])

    setFilteredAssignedOwners(sortedClient)
    setAssignedOwners(sortedClient)
    isChanged(true)
  }

  const removeOwner = (owner) => {
    const owners = assignedOwners?.filter((item) => item.value !== owner.value)
    setAssignedOwners(owners)
    setFilteredAssignedOwners(filteredAssignedOwners?.filter((item) => item.value !== owner.value))
    isChanged(true)
  }

  const getAllFilteredOwner = (e) => {
    const searchValue = e.target.value.toLowerCase()
    if (searchValue) {
      let data = []
      data = allOwners.filter(
        (item: any) =>
          item.label.toLowerCase().includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue)
      )
      setAllFilteredOwners(data)
    } else setAllFilteredOwners(owners)
  }

  const getAssignedFilterOwner = (e) => {
    const searchValue = e.target.value.toLowerCase()
    if (searchValue) {
      let data = []
      data = assignedOwners?.filter(
        (item: any) =>
          item.label.toLowerCase().includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue)
      )
      setFilteredAssignedOwners(data)
    } else setFilteredAssignedOwners(assignedOwners)
  }

  return (
    <>
      {!isUserProfileFormSet && !isUserCreation ? (
        <Loader loader="ClientAssignment" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${padding} bg-white inline-flex w-full px-7 pt-5`}>
            <label className="text-base leading-6 font-primary text-primary-text w-full font-bold">
              Client Assignment
            </label>

            <div className="inline-flex gap-[65px] justify-end font-primary text-base text-silver-darker w-full items-center">
              <span className="">
                <label className="font-medium leading-6">Assigned: </label>
                <label className="text-zinc font-bold leading-6">{assignedOwners?.length}</label>
              </span>
              <label className="ml-10">
                <label className="font-medium leading-6">Remaining: </label>
                <label className="text-zinc font-bold leading-6">
                  {allOwners?.length - assignedOwners?.length}
                </label>
              </label>
            </div>
          </div>

          <div className={`grid grid-cols-12 gap-9 bg-white px-7 ${padding ? ' pb-2' : 'pb-5'}`}>
            <div className={`col-span-6 left ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
              <div className="grid-cols-12 relative mt-9">
                <div className="flex items-center justify-between">
                  <span>
                    <label className="font-primary text-primary-text font-medium leading-6">
                      All Clients
                    </label>
                  </span>
                  <span>
                    <label
                      className="text-base tracking-[.32px] font-fatfrank font-black cursor-pointer text-primary hover:text-primary-brand"
                      onClick={() => {
                        allOwners.forEach((owner) => {
                          const isAssigned = assignedOwners.some(
                            (assignedOwner) => owner.value === assignedOwner.value
                          )
                          if (!isAssigned) {
                            owner.isNew = true
                          }
                        })
                        setFilteredAssignedOwners([...allOwners])
                        setAssignedOwners([...allOwners])
                        isChanged(true)
                      }}
                    >
                      Add All Clients
                    </label>
                  </span>
                </div>
              </div>
              <div className={`mt-1 ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
                <div className="pr-12">
                  <InputField
                    icon={SearchIcon}
                    name="search"
                    type={'search'}
                    onChange={getAllFilteredOwner}
                    placeholder="Search by name or email"
                  />
                </div>
                <div
                  className={`border-[1px] border-primary-outline mt-2 pt-2 pl-2   ${height} ${
                    isUserCreation ? ' overflow-y-scroll' : 'flex flex-col	flex-1 overflow-y-auto'
                  }`}
                >
                  {allFilteredOwners?.map((owner, index) => {
                    return (
                      <OwnerAssignment
                        allFilteredOwners={allFilteredOwners}
                        filteredAssignedOwners={filteredAssignedOwners}
                        assignedOwners={assignedOwners}
                        removeOwner={removeOwner}
                        owner={owner}
                        index={index}
                        isAssignedOwner={false}
                        addOwner={addOwner}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={`col-span-6 right ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
              <div className="grid-cols-12 relative mt-9">
                <div className="flex items-center justify-between">
                  <span>
                    <label className="font-primary text-primary-text font-medium leading-6">
                      {firstName ? `${firstName}’s Clients` : 'Assigned Clients'}
                    </label>
                  </span>
                  <span>
                    <label
                      className="text-base tracking-[.32px] font-fatfrank font-black cursor-pointer text-primary hover:text-primary-brand"
                      onClick={() => {
                        setFilteredAssignedOwners([])
                        setAssignedOwners([])
                        isChanged(true)
                      }}
                    >
                      Remove All Clients
                    </label>
                  </span>
                </div>
              </div>

              <div className={`mt-1 ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
                <div className="pr-12">
                  <InputField
                    name="search"
                    type={'search'}
                    icon={SearchIcon}
                    onChange={getAssignedFilterOwner}
                    placeholder="Search by name or email"
                  />
                </div>
                <div
                  className={`border-[1px] border-primary-outline mt-2 pt-2 pl-2 overflow-y-auto ${height} ${
                    isUserCreation ? 'overflow-y-scroll' : 'flex flex-col	flex-1 overflow-y-auto'
                  }`}
                >
                  {filteredAssignedOwners?.map((owner, index) => {
                    return (
                      <OwnerAssignment
                        allFilteredOwners={allFilteredOwners}
                        filteredAssignedOwners={filteredAssignedOwners}
                        assignedOwners={assignedOwners}
                        removeOwner={removeOwner}
                        owner={owner}
                        index={index}
                        isAssignedOwner={true}
                        addOwner={addOwner}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

AssignOwnerToAdvisor.defaultProps = {
  assignment: '',
  padding: 'pt-2',
  height: 'min-h-[68px] max-h-[50vh]',
  isUserCreation: false,
}

export default AssignOwnerToAdvisor
