import React from 'react'
import { FIELD_NAME, LABEL } from 'config'
import { AppFC, IOptionsItem } from 'types'
import InputField from 'components/Common/InputField'
import SelectField from 'components/Common/SelectField'
import { calculateYearMonths, isFutureDate, yearOptions } from 'utils/helper'

import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormReturn,
  UseFormTrigger,
} from 'react-hook-form'
import {
  BUSINESS_STRUCTURE,
  monthOptions,
  registeredBusinessStructure,
  resetFields,
} from 'components/MonthlyAttestation/utils'
interface IRegisterBusinessFields {
  year: string
  month: string
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: UseFormReturn['setValue']
  trigger: UseFormTrigger<FieldValues>
  registerBusinessStructureOptions: Array<IOptionsItem>
  watch: (name: string) => FieldValues[keyof FieldValues]
}
const RegisterBusinessFields: AppFC<IRegisterBusinessFields> = ({
  errors,
  year,
  month,
  watch,
  control,
  setValue,
  trigger,
  registerBusinessStructureOptions,
}) => {
  const registeredBusinessValue = watch(BUSINESS_STRUCTURE.REGISTER_BUSINESS_OPTION_ID)

  return (
    <>
      <Controller
        name={FIELD_NAME.REGISTER_BUSINESS_STRUCTURE_OPTION_ID}
        control={control}
        render={({ field }) => (
          <SelectField
            {...field}
            classes={'mt-4'}
            label={LABEL.REGISTER_BUSINESS_STRUCTURE}
            options={registerBusinessStructureOptions?.filter((obj) =>
              obj.industry.includes('attestation')
            )}
            onChange={(e) => {
              if (e === registeredBusinessStructure.noBusinessOptionId) {
                resetFields(setValue)
              }
              field.onChange(e)
              trigger(FIELD_NAME.MONTH)
              trigger(FIELD_NAME.YEAR)
            }}
            placeholder={`Select ${LABEL.REGISTER_BUSINESS_STRUCTURE}`}
          />
        )}
      />
      <div className=" grid grid-cols-12 gap-2 mt-3 ">
        <div className="col-span-6">
          <Controller
            name={FIELD_NAME.MONTH}
            control={control}
            render={({ field }) => (
              <SelectField
                {...field}
                label={LABEL.MONTH}
                errors={errors?.month || isFutureDate(month, year)}
                options={monthOptions}
                disable={
                  registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
                  registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
                  !registeredBusinessValue
                }
                placeholder="Select"
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Controller
            name={FIELD_NAME.YEAR}
            control={control}
            render={({ field }) => (
              <SelectField
                {...field}
                label={LABEL.YEAR}
                options={yearOptions()}
                errors={errors?.year}
                disable={
                  registeredBusinessValue === registeredBusinessStructure.noBusinessOptionId ||
                  registeredBusinessValue === registeredBusinessStructure.tellMeMoreOptionId ||
                  !registeredBusinessValue
                }
                placeholder="Select"
              />
            )}
          />
          <div></div>
        </div>
      </div>
      <InputField
        className="mt-3"
        type={'text'}
        label={LABEL.YEARS_IN_BUSINESS}
        value={calculateYearMonths(month, year)}
        disabled={true}
      />
    </>
  )
}
export default RegisterBusinessFields
