import { isEmpty } from 'lodash'
import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { CrossIcon } from '../SvgIcons'

export const BACK_BUTTON_TEST_ID = 'BACK_BUTTON_TEST_ID'

/** Open for additonal options... */
const IconButtons = {
  cross: CrossIcon,
}

export interface IBackButtonProps {
  icon?: 'cross'
  className?: string
  setShow?: Dispatch<SetStateAction<boolean>>
  user?: Object
}

const iconClasses = 'fill-primary hover:fill-primary-hover hover:cursor-pointer'

/** A button that navigates the user to previous page when clicked. The button is not rendered if the page was visited through a direct link and no previous page exists. */
const BackButton: FC<IBackButtonProps> = ({ className = '', icon = 'cross', user, setShow }) => {
  const history = useHistory()
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const Icon = useMemo(() => IconButtons[icon], [icon])

  return history?.length > 1 ? (
    <div className={`flex items-center justify-center ${className}`}>
      <span
        data-testid={BACK_BUTTON_TEST_ID}
        onClick={() => (isEmpty(user) ? goBack() : setShow && setShow(false))}
      >
        <Icon className={iconClasses} />
      </span>
    </div>
  ) : null
}

export default BackButton
