import React from 'react'

interface IFilledArrowDownIconProps {
  className: string
}

const FilledArrowDownIcon: React.FC<IFilledArrowDownIconProps> = ({ className }) => {
  return (
    <svg
      width="25"
      height="24"
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 10L12.5 15L17.5 10H7.5Z" fill="#5E06B9" />
    </svg>
  )
}

FilledArrowDownIcon.defaultProps = {
  className: '',
}

export default FilledArrowDownIcon
