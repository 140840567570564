import { setFlashUrlParams } from 'utils/flashParameters'
import { ApiResponseError } from 'utils/error-handling/api-response-error'
import { ErrorType } from '../../../../__generated__/api-types-and-hooks'

export const handleSetUserMeetingError = (errors: ApiResponseError[]) => {
  for (const error of errors) {
    if (error.errorType === ErrorType.FailedMeetingEmailInvites) {
      const failedInvitesError = error?.errorInfo
      const flashParams = {
        meetingId: failedInvitesError?.meetingId,
        failedEmails: failedInvitesError?.failedInvitesEmails.join(','),
        meetingCreationStatus: 'failed',
        toastVisibilityTimeout: '5000',
      }
      setFlashUrlParams(flashParams)
      return
    }
  }

  const flashParams = {
    meetingCreationStatus: 'failed',
    toastVisibilityTimeout: '5000',
    toastMessage: 'Meeting could not be created. Please try to request the meeting again.',
    toastVariant: 'alert',
  }
  setFlashUrlParams(flashParams)
  const messages = errors.map(({ message }) => message).join(', ')
  throw new Error(messages)
}
