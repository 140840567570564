import React from 'react'

function MsPowerPointIcon({ className }) {
  return (
    <svg
      className={className}
      width="33"
      height="44"
      viewBox="0 0 33 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0V11H33L22 0ZM19.25 11V0H4.125C1.8468 0 0 1.8468 0 4.125V39.875C0 42.1523 1.8468 44 4.125 44H28.875C31.1532 44 33 42.1532 33 39.875V13.75H22.0773C20.4789 13.75 19.25 12.5211 19.25 11ZM24.0281 26.4773C24.4234 30.3789 21.3555 33.6875 17.5312 33.6875H13.75V37.125C13.75 37.8812 13.1313 38.5 12.375 38.5H11C10.2407 38.5 9.625 37.8843 9.625 37.125V22C9.625 21.2407 10.2407 20.625 11 20.625H17.1454C20.5648 20.625 23.6844 23.0742 24.0281 26.4773ZM13.75 29.5625H17.5312C18.8581 29.5625 19.9375 28.4831 19.9375 27.1562C19.9375 25.8294 18.8547 24.75 17.5312 24.75H13.75V29.5625Z"
        fill="black"
      />
    </svg>
  )
}
export default MsPowerPointIcon
