import { call, put, takeLatest, select } from 'redux-saga/effects'
import UserApi from 'api/user'

import {} from 'store/actions/user'
import { GET_BSO_PROFILE, SEARCH_INDUSTRY_REPORT } from 'store/types'
import { getBSOProfileAction, searchIndustryReportAction } from 'store/actions/bso'
import { Action } from 'types'
import { IndustryReport } from '__generated__/api-types-and-hooks'

export type SearchIndustryReportPayload = {
  keyword: string
}

function* getBSOProfile() {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const bsoRes = yield call(UserApi.getBSOProfile, tenantId)

    if (bsoRes) {
      yield put(getBSOProfileAction.FULLFILLED(bsoRes.getBusinessSupportOrganization))
    }
  } catch (error) {
    console.log('get bso profile error  : ', error)
    yield put(getBSOProfileAction.REJECTED())
  }
}

function* searchIndustryReport(action: Action<string, SearchIndustryReportPayload>) {
  const { keyword } = action.payload

  try {
    const res = (yield call(UserApi.searchIndustryReport, keyword))
      .searchIndustryReport as IndustryReport
    if (res) {
      yield put(searchIndustryReportAction.FULLFILLED(res))
    }
  } catch (error) {
    console.log('search industry report error  : ', error)
    yield put(searchIndustryReportAction.REJECTED(error))
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherBSO() {
  yield takeLatest(GET_BSO_PROFILE.STARTED, getBSOProfile)
  yield takeLatest(SEARCH_INDUSTRY_REPORT.STARTED, searchIndustryReport)
}
