import { GET_ATTESTATION_SUMMARY } from 'store/types'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  GetAttestationSummaryAction,
  getAttestationSummaryAction,
} from 'store/actions/attestationSummary'
import AttestationSummaryApi from 'api/attestationSummary'
import { QueryResponse } from 'types'

function* getAttestationSummary(action: GetAttestationSummaryAction.STARTED) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    type Res = QueryResponse<'getAttestationSummary'>
    const res = yield call(AttestationSummaryApi.getAttestationSummary, {
      ...action.payload,
      tenantId,
    }) as unknown as Res
    if (res && (res as Res)?.getAttestationSummary) {
      yield put(getAttestationSummaryAction.FULLFILLED(res.getAttestationSummary))
    }
  } catch (error) {
    console.log('Error getting attestation summary:', error)
    yield put(getAttestationSummaryAction.REJECTED(error))
  }
}

export function* watcherAttestationSummary() {
  yield takeLatest(GET_ATTESTATION_SUMMARY.STARTED, getAttestationSummary)
}
