import React from 'react'

function ExclamationIcon({ className = '' }) {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM8.75 4V4.75V8.25V9H7.25V8.25V4.75V4H8.75ZM7.25 11.5V10H8.75V11.5H7.25Z" />
    </svg>
  )
}
ExclamationIcon.defaultProps = {
  className: '',
}
export default ExclamationIcon
