import {
  UPDATE_PLAY_STEP,
  UPDATE_SETUP_STEP_ID,
  GET_PLAY,
  AUTHORIZE_CONNECTOR,
  UPDATE_PLAY_STAGE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  RESET_PLAY,
  GET_PLAY_RESPONSE,
  SAVE_USER_PLAY_FILE,
} from 'store/types'

const initialState = {
  play: {},
  assessmentResponse: null,
  authorizeConnector: {},
  playStage: {},
  isPlayCompleted: null,
  playResponseData: {},
  fileData: {},
  uploadFileLoading: false,
}

function reducer(state = initialState, action) {
  // return state
  switch (action.type) {
    case RESET_PLAY: {
      return {
        play: {},
        assessmentResponse: null,
        authorizeConnector: {},
        playStage: {},
      }
    }
    case GET_PLAY.FULLFILLED: {
      let {
        play,
        playStage,
        assessmentResponse,
        isPlayCompleted,
        getResponse,
        isUpdateStage,
        isPlayInReview,
        disableButton,
      } = action?.payload

      return {
        ...state,
        play,
        playStage,
        isPlayInReview,
        assessmentResponse,
        isPlayCompleted,
        getResponse,
        isUpdateStage,
        disableButton,
      }
    }

    case UPDATE_PLAY_STEP.FULLFILLED:
      return {
        ...state,
        activeStep: 'guide',
      }
    case UPDATE_SETUP_STEP_ID:
      return {
        ...state,
      }

    case AUTHORIZE_CONNECTOR.FULLFILLED:
      return {
        ...state,
        authorizeConnector: action.payload,
      }

    case UPDATE_PLAY_STAGE.FULLFILLED:
      return {
        ...state,
        playStage: action.payload.playStage,
      }

    case GET_PLAY_ASSESSMENT_RESPONSE.FULLFILLED:
      let { assessmentResponse } = action.payload
      return {
        ...state,
        assessmentResponse,
      }
    case GET_PLAY_RESPONSE.FULLFILLED:
      return {
        ...state,
        playResponseData: action.payload,
      }
    case SAVE_USER_PLAY_FILE.STARTED:
      return {
        ...state,
        fileData: {},
        uploadFileLoading: true,
      }
    case SAVE_USER_PLAY_FILE.FULLFILLED:
      return {
        ...state,
        fileData: action.payload,
        uploadFileLoading: false,
      }
    default:
  }
  return state
}

export default reducer
