import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const DirectoryListLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 600"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <circle cx="53" cy="47" r="25" />
        <rect x="95" y="30" rx="7" ry="7" width="107" height="10" />
        <rect x="99" y="54" rx="6" ry="6" width="64" height="8" />
        <circle cx="410" cy="45" r="16" />
        <rect x="15" y="91" rx="0" ry="0" width="425" height="1" />

        <circle cx="53" cy="147" r="25" />
        <rect x="95" y="130" rx="7" ry="7" width="107" height="10" />
        <rect x="99" y="154" rx="6" ry="6" width="64" height="8" />
        <circle cx="410" cy="145" r="16" />
        <rect x="15" y="191" rx="0" ry="0" width="425" height="1" />

        <circle cx="53" cy="247" r="25" />
        <rect x="95" y="230" rx="7" ry="7" width="107" height="10" />
        <rect x="99" y="254" rx="6" ry="6" width="64" height="8" />
        <circle cx="410" cy="245" r="16" />
        <rect x="15" y="291" rx="0" ry="0" width="425" height="1" />

        <circle cx="53" cy="347" r="25" />
        <rect x="95" y="330" rx="7" ry="7" width="107" height="10" />
        <rect x="99" y="354" rx="6" ry="6" width="64" height="8" />
        <circle cx="410" cy="345" r="16" />
        <rect x="15" y="391" rx="0" ry="0" width="425" height="1" />
      </ContentLoader>
    </>
  )
}

DirectoryListLoader.defaultProps = {
  isMobile: false,
}

export default DirectoryListLoader
