import React from 'react'

function NotificationBellIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="27"
      viewBox="0 0 24 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1957_128)">
        <path d="M11.999 0C11.0508 0 10.2847 0.754102 10.2847 1.6875V2.7C6.37397 3.48047 3.42755 6.88711 3.42755 10.9688V11.9602C3.42755 14.4387 2.50076 16.8328 0.829332 18.6891L0.432903 19.1268C-0.0170969 19.6225 -0.12424 20.3344 0.148975 20.9408C0.422189 21.5473 1.03826 21.9375 1.71326 21.9375H22.2847C22.9597 21.9375 23.5704 21.5473 23.849 20.9408C24.1275 20.3344 24.015 19.6225 23.565 19.1268L23.1686 18.6891C21.4972 16.8328 20.5704 14.4439 20.5704 11.9602V10.9688C20.5704 6.88711 17.624 3.48047 13.7133 2.7V1.6875C13.7133 0.754102 12.9472 0 11.999 0ZM14.4258 26.0139C15.0686 25.3811 15.4275 24.5215 15.4275 23.625H11.999H8.5704C8.5704 24.5215 8.92933 25.3811 9.57219 26.0139C10.215 26.6467 11.0883 27 11.999 27C12.9097 27 13.7829 26.6467 14.4258 26.0139Z" />
      </g>
      <defs>
        <clipPath id="clip0_1957_128">
          <rect width="24" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
NotificationBellIcon.defaultProps = {
  className: '',
}

export default NotificationBellIcon
