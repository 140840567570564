import React from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'

const gradientId = 'piechart-gradient'
const progressFill = `url(#${gradientId})`
const remainingFill = '#C4C4C4'
const aqua = '#77FADB'
const blue = '#52B4F9'

const DonutChart = ({ value, total }) => {
  const percentage = value && total ? (value / total) * 100 : 0

  const getData = () => {
    const details = [
      { name: 'Remaining', value: total - value },
      { name: 'Value', value },
    ]
    return details
  }

  return (
    <PieChart width={120} height={120}>
      <defs>
        <linearGradient id={gradientId} x1="1" y1="1" x2="0" y2="0">
          <stop offset="30%" stopColor={blue} />
          <stop offset="95%" stopColor={aqua} />
        </linearGradient>
      </defs>
      <Pie data={getData()} dataKey="value" outerRadius={55} innerRadius={43}>
        <Label
          value={`${parseInt(`${percentage}`)}%`}
          dy={4}
          position="centerBottom"
          className="font-bold fill-black"
          fontSize="20px"
        />
        <Label
          value={`${value}/${total}`}
          dy={12}
          position="centerTop"
          className="text-bold fill-black"
          fontSize="14px"
        />
        <Cell id="remaining" fill={remainingFill} stroke="none" />
        <Cell id="progress" fill={progressFill} />
      </Pie>
    </PieChart>
  )
}

export default DonutChart
