import get from 'lodash/get'
import playBookApi from 'api/playBook'

import { select, call, put, takeLatest } from 'redux-saga/effects'
import { getDashboardListingAction, getAssessmentPlayListingAction } from 'store/actions/PlayBook'
import { GET_DASHBOARD_LISTING, GET_ASSESSMENT_PLAY_LISTING } from 'store/types'
import { Assessment, Play } from '__generated__/api-types-and-hooks'

function* getDashboardListing(action) {
  const tenantId = yield select((state) => state.user.tenantId)

  let data = yield call(playBookApi.getDashboardListing, tenantId, action?.payload?.category)

  if (get(data, 'getDashboardListing.data')) {
    const dashboardList = JSON.parse(get(data, 'getDashboardListing.data')) as {
      plays: Play[]
      assessments: Assessment[]
    }
    yield put(getDashboardListingAction.FULLFILLED(dashboardList))
  }
}
function* getAssessmentPlayListing() {
  const tenantId = yield select((state) => state.user.tenantId)

  let data = yield call(playBookApi.getAssessmentPlayListing, tenantId)
  if (get(data, 'getAssessmentPlayListing.data')) {
    const assessmentPlayList = JSON.parse(get(data, 'getAssessmentPlayListing.data'))
    yield put(getAssessmentPlayListingAction.FULLFILLED(assessmentPlayList))
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherPlayBook() {
  yield takeLatest(GET_DASHBOARD_LISTING.STARTED, getDashboardListing)
  yield takeLatest(GET_ASSESSMENT_PLAY_LISTING.STARTED, getAssessmentPlayListing)
}
