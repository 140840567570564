import styled from 'styled-components'

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 23px;
    height: 25px;
    /* border-radius: 4px; */
    outline: none;
    /* border: 2px solid #5bbab9; */
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
  }
  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 4px;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 8px;
    width: 7px;
    height: 14px;
    border: solid #5e06b9;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  input[type='checkbox']:checked {
    /* border-color: #5bbab9; */
    /* background-color: #5bbab9; */
    accent-color: #5bbab9;
    color: #5e06b9;
  }
`
