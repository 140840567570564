import React, { Fragment, useEffect, useState } from 'react'
import Button from 'components/Common/Button'
import ModalBox from 'components/Common/ModalBox'
import UploadFiles from 'components/Common/Upload/UploadFiles'
import FilesCard from 'components/Common/FilesCard'
import StreamChat from 'components/Common/FilesCard/StreamChat'
import XIcon from 'components/Common/SvgIcons/XIcon'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import { CustomFile } from 'types'

const Files = ({
  userId,
  userFiles,
  streamChatId,
  uploadedFile,
  uploadFileErrors,
  uploadFileAction,
  getUserFilesAction,
  saveUserFileAction,
  resetUploadFileAction,
  uploadFileLoading,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const userRole = getRoleFromPath()

  useEffect(() => {
    getUserFilesAction({ userId: userId })
    // eslint-disable-next-line
  }, [])

  const closemodal = () => {
    setShowUploadModal(false)
  }
  const getFiles = (files: CustomFile) => {
    uploadFileAction({
      files,
      isMultiple: true,
    })
  }
  const saveFile = () => {
    saveUserFileAction({
      generationNotification: true,
      userId,
    })
    setShowUploadModal(false)
  }

  return (
    <div
      className={`xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 lg:col-span-3 sm:overflow-auto ${
        userRole !== ROLES.BUSINESS_OWNER ? 'xs:p-4' : ''
      }`}
    >
      <div
        className={`${
          userRole !== ROLES.BUSINESS_OWNER ? 'text-2xl' : 'text-xl font-bold my-5 '
        } font-primary`}
      >
        Files
      </div>
      <div className="flex">
        <Button
          label="Upload Files"
          variant="secondary"
          type={'button'}
          className="max-w-1/2"
          onClick={() => setShowUploadModal(true)}
        />
      </div>
      {userFiles &&
        userFiles.map((file, index) => {
          return (
            <Fragment key={index}>
              {file.url ? (
                <FilesCard file={file} />
              ) : (
                <StreamChat file={file} userId={userId} streamChatId={streamChatId} />
              )}
            </Fragment>
          )
        })}
      {showUploadModal && (
        <ModalBox onClose={closemodal} title="File Upload" Icon={XIcon}>
          <UploadFiles
            setShowUploadModal={setShowUploadModal}
            showUploadModal={showUploadModal}
            uploadedFile={uploadedFile}
            uploadFileErrors={uploadFileErrors}
            resetUploadFileAction={resetUploadFileAction}
            uploadFileLoading={uploadFileLoading}
            isBorderVariantChanged={true}
            userId={userId}
            getFiles={getFiles}
            saveFile={saveFile}
          />
        </ModalBox>
      )}
    </div>
  )
}

export default Files
