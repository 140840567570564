import React from 'react'

function HelpIcon() {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.5" cy="13.5" r="13.5" fill="#5E06B9" />
      <g clipPath="url(#clip0_1876_5623)">
        <path
          d="M11.8349 18.99H14.1299V21.285H11.8349V18.99ZM13.3649 5.98499C17.4577 6.15329 19.2401 10.2843 16.8074 13.3825C16.1725 14.1475 15.1474 14.6524 14.6425 15.295C14.1299 15.93 14.1299 16.695 14.1299 17.46H11.8349C11.8349 16.1824 11.8349 15.1038 12.3475 14.3388C12.8524 13.5738 13.8775 13.1224 14.5124 12.6175C16.3637 10.9039 15.9047 8.47889 13.3649 8.27999C12.7562 8.27999 12.1725 8.52178 11.7421 8.95218C11.3117 9.38257 11.0699 9.96631 11.0699 10.575H8.7749C8.7749 9.35764 9.25849 8.19016 10.1193 7.32937C10.9801 6.46857 12.1476 5.98499 13.3649 5.98499Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1876_5623">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default HelpIcon
