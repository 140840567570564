import { createSelector } from 'reselect'
import { IUserRecommendations } from 'types'

const getAssessments = (state) => state.assessment
const getRecommendations = (state) => state.owner.recommendations

export const getCurrentAssessmentSelector = createSelector([getAssessments], (assessments) => {
  const { currentAssessmentNumber, saleAssessments } = assessments
  return saleAssessments[currentAssessmentNumber]
})

export const getAssessmentSummarySelector = createSelector([getAssessments], (assessments) => {
  const { currentAssessmentNumber, saleAssessments, responses } = assessments
  let summary: any = []
  for (let i = 0; i <= currentAssessmentNumber; i += 1) {
    summary.push({
      ...saleAssessments[i],
      response: responses[i],
    })
  }
  return summary
})

/** Returns plays and assessments that are not yet completed by the user */
export const getRecommendationsSelector = createSelector(
  [getRecommendations],
  (recommendations) => {
    return recommendations
      ? recommendations.filter((recommendation) => {
          return Boolean(recommendation?.meta?.isCompleted) === false
        })
      : []
  }
)

export const getPlaysFromRecommendationsSelector = createSelector(
  [getRecommendations],
  (recommendations: IUserRecommendations[]) => {
    return recommendations
      ? recommendations.filter((recommendation) => {
          return recommendation.meta?.isCompleted === false && recommendation.meta?.type === 'play'
        })
      : []
  }
)
