import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class ATTESTATION_SUMMARY_API {
  getAttestationSummary = (payload: api.GetAttestationSummaryInput) => {
    return API.fetch<'getAttestationSummary'>(api['GetAttestationSummaryDocument'], {
      input: payload,
    })
  }
}

// eslint-disable-next-line
export default new ATTESTATION_SUMMARY_API()
