import styled from 'styled-components'
import tw from 'twin.macro'

export const ViewWrapper = styled.div`
  ${tw`w-full h-full flex flex-col md:flex-row`}
`

export const CategorySectionWrapper = styled.section`
  ${tw`p-6 sm:p-8 mdl:px-12 bg-white md:max-w-[40vw] md:flex md:flex-col md:gap-1.5`}
`

export const IconAndTitleBoxWrapper = styled.div`
  ${tw`flex items-center sm:justify-center sm:relative sm:right-2 md:right-0 md:justify-start`}
`

export const IconContainerWrapper = styled.div`
  ${tw`flex`}
`

export const CategoryTitleWrapper = styled.h1`
  ${tw`font-secondary text-xl sm:text-2xl relative right-1 whitespace-nowrap`}
`

export const RecommendationSectionWrapper = styled.section`
  ${tw`px-5 py-6 sm:py-8 sm:px-8 sm:p-8 lg:px-14 lg:py-10 h-full w-full`}
`

export const RecommendationContentBoxWrapper = styled.div`
  ${tw`h-full w-full flex flex-col flex-auto gap-8 max-w-[1000px]`}
`

export const DescriptionWrapper = styled.p`
  ${tw`lg:max-w-[470px]`}
`

export const CardSpacerWrapper = styled.div`
  ${tw`flex flex-col gap-6`}
`
