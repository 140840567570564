import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const advisorConnectedToolLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="5" rx="0" ry="0" width="120" height="27" />
        <rect x="48" y="60" rx="0" ry="0" width="260" height="27" />
        <rect x="48" y="132" rx="0" ry="0" width="260" height="41" />
      </ContentLoader>
    </>
  )
}

export default advisorConnectedToolLoader
