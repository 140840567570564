import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { IOptions } from 'types'
import Loader from 'components/Loaders'
import hookForms from 'utils/hookForms'
import { useForm } from 'react-hook-form'
import { FIELD_NAME, FORM, LABEL } from 'config'
import { yupResolver } from '@hookform/resolvers/yup'
import InputField from 'components/Common/InputField'
import useFormContainer from 'hooks/useFormContainer'
import RegisterBusinessFields from './RegisterBusinessFields'
import BusinessEmployeesFields from './BusinessEmployeesFields'
import { clientDetailsBusinessSchema } from 'pages/Owner/validation'
import BusinessClassificationFields from './BusinessClassificationsFields'
import {
  AdditionalBusinessProfileWrapper,
  BusinessProfileWrapper,
  FormWrapper,
} from 'components/Common/EditBusinessProfile/style'
import { useCheckInitialAssessmentFormValid } from 'hooks/useCheckInitialAssessmentFormValid'
import { isFutureDate } from 'utils/helper'
import { isEmpty } from 'lodash'
interface IEditClientDetailsBusinessProfile {
  className: string
  options: IOptions
  detailsLoading: boolean
  onSubmit: (payload) => void
  setIsFormValid: (payload) => void
  isAssessmentOptionsLoading: boolean
  businessClassificationOptionId: string
  checkErrors: (errors, isValid) => void
  setIndustryChanged: Dispatch<SetStateAction<boolean>>
}

const EditClientDetailsBusinessProfile: FC<IEditClientDetailsBusinessProfile> = ({
  options,
  onSubmit,
  checkErrors,
  setIsFormValid,
  detailsLoading,
  className = '',
  setIndustryChanged,
  isAssessmentOptionsLoading,
  businessClassificationOptionId,
}) => {
  const formValues = hookForms.getForm(FORM.CLIENT_BUSINESS_PROFILE)?.getValues()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(clientDetailsBusinessSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = methods

  useEffect(() => {
    checkErrors(
      !isEmpty(errors) ? errors : isFutureDate(formValues?.month, formValues?.year),
      isValid
    )
    // eslint-disable-next-line
  }, [checkErrors, Object.keys(errors), formValues?.month, isValid, formValues?.year])

  useCheckInitialAssessmentFormValid(
    isDirty,
    formValues,
    setIsFormValid,
    setIndustryChanged,
    businessClassificationOptionId
  )

  useFormContainer(FORM.CLIENT_BUSINESS_PROFILE, methods)
  return (
    <>
      <BusinessProfileWrapper data-testid="client-detail-fields">
        <div className="my-8">
          <label className="font-normal text-2xl">Business Profile</label>
        </div>
        {detailsLoading || isAssessmentOptionsLoading ? (
          <Loader loader="AdvisorEditOwnerDetail" />
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormWrapper>
                <div className={className}>
                  <InputField
                    id={'businessName'}
                    errors={errors.businessName}
                    type={'text'}
                    label={LABEL.BUSINESS_NAME}
                    {...register(FIELD_NAME.BUSINESS_NAME)}
                  />
                </div>
                <div className={className}>
                  <InputField
                    id={'businessEmail'}
                    errors={errors.businessEmail}
                    type={'text'}
                    label={LABEL.BUSINESS_EMAIL}
                    {...register(FIELD_NAME.BUSINESS_EMAIL)}
                  />
                </div>
                <div className={className}>
                  <InputField
                    id={'domainName'}
                    errors={errors.domainName}
                    type={'text'}
                    label={LABEL.BUSINESS_WEBSITE}
                    {...register(FIELD_NAME.DOMAIN_NAME)}
                  />
                </div>
                <AdditionalBusinessProfileWrapper>
                  <RegisterBusinessFields
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    registerBusinessStructureOptions={
                      options[FIELD_NAME.REGISTER_BUSINESS_STRUCTURE]
                    }
                    watch={watch}
                    year={formValues?.year}
                    month={formValues?.month}
                    setValue={setValue}
                  />
                  <BusinessClassificationFields
                    control={control}
                    options={options}
                    formValues={formValues}
                  />
                  <BusinessEmployeesFields errors={errors} register={register} />
                </AdditionalBusinessProfileWrapper>
              </FormWrapper>
            </form>
          </>
        )}
      </BusinessProfileWrapper>
    </>
  )
}
export default EditClientDetailsBusinessProfile
