import React from 'react'

import { AppFC } from 'types'

import Button from 'components/Common/Button'
import Highlighter from 'react-highlight-words'

interface IClientSearchOptionProps {
  addOwner: (data: any) => any
  data: any
  selectProps: { inputValue: string }
}

const ClientSearchOption: AppFC<IClientSearchOptionProps> = ({ addOwner, data, selectProps }) => {
  return (
    <>
      <div className="flex justify-between items-center py-1 hover:!bg-cream-dark active:!bg-cream-dark px-3">
        <div className="font-primary text-base font-normal text-primary-text mr-1">
          <label className="block">
            <Highlighter
              highlightStyle={{ fontWeight: 'bold', backgroundColor: 'transparent' }}
              searchWords={[selectProps.inputValue]}
              textToHighlight={data.label}
              autoEscape={true}
            />
          </label>
          <label className="block break-all">
            <Highlighter
              highlightStyle={{ fontWeight: 'bold', backgroundColor: 'transparent' }}
              searchWords={[selectProps.inputValue]}
              textToHighlight={data.email}
              autoEscape={true}
            />
          </label>
        </div>
        <div className="flex-shrink-0 flex-grow-0 pr-2">
          <Button
            onClick={() => addOwner(data)}
            variant="text"
            type="button"
            leftIcon="plus-circle"
          />
        </div>
      </div>
    </>
  )
}

export default ClientSearchOption
