import moment from 'moment-timezone'

export default class DateFormatter {
  date: Date
  constructor(date) {
    this.date = new Date(date)
  }

  get dateString() {
    return this.date.toDateString()
  }

  /** Returns day # of the month between 1 - 30/31 */
  get day() {
    return this.date.getDate()
  }

  /** Returns day of week formatted like "Mon", "Tue", "Wed", etc. */
  get abbreviatedDayOfWeek() {
    return this.dateString.split(' ')[0]
  }

  /** Returns time string with format "HH:MM AM/PM" */
  get timeOfDay() {
    let time = moment(this.date).format('h:mm a')

    return time
  }

  /** Returns month formatted like "Jan", "Feb", "Mar", etc. */
  get abbreviatedMonth() {
    return this.dateString.split(' ')[1]
  }

  /** Returns date expression formatted like "Jan 12" */
  get abbreviatedMonthAndDay() {
    return `${this.abbreviatedMonth} ${this.day}`
  }

  /** Checks if this date represents today */
  get dateIsToday() {
    const today = new Date().toDateString()
    return today === this.dateString
  }
}
