import React from 'react'

export default function LineChartIcon({ className = '' }) {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.95443 2.45417C3.95443 1.61872 3.27946 0.943756 2.44401 0.943756C1.60856 0.943756 0.933594 1.61872 0.933594 2.45417V18.3135C0.933594 20.3998 2.62337 22.0896 4.70964 22.0896H23.5898C24.4253 22.0896 25.1003 21.4146 25.1003 20.5792C25.1003 19.7437 24.4253 19.0688 23.5898 19.0688H4.70964C4.29427 19.0688 3.95443 18.7289 3.95443 18.3135V2.45417ZM21.6546 12.6023C22.3673 13.315 23.5898 12.81 23.5898 11.7999V6.60782C23.5898 5.98005 23.0848 5.47501 22.457 5.47501H17.2603C16.2502 5.47501 15.7451 6.6975 16.4578 7.41023L17.9871 8.93952L15.2826 11.6441L11.818 8.1796C11.228 7.58959 10.2699 7.58959 9.67985 8.1796L5.90381 11.9556C5.3138 12.5456 5.3138 13.5038 5.90381 14.0938C6.49381 14.6838 7.45199 14.6838 8.04199 14.0938L10.7513 11.3892L14.2158 14.8538C14.8058 15.4438 15.764 15.4438 16.354 14.8538L20.13 11.0777L21.6546 12.6023Z"
        fill="white"
      />
    </svg>
  )
}
