import React, { Fragment, useState } from 'react'

import Button from 'components/Common/Button'
import ModalBox from 'components/Common/ModalBox'

const PdfViewer = ({ src }) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <Fragment>
      <div className="flex justify-end items-center gap-3 pr-2">
        <div>
          <Button
            label="View"
            variant="text"
            className="w-full"
            onClick={() => {
              setOpenModal(true)
            }}
          />
        </div>
      </div>
      {openModal && (
        <ModalBox margin="my-3" height="h-full" width="w-full" onClose={() => setOpenModal(false)}>
          <iframe className="h-screen w-full p-5" src={src} title="Pdf viewer" />
        </ModalBox>
      )}
    </Fragment>
  )
}

export default PdfViewer
