import React from 'react'

function PauseIcon({ className }) {
  return (
    <svg
      width="56"
      height="55"
      viewBox="0 0 56 55"
      className={className}
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50.1602 27.5C50.1602 21.5741 47.8061 15.8908 43.6158 11.7006C39.4256 7.51032 33.7423 5.15625 27.8164 5.15625C21.8905 5.15625 16.2073 7.51032 12.017 11.7006C7.82672 15.8908 5.47266 21.5741 5.47266 27.5C5.47266 33.4259 7.82672 39.1091 12.017 43.2994C16.2073 47.4897 21.8905 49.8438 27.8164 49.8438C33.7423 49.8438 39.4256 47.4897 43.6158 43.2994C47.8061 39.1091 50.1602 33.4259 50.1602 27.5ZM0.316406 27.5C0.316406 20.2065 3.21372 13.2118 8.37097 8.05456C13.5282 2.89731 20.523 0 27.8164 0C35.1099 0 42.1046 2.89731 47.2618 8.05456C52.4191 13.2118 55.3164 20.2065 55.3164 27.5C55.3164 34.7935 52.4191 41.7882 47.2618 46.9454C42.1046 52.1027 35.1099 55 27.8164 55C20.523 55 13.5282 52.1027 8.37097 46.9454C3.21372 41.7882 0.316406 34.7935 0.316406 27.5ZM24.3789 19.7656V35.2344C24.3789 36.6631 23.2295 37.8125 21.8008 37.8125C20.3721 37.8125 19.2227 36.6631 19.2227 35.2344V19.7656C19.2227 18.3369 20.3721 17.1875 21.8008 17.1875C23.2295 17.1875 24.3789 18.3369 24.3789 19.7656ZM36.4102 19.7656V35.2344C36.4102 36.6631 35.2607 37.8125 33.832 37.8125C32.4033 37.8125 31.2539 36.6631 31.2539 35.2344V19.7656C31.2539 18.3369 32.4033 17.1875 33.832 17.1875C35.2607 17.1875 36.4102 18.3369 36.4102 19.7656Z" />
    </svg>
  )
}

PauseIcon.defaultProps = {
  className: '',
}
export default PauseIcon
