import React from 'react'
import InfoCircleIcon from 'components/Common/SvgIcons/InfoCircleIcon'
import moment from 'moment'
import { UserIntegrations } from '../../../../__generated__/api-types-and-hooks'
import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'

const ToolItemStatus = ({ data }: { data: UserIntegrations }) => {
  const hasError = !data.isConnected && data.isRecommended

  const getItemStatus = () => {
    return data.isConnected ? 'Connected' : 'Not Connected'
  }

  return (
    <div className={`mb-6 relative ${hasError ? 'text-primary-error' : 'text-black-light'}`}>
      <div className="space-y-4 lg:space-y-0 lg:flex sm:items-center lg:justify-between font-primary">
        <div className="flex items-center space-x-3 lg:w-[65%]">
          {hasError && (
            <span className="">
              <InfoCircleIcon className="" />
            </span>
          )}
          <span className="mr-2 text-xl">{data.name}</span>
          {data.isRecommended && (
            <PillTextBox
              colorTheme={PillColorTheme.DISABLE}
              className="border border-grey-darkest1x"
            >
              Recommended
            </PillTextBox>
          )}
        </div>
        <div className="flex items-center space-x-4 lg:w-[35%]">
          <PillTextBox
            colorTheme={data.isConnected ? PillColorTheme.SUCCESS : PillColorTheme.ERROR}
          >
            {getItemStatus()}
          </PillTextBox>
          {data?.lastConnectedAt && data.isConnected && (
            <span className="text-xs lg:flex lg:flex-wrap">
              {moment(data?.lastConnectedAt).format('MMM. D, YYYY')}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ToolItemStatus
