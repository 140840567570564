import React from 'react'

function PlusCircleIcon({ className = '', iconClasses = '', fill = 'bg-primary' }) {
  return (
    <div
      className={`h-5 w-5 flex justify-center items-center rounded-full bg-primary-brandLight relative ${className}`}
    >
      <div className={`${fill} w-1/2 h-px absolute ${iconClasses}`}></div>
      <div className={`${fill} w-1/2 h-px absolute rotate-90 ${iconClasses}`}></div>
    </div>
  )
}

export default PlusCircleIcon
