import { getAuthToken } from './auth'

export const API_URL = process.env.REACT_APP_API_URL!

/**
 * Custom fetcher used by codegen'd hooks to first load auth token, then make the fetch request.
 */
export const fetcher = <TData, TVariables>(query: string, variables?: TVariables) => {
  return async (): Promise<TData> => {
    const authToken = await getAuthToken()
    const res = await fetch(API_URL, {
      method: 'POST',
      // ...{ credentials: 'include' },
      headers: {
        ...(authToken && { Authorization: authToken }),
      },
      body: JSON.stringify({ query, variables }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json.data
  }
}
