import React, { FC } from 'react'

import Button from 'components/Common/Button'
import {
  TextWrapper,
  ContentWrapper,
  HeadingWrapper,
  LicenseModalBoxWrapper,
} from 'components/Admin/UserManagement/style'
import { modalBoxText } from 'components/Admin/UserManagement/config/data/index'

interface ILicenseLimitReachedProps {
  onClose: () => void
}

const LicenseLimitReached: FC<ILicenseLimitReachedProps> = ({ onClose }) => {
  return (
    <LicenseModalBoxWrapper>
      <ContentWrapper>
        <HeadingWrapper>{modalBoxText.heading}</HeadingWrapper>
        <TextWrapper>{modalBoxText.text}</TextWrapper>
      </ContentWrapper>
      <Button fontSize="xxl" label="Close" onClick={onClose} />
    </LicenseModalBoxWrapper>
  )
}

export default LicenseLimitReached
