import React from 'react'

function CalendarIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
    >
      <path
        d="M6.44643 1.03125C6.44643 0.459766 5.99263 0 5.42857 0C4.86451 0 4.41071 0.459766 4.41071 1.03125V2.75H2.71429C1.21719 2.75 0 3.9832 0 5.5V6.1875V8.25V19.25C0 20.7668 1.21719 22 2.71429 22H16.2857C17.7828 22 19 20.7668 19 19.25V8.25V6.1875V5.5C19 3.9832 17.7828 2.75 16.2857 2.75H14.5893V1.03125C14.5893 0.459766 14.1355 0 13.5714 0C13.0074 0 12.5536 0.459766 12.5536 1.03125V2.75H6.44643V1.03125ZM2.03571 8.25H16.9643V19.25C16.9643 19.6281 16.6589 19.9375 16.2857 19.9375H2.71429C2.34107 19.9375 2.03571 19.6281 2.03571 19.25V8.25Z"
        fill="#D4D4D8"
      />
    </svg>
  )
}

CalendarIcon.defaultProps = {
  className: '',
}
export default CalendarIcon
