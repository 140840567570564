import styled from 'styled-components'
import tw from 'twin.macro'

export const UserStepperWrapper = styled.div`
  ${tw`flex flex-row justify-center gap-2 xs:pt-6 md:pt-16 items-center`}
`

export const AdvisorFormWrapper = styled.div`
  ${tw`w-[100%] gap-[34px] flex flex-col items-center overflow-x-hidden bg-white pb-10 pt-8`}
`

export const FormHeadingTextWrapper = styled.p`
  ${tw`font-normal font-primary xs:text-xs sm:text-[17px] mt-2 text-primary-text`}
`

export const ProfileFormWrapper = styled.div`
  ${tw`w-full sm:flex sm:justify-center`}
`

export const ProgressbarWrapper = styled.div`
  ${tw`xs:w-[300px] sm:w-[640px]`}
`

export const AdminAccessWrapper = styled.div`
  ${tw`flex flex-col w-full justify-center`}
`

export const HeadingWrapper = styled.p`
  ${tw`font-normal text-4xl text-center text-purple-dark`}
`

export const FormWrapper = styled.div`
  ${tw`grid grid-cols-1 gap-12 mt-8 w-full flex justify-center items-center flex-col xs:px-4 sm:px-0`}
`

export const TextWrapper = styled.p`
  ${tw`bg-transparent text-center text-black text-base py-2 rounded`}
`

export const CheckBoxWrapper = styled.div`
  ${tw`flex items-center xs:px-0 sm:px-4`}
`

export const CheckBoxTextWrapper = styled.label`
  ${tw`bg-transparent font-normal text-black text-sm rounded`}
`

export const ButtonWrapper = styled.div`
  ${tw`xs:px-0 sm:px-4`}
`
export const x8 = styled.div`
  ${tw`xs:w-[300px] sm:w-[640px]`}
`
