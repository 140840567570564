import { SortOrder, Tool } from '__generated__/api-types-and-hooks'
import { pick } from 'lodash'
import {
  GET_ADVISORS_DASHBOARD,
  GET_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_TOP_ACTIVE_CLIENTS,
  OPEN_CLIENT_DETAIL,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  SHOW_COMMENTS,
  GET_MY_TOOLS,
  UPDATE_USER_CALENDAR_EVENT,
  GET_CLIENT_ATTESTATION_REPORT,
  RESET_CLIENTS,
  GET_TOOLS_BY_TYPE,
  RESET_ERROR,
  RESET_SAVED_CURRENT_PAGE,
  GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS,
  CANCEL_TACKLE_MEETING_EVENT,
  SET_TOOL_CONNECTION_STATUS,
  GET_TOOL_CATEGORIES,
  GET_SEARCH_TOOLS,
} from 'store/types'
import { updateToolConnectionState } from '../../utils/helper'

const initialState = {
  clientsData: {},
  list: [],
  topActiveClients: [{}, {}, {}],
  clientConnectedTools: {},
  clientAssessmentList: [],
  clientAssessments: {},
  client: {
    profile: {},
    businessProfile: {},
    activity: {
      scoreCards: {},
      activityLogs: [],
    },
    details: {},
  },
  assignedOwnerList: [],
  openClientDetail: false,
  disableButton: false,
  isClientAttestationReportLoading: false,
  allTools: [],
  meetingEvents: [],
  updatedComments: [{ id: '', tenantId: '', comment: '', tackleId: '' }],
  showComments: false,
  ownerIds: [],
  toolsByType: [],
  tackleMeeting: {
    data: [],
    pagination: '',
    totalCount: 0,
    totalUnread: 0,
    moreDataExists: true,
    filter: '',
  },
  myTools: [],
  savedCurrentPage: null,
  clientReport: {
    sort: SortOrder.Ascending,
    items: [],
    lastEvaluatedKey: '',
    prevEvaluatedKey: '',
    totalPagesApi: 1,
    totalRecordsApi: 1,
    noOfItemsPerPageApi: 2,
    currentPageApi: 1,
  },
  error: null,
  mobileContactError: null,
  isAllClientFetching: false,
  isDataFetched: false,
  clientAssessmentRecommendations: [],
  isFetchingRecommendations: false,
  isDisabled: false,
  categories: [],
  subCategories: [],
  tools: [],
  hasSubCategory: false,
  isFetching: false,
  searchToolErrorMessage: '',
  searchedToolQuery: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADVISORS_DASHBOARD.STARTED:
      return {
        ...state,
        clientsData: {},
        isAllClientFetching: state.list.length < 1 && !state.isDataFetched,
      }
    case GET_ADVISORS_DASHBOARD.FULLFILLED:
      return {
        ...state,
        ...action.payload,
      }
    case GET_CLIENTS.FULLFILLED:
      return {
        ...state,
        assignedOwnerList: action.payload,
      }
    case GET_CLIENTS.RESET:
      return {
        ...state,
        assignedOwnerList: [],
      }
    case GET_TOP_ACTIVE_CLIENTS.FULLFILLED:
      return {
        ...state,
        topActiveClients: action.payload,
      }
    case GET_CLIENT_PROFILE.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          profile: action.payload,
        },
      }

    case GET_CLIENT_ACTIVITY_LOGS.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          activity: {
            ...state.client.activity,
            activityLogs: action.payload,
          },
        },
      }

    case GET_CLIENT_ACTIVITY_SCORE.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          activity: {
            ...state.client.activity,
            scoreCards: action.payload,
          },
        },
      }

    case GET_CLIENT_PROFILE_DETAILS.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          businessProfile: action?.payload.businessProfile,
          assessmentResponse: action?.payload?.assessmentResponse,
        },
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.STARTED:
      return {
        ...state,
        disableButton: true,
        openClientDetail: true,
        error: null,
        mobileContactError: null,
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.FULLFILLED:
      return {
        ...state,
        disableButton: false,
        openClientDetail: false,
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.REJECTED:
      return {
        ...state,
        disableButton: false,
        ...action.payload,
      }
    case OPEN_CLIENT_DETAIL:
      return {
        ...state,
        openClientDetail: action.payload,
      }
    case GET_CLIENT_ASSESSMENT_LIST.FULLFILLED:
      return {
        ...state,
        clientAssessmentList: action.payload,
      }

    case GET_CLIENT_ASSESSMENTS.FULLFILLED:
      return {
        ...state,
        clientAssessments: {
          ...state.clientAssessments,
          ...action.payload,
        },
      }
    case GET_CLIENT_CONNECTED_TOOLS.STARTED:
      return {
        ...state,
        clientConnectedTools: {},
      }
    case GET_CLIENT_CONNECTED_TOOLS.FULLFILLED:
      return {
        ...state,
        clientConnectedTools: {
          ...action.payload,
        },
      }
    case GET_ALL_TOOLS.FULLFILLED:
      return {
        ...state,
        allTools: action.payload,
      }

    case GET_TOOLS_BY_TYPE.FULLFILLED:
      return {
        ...state,
        toolsByType: action.payload,
      }
    case GET_TACKLE_MEETING_EVENTS.FULLFILLED:
      return {
        ...state,
        tackleMeeting: {
          ...state.tackleMeeting,
          ...pick(action.payload, [
            'data',
            'pagination',
            'totalCount',
            'totalUnread',
            'moreDataExists',
            'filter',
          ]),
        },
      }
    case CANCEL_TACKLE_MEETING_EVENT.STARTED:
    case UPDATE_USER_CALENDAR_EVENT.STARTED:
      return {
        ...state,
        isDisabled: true,
      }

    case UPDATE_USER_CALENDAR_EVENT.FULLFILLED:
      return {
        ...state,
        isDisabled: false,
        updatedComments: state.updatedComments.some(
          (comment) => comment?.tackleId === action?.payload?.tackleId
        )
          ? state.updatedComments.map((obj) =>
              obj?.tackleId === action?.payload?.tackleId ? action.payload : obj
            )
          : [...state.updatedComments, action.payload],
      }
    case CANCEL_TACKLE_MEETING_EVENT.FULLFILLED:
    case CANCEL_TACKLE_MEETING_EVENT.REJECTED:
    case UPDATE_USER_CALENDAR_EVENT.REJECTED:
      return {
        ...state,
        isDisabled: false,
      }
    case SHOW_COMMENTS:
      return {
        ...state,
        ownerIds: state.ownerIds.find((id) => id === action.payload)
          ? state.ownerIds.filter((element) => element !== action.payload)
          : [...state.ownerIds, action.payload],
      }
    case GET_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        myTools: action.payload,
      }
    case GET_CLIENT_ATTESTATION_REPORT.STARTED:
      return {
        ...state,
        isClientAttestationReportLoading: true,
        savedCurrentPage: action.payload?.currentPage,
        clientReport: {
          ...state.clientReport,
          sort: action?.payload?.sort,
          items:
            action.payload?.sort !== state.clientReport.sort ? [] : [...state.clientReport.items],
        },
      }
    case GET_CLIENT_ATTESTATION_REPORT.FULLFILLED:
      return {
        ...state,
        isClientAttestationReportLoading: false,
        clientReport: {
          ...state.clientReport,
          items: [...action.payload.items],
          lastEvaluatedKey: action.payload.lastEvaluatedKey,
          prevEvaluatedKey: action.payload.prevEvaluatedKey,
          totalPages: action.payload.totalPages,
          totalRecords: action.payload.totalRecords,
          noOfItemsPerPage: action.payload.pageSize,
          currentPage: action.payload.currentPage,
        },
      }
    case GET_CLIENT_ATTESTATION_REPORT.RESET:
      return {
        ...state,
        clientReport: {
          items: [],
          lastEvaluatedKey: '',
        },
      }
    case RESET_CLIENTS:
      return {
        ...initialState,
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        mobileContactError: null,
      }
    case RESET_SAVED_CURRENT_PAGE:
      return {
        ...state,
        savedCurrentPage: null,
      }
    case GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
      return {
        ...state,
        isFetchingRecommendations: true,
      }
    case GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        isFetchingRecommendations: false,
        clientAssessmentRecommendations: action.payload,
      }
    case GET_CLIENT_RECOMMENDED_PLAYS_ASSSESSMENTS.RESET:
      return {
        ...state,
        clientAssessmentRecommendations: [],
      }
    case SET_TOOL_CONNECTION_STATUS:
      const allTools = updateToolConnectionState(state.allTools as Tool[], action)
      const myTools = updateToolConnectionState(state.myTools as Tool[], action)
      const tools = updateToolConnectionState(state.tools as Tool[], action)
      return {
        ...state,
        allTools,
        myTools,
        tools,
      }
    case GET_TOOL_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      }
    case GET_SEARCH_TOOLS.STARTED:
      return {
        ...state,
        isFetching: true,
        searchedToolQuery: action.payload.input.freeFormSearchQuery ?? '',
        tools: [],
        searchToolErrorMessage: '',
        hasSubCategory: action.payload.hasSubCategory,
      }
    case GET_SEARCH_TOOLS.FULLFILLED:
      return {
        ...state,
        isFetching: false,
        tools: action.payload,
      }
    case GET_SEARCH_TOOLS.REJECTED:
      return {
        ...state,
        isFetching: false,
        tools: [],
        searchToolErrorMessage: action.payload,
      }
    case GET_SEARCH_TOOLS.RESET:
      return {
        ...state,
        isFetching: false,
        tools: [],
        searchToolErrorMessage: '',
      }
    default:
  }
  return state
}

export default reducer
