import React from 'react'

function TickIcon({ className }) {
  return (
    <svg
      className={className}
      width="19"
      height="20"
      viewBox="-6 0 120 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.898 1.60155C105.375 3.05468 105.375 5.4453 103.898 6.89843L40.1484 70.6484C38.6953 72.125 36.3047 72.125 34.8516 70.6484L1.09828 36.8984C-0.366094 35.4453 -0.366094 33.0547 1.09828 31.6016C2.56172 30.125 4.93594 30.125 6.40078 31.6016L37.5 62.7031L98.6016 1.60155C100.055 0.134363 102.445 0.134363 103.898 1.60155Z"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TickIcon
