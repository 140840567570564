import { useGetUserAssessmentResponseQuery } from '__generated__/api-types-and-hooks'
import { isEmpty, isNumber, isString, mapValues, toString } from 'lodash'
import { IResponse } from 'types'

export const parseJsonKeys = (input) =>
  mapValues(input, (value) => {
    if (isString(value)) {
      try {
        const parsedValue = JSON.parse(value)
        if (isNumber(parsedValue)) {
          return toString(parsedValue)
        }
        return parsedValue
      } catch (error) {
        return value
      }
    } else {
      return value
    }
  })

export const getParsedAssessmentResponse = (userAssessmentResponse) => {
  const assessmentResponse = userAssessmentResponse.assessmentResponse
    ? (userAssessmentResponse.assessmentResponse.map((response) =>
        parseJsonKeys(response)
      ) as IResponse[])
    : []

  return { ...userAssessmentResponse, assessmentResponse }
}

export const useUserAssessmentResponse = (assessmentType, userId) => {
  let userAssessmentResponse
  const { data, isLoading } = useGetUserAssessmentResponseQuery({ type: assessmentType, userId })

  if (!isEmpty(data?.getUserAssessmentResponse?.data))
    userAssessmentResponse = data!.getUserAssessmentResponse!.data

  if (!isEmpty(userAssessmentResponse)) {
    userAssessmentResponse = getParsedAssessmentResponse(userAssessmentResponse)
  }

  return { userAssessmentResponse, isLoading }
}
