import React from 'react'

function UpArrowIcon({ className }) {
  return (
    <svg
      className={className}
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.322831 5.33047L4.69189 0.830469C4.9363 0.608984 5.21817 0.5 5.50005 0.5C5.78192 0.5 6.06311 0.609863 6.27796 0.82959L10.647 5.32959C10.9618 5.65148 11.0561 6.13502 10.8857 6.55584C10.7153 6.97666 10.3435 7.25 9.86911 7.25H1.10039C0.655581 7.25 0.254081 6.97645 0.0835809 6.55566C-0.0869191 6.13488 0.00806937 5.65039 0.322831 5.33047Z" />
    </svg>
  )
}
UpArrowIcon.defaultProps = {
  className: 'cursor-pointer',
}

export default UpArrowIcon
