import React from 'react'

function AdvisorCrossIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="26"
      viewBox="0 0 16 26"
    >
      <g clip-path="url(#clip0_27037_1416)">
        <path d="M12.8393 19.3095C12.5853 19.5635 12.1744 19.5635 11.9201 19.3095L6.53114 13.9187L1.14096 19.3095C0.886967 19.5635 0.476021 19.5635 0.221744 19.3095C-0.0322482 19.0555 -0.0322482 18.6445 0.221744 18.3902L5.61315 13.0006L0.222557 7.60983C-0.0314358 7.35581 -0.0314358 6.94482 0.222557 6.69052C0.476549 6.43649 0.887495 6.43649 1.14177 6.69052L6.53114 12.0825L11.9213 6.69173C12.1753 6.43771 12.5863 6.43771 12.8405 6.69173C13.0945 6.94575 13.0945 7.35675 12.8405 7.61105L7.44914 13.0006L12.8393 18.3914C13.0952 18.6433 13.0952 19.0576 12.8393 19.3095Z" />
      </g>
      <defs>
        <clipPath id="clip0_27037_1416">
          <rect width="15.625" height="25" fill="white" transform="matrix(-1 0 0 1 15.6562 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
AdvisorCrossIcon.defaultProps = {
  className: 'cursor-pointer',
}
export default AdvisorCrossIcon
