import React from 'react'

function RetailIcon({ className }) {
  return (
    <svg className={className} viewBox="2 -11 60 105" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.3548 15.832C24.533 15.832 26.3129 14.0646 26.3129 11.8739C26.3129 9.68324 24.533 7.90332 22.3548 7.90332C20.1641 7.90332 18.3842 9.68324 18.3842 11.8739C18.3842 14.0646 20.1641 15.832 22.3548 15.832ZM22.3548 9.78281C23.4999 9.78281 24.4459 10.7288 24.4459 11.8864C24.4459 13.0439 23.4999 13.9774 22.3548 13.9774C21.1972 13.9774 20.2513 13.0439 20.2513 11.8864C20.2513 10.7288 21.1972 9.78281 22.3548 9.78281Z" />
      <path d="M22.3548 54.3437C20.8487 54.3437 19.6164 53.1115 19.6164 51.6054C19.6164 51.0951 19.1932 50.6719 18.6829 50.6719C18.1726 50.6719 17.7494 51.0951 17.7494 51.6054C17.7494 53.821 19.3302 55.6756 21.4212 56.1112V57.7169C21.4212 58.2272 21.8444 58.6504 22.3548 58.6504C22.8651 58.6504 23.2883 58.2272 23.2883 57.7169V56.1236C25.3794 55.688 26.9602 53.8334 26.9602 51.6178C26.9602 49.0787 24.894 47.0125 22.3548 47.0125C20.8487 47.0125 19.6164 45.7802 19.6164 44.2741C19.6164 42.768 20.8362 41.5358 22.3548 41.5358C23.8733 41.5358 25.0931 42.7556 25.0931 44.2741C25.0931 44.7844 25.5163 45.2076 26.0266 45.2076C26.537 45.2076 26.9602 44.7844 26.9602 44.2741C26.9602 42.0586 25.3794 40.204 23.2883 39.7683V38.1626C23.2883 37.6523 22.8651 37.2291 22.3548 37.2291C21.8444 37.2291 21.4212 37.6523 21.4212 38.1626V39.7683C19.3302 40.204 17.7494 42.0586 17.7494 44.2741C17.7494 46.8133 19.8156 48.8795 22.3548 48.8795C23.8609 48.8795 25.0931 50.1118 25.0931 51.6178C25.0931 53.1239 23.8609 54.3437 22.3548 54.3437Z" />
      <path d="M36.2954 28.7156H8.40172C8.15278 28.7156 7.91629 28.8152 7.74203 28.9894C7.56778 29.1637 7.4682 29.4126 7.4682 29.6491V66.2557C7.4682 66.5046 7.56778 66.7411 7.74203 66.9154C7.91629 67.0896 8.15278 67.1892 8.40172 67.1892H36.2954C36.5443 67.1892 36.7808 67.0896 36.9551 66.9154C37.1294 66.7411 37.2289 66.5046 37.2289 66.2557V29.6491C37.2289 29.4126 37.1294 29.1637 36.9551 28.9894C36.7808 28.8152 36.5443 28.7156 36.2954 28.7156ZM35.3619 65.3097H9.33525V30.5826H35.3619V65.3097Z" />
      <path d="M66.1308 55.0032L47.9707 12.0611C47.311 10.5052 46.0289 9.273 44.4606 8.6631L26.8108 1.86705L26.6863 1.74258C25.5163 0.622349 23.9729 0 22.3423 0C20.7242 0 19.1808 0.622349 18.0108 1.74258L1.90439 17.2889C0.697031 18.4589 0 20.1019 0 21.7822V68.4086C0 71.8564 2.80057 74.657 6.23594 74.657H38.4487C40.9257 74.657 43.0417 73.2007 44.0623 71.1096L62.8075 63.1933C65.1226 62.21 66.6162 59.9571 66.6162 57.4428C66.6162 56.5964 66.4544 55.7874 66.1308 55.0032ZM42.8301 68.4086C42.8301 70.8233 40.8634 72.7899 38.4487 72.7899H6.23594C3.83367 72.7899 1.86705 70.8233 1.86705 68.4086V21.7698C1.86705 20.5873 2.35248 19.4422 3.19887 18.6207L19.3053 3.0993C20.1268 2.31514 21.2097 1.87949 22.3423 1.87949C23.4875 1.87949 24.5703 2.31514 25.3918 3.0993L25.6034 3.29845C25.6283 3.32334 25.6532 3.34824 25.6906 3.37313L41.4982 18.6207C42.3446 19.4422 42.8301 20.5873 42.8301 21.7698V68.4086ZM62.0731 61.4756L44.6598 68.8318C44.6722 68.6824 44.6971 68.5455 44.6971 68.4086V21.7698C44.6971 20.0894 44.0001 18.4464 42.7927 17.2764L30.2711 5.20284L43.7885 10.4057C44.8963 10.8289 45.7924 11.7002 46.253 12.7955L64.4007 55.7376C64.6372 56.2852 64.7492 56.8578 64.7492 57.4428C64.7492 59.1854 63.7036 60.791 62.0731 61.4756Z" />
    </svg>
  )
}

export default RetailIcon
