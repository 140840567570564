import React, { useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'mobile-device-detect'
import slice from 'lodash/slice'
import size from 'lodash/size'
import ModalBox from 'components/Common/ModalBox'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import TopSellingProductsTable from 'components/Owner/Data/Product/TopSellingProductsTable'
import Loader from 'components/Loaders'
import { getLastUpdatedData } from '../utils'
import { ISelectedDate } from 'components/Common/DatePicker/useDatePickerHandler'
import OwnerApi from 'api/ownerData'
import { ChartStatus, GetChartDataInput } from '../../../../__generated__/api-types-and-hooks'
import { TopSellingProduct, getIntervalChartData } from './utils'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../App'
import withChartWrapper, { WithChartWrapperProps } from '../ChartHOC'
import { ROLES } from 'config'
import { getRoleFromPath } from 'utils/helper'

const title = 'WHAT ARE MY TOP SELLING PRODUCTS?'

type TopSellingProductsData = {
  data?: TopSellingProduct[]
  status: ChartStatus
}

const onFetchDataMethod = async (input: GetChartDataInput): Promise<TopSellingProductsData> => {
  const chartName = 'TOP_SELLING_PRODUCTS'
  const res = await OwnerApi.getSalesChartData({ ...input, chartName })
  const data = JSON.parse(res.getChartData.data)
  if (!data?.chartData?.length) {
    return { data: undefined, status: ChartStatus.Success }
  }
  return { data: data?.chartData, status: ChartStatus.Success }
}

interface ITopSellingProductsProps
  extends WithChartWrapperProps<GetChartDataInput, TopSellingProductsData> {
  selectedDate: ISelectedDate
}

const TopSellingProducts = withChartWrapper(
  onFetchDataMethod,
  ({ chartData, onFetchData, selectedDate }: ITopSellingProductsProps) => {
    const { mutate: fetchData, isLoading } = useFetchChartData(onFetchData)
    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    useEffect(() => {
      fetchData({
        businessId: userId,
        endDate: selectedDate.endDate,
        chartName: '',
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const products = useMemo(() => {
      const data = getIntervalChartData(
        chartData?.data,
        selectedDate.startDate,
        selectedDate.endDate,
        selectedDate.type
      )
      return data
    }, [chartData?.data, selectedDate])

    const [showModal, setShowModal] = useState(false)
    const topProducts = useMemo(() => {
      return slice(products, 0, 3)
    }, [products])

    const lastUpdated = useMemo(() => {
      return getLastUpdatedData(chartData?.data)
    }, [chartData?.data])

    return (
      <>
        <div className="totalSale flex flex-col h-full p-6 bg-secondary">
          <h5 className="font-semibold leading-7 text-lg font-primary text-primary-text mb-6">
            {title}
          </h5>
          {isLoading && <Loader loader="TableLoader" />}
          {!isLoading && chartData?.data?.length ? (
            <>
              <TopSellingProductsTable products={topProducts} />
              {size(products) > 3 && (
                <div className="mt-5 mb-[44px]">
                  <h6
                    onClick={() => setShowModal(true)}
                    className="text-primary font-bold text-sm leading-5 cursor-pointer"
                  >
                    Show All
                  </h6>
                </div>
              )}
              {/* )} */}
            </>
          ) : (
            <></>
          )}
          <SectionFooter
            lastUpdated={lastUpdated ?? ''}
            getChartDataAction={() => {
              fetchData({
                businessId: userId,
                endDate: selectedDate.endDate,
                chartName: '',
                userId,
              })
            }}
          />
        </div>
        {showModal && (
          <ModalBox
            title={isMobileOnly ? title : ''}
            showCancelButton={true}
            onClose={() => setShowModal(false)}
            classes="h-full md:mt-5 md:mb-5 md:w-full p-10 rounded-[10px] xs:w-full"
          >
            <TopSellingProductsTable
              products={products}
              className="mx-[-2.5rem] px-[2.5rem] mb-[-2rem]"
            />
          </ModalBox>
        )}
      </>
    )
  }
)

export default TopSellingProducts
