import React, { useEffect, useMemo } from 'react'
import round from 'lodash/round'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import { StatValue } from 'components/Owner/Data/styles'
import { getSalesGrowth } from 'components/Owner/Data/Product/utils'
import Loader from 'components/Loaders'
import { ChartStatus, GetChartDataInput } from '../../../../__generated__/api-types-and-hooks'
import OwnerApi from 'api/ownerData'
import withChartWrapper, { WithChartWrapperProps } from '../ChartHOC'
import { SalesSummary, getLastUpdatedData } from '../utils'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { ISelectedDate } from 'components/Common/DatePicker/useDatePickerHandler'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'

type GrowthData = {
  data?: SalesSummary[]
  status: ChartStatus
}

const onFetchDataMethod = async (input: GetChartDataInput): Promise<GrowthData> => {
  const chartName = 'SALES_GROWTH_RATE'
  const res = await OwnerApi.getSalesChartData({ ...input, chartName })
  const data = JSON.parse(res.getChartData.data)
  if (!data?.chartData?.length) {
    return { data: undefined, status: ChartStatus.Success }
  }
  return { data: data?.chartData, status: ChartStatus.Success }
}

interface IGrowthProps extends WithChartWrapperProps<GetChartDataInput, GrowthData> {
  selectedDate: ISelectedDate
}

const Growth = withChartWrapper(
  onFetchDataMethod,
  ({ chartData, onFetchData, selectedDate }: IGrowthProps) => {
    const salesGrowthData = chartData?.data
    const { mutate: fetchData, isLoading } = useFetchChartData(onFetchData)
    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)
    useEffect(() => {
      fetchData({
        businessId: userId,
        endDate: selectedDate.endDate,
        chartName: '',
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const growthPercentage = useMemo(() => {
      if (!salesGrowthData?.length) return 0
      const data = getSalesGrowth(
        salesGrowthData,
        selectedDate.startDate,
        selectedDate.endDate,
        selectedDate.type
      )
      return data
    }, [salesGrowthData, selectedDate])

    const lastUpdated = useMemo(() => {
      if (!chartData?.data) return ''
      return getLastUpdatedData(chartData?.data)
    }, [chartData?.data])

    return (
      <div className="totalSale bg-secondary xs:p-4 xxl:p-6 h-[420px] rounded-xl ">
        <h5 className="whitespace-nowrap font-semibold leading-7 text-lg font-primary text-primary-text mb-16">
          HOW FAST AM I GROWING?
        </h5>
        {isLoading && <Loader loader="ChartLoader" />}

        <div
          className={`flex gap-5 flex-col items-center h-[calc(100%-92px)] ${
            isLoading && 'hidden'
          }`}
        >
          {chartData?.data?.length ? (
            <>
              <StatValue>{growthPercentage ? round(growthPercentage, 2) + '%' : 'N/A'}</StatValue>
              <div className="text-lg leading-6 text-primary-text font-primary text-center">
                Sales Growth Rate
              </div>
            </>
          ) : (
            <></>
          )}

          <SectionFooter
            lastUpdated={lastUpdated ?? ''}
            getChartDataAction={() => {
              fetchData({
                businessId: userId,
                endDate: selectedDate.endDate,
                chartName: '',
                userId,
              })
            }}
          />
        </div>
      </div>
    )
  }
)

export default Growth
