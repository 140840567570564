import { setFlashUrlParams } from 'utils/flashParameters'
import { ApiResponseError } from 'utils/error-handling/api-response-error'
import { ErrorType } from '../../../../__generated__/api-types-and-hooks'

export const handleGetUserCalendarEventsError = (errors: ApiResponseError[]) => {
  for (const error of errors) {
    if (error.errorType === ErrorType.DisconnectedToolError) {
      const disconnectedToolError = error?.errorInfo
      if (!disconnectedToolError?.disconnectedTools) return
      const disconnectedTools = disconnectedToolError.disconnectedTools.map((tool: string) => {
        return tool
          .trim()
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
      })
      const flashParams = {
        disconnectedTools: disconnectedTools.join(', '),
      }
      setFlashUrlParams(flashParams)
      return
    }
  }
}
