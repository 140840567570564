import styled from 'styled-components'
import tw from 'twin.macro'

export const ContentBoxWrapper = styled.div`
  ${tw`flex flex-col gap-2`}
`

export const HeaderWrapper = styled.h1`
  ${tw`font-secondary text-2xl text-white`}
`

export const SubheaderWrapper = styled.h6`
  ${tw`font-semibold text-white`}
`

export const ParagraphWrapper = styled.p`
  ${tw`text-white`}
`
