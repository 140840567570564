import React from 'react'
import { useSelector } from 'react-redux'

import { ROLES } from 'config'
import { RootState } from 'App'
import { ASSESSMENTS } from 'config/data'

import Navbar from 'components/Common/Navbar'
import ProgressBar from 'components/Common/ProgressBar'
import assessmentHOC from 'components/Assessment/assessmentHOC'
import QuestionsContainerStyle from 'components/Common/QuestionBox'

import { getReturnToPath, isActiveStep, navigateTo } from 'utils/helper'
import { CancelIcon } from 'components/Common/SvgIcons'
import { QuestionsContainer } from 'pages/Assessment/containers'
import { IOptionTypes } from 'components/Assessment/AssessmentQuestion/utils'

enum ACTIVESTEP {
  TERRIFIC = 'Terrific',
}

const Assessment = ({
  totalProgress,
  activeStep,
  currentUser,
  assessmentType,
  activeQuestion,
  setIsLastQuestion,
  isCompletedAssessment,
}) => {
  const { assessmentBgColor } = useSelector((state: RootState) => state.assessment)
  if (activeStep === ACTIVESTEP.TERRIFIC) {
    navigateTo(ROLES.BUSINESS_OWNER, 'dashboard')
  }
  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || 'dashboard'

  return (
    <div
      id="assessment-container"
      className={`${assessmentBgColor} relative h-auto min-h-screen w-[100%]`}
    >
      <Navbar
        className="h-auto"
        noCenterContent
        noRightContent
        leftContent={() => {
          return (
            <div
              className="flex gap-5 items-center "
              onClick={() => navigateTo(ROLES.BUSINESS_OWNER, `${redirectPath}`)}
            >
              <CancelIcon className="fill-primary cursor-pointer" />
              <div className="text-sm">
                ASSESSMENT
                <div className="font-semibold"> Your {ASSESSMENTS[assessmentType]?.title}</div>
              </div>
            </div>
          )
        }}
      />
      <div
        className={`${
          activeStep !== 'Terrific' ? 'pt-6  xs:max-w-[510px]' : ''
        } flex flex-col px-5 mx-auto pb-9`}
      >
        {activeStep !== 'Terrific' &&
          activeStep &&
          activeQuestion?.optionType !== IOptionTypes.BUTTON && (
            <ProgressBar
              hasFixedWidth={false}
              bgColorClass=" bg-gradient-to-r from-[#8400F7] to-[#52B4F9]"
              width={totalProgress}
            />
          )}
        <div className={isActiveStep(activeStep, 'Questions')}>
          <QuestionsContainerStyle activeQuestion={activeQuestion}>
            <QuestionsContainer
              userId={currentUser?.id}
              assessmentType={assessmentType}
              onSubmit={() => {
                setIsLastQuestion(true)
              }}
            />
          </QuestionsContainerStyle>
        </div>
      </div>
    </div>
  )
}

export default assessmentHOC(Assessment)
