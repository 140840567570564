import API from 'api'
import * as api from '__generated__/api-types-and-hooks'

class PLAYBOOK_API {
  getDashboardListing = (tenantId = undefined, category = undefined): Promise<any> => {
    return API.fetch(api['GetDashboardListingDocument'], { tenantId, category })
  }
  getAssessmentPlayListing = (tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetAssessmentPlayListingDocument'], { tenantId })
  }
}

// eslint-disable-next-line
export default new PLAYBOOK_API()
