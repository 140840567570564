import React, { FC } from 'react'

import get from 'lodash/get'
import { getInitials } from 'components/Chat/helper'
import { TopActiveClient } from '__generated__/api-types-and-hooks'
import {
  UserInfoWrapper,
  UserInitialsWrapper,
  UserProfileInfoWrapper,
  UserProfileInitialWrapper,
} from 'components/Clients/style'

export interface IUserProfileInfoProps {
  profile: TopActiveClient
}
const UserProfileInfo: FC<IUserProfileInfoProps> = ({ profile }) => {
  const initials = getInitials({
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
  })
  return (
    <UserProfileInfoWrapper>
      <UserProfileInitialWrapper>
        <UserInitialsWrapper>{initials}</UserInitialsWrapper>
      </UserProfileInitialWrapper>
      <UserInfoWrapper>
        <div className="font-semibold">
          {get(profile, 'firstName', '') + ' ' + get(profile, 'lastName', '')}
        </div>
        <div> {get(profile, 'businessName', '')}</div>
      </UserInfoWrapper>
    </UserProfileInfoWrapper>
  )
}

export default UserProfileInfo
