import React from 'react'

import styled from 'styled-components'
import { ASSESSMENTS, ROLES } from 'config'
import tw from 'twin.macro'

import AssessmentAvatarIcon from 'assets/svgs/AssessmentAvatarIcon.svg'
import EllipseIcon from 'components/Common/SvgIcons/EllipseIcon'
import { EarnedSectionWrapper } from '../style'
import { navigateTo } from 'utils/helper'

export const Wrapper = styled.div.attrs({
  className:
    'bg-secondary rounded p-4 flex items-center justify-start gap-2 text-primary-text flex-col border border-solid border-primary-disabled cursor-pointer w-full md:min-w-[160px] md:max-w-[160px] md:min-h-[180px] sm:min-w-[160px] sm:max-w-[160px] xs:min-w-[130px] xs:max-w-[130px] h-full ',
})`
  & {
    .img-wrap {
      ${tw`bg-yellow-darker w-14 h-14 rounded-3xl flex items-center justify-center `}
    }
    .text-wrap {
      ${tw` text-base font-medium text-center break-words font-primary  `}
    }
    .heading-wrap {
      ${tw`font-normal text-xs font-primary `}
    }
  }
`

const AssessmentSuccess = ({ recommendations, hideRecommendations, allowNextPlayMessage }) => {
  return (
    <>
      {/* This "EarnedSectionWrapper" was deleted in the Play "Use" tab at request of designer. Good chance it should be deleted here too! */}
      <EarnedSectionWrapper />
      <div className=" max-w-[1220px] w-[100%] my-0 mx-[auto] ">
        <div className="breadCrumbs font-normal text-base uppercase "></div>
      </div>
      {allowNextPlayMessage && (
        <>
          <div className="flex px-[140px]">
            <div className={`flex-1 p:2 justify-between flex flex-col overflow-auto `}>
              <div
                id="messages"
                className=" h-[100px] flex flex-col w-[100%] max-w-[910px]   my-0 mx-[auto] items-center  overflow-y-hidden text-center scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch pt-8 mt-6"
              >
                <div className="flex  w-[100%]  relative items-center  justify-center  border-y border-t-0 border-solid border-primary">
                  <img
                    src={AssessmentAvatarIcon}
                    className="w-16 h-16 absolute rounded-full bg-white shadow-black/50"
                    alt="person"
                  />
                </div>
                <div className="mt-24 mb-[68px] font-normal text-primary-text"></div>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-center text-2xl	font-primary color-black mb-6">
              🙌🏾 Thank you for completing the assessment!
            </h1>
            <h2 className="text-center text-xl	font-primary color-black mb-6">
              Based on your responses, we recommend these next steps for you:
            </h2>
          </div>
        </>
      )}
      {!hideRecommendations && (
        <div>
          <div className="flex text-center items-center justify-center mb-7">
            <div className="sm:w-[550px] md:w-[850px] lg:w-[850px] ">
              <div className="flex justify-center content-center items-stretch gap-2 justify-center flex-wrap gap-5 xxl:gap-8">
                {recommendations.map((recommendation, index) => {
                  return (
                    <div>
                      <Wrapper
                        onClick={() =>
                          navigateTo(
                            ROLES.BUSINESS_OWNER,
                            `${
                              recommendation?.meta?.type === 'assessment'
                                ? `assessmentQuestion/${recommendation.id}`
                                : `plays/${recommendation.id}/guide`
                            }`
                          )
                        }
                      >
                        <div>
                          <EllipseIcon className={'rounded-full fill-primary h-[54px] w-[54px]'} />
                        </div>
                        <div>
                          <label className="text-xs font-normal	text-primary-text uppercase font-primary">
                            {recommendation?.meta?.type}
                          </label>
                        </div>
                        <div className="">
                          <p className=" font-primary text-[17px] text-primary-text font-normal font-semibold leading-[20px] text-wrap line-clamp-4">
                            {`${
                              recommendation?.meta?.type === 'assessment'
                                ? `${ASSESSMENTS[recommendation.id]?.title}`
                                : `${recommendation?.description}`
                            }`}
                          </p>
                        </div>
                      </Wrapper>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <h1
            className="text-center font-primary text-primary text-lg font-bold mt-8 cursor-pointer"
            onClick={() => navigateTo(ROLES.BUSINESS_OWNER, 'dashboard')}
          >
            Return to my Dashboard
          </h1>
        </div>
      )}
    </>
  )
}

AssessmentSuccess.defaultProps = {
  hideRecommendations: false,
  allowNextPlayMessage: true,
}

export default AssessmentSuccess
