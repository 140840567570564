import React, { FC } from 'react'
import ExclamationIcon from 'components/Common/SvgIcons/ExclamationIcon'

export interface IErrorMessageProps {
  id: string | undefined
  children?: React.ReactNode
  hideExclamationMark?: boolean
}

const ErrorMessage: FC<IErrorMessageProps> = ({ id, children, hideExclamationMark }) => {
  return (
    <div className="flex flex-row items-center gap-1 my-2">
      {!hideExclamationMark && (
        <ExclamationIcon className="w-[16px] h-[16px] min-w-[16px] min-h-[16px] fill-primary-error" />
      )}
      <p
        id={`${id}-error`}
        data-testid={`${id}-error`}
        className="text-primary-error font-primary xs:text-sm sm:text-xl font-medium"
      >
        {children}
      </p>
    </div>
  )
}

export default ErrorMessage
