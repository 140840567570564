import React from 'react'

export default function PayPalIcon() {
  return (
    <svg
      width="103"
      height="28"
      viewBox="0 0 103 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_26573_8178)">
        <path
          d="M12.8689 0.00133133H4.93532C4.39233 0.00133133 3.93067 0.407745 3.84595 0.960016L0.637237 21.9169C0.573419 22.3302 0.884382 22.7031 1.2915 22.7031H5.07912C5.62201 22.7031 6.08367 22.2969 6.16839 21.7436L7.03377 16.0913C7.11735 15.5378 7.58015 15.1315 8.12191 15.1315H10.6334C15.8595 15.1315 18.8756 12.5264 19.6634 7.36394C20.0183 5.10534 19.6784 3.33079 18.6517 2.08799C17.5242 0.72315 15.5242 0.00133133 12.8689 0.00133133ZM13.7842 7.65555C13.3503 10.5881 11.1752 10.5881 9.07201 10.5881H7.87486L8.71471 5.1114C8.76464 4.78033 9.04299 4.53656 9.36785 4.53656H9.9165C11.3492 4.53656 12.7007 4.53656 13.399 5.37776C13.8155 5.87974 13.9431 6.62539 13.7842 7.65555Z"
          fill="#283B82"
        />
        <path
          d="M36.5824 7.56124H32.7833C32.4597 7.56124 32.1802 7.80503 32.1301 8.13607L31.9621 9.23069L31.6963 8.8339C30.8739 7.6042 29.0397 7.1932 27.2092 7.1932C23.011 7.1932 19.4252 10.4687 18.7269 15.0635C18.3639 17.3555 18.8801 19.5472 20.1422 21.0755C21.2999 22.4808 22.9564 23.0664 24.9275 23.0664C28.3102 23.0664 30.186 20.8259 30.186 20.8259L30.0166 21.9133C29.9527 22.3292 30.2637 22.702 30.6686 22.702H34.0906C34.6347 22.702 35.0941 22.2957 35.18 21.7425L37.2332 8.34753C37.2983 7.93524 36.9885 7.56124 36.5824 7.56124ZM31.2868 15.1783C30.9204 17.414 29.1975 18.9151 27.0006 18.9151C25.8973 18.9151 25.0156 18.5506 24.4494 17.8598C23.888 17.1739 23.6745 16.1976 23.8532 15.1101C24.1954 12.8934 25.9472 11.3435 28.1105 11.3435C29.1895 11.3435 30.0665 11.7127 30.6442 12.4093C31.2232 13.1132 31.4526 14.0955 31.2868 15.1783Z"
          fill="#283B82"
        />
        <path
          d="M56.8179 7.56157H53.0002C52.636 7.56157 52.2937 7.748 52.0873 8.05996L46.8217 16.0498L44.5899 8.37182C44.4495 7.89136 44.0191 7.56157 43.5319 7.56157H39.7803C39.3243 7.56157 39.0077 8.02041 39.1527 8.46258L43.3579 21.1751L39.4044 26.9244C39.0934 27.3772 39.4066 28 39.9439 28H43.757C44.1189 28 44.4577 27.8183 44.663 27.5123L57.3608 8.63118C57.6648 8.17936 57.3528 7.56157 56.8179 7.56157Z"
          fill="#283B82"
        />
        <path
          d="M69.4587 0.00133133H61.524C60.9823 0.00133133 60.5205 0.407745 60.4358 0.960016L57.227 21.9169C57.1634 22.3302 57.4742 22.7031 57.8791 22.7031H61.9509C62.329 22.7031 62.6528 22.4188 62.7119 22.0316L63.6224 16.0913C63.7059 15.5378 64.169 15.1315 64.7106 15.1315H67.2211C72.4481 15.1315 75.4632 12.5264 76.252 7.36394C76.6083 5.10534 76.2661 3.33079 75.2393 2.08799C74.1129 0.72315 72.1141 0.00133133 69.4587 0.00133133ZM70.3741 7.65555C69.9413 10.5881 67.7664 10.5881 65.6619 10.5881H64.4659L65.307 5.1114C65.3569 4.78033 65.6328 4.53656 65.9588 4.53656H66.5075C67.9392 4.53656 69.2917 4.53656 69.99 5.37776C70.4065 5.87974 70.5329 6.62539 70.3741 7.65555Z"
          fill="#469BDB"
        />
        <path
          d="M93.1714 7.56124H89.3745C89.0485 7.56124 88.7711 7.80503 88.7225 8.13607L88.5543 9.23069L88.2875 8.8339C87.4651 7.6042 85.6321 7.1932 83.8016 7.1932C79.6032 7.1932 76.0188 10.4687 75.3203 15.0635C74.9585 17.3555 75.4723 19.5472 76.7346 21.0755C77.8946 22.4808 79.5487 23.0664 81.5197 23.0664C84.9024 23.0664 86.7782 20.8259 86.7782 20.8259L86.6088 21.9133C86.5451 22.3292 86.8559 22.702 87.2632 22.702H90.6842C91.2259 22.702 91.6877 22.2957 91.7724 21.7425L93.8269 8.34753C93.8893 7.93524 93.5785 7.56124 93.1714 7.56124ZM87.8756 15.1783C87.5113 17.414 85.7863 18.9151 83.5893 18.9151C82.4883 18.9151 81.6044 18.5506 81.0382 17.8598C80.477 17.1739 80.2657 16.1976 80.4421 15.1101C80.7865 12.8934 82.536 11.3435 84.6996 11.3435C85.7783 11.3435 86.6552 11.7127 87.233 12.4093C87.8141 13.1132 88.0438 14.0955 87.8756 15.1783Z"
          fill="#469BDB"
        />
        <path
          d="M97.6514 0.576294L94.395 21.9169C94.3314 22.3302 94.6421 22.7031 95.0471 22.7031H98.3206C98.8647 22.7031 99.3266 22.2969 99.4101 21.7436L102.621 0.787975C102.685 0.374334 102.374 0.000337601 101.969 0.000337601H98.3032C97.9796 0.00133324 97.7013 0.24543 97.6514 0.576294Z"
          fill="#469BDB"
        />
      </g>
      <defs>
        <clipPath id="clip0_26573_8178">
          <rect width="102" height="28" fill="white" transform="translate(0.628906)" />
        </clipPath>
      </defs>
    </svg>
  )
}
