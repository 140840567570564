import { FIELD_NAME, INDUSTRY_TYPE } from 'config'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAssessmentOptionsSelector } from 'store/selectors/owner'

export const useCheckInitialAssessmentFormValid = (
  isDirty,
  formValues,
  setIsFormValid,
  setIndustryChanged,
  businessClassification
) => {
  const state = useSelector((state: any) => state)
  const options = getAssessmentOptionsSelector(state)
  const businessClassificationOptions = options?.businessClassification

  useEffect(() => {
    if (isDirty) {
      businessClassification === formValues?.businessClassificationOptionId
        ? setIndustryChanged(false)
        : setIndustryChanged(true)
    }

    isDirty && setIsFormValid(false)
    if (businessClassificationOptions) {
      for (const classification of businessClassificationOptions) {
        if (classification.value === formValues?.businessClassificationOptionId) {
          if (
            (classification.label === INDUSTRY_TYPE.SERVICES &&
              isEmpty(formValues[FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID])) ||
            (classification.label === INDUSTRY_TYPE.FOOD &&
              isEmpty(formValues[FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS]))
          ) {
            setIsFormValid(true)
            break // No need to continue searching
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [formValues, setIsFormValid, isDirty])
}
