import React, { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { AppDispatch, RootState } from 'App'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import useNavigateTo from 'hooks/useNavigateTo'
import { getAssessmentPlayListingAction } from 'store/actions/PlayBook'

import PlayBookCategoryIcon from 'components/Owner/PlayBookCategoryIcon'
import TakePlaysToast from 'components/Owner/PlayBooksCategoryDetails/TakePlaysToast'
import PlaybooksCategoryContentCard from 'components/Owner/PlayBooksCategoryDetails/PlaybooksCategoryContentCard'
import Loader from 'components/Loaders'

import {
  ViewWrapper,
  CategorySectionWrapper,
  IconAndTitleBoxWrapper,
  IconContainerWrapper,
  CategoryTitleWrapper,
  RecommendationSectionWrapper,
  RecommendationContentBoxWrapper,
  DescriptionWrapper,
  CardSpacerWrapper,
} from 'components/Owner/PlayBooksCategoryDetails/styles'

import { scrollToTop } from 'utils/helper'
import { CURRENT_TAB } from 'config'
import { PlayBooksRecommendationCardContainer } from 'pages/Assessment/containers'

export enum PlayBookCategoryParam {
  SALES_ESSENTIALS = 'SALES_ESSENTIALS',
  FINANCING_ESSENTIALS = 'FINANCING_ESSENTIALS',
  MARKETING_ESSENTIALS = 'MARKETING_ESSENTIALS',
  BUSINESS_ESSENTIALS = 'BUSINESS_ESSENTIALS',
}

const playbooksCategoryParams = [
  PlayBookCategoryParam.SALES_ESSENTIALS,
  PlayBookCategoryParam.FINANCING_ESSENTIALS,
  PlayBookCategoryParam.MARKETING_ESSENTIALS,
  PlayBookCategoryParam.BUSINESS_ESSENTIALS,
]

const industrySubIndustryMaps = {
  food: {
    subIndustries: ['restaurant', 'kitchen', 'mobile'],
  },
}
const industries = [
  'retail',
  'childcare',
  'restaurant',
  'services',
  'mobile',
  'ecommerce',
  'kitchen',
  'food',
] as const
export type Industry = typeof industries[number]

export const determineIndustryCode = (industry: Industry, assessments) => {
  let result = industry
  if (isIndustryWithSubIndustries(industry)) {
    for (const assessment of assessments) {
      const searchResult = findSubIndustryFromAssessmentType(industry, assessment.assessmentType)
      if (searchResult) {
        result = searchResult
        break
      }
    }
  }
  return result
}
/** Configuration data for the page depending on the category parameter in the URL*/
export const playBooksCategoryConfig = {
  [PlayBookCategoryParam.SALES_ESSENTIALS]: {
    pageTitle: 'Sales Essentials',
    topic: 'sales',
    level: 1,
    categoryIconColor: 'pink' as const,
    description:
      "Sales Essentials is your ultimate resource for boosting your business's sales. This comprehensive guide covers essential aspects such as payment acceptance, supplier management, effective sales tools and strategies, point of sales systems, sales tracking, payment management, and pricing strategies.",
    getAssessmentId(industry: Industry, assessments) {
      return `sales_lvl_${determineIndustryCode(industry, assessments)}_1`
    },
    associatedPlayTitle: 'Sales Level 1',
  },
  [PlayBookCategoryParam.FINANCING_ESSENTIALS]: {
    pageTitle: 'Finance Essentials',
    topic: 'finance',
    level: 1,
    categoryIconColor: 'blue' as const,
    description:
      'Finance Essentials is a comprehensive guide designed to provide you with clear and practical tips, along with essential tools, to streamline your bookkeeping, tax management, expense tracking, transaction recording, invoice handling, budget management, and budget tracking.',
    getAssessmentId(industry: Industry, assessments) {
      return `finance_lvl_${determineIndustryCode(industry, assessments)}_1`
    },
    associatedPlayTitle: 'Financing Level 1',
  },
  [PlayBookCategoryParam.MARKETING_ESSENTIALS]: {
    pageTitle: 'Marketing Essentials',
    topic: 'marketing',
    level: 1,
    categoryIconColor: 'aquamarine' as const,
    description:
      'Marketing Essentials is your key to building a strong brand and reaching your target audience. This guide will help you understand and analyze both your digital and local competition, enabling you to identify opportunities for growth.',
    getAssessmentId(industry: Industry, assessments) {
      return `marketing_lvl_${determineIndustryCode(industry, assessments)}_1`
    },
    associatedPlayTitle: 'PLACEHOLDER - API NOT CAUGHT UP',
  },
  [PlayBookCategoryParam.BUSINESS_ESSENTIALS]: {
    pageTitle: 'Business Essentials',
    topic: 'business',
    level: 1,
    categoryIconColor: 'orange' as const,
    description:
      'Business Essentials is your ultimate guide to legitimizing your business, communicating professionally, increasing your visibility to customers, and initiating brand development. This comprehensive set of questions will walk you through the essential steps you need to take for achieving success in these areas.',
    getAssessmentId(industry: Industry, assessments) {
      return `business_essentials_${determineIndustryCode(industry, assessments)}_1`
    },
    associatedPlayTitle: 'Business Essentials Level 1',
  },
}

const usePlayBooksCategoryConfig = (category: string | PlayBookCategoryParam) => {
  const config = useMemo(() => {
    const fallbackContent = {
      pageTitle: '',
      categoryIconColor: 'pink' as const,
      description: '',
    }
    return playBooksCategoryConfig?.[category as PlayBookCategoryParam] || fallbackContent
  }, [category])
  return config
}

const isCategoryValid = (category: string | PlayBookCategoryParam) =>
  playbooksCategoryParams.includes(category as PlayBookCategoryParam)

const useIsCategoryValid = (category) => {
  return useMemo(() => isCategoryValid(category), [category])
}

const useRedirectToPlaybooksOnInvalidCategory = (category: string) => {
  const redirect = useNavigateTo('playBooks')
  useEffect(() => {
    const valid = isCategoryValid(category)
    if (!valid) redirect()
  }, [category, redirect])
}

export enum RecommendableStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}
/* A "recommendable" represents either a play or assessment */
const getRecommendableStatus = (recommendable) => {
  let status: RecommendableStatus = RecommendableStatus.NOT_STARTED
  if (recommendable?.inProgress) {
    status = RecommendableStatus.IN_PROGRESS
  }
  if (recommendable?.isCompleted) {
    status = RecommendableStatus.COMPLETED
  }
  return status
}

const isIndustryWithSubIndustries = (industry: Industry) => {
  return Object.keys(industrySubIndustryMaps).includes(industry)
}

const findSubIndustryFromAssessmentType = (
  industry: Industry,
  assessmentType: string
): Industry | null => {
  for (const subIndustry of industrySubIndustryMaps[industry].subIndustries || []) {
    if (assessmentType.includes(subIndustry)) {
      return subIndustry
    }
  }
  return null
}

/** Find the correct assessment of a given category by examining the string content of the "assessmentType" property. */
const findAssessmentByCategory = (category: PlayBookCategoryParam, assessments, industry) => {
  const config = playBooksCategoryConfig?.[category]
  if (!config) return undefined
  return assessments.find(({ assessmentType }) => {
    if (
      assessmentType.includes(config.topic) &&
      assessmentType.includes(String(config.level)) &&
      assessmentType.includes(industry)
    ) {
      return true
    } else if (
      isIndustryWithSubIndustries(industry) &&
      assessmentType.includes(config.topic) &&
      assessmentType.includes(String(config.level))
    ) {
      const result = findSubIndustryFromAssessmentType(industry, assessmentType)
      return result !== null
    }
    return false
  })
}

const connector = connect(
  (state: RootState) => {
    return {
      industry: state.user?.businessProfile?.businessClassification?.toLowerCase() || '',
      assessments: state.playBook.assessmentPlayList.assessments,
      plays: state.playBook.assessmentPlayList.plays,
      isPlayBookLoading: state.loadingStatus.getAssessmentPlayListingLoading,
    }
  },
  (dispatch: AppDispatch) => {
    return bindActionCreators(
      {
        getAssessmentPlayListingAction: getAssessmentPlayListingAction.STARTED,
      },
      dispatch
    )
  }
)

const PlayBooksCategoryDetails = connector<FC<ConnectedProps<typeof connector>>>(
  ({ industry, getAssessmentPlayListingAction, assessments, plays, isPlayBookLoading }) => {
    useEffect(() => scrollToTop(), [])
    const { category } = useParams()

    const isCategoryValid = useIsCategoryValid(category)
    useRedirectToPlaybooksOnInvalidCategory(category)

    useEffect(() => {
      getAssessmentPlayListingAction()
    }, [getAssessmentPlayListingAction])

    const {
      topic,
      level,
      pageTitle,
      description,
      getAssessmentId,
      categoryIconColor,
      associatedPlayTitle,
    } = usePlayBooksCategoryConfig(category)

    /** Use the assessment that corresponds to the given route param category */
    const useAssessment = (category: PlayBookCategoryParam) => {
      const assessment = useMemo(
        () => findAssessmentByCategory(category, assessments, industry),
        // linter is wrong in this case -- these are valid deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [category, assessments, industry]
      )
      return assessment
    }

    /** One assessment is displayed on a playbooks-category page, but identifying the correct one is not straightforward. Currently, the best way to identify the correct assessment is to check for certain substrings in the "assessmentType" property, which plays the loose/informal role of an ID (but this is not perfectly consistent). Care should be taken to verify that the correct assessment is displayed when new variants of this page are added in the future. */
    const assessmentOfCategory = useAssessment(category)

    /** The client-side computation of the assessmentId is a fast workaround to ids not being returned from the "getAssessmentPlayListing" API call, which is responsible for fetching the assessment and plays shown on the page. This runs the risk of future errors if the assessmentId should change in the database. The API response should include the ids of each assessment as soon as possible, but for now this is safe and maintainable enough given the limited number of assessments actually available to users. */
    const assessmentId = useMemo(
      () => getAssessmentId(industry, assessments),
      [industry, getAssessmentId, assessments]
    )

    const assessmentStatus = useMemo(
      () => getRecommendableStatus(assessmentOfCategory),
      [assessmentOfCategory]
    )

    /** The plays associated with the category of the page, selected from the complete, uncategorized collection of plays recommended to the user */
    const playsOfCategory = useMemo(() => {
      const _playsOfCategory = plays.filter((play) => {
        const playCategory = `${play.category} Level 1`
        return (
          playCategory.toLowerCase() === associatedPlayTitle.toLowerCase() ||
          play.title === associatedPlayTitle
        )
      })
      const notStarted = _playsOfCategory.filter(
        (p) => !p.inProgress && !p.isCompleted && !p.isLocked
      )
      const locked = _playsOfCategory.filter((p) => !p.inProgress && !p.isCompleted && p.isLocked)
      const inProgress = _playsOfCategory.filter((p) => p.inProgress)
      const completed = _playsOfCategory.filter((p) => p.isCompleted)
      return [...inProgress, ...notStarted, ...locked, ...completed]
    }, [associatedPlayTitle, plays])

    const financeEssentialsAssessment = useAssessment(PlayBookCategoryParam.FINANCING_ESSENTIALS)

    const salesEssentialsAssessment = useAssessment(PlayBookCategoryParam.SALES_ESSENTIALS)

    /** The user can complete Business Essentials, Finance Essentials, and Sales Essentials in any order they choose.
     * However, Marketing Essentials requires the user take Finance Essentials and Sales Essentials first. */
    const isAssessmentLocked = useMemo(() => {
      const marketingConfig = playBooksCategoryConfig[PlayBookCategoryParam.MARKETING_ESSENTIALS]
      if (level !== marketingConfig.level || topic !== marketingConfig.topic) return false
      /* If pre-requisite plays are not found, they have not yet been recommended. Pre-requisites have not been met, so assessment is locked. */
      if (!financeEssentialsAssessment || !salesEssentialsAssessment) return true
      /* If required pre-requisite assessments are incomplete the assessment is locked. */
      return (
        financeEssentialsAssessment.isCompleted ||
        salesEssentialsAssessment.isCompleted ||
        Boolean(assessmentOfCategory?.isLocked)
      )
    }, [
      assessmentOfCategory?.isLocked,
      financeEssentialsAssessment,
      salesEssentialsAssessment,
      level,
      topic,
    ])

    return isPlayBookLoading ? (
      <Loader loader="PlayBookLoader" />
    ) : isCategoryValid ? (
      <ViewWrapper>
        <CategorySectionWrapper>
          <IconAndTitleBoxWrapper>
            <IconContainerWrapper>
              <PlayBookCategoryIcon className="w-4/5" color={categoryIconColor} />
            </IconContainerWrapper>
            <CategoryTitleWrapper>{pageTitle}</CategoryTitleWrapper>
          </IconAndTitleBoxWrapper>
          <DescriptionWrapper>{description}</DescriptionWrapper>
        </CategorySectionWrapper>
        <RecommendationSectionWrapper>
          <RecommendationContentBoxWrapper>
            <PlaybooksCategoryContentCard title="Assessment">
              <PlayBooksRecommendationCardContainer
                variant="assessment"
                title={`${pageTitle} Level ${level}`}
                status={assessmentStatus}
                locked={isAssessmentLocked}
                id={assessmentId}
                loading={isPlayBookLoading}
                viewedContentFrom={CURRENT_TAB.PLAYBOOK}
              />
            </PlaybooksCategoryContentCard>
            <TakePlaysToast />
            <PlaybooksCategoryContentCard title="Plays">
              <CardSpacerWrapper>
                {playsOfCategory.map((play, idx) => {
                  return (
                    <PlayBooksRecommendationCardContainer
                      key={`play-books-recommendation-card-${idx + 1}`}
                      level={`${level}`}
                      status={getRecommendableStatus(play)}
                      locked={Boolean(play?.isLocked)}
                      title={play?.description}
                      category={play?.meta?.category}
                      id={play?.id}
                      variant="play"
                      loading={isPlayBookLoading}
                      viewedContentFrom={CURRENT_TAB.PLAYBOOK}
                    />
                  )
                })}
              </CardSpacerWrapper>
            </PlaybooksCategoryContentCard>
          </RecommendationContentBoxWrapper>
        </RecommendationSectionWrapper>
      </ViewWrapper>
    ) : (
      <div></div>
    )
  }
)

export default PlayBooksCategoryDetails
