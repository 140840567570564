import { IUSER_NOTIFICATION_TYPES } from 'components/NotificationPanel/types'
import { ROLES } from 'config'
import { createSelector } from 'reselect'

import { getRoleFromPath } from 'utils/helper'
export const getNotifications = (state) => state.user?.notifications?.data
export const getTenantId = (state) => state.user.tenantId

export const getParsedNotificationSelector = createSelector(
  [getNotifications, getTenantId],
  (notifications, tenantId) => {
    const userRole = getRoleFromPath()
    return notifications?.map((notification) => {
      const parsedMeta = JSON.parse(notification?.meta)
      if (
        notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME &&
        userRole === ROLES.BUSINESS_OWNER
      ) {
        return {
          ...notification,
          meta: parsedMeta,
          url: `${userRole}/${tenantId}/attestation`,
        }
      } else if (
        notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_UPDATE &&
        (userRole === ROLES.BSO_ADMIN || userRole === ROLES.BSO_ADVISOR)
      ) {
        return {
          ...notification,
          meta: parsedMeta,
          url: `${userRole}/${tenantId}/data-reports/reports`,
        }
      } else if (
        notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME &&
        (userRole === ROLES.BSO_ADMIN || userRole === ROLES.BSO_ADVISOR)
      ) {
        return {
          ...notification,
          meta: parsedMeta,
          url: `${userRole}/${tenantId}/data-reports/reports`,
        }
      } else if (notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING) {
        let url = `${userRole}/${tenantId}/calendar`
        if (parsedMeta?.resourceId) {
          url += `?details=${parsedMeta.resourceId}`
        }
        return {
          ...notification,
          meta: parsedMeta,
          url,
        }
      } else
        return {
          ...notification,
          meta: parsedMeta,
        }
    })
  }
)
