import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

export interface IGoal {
  id: string
  progress: number
  goalName: string
}

const goalCenterProps = (state: RootState) => {
  return {
    bsoProfile: state.bso.bsoProfile,
    userId: state.user?.user.id,
  }
}

function goalCenterDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const GoalCenterConnector = connect(goalCenterProps, goalCenterDispatch)

export const getGoalsSelector = (goals): IGoal[] =>
  goals.map(
    ({ id, progress, goalName }) =>
      ({
        id,
        progress,
        goalName,
      } as IGoal)
  )
