import React, { useEffect, useState } from 'react'
// import get from 'lodash/get'
// import StatCard from 'components/Owner/Data/Finance/StatCard'
import GrowingContainer from 'components/Common/GrowingContainer'
// import { intervalTypes } from '../utils'
// import { IPNLData, setPNLData } from 'components/Owner/Data/Finance/utils'
// import MultipleStatCard from './MultipleStatCard'
// import Expenses from './Expenses'
import { PageHeading } from 'components/Owner/Data/styles'
import SelectField from 'components/Common/SelectField'
import { AccountingCharts, DateRanges } from '../../../../__generated__/api-types-and-hooks'
import { chartRangeChanged, chartRangeViewed, getRoleFromPath, navigateTo } from 'utils/helper'
import { DateRangesNamesMap, IDataTabs, ROLES } from 'config'
import { LiquidCashChart } from './LiquidCash'
import { ProfitAndLossChart } from './ProfitAndLoss'
import moment from 'moment'

const getDateByDateRange = (filter: string) => {
  let startDate: string = ''
  let endDate: string = ''

  switch (filter) {
    case DateRanges.ThisMonth:
      startDate = moment().startOf('month').format('YYYY-MM-DD')
      endDate = moment().endOf('month').format('YYYY-MM-DD')
      break
    case DateRanges.ThisQuarter:
      startDate = moment().startOf('quarter').format('YYYY-MM-DD')
      endDate = moment().endOf('quarter').format('YYYY-MM-DD')
      break
    case DateRanges.Last12Months:
      startDate = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD')
      endDate = moment().endOf('month').format('YYYY-MM-DD')
      break
    case DateRanges.ThisYearToDate:
      startDate = moment().startOf('year').format('YYYY-MM-DD')
      endDate = moment().format('YYYY-MM-DD')
      break
    default:
      break
  }

  return { startDate, endDate }
}

const Finance = ({ isLoading, financeData, getFinanceDataAction }) => {
  const [dateRange, setDateRange] = useState(DateRanges.ThisMonth)
  // const getPNL = setPNLData(financeData)
  // const pnlChartStatus = get(financeData, `pnlData.chartStatus`, 0)

  useEffect(() => {
    getFinanceDataAction()
  }, [getFinanceDataAction])

  const handleChange = (selectedDateRange: DateRanges) => {
    setDateRange(selectedDateRange)
    const { startDate, endDate } = getDateByDateRange(selectedDateRange)
    chartRangeChanged({
      section: IDataTabs.FINANCE,
      chartName: [AccountingCharts.ProfitAndLoss, AccountingCharts.LiquidCash],
      endDate,
      startDate,
    })
  }

  return (
    <GrowingContainer>
      <div className="grid col-span-12">
        <div className="flex flex-col sm:flex-row  justify-between py-7 px-5">
          <div className="flex flex-col">
            <PageHeading>Finance</PageHeading>
            {getRoleFromPath() === ROLES.BUSINESS_OWNER && (
              <p className="leading-9 text-black mb-4 mt-4 font-primary">
                If you haven’t connected your QuickBooks account to our system, please visit{' '}
                <span
                  className="text-primary hover:cursor-pointer"
                  onClick={() => navigateTo('', 'tools/featured')}
                >
                  Tools
                </span>
                .
              </p>
            )}
          </div>
          <div className="flex md:justify-end mb-6 mt-6 sm:mt-0">
            <SelectField
              label="FILTER BY TIME"
              placeholder="Select"
              isDropDownMenu={true}
              value={dateRange}
              customStyle={{
                control: {
                  backgroundColor: 'white',
                },
                container: {
                  borderRadius: '0.25rem',
                  minWidth: '300px',
                  border: '1px solid #D4D4D8',
                },
              }}
              name="dateRanges"
              options={Object.entries(DateRangesNamesMap).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              onChange={(selectedDateRange: DateRanges) => handleChange(selectedDateRange)}
              onMenuOpen={() => {
                chartRangeViewed({
                  section: IDataTabs.FINANCE,
                  chartName: [AccountingCharts.ProfitAndLoss, AccountingCharts.LiquidCash],
                })
              }}
            />
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="xs:col-span-12 md:col-span-4">
          <ProfitAndLossChart period={dateRange} />
        </div>
        <div className="xs:col-span-12 md:col-span-4">
          <LiquidCashChart period={dateRange} />
        </div>
        {/* <div className="xs:col-span-12 md:col-span-4">
          <StatCard
            title="Total Income"
            aggValue={getPNL(IPNLData.Other_Income)}
            source={getPNL(IPNLData.Other_Income, 'Source')}
            percentageValue={getPNL(IPNLData.Other_Income, 'day_percentage_change')}
            interval={intervalTypes.month}
            status={pnlChartStatus}
            getFinanceData={getFinanceDataAction}
            isLoading={isLoading}
          />
          <StatCard
            title="Total Expense"
            aggValue={getPNL(IPNLData.Expenses)}
            source={getPNL(IPNLData.Other_Income, 'Source')}
            percentageValue={getPNL(IPNLData.Expenses, 'day_percentage_change')}
            interval={intervalTypes.month}
            status={pnlChartStatus}
            getFinanceData={getFinanceDataAction}
            isLoading={isLoading}
          />
          <StatCard
            title="Profit"
            aggValue={getPNL(IPNLData.GROSS_PROFIT)}
            source={getPNL(IPNLData.Other_Income, 'Source')}
            percentageValue={getPNL(IPNLData.GROSS_PROFIT, 'day_percentage_change')}
            interval={intervalTypes.month}
            status={pnlChartStatus}
            getFinanceData={getFinanceDataAction}
            isLoading={isLoading}
          />
          <MultipleStatCard
            title="Burn Rate"
            aggValuePrimary={getPNL(IPNLData.Burn_Rate)}
            aggValueSecondary={getPNL(IPNLData.Burn_Rate, 'day_percentage_value')}
            percentageValue={getPNL(IPNLData.Burn_Rate, 'day_percentage_change')}
            interval="compared to the previous month"
            isLoading={isLoading}
          />
        </div>
        <div className="col-span-12">
          <Expenses isLoading={isLoading} />
        </div> */}
      </div>
    </GrowingContainer>
  )
}

export default Finance
