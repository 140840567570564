import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledCircle = styled.div<{ $color: string }>`
  background: ${(props) => props.$color};
  ${tw`rounded-full w-3 h-3 my-auto`}
`

export const ClientDataItemCircle = ({ color }) => {
  return <StyledCircle $color={color} />
}
