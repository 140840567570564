import React from 'react'
import Button from 'components/Common/Button'

const SkipButton = ({ label, isHidden, handleClick, margin, className }) => {
  return (
    <div className={`flex ${className}`}>
      <div className={`w-max ${margin}`}>
        <Button
          variant="text"
          type="button"
          label={label}
          onClick={handleClick}
          className={`${isHidden ? 'hidden' : ''} !px-0 w-full`}
        />
      </div>
    </div>
  )
}
SkipButton.defaultProps = {
  margin: 'mt-6',
  className: 'justify-center ',
}
export default SkipButton
