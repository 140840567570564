import tw from 'twin.macro'
import styled from 'styled-components'
import { ChevronRightIcon } from 'components/Common/SvgIcons'

export const DictionaryWrapper = styled.span`
  .alpha-list {
    overflow: hidden !important;
  }
  .alpha-list > div > div > div:first-child {
    display: none;
  }
  .alpha-list > div > div > div:first-child {
    display: none;
  }
  .alpha-list > div > div > div {
    margin: 0% !important;
  }
  .alpha-list > div:last-child > div {
    font-size: 12px !important;
    font-weight: normal;
    color: #71717a !important;
    top: 20px !important;
  }
  .alpha-list {
    position: unset !important;
    overflow: scroll;
    height: 100%;
  }
  .alpha-list > div {
    padding: 0px !important;
  }

  @media all and (min-width: 321px) and (max-width: 480px) {
    .alpha-list {
      height: 100vh !important;
    }
  }
  @media all and (min-width: 480px) and (max-width: 1190px) {
    .alpha-list > div {
      padding: 0px !important;
    }
  }
  @media all and (min-width: 1200px) {
    .alpha-list > div:last-child {
      right: 0px !important;
      position: absolute;
    }
  }
`

export const BreadCrumbWrapper = styled.div`
  ${tw`text-lg font-normal flex items-center gap-1`}
`
export const BreadCrumbLabelWrapper = styled.label`
  ${tw`xs:text-base sm:text-xl font-bold`}
`
export const BreadCrumbLabel = styled.svg`
  ${tw`xs:text-base sm:text-xl font-bold`}
`

export const StyledChevronRightIconWrapper = styled(ChevronRightIcon)`
  ${tw`inline-block w-4 h-5`}
`

export const TopActiveClientsDesktopWrapper = styled.div`
  ${tw`mdl:block xs:hidden`}
`

export const TopActiveClientsMobileWrapper = styled.div`
  ${tw`md:col-span-12 mdl:hidden`}
`

export const MarginWrapper = styled.div`
  ${tw`xs:my-2`}
`

export const TopActiveClientsWrapper = styled.div`
  ${tw`grid md:grid-cols-3 mdl:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-3 gap-1 mdl:gap-4`}
`

export const ClientsWrapper = styled.div`
  ${tw`xs:grid grid-cols-11 sm:grid-cols-12 w-[100%]`}
`

export const GrowingContainerWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-7 xl:col-span-8 xxl:col-span-8 mdl:col-span-7`}
`

export const NoClientWrapper = styled.p`
  ${tw`text-base font-semibold text-xl`}
`

export const TextWrapper = styled.p`
  ${tw`font-primary font-normal text-zinc`}
`

export const UserProfileInfoWrapper = styled.div`
  ${tw`flex flex-col gap-2 items-center`}
`

export const UserProfileInitialWrapper = styled.div`
  ${tw`bg-blue-lighter1x rounded-full h-[79.8px] w-[79.8px] p-2.5 flex items-center justify-center`}
`

export const UserInitialsWrapper = styled.div`
  ${tw`rounded-full inline-flex justify-center items-center text-white font-bold uppercase text-xl`}
`

export const UserInfoWrapper = styled.div`
  ${tw`text-2xl text-center`}
`
