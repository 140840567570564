import React from 'react'
import { formatNumber } from 'utils/helper'
import SectionFooter from 'components/Owner/Data/SectionFooter'

const StatCard = ({ title, aggValue, aggValuelabel, updatedDate, handleRefresh }) => {
  return (
    <div className="totalSale bg-white xs:p-4 xxl:p-6 h-[420px] flex flex-col">
      <h5 className="font-semibold leading-7 text-lg font-primary text-primary-text mb-16 h-[56px]">
        {title}
      </h5>
      <div className="flex gap-4 flex-col items-center h-full ">
        <h2 className="xs:text-[40px] md:text-[60px] leading-[92px] font-secondary  lg:text-[80px] font-black text-primary-text tracking-wide">
          {formatNumber(aggValue) || 0}
        </h2>
        <div className="font-primary items-center text-sm text-primary-text">{aggValuelabel}</div>
        <SectionFooter lastUpdated={updatedDate || ''} getChartDataAction={handleRefresh} />
      </div>
    </div>
  )
}

export default StatCard
