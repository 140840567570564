import React from 'react'
import { AppFC } from 'types'

import Button from 'components/Common/Button'
import AlertBanner from 'components/Common/AlertBanner'
import ModalBox from 'components/Common/ModalBox'
import { STATUS } from 'config'

export interface IConfirmationModalProps {
  title: string
  status?: string
  isSuccess: boolean
  description: string
  errorMessage: string
  onClose?: () => void
  successDescription: string
  onConfirm: () => void
}

const ConfirmationModal: AppFC<IConfirmationModalProps> = ({
  title = '',
  status = '',
  isSuccess,
  errorMessage,
  description = '',
  successDescription,
  onConfirm,
  onClose = () => {},
}) => {
  return (
    <ModalBox title={title} onClose={onClose}>
      <div className="p-7">
        {isSuccess && (
          <>
            {!errorMessage && (
              <AlertBanner type="success" title="Succeeded">
                <p>{successDescription}</p>
              </AlertBanner>
            )}
            {errorMessage && (
              <AlertBanner type="error" title="Error">
                <p>Please close and try again later.</p>
                <p>(Error Message: {errorMessage})</p>
              </AlertBanner>
            )}
            <div className="flex mt-2">
              <div className="flex-auto">
                <Button variant="primary" label="Close" onClick={onClose} className="w-full" />
              </div>
            </div>
          </>
        )}
        <>
          {!errorMessage && !isSuccess && (
            <>
              <p className="mb-8">{description}</p>
              <div className="flex">
                <div className="flex-auto mr-2">
                  <Button
                    variant="primary"
                    label="Confirm"
                    status={status}
                    onClick={onConfirm}
                    className="w-full"
                    disabled={status === STATUS.LOADING ? true : false}
                  />
                </div>
                <div className="flex-auto ml-2">
                  <Button variant="secondary" label="Cancel" onClick={onClose} className="w-full" />
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </ModalBox>
  )
}

export default ConfirmationModal
