import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'
import colors from 'colors'

const BusinessAssessmentFormLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 500"
        backgroundColor={`${get(colors, 'cream.dark', '')}`}
        foregroundColor={`${colors.grey.lighter}`}
      >
        <rect x="0" y="32" rx="2" ry="2" width="200" height="17" />
        <rect x="0" y="60" rx="2" ry="2" width="450" height="50" />

        <rect x="0" y="132" rx="2" ry="2" width="200" height="17" />
        <rect x="0" y="160" rx="2" ry="2" width="450" height="50" />

        <rect x="0" y="232" rx="2" ry="2" width="200" height="17" />
        <rect x="0" y="260" rx="2" ry="2" width="450" height="50" />

        <rect x="0" y="332" rx="2" ry="2" width="200" height="17" />
        <rect x="0" y="360" rx="2" ry="2" width="450" height="50" />

        <rect x="0" y="432" rx="2" ry="2" width="200" height="17" />
        <rect x="0" y="460" rx="2" ry="2" width="450" height="50" />
      </ContentLoader>
    </>
  )
}

export default BusinessAssessmentFormLoader
