import DateFormatter from './DateFormatter'

export default class DateLabeler extends DateFormatter {
  /** Returns date expression formatted as either "Today" or "Jan 14"  */
  get shortLabel() {
    return this.dateIsToday ? 'Today' : this.abbreviatedMonthAndDay
  }

  /** Returns date expression formatted like "11:02 AM Mon, Jan 12" */
  get longLabel() {
    return `${this.timeOfDay} ${this.abbreviatedDayOfWeek},  ${this.abbreviatedMonthAndDay}`
  }
}
