import React, { useContext, useState } from 'react'

type UnreadContextValue = {
  chatsUnreadCount: number
  channelMessageCount: Record<string, number>
  setChannelMessageCount: any
  setChatsUnreadCount: React.Dispatch<React.SetStateAction<number>>
}

const UnreadContext = React.createContext({} as UnreadContextValue)

export const UnreadProvider: React.FC = ({ children }) => {
  const [chatsUnreadCount, setChatsUnreadCount] = useState(0)
  const [channelMessageCount, setChannelMessageCount] = useState<Record<string, number>>({})

  const contextValue: UnreadContextValue = {
    chatsUnreadCount,
    channelMessageCount,
    setChannelMessageCount,
    setChatsUnreadCount,
  }

  return <UnreadContext.Provider value={contextValue}>{children}</UnreadContext.Provider>
}

export const useUnreadContext = () => useContext(UnreadContext)
