import React from 'react'

function MeetingScheduleIcon({ className }) {
  return (
    <svg
      className={className}
      width="86"
      height="77"
      viewBox="0 0 86 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.0522 37.6108C44.0522 37.6108 54.883 41.723 56.5425 43.2408C58.2019 44.7586 67.7821 75.5998 67.7821 75.5998H0.999969C0.999969 75.5998 5.94994 54.8852 7.16417 47.8912C8.37839 40.8973 13.7452 39.3917 16.4732 38.8413C19.2011 38.2908 44.0522 37.6108 44.0522 37.6108Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8145 43.4593L27.2473 75.5876H15.6677L20.3222 40.6464L25.8145 43.4593Z"
        fill="#F45E13"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1293 34.9152C43.1293 34.9152 44.9183 37.542 44.5621 38.3798C44.2059 39.2176 40.1423 45.5437 36.0828 46.2601C32.0233 46.9765 26.8912 44.7059 24.143 43.0222C21.3948 41.3385 17.8169 40.0392 17.2219 39.679C16.6269 39.3188 18.7721 36.4411 19.7272 35.9797C20.6824 35.5183 21.8764 34.7655 21.8764 34.7655C21.8764 34.7655 23.1878 33.0939 23.4266 32.7418C23.6654 32.3896 23.7868 31.7866 24.143 31.309C24.2749 31.1071 24.4508 30.9377 24.6576 30.8136C24.8644 30.6895 25.0967 30.614 25.3369 30.5926C25.936 30.4752 26.7697 33.4582 30.3517 34.0572C33.9336 34.6562 42.6517 35.0124 42.6517 35.0124L43.1293 34.9152Z"
        fill="#F45E13"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0233 46.2602C32.0233 46.2602 25.0982 42.7956 24.2644 40.0514C23.4307 37.3073 23.4266 32.7661 23.4266 32.7661"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7698 43.2731C26.7698 43.2731 23.7868 41.9618 22.8316 40.1688C21.8765 38.3758 21.8765 34.7979 21.8765 34.7979"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.9704 20.0735C47.0999 20.5592 44.3031 34.4256 42.2997 37.9306C40.2962 41.4357 36.1274 43.4351 33.6221 42.2694C30.7241 40.9136 27.5307 38.2544 26.1465 35.3039C25.8915 34.7575 24.479 28.8523 24.7785 28.7511C24.7785 28.7511 22.775 29.4189 21.9453 25.7479C21.1156 22.0769 20.4437 19.9075 21.4434 17.9041C22.4431 15.9006 28.1135 11.2299 33.2902 12.0758C38.4668 12.9217 46.1366 16.9044 46.9704 20.0735Z"
        fill="#5F6368"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1202 15.7184C33.1202 15.7184 40.4622 21.3848 45.4688 21.8948C50.4754 22.4048 50.4714 20.0735 50.4714 20.0735C50.4714 20.0735 56.9796 21.911 56.3118 16.9043C55.644 11.8977 45.6145 7.89077 45.6145 7.89077C45.6145 7.89077 49.1195 6.89106 47.452 5.05759C45.7845 3.22412 39.4422 1.21661 35.2693 1.71848C31.0965 2.22036 29.263 3.22007 29.263 3.22007C29.263 3.22007 30.4327 0.216897 27.9273 1.19637C25.422 2.17584 23.2769 7.057 23.2769 7.057C23.2769 7.057 18.9381 7.39294 18.6021 8.55859C18.2662 9.72424 21.1075 10.0521 21.1075 10.0521C21.1075 10.0521 18.4362 15.0587 19.7718 17.8959C21.1075 20.7332 23.9447 21.5669 24.1106 22.0688C24.2766 22.5707 23.6088 24.4042 24.9444 23.7363C26.2801 23.0685 26.7819 20.2313 26.7819 20.2313C26.7819 20.2313 28.7813 18.2319 28.9513 17.3981C29.1213 16.5644 29.2832 15.5646 29.2832 15.5646C29.2832 15.5646 30.4529 19.0697 33.1202 19.5675C35.7874 20.0654 33.1202 15.7184 33.1202 15.7184Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9606 36.4291C38.9606 36.4291 36.7912 38.7644 32.7883 35.5912"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0568 25.4323C28.0568 25.4323 35.7914 25.2663 36.6252 25.6023C37.4589 25.9382 37.6249 27.9376 37.127 29.2733C36.6292 30.6089 35.4555 32.4424 32.4523 32.4424C29.4491 32.4424 27.9475 30.9408 26.9478 28.1036C25.9481 25.2663 28.0568 25.4323 28.0568 25.4323Z"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.1445 25.4323C47.1445 25.4323 41.1907 25.2663 40.5472 25.6023C39.9037 25.9382 39.7782 27.9376 40.1425 29.2733C40.5067 30.6089 41.4295 32.4424 43.7406 32.4424C46.0517 32.4424 47.2092 30.9408 47.9782 28.1036C48.7472 25.2663 47.1445 25.4323 47.1445 25.4323Z"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5836 26.3752L24.0419 23.0159"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6252 25.582H40.5876"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.043 26.2093L46.1569 24.983"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7396 28.4111C32.7396 29.0344 33.0351 29.5444 33.3994 29.5444C33.7636 29.5444 34.0551 29.0344 34.0551 28.4111C34.0551 27.7878 33.7596 27.2778 33.3994 27.2778C33.0392 27.2778 32.7396 27.7838 32.7396 28.4111Z"
        fill="#263238"
      />
      <path
        d="M41.4578 27.9334C41.4578 28.5608 41.7492 29.0667 42.1135 29.0667C42.4778 29.0667 42.7692 28.5608 42.7692 27.9334C42.7692 27.3061 42.4737 26.8002 42.1135 26.8002C41.7533 26.8002 41.4578 27.3061 41.4578 27.9334Z"
        fill="#263238"
      />
      <path
        d="M44.5621 24.052C44.5621 24.052 42.1742 23.0969 40.9802 23.8132"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.496 23.9347C33.8627 23.4602 32.1095 23.6312 30.5986 24.4123"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.6427 71.5929C74.5344 71.0395 75.5332 70.6812 76.5734 70.5417C77.6135 70.4022 78.6714 70.4845 79.6774 70.7834C82.968 71.7426 84.6112 74.6244 84.6112 75.5836H70.765C70.9361 74.753 71.2809 73.9681 71.7768 73.2803C72.2728 72.5925 72.9087 72.0174 73.6427 71.5929Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1464 68.3023C82.1464 68.3023 83.9272 70.9088 83.9272 71.8681C83.9272 72.8273 84.8864 73.7865 83.5225 74.1994C82.1585 74.6122 81.3288 69.812 81.3288 69.2656C81.3288 68.7192 82.1464 68.3023 82.1464 68.3023Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.6774 68.1647C79.6774 68.1647 81.5958 67.76 82.1463 68.3023C82.6967 68.8447 83.6519 73.653 83.5143 74.1994C83.3767 74.7458 82.0087 74.8834 81.5958 74.337C81.183 73.7906 79.6774 68.1647 79.6774 68.1647Z"
        fill="#5F6368"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.4491 70.3583C71.4491 70.3583 77.2085 68.2981 78.443 67.4765C79.6774 66.6549 80.3615 68.286 80.7743 69.2614C81.1871 70.2368 82.1464 73.6488 82.1464 74.608C82.1464 75.5673 80.6367 75.9761 80.3615 74.8792C80.0862 73.7824 77.8925 70.2207 77.8925 70.2207L75.0148 71.3175C75.0148 71.3175 74.7396 71.7222 75.9741 72.552C77.2085 73.3817 78.9894 75.1544 78.443 75.4297C77.8966 75.7049 76.2493 75.5673 75.6989 75.0249C75.1484 74.4826 72.2747 73.7905 72.2747 73.7905C72.2747 73.7905 69.6682 75.4378 69.1178 75.5754C68.5673 75.713 65.1432 75.0249 65.1432 75.0249C65.1432 75.0249 68.9842 71.7344 69.5306 71.3256C70.1393 70.9454 70.7815 70.6216 71.4491 70.3583Z"
        fill="#5F6368"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5087 68.2295H22.3864C23.6209 68.2295 26.0898 67.42 26.2274 68.7759C26.365 70.1318 23.4833 70.9696 22.1153 70.9696C20.7472 70.9696 19.9216 70.4232 18.2743 71.2448C16.627 72.0664 15.6677 72.6169 15.6677 72.6169L17.5903 69.5975L19.5087 68.2295Z"
        fill="#5F6368"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12742 75.357C8.12742 75.357 10.7339 69.7351 11.9684 67.9543C13.2029 66.1734 20.4679 64.8013 21.2774 64.8013C22.0869 64.8013 24.1592 69.3263 23.884 70.4232C23.6087 71.52 23.0745 70.2856 22.6697 69.6137C22.265 68.9418 20.7513 67.0112 20.7513 67.0112C20.7513 67.0112 18.42 70.8481 17.4607 72.9042C16.5015 74.9603 14.579 75.2355 13.4821 75.5108C12.3853 75.786 8.12742 75.357 8.12742 75.357Z"
        fill="#5F6368"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8543 67.1204C14.8543 67.1204 17.4608 63.6923 18.8329 64.2387C20.2049 64.7851 22.3946 71.3702 22.6698 72.1918C22.945 73.0135 21.2977 72.4671 21.0266 71.7871C20.7554 71.1071 18.2824 67.5373 18.2824 67.5373L16.0887 68.9094"
        fill="#5F6368"
      />
      <path
        d="M14.8543 67.1204C14.8543 67.1204 17.4608 63.6923 18.8329 64.2387C20.2049 64.7851 22.3946 71.3702 22.6698 72.1918C22.945 73.0135 21.2977 72.4671 21.0266 71.7871C20.7554 71.1071 18.2824 67.5373 18.2824 67.5373L16.0887 68.9094"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9276 67.1204C12.9276 67.1204 15.6677 64.7891 16.631 65.0603C17.5943 65.3315 21.0184 72.0542 20.468 72.3456C19.9175 72.637 18.8247 72.0704 18.2743 71.2488C17.7238 70.4271 16.631 68.5046 16.631 68.5046L14.5709 70.0143"
        fill="#5F6368"
      />
      <path
        d="M12.9276 67.1204C12.9276 67.1204 15.6677 64.7891 16.631 65.0603C17.5943 65.3315 21.0184 72.0542 20.468 72.3456C19.9175 72.637 18.8247 72.0704 18.2743 71.2488C17.7238 70.4271 16.631 68.5046 16.631 68.5046L14.5709 70.0143"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.588 69.0065C11.588 69.0065 12.9277 66.3069 13.8869 66.7198C14.8462 67.1326 18.1367 72.204 17.1775 72.204C16.2182 72.204 13.3365 69.1887 13.3365 69.1887C13.3365 69.1887 13.6117 71.52 13.3365 72.7504"
        fill="#5F6368"
      />
      <path
        d="M11.588 69.0065C11.588 69.0065 12.9277 66.3069 13.8869 66.7198C14.8462 67.1326 18.1367 72.204 17.1775 72.204C16.2182 72.204 13.3365 69.1887 13.3365 69.1887C13.3365 69.1887 13.6117 71.52 13.3365 72.7504"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4103 75.5876H15.433V74.4745H51.4103V75.5876Z"
        fill="#808080"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.674 51.0725H41.5387L34.217 75.5876H70.3522L77.674 51.0725Z"
        fill="#808080"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1386 27.7514C39.1386 27.7514 42.4737 35.0934 40.6402 35.2593C38.8067 35.4253 37.1351 33.4218 37.1351 33.4218"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6411 27.1928H40.0979"
        stroke="#5BBAB9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MeetingScheduleIcon
