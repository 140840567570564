import React, { FC } from 'react'

import { capitalize } from 'lodash'
import { actionOption } from 'config'
import { NAMES } from 'components/Admin/UserManagement/config/enums/index'

import SelectField from 'components/Common/SelectField'
import {
  LabelWrapper,
  UserActionWrapper,
} from 'components/Admin/UserManagement/UserManagementList/style'

import { onSelectActionsOptions } from 'utils/helper'
import { AdminRightsContainer } from 'pages/Admin/container/UserManagement'
import { IUser } from 'components/Admin/UserManagement/UserManagementList'
interface IUserActionProps {
  user: IUser
  setSelectedUser: (user: IUser | undefined) => void
  showAdminRightsToggle: boolean
  setOpenResetPasswordModal: (value: boolean) => void
  setOpenUserManagementModal: (value: boolean) => void
  openConfirmationModalAction: (value: boolean) => void
}

const UserAction: FC<IUserActionProps> = ({
  user,
  setSelectedUser,
  showAdminRightsToggle,
  setOpenResetPasswordModal,
  setOpenUserManagementModal,
  openConfirmationModalAction,
}) => {
  return (
    <UserActionWrapper>
      <SelectField
        name={NAMES.ACTION}
        placeholder={`${capitalize(NAMES.ACTION)}s`}
        options={actionOption
          .filter((o) => o.action === user?.activated)
          .map((opt) => {
            return { label: opt.label, value: opt.value }
          })}
        onChange={(value) => {
          setSelectedUser(user)
          onSelectActionsOptions(
            value,
            user,
            openConfirmationModalAction,
            setOpenResetPasswordModal
          )
        }}
      />
      {showAdminRightsToggle && <AdminRightsContainer params={user} />}
      <LabelWrapper
        onClick={() => {
          setSelectedUser(user)
          setOpenUserManagementModal(true)
        }}
      >
        Edit
      </LabelWrapper>
    </UserActionWrapper>
  )
}

export default UserAction
