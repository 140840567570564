import React from 'react'

function InfoCircleIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9420_94208)">
        <path
          d="M12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24ZM12 1.5C6.21094 1.5 1.5 6.21094 1.5 12C1.5 17.7891 6.21094 22.5 12 22.5C17.7891 22.5 22.5 17.7891 22.5 12C22.5 6.21094 17.7891 1.5 12 1.5ZM12 9.75C12.4146 9.75 12.75 10.0854 12.75 10.5V18C12.75 18.4146 12.4146 18.75 12 18.75C11.5854 18.75 11.25 18.4125 11.25 18V10.5C11.25 10.0875 11.5875 9.75 12 9.75ZM12 7.875C11.3789 7.875 10.875 7.37109 10.875 6.75C10.875 6.12891 11.3789 5.625 12 5.625C12.6211 5.625 13.125 6.12891 13.125 6.75C13.125 7.37109 12.6234 7.875 12 7.875Z"
          fill="#DC2626"
        />
      </g>
      <defs>
        <clipPath id="clip0_9420_94208">
          <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InfoCircleIcon
