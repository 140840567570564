import React, { FC, useCallback } from 'react'

import { ConnectedProps, connect } from 'react-redux'
import { AppDispatch, RootState } from 'App'
import { bindActionCreators } from 'redux'

import { Columns } from 'types'

import UserTableHeader from 'components/Common/UserTableHeader'
import {
  addUserToGroupAction,
  getAdvisorsAccountDetailsUMAction,
  getBusinessesAction,
} from 'store/actions/userManagement'
import { getAdvisorsAccountDetailsUM } from 'store/selectors/userManagement'

import { AdminRightsContainer } from 'pages/Admin/container/UserManagement'
import { TableActionsContainer, UserManagementLayoutContainer } from 'pages/Common/container'

const sortFields = [
  { label: 'Name', value: 'name' },
  { label: 'Date Created', value: 'createdAt', fieldType: 'date' },
  { label: 'Status', value: 'status' },
]

const connector = connect(
  (state: RootState) => ({
    loading: state.loadingStatus.advisorDataLoadingUM,
    advisorAccounts: getAdvisorsAccountDetailsUM(state),
  }),
  (dispatch: AppDispatch) =>
    bindActionCreators(
      {
        getAdvisorsAccountDetailsAction: getAdvisorsAccountDetailsUMAction.STARTED,
        addUserToGroupAction: addUserToGroupAction.STARTED,
        getBusinessesAction: getBusinessesAction.STARTED,
      },
      dispatch
    )
)

interface IAdvisorProps extends ConnectedProps<typeof connector> {}

const Advisors = connector<FC<IAdvisorProps>>(
  ({ loading, advisorAccounts, getAdvisorsAccountDetailsAction, getBusinessesAction }) => {
    const getData = useCallback(() => {
      getBusinessesAction({ allStatuses: false })
      getAdvisorsAccountDetailsAction({ isPaginated: false })
    }, [getAdvisorsAccountDetailsAction, getBusinessesAction])

    return (
      <UserManagementLayoutContainer
        getData={getData}
        loading={loading}
        tableColumns={columns}
        sortFields={sortFields}
        userAccountType="Advisor"
        searchByProperties={['name']}
        userAccounts={advisorAccounts}
        searchInputPlaceholder="Search by name"
        createAccountUrlPath="create-advisor"
        isV2FlagOn={false}
      />
    )
  }
)

export default Advisors

const columns: Columns = [
  {
    text: 'records',
    tableType: 'user',
    targets: 1,
    colSpan: 'col-span-3',
    textDirection: 'left',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return <UserTableHeader className="col-span-3">{params?.name}</UserTableHeader>
    },
  },
  {
    targets: 2,
    textDirection: 'left',
    colSpan: 'col-span-3',
    colFields: [
      {
        field: 'created',
        type: 'date',
        headerName: 'Created',
        sortable: true,
      },
      {
        field: 'activated',
        type: 'string',
        headerName: 'Activated',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <div>{params?.created}</div>
          <div className="capitalize">
            {params?.status === 'active' ? params?.updated : params?.status}
          </div>
        </UserTableHeader>
      )
    },
  },
  {
    targets: 1,
    field: '',
    headerName: 'Admin',
    textDirection: 'left',
    colSpan: 'col-span-2',
    colFields: [
      {
        field: '',
        type: 'string',
        headerName: 'Admin',
        paddingTop: 'pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <div className="p-3 text-sm text-grey whitespace-nowrap self-center col-span-2">
          <AdminRightsContainer params={params} />
        </div>
      )
    },
  },
  {
    targets: 1,
    textDirection: 'right',
    colSpan: 'col-span-4',
    direction: 'justify-end',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <div className="p-3 text-sm text-grey whitespace-nowrap self-center col-span-4">
          <TableActionsContainer userData={params} />
        </div>
      )
    },
  },
]
