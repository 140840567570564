import { useEffect } from 'react'
import { FIELD_NAME, industry } from 'config'
import { FieldValues, UseFormSetValue, UseFormUnregister } from 'react-hook-form'
interface IFromValues {
  businessClassificationOptionId: string
}
const useRegisterIndustryContainer = (
  formValues: IFromValues,
  unregister: UseFormUnregister<FieldValues>,
  isDirty: boolean,
  setValue: UseFormSetValue<FieldValues>,
  isClient?: boolean
) => {
  useEffect(() => {
    if (isDirty) {
      if (isClient) {
        setValue(FIELD_NAME.BUSINESS_LOCATIONS_OPTION_IDS, [])
      }
      if (formValues?.businessClassificationOptionId === industry.foodId) {
        unregister(FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID)
      } else if (formValues?.businessClassificationOptionId === industry.servicesId) {
        unregister(FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS)
      } else {
        unregister(FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID)
        unregister(FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS)
        if (isClient) {
          unregister(FIELD_NAME.STRONG_BUSINESS_AREAS_OPTION_IDS)
          unregister(FIELD_NAME.SUPPORT_AND_HELP_OPTION_IDS)
          unregister(FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS)
          unregister(FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.businessClassificationOptionId])
}
useRegisterIndustryContainer.defaultProps = {
  isClient: true,
}
export default useRegisterIndustryContainer
