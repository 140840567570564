import { MessageUIComponentProps } from 'stream-chat-react'
import React from 'react'

import './CustomMessage.css'
import { MessageSimple } from './MessageSimple'

const CustomMessage = (props: MessageUIComponentProps) => {
  return (
    <>
      <MessageSimple {...props} />
    </>
  )
}

export default CustomMessage
