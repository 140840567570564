import React from 'react'

function Reports(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.6875 3.19141C7.6875 1.48278 9.07262 0.0976562 10.7812 0.0976562H14.2188C15.9273 0.0976562 17.3125 1.48278 17.3125 3.19141V5.62516H21.7812C23.4898 5.62516 24.875 7.01026 24.875 8.71891V23.8439C24.875 24.4135 24.4133 24.8752 23.8438 24.8752H1.15625C0.586711 24.8752 0.125 24.4135 0.125 23.8439V12.8439C0.125 11.1352 1.51012 9.7502 3.21875 9.7502H7.6875V3.19141ZM9.75 22.8127H15.25V3.19141C15.25 2.62187 14.7883 2.16016 14.2188 2.16016H10.7812C10.2117 2.16016 9.75 2.62185 9.75 3.19141V22.8127ZM7.6875 11.8127H3.21875C2.64921 11.8127 2.1875 12.2744 2.1875 12.8439V22.8127H7.6875V11.8127ZM17.3125 22.8127H22.8125V8.71891C22.8125 8.14935 22.3508 7.68766 21.7812 7.68766H17.3125V22.8127Z"
        fill="white"
      />
    </svg>
  )
}

export default Reports
